import { SerializedStyles } from '@emotion/core'
import React, { useRef } from 'react'
import { useEffect } from 'src/hooks'
import { Memoized } from 'src/hooks/dependencies'

type InfiniteScrollerProps = {
  hasMore: boolean
  isLoading: boolean
  loadMore: Memoized<() => void>
  loader?: React.ReactNode
  endMessage?: React.ReactNode
  wrapperStyle?: SerializedStyles
  loaderStyle?: SerializedStyles
}
/**
 * NOTE: We have a twin in soc-editor
 */
const InfiniteScroller: React.FC<InfiniteScrollerProps> = props => {
  const {
    children,
    hasMore,
    isLoading,
    loadMore,
    loader,
    endMessage,
    wrapperStyle,
    loaderStyle,
  } = props

  const observerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && !isLoading) {
        loadMore()
      }
    })

    const currentRef = observerRef.current

    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [hasMore, isLoading, loadMore])

  return (
    <div css={wrapperStyle ?? undefined}>
      {children}
      {!hasMore && !isLoading && (
        <div>{endMessage ?? 'No more items to load.'}</div>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div ref={observerRef} style={{ height: '5px', width: '5px' }} />
      </div>
      {hasMore && isLoading && (
        <div css={loaderStyle}>{loader ?? 'Loading...'}</div>
      )}
    </div>
  )
}

export default InfiniteScroller
