import { ActionCreator } from '@sendoutcards/core'
import { DrawerState } from '../types/act'

export const setIsDigitalCard = ActionCreator(
  'SET_IS_DIGITAL_CARD',
  (isDigitalCard: boolean) => ({ isDigitalCard }),
)

export const setDuvetState = ActionCreator(
  'SET_DUVET_STATE',
  (duvetState: DrawerState) => ({ duvetState }),
)
