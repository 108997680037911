import React, { Fragment } from 'react'
import ResizeDetector from 'react-resize-detector'
import { Flex, Icon } from '@sendoutcards/quantum-design-ui'
// @src imports
import { Button } from 'src/chrome'
import { CardType } from 'src/graphql/generated/graphql'
// relative imports
import Panel from './Panel/Panel'
import ImageElement from '../components/ImageElement'
import {
  ComputedFullBleed,
  getSinglePanelIndex,
  getSinglePanelIndexMap,
  variations as getVariations,
} from '../../redux/selectors/editor'

import styles from '../styles/components/panel.module.scss'
import { Api } from '../api'
import { Steps } from '../types'

type Props = {
  api: Api
  fullBleed: ComputedFullBleed
}

const elementDisplayStyles: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}

const FullBleed: React.FC<Props> = props => {
  const { api, fullBleed } = props
  const {
    step,
    panelView,
    fullBleeds,
    card,
    selectPanel,
    selectFullBleed,
    updateFullBleed,
    resetStep,
    resolveLocation,
    isMobile,
  } = api

  // TODO: React Query Refactor - not sure if this is how we want to handle undefined results
  if (!card) throw Error('No card provided!')

  const { fullBleedIndex, panelIndex } = step
  const numFullBleeds = fullBleeds.length - 1
  const numPanels =
    card.type === 'THREE_PANEL'
      ? numFullBleeds + 2
      : card.type === 'TWO_PANEL' || card.type === 'TWO_PANEL_BIG'
      ? numFullBleeds + 1
      : numFullBleeds

  const backgroundElement = fullBleed.backgroundElement

  const singlePanelIndex = getSinglePanelIndex(
    fullBleeds,
    fullBleedIndex,
    panelIndex,
  )

  const currentIndex: number =
    panelView === 'fullbleed' ? fullBleedIndex : singlePanelIndex

  const setPanelIndex =
    panelView === 'fullbleed'
      ? selectFullBleed
      : (index: number) => {
          const { fullBleedIndex, panelIndex } = getSinglePanelIndexMap(
            fullBleeds,
          )[index]

          selectPanel(fullBleedIndex, panelIndex)
        }

  const removeSpan = () =>
    updateFullBleed(step.fullBleedIndex, fullBleed => ({
      ...fullBleed,
      backgroundElement: fullBleed.backgroundElement && {
        ...fullBleed.backgroundElement,
        image: {
          image: null,
          position: { x: 0.5, y: 0.5 },
          scale: 1,
          filter: null,
        },
      },
    }))

  const isLastPanel =
    currentIndex < (panelView === 'fullbleed' ? numFullBleeds : numPanels)

  return (
    <Flex>
      <div
        className={`${
          card.isHorizontal
            ? `${styles.fullBleed} ${styles.horizontalCard}`
            : styles.fullBleed
        } ${step.type !== Steps.Idle && styles.openDrawer}`}
      >
        <Flex position="relative" flexDirection="column-reverse">
          <Fragment>
            {panelView === 'fullbleed' &&
              backgroundElement &&
              (!card.detailedSendableCard ||
                (card.detailedSendableCard &&
                  !card.detailedSendableCard.insideRightImage)) && (
                <Fragment>
                  <div
                    className={
                      card.isHorizontal
                        ? `${styles.fullBleedPanelContainer} ${styles.horizontalContainer}`
                        : styles.fullBleedPanelContainer
                    }
                    style={{
                      ...(panelIndex === null
                        ? {
                            zIndex: 102,
                            background: '#fff',
                          }
                        : {}),
                    }}
                  >
                    <div className={styles.panelContent}>
                      <ImageElement
                        api={api}
                        element={backgroundElement}
                        elementDisplayStyles={elementDisplayStyles}
                      />
                    </div>
                  </div>
                  {panelIndex === undefined &&
                    backgroundElement.image &&
                    backgroundElement.image.image && (
                      <div className={styles.imageSpanActions}>
                        <Button
                          title={'Remove'}
                          className={styles.spanningAction}
                          onClick={() => {
                            removeSpan()
                            resetStep()
                          }}
                        />
                        <Button
                          title={'Done'}
                          className={styles.spanningAction}
                          onClick={() => resetStep()}
                        />
                      </div>
                    )}
                </Fragment>
              )}
          </Fragment>
          <Flex cursor="pointer" width="100%" justifyContent={'space-evenly'}>
            <Flex
              position="relative"
              id={'carrot_left_btn'}
              onClick={
                currentIndex !== 0
                  ? () => setPanelIndex(currentIndex - 1)
                  : undefined
              }
            >
              <Flex
                width={'40px'}
                height={'40px'}
                justifyContent={'center'}
                alignItems={'center'}
                backgroundColor={currentIndex === 0 ? 'lightGrey' : 'black'}
                borderRadius={'medium'}
                boxShadow={'mediumLight'}
                cursor={currentIndex !== 0 ? 'pointer' : 'not-allowed'}
              >
                <Icon name="leftChevron" size={30} primaryColor={'white'} />
              </Flex>
            </Flex>
            <div
              id={'carrot_right_btn'}
              onClick={
                isLastPanel ? () => setPanelIndex(currentIndex + 1) : undefined
              }
            >
              <Flex
                width={'40px'}
                height={'40px'}
                justifyContent={'center'}
                alignItems={'center'}
                backgroundColor={isLastPanel ? 'black' : 'lightGrey'}
                borderRadius={'medium'}
                boxShadow={'mediumLight'}
                cursor={isLastPanel ? 'pointer' : 'not-allowed'}
              >
                <Icon name="rightChevron" size={30} primaryColor="white" />
              </Flex>
            </div>
          </Flex>
          <ResizeDetector
            handleWidth={true}
            handleHeight={true}
            querySelector={'#editor-window'}
          >
            {({
              width: fullContainerWidth,
              height: fullContainerHeight,
            }: {
              width: number
              height: number
            }) => {
              // Cap the width & height so it doesn't disappear on mobile
              const containerWidth = Math.max(fullContainerWidth - 100, 300)
              const containerHeight = Math.max(fullContainerHeight - 75, 500)

              const width =
                panelView === 'fullbleed'
                  ? fullBleed.width
                  : fullBleed.panels[0].width * fullBleed.width
              const height =
                panelView === 'fullbleed'
                  ? fullBleed.height
                  : fullBleed.panels[0].height * fullBleed.height
              const maxByHeight = {
                height: containerHeight,
                width: (containerHeight / height) * width,
              }
              const maxByWidth = {
                height: (containerWidth / width) * height,
                width: containerWidth,
              }
              const canHeightFit =
                maxByHeight.height <= containerHeight &&
                maxByHeight.width <= containerWidth
              const canWidthFit =
                maxByWidth.height <= containerHeight &&
                maxByWidth.width <= containerWidth

              const heightArea = maxByHeight.height * maxByHeight.width
              const widthArea = maxByWidth.height * maxByWidth.width

              const shouldLimitHeight =
                canHeightFit && canWidthFit
                  ? heightArea > widthArea
                  : canHeightFit

              const calculatedWidth = shouldLimitHeight
                ? maxByHeight.width
                : maxByWidth.width

              const hasVariations = getVariations(card)

              const isInnerFullBleed =
                panelView === 'fullbleed' &&
                resolveLocation(card.type) !== 'Front' &&
                resolveLocation(card.type) !== 'Back'

              const isHorizontalCard = card.isHorizontal

              const isThreePanelCard = card.type === 'THREE_PANEL'

              return (
                <div
                  style={{
                    width:
                      hasVariations && !isMobile
                        ? calculatedWidth - 110
                        : hasVariations && isMobile && isInnerFullBleed
                        ? calculatedWidth + 40
                        : hasVariations && isMobile
                        ? calculatedWidth - 30
                        : isInnerFullBleed && !isHorizontalCard
                        ? calculatedWidth + 20
                        : isThreePanelCard &&
                          isHorizontalCard &&
                          isInnerFullBleed
                        ? calculatedWidth - 50
                        : calculatedWidth - 30,
                    paddingBottom: '1.5rem',
                  }}
                  className={`
                    ${styles.fullBleedPanels}
                    ${card.isHorizontal ? styles.horizontalCard : ''}
                    ${
                      card.type === CardType.ThreePanel &&
                      fullBleedIndex === 1 &&
                      !card.isHorizontal
                        ? styles.threePanelFullBleedPortrait
                        : card.type === CardType.ThreePanel &&
                          fullBleedIndex === 1 &&
                          card.isHorizontal
                        ? styles.threePanelFullBleedLandscape
                        : ''
                    }
                  `}
                >
                  {fullBleed.panels.map((panel, index) => (
                    <Panel
                      key={panel.location}
                      api={api}
                      fullBleed={fullBleed}
                      panel={panel}
                      index={panel.location}
                      hasBackgroundSpread={
                        !!(
                          backgroundElement &&
                          backgroundElement.image &&
                          backgroundElement.image.image
                        )
                      }
                    />
                  ))}
                </div>
              )
            }}
          </ResizeDetector>
        </Flex>
      </div>
    </Flex>
  )
}

export default FullBleed
