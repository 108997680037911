import Action from 'src/redux/action'
import * as actions from 'src/redux/actions/act'

import { DrawerState } from '../types/act'

export type ACTState = {
  duvetState: DrawerState
}

const initialState: ACTState = {
  duvetState: 'previewCollapsed',
}

const act = (state: ACTState = initialState, action: Action): ACTState => {
  switch (action.type) {
    case actions.setDuvetState.type:
      return {
        ...state,
        duvetState: action.duvetState,
      }
    default:
      return state
  }
}

export default act
