import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type SendTypeScreenProps = {
  title: string
  imageUrl: string
  cost: string
  isSelected: boolean
  onSelect: (isSelected: boolean) => void
}

export const SendTypeScreen = (props: SendTypeScreenProps) => {
  const { title, imageUrl, cost, isSelected, onSelect } = props
  const primaryFont = 'Montserrat, san serif'

  const handleToggle = () => {
    onSelect(!isSelected)
  }

  return (
    <Flex
      position="relative"
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      inset="1rem"
      rowGap="x1_5"
      backgroundColor={isSelected ? '#fff' : '#c4c4c4d9'}
      flexDirection="column"
      style={{
        borderRadius: '.5rem',
        boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
        border: '2px solid rgba(233, 233, 233, 0.70)',
        opacity: isSelected ? 1 : 0.6,
      }}
      onClick={handleToggle}
    >
      <Flex
        position="absolute"
        width="fit-content"
        height="fit-content"
        right="6px"
        top="6px"
        backgroundColor={isSelected ? '#f2f2f2' : '#c4c4c4d9'}
        inset={{ horizontal: '.5rem' }}
        style={{ borderRadius: '30px' }}
      >
        <Text
          content={cost}
          type="body"
          style={{
            fontFamily: primaryFont,
            fontSize: '.625rem',
            fontWeight: 700,
            color: '#6B7280',
          }}
        />
      </Flex>
      <img
        src={imageUrl}
        alt="#"
        style={{
          width: '3.25rem',
          height: '4rem',
          boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
        }}
      />
      <Text
        content={title}
        type="body"
        whiteSpace="pre"
        alignment="center"
        lineHeight={1.8}
        style={{
          fontFamily: primaryFont,
          fontSize: '.625rem',
          fontWeight: 700,
          color: '#404040',
        }}
      />
    </Flex>
  )
}
