import React from 'react'
import { SVGProps } from './types'

export const Chevron: React.FC<SVGProps> = ({
  size = 24,
  color = '#3BB07B',
  onClick,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7722 13.7657C16.4685 14.0781 15.976 14.0781 15.6723 13.7657L10 7.93137L4.32775 13.7657C4.02401 14.0781 3.53155 14.0781 3.22781 13.7657C2.92406 13.4533 2.92406 12.9467 3.22781 12.6343L9.45003 6.23431C9.75377 5.9219 10.2462 5.9219 10.55 6.23431L16.7722 12.6343C17.0759 12.9467 17.0759 13.4533 16.7722 13.7657Z"
      />
    </svg>
  )
}
