import React from 'react'
import { useGetDigitalCardByToken } from 'src/react_query'
import ViewAct from '../components/ViewAct/ViewAct'
import { ActRoute } from '../routes/ActRoute'
import { useQueryParams } from 'src/hooks'
import { LoadingSpinner } from '@sendoutcards/quantum-design-ui'

type ActProps = {
  route: ActRoute
}

const Act: React.FC<ActProps> = () => {
  const { token } = useQueryParams()

  const digitalCardQuery = useGetDigitalCardByToken({ token: token ?? '' })

  return digitalCardQuery.data?.card ? (
    <ViewAct card={digitalCardQuery.data?.card} />
  ) : (
    <LoadingSpinner size="large" />
  )
}

export default Act
