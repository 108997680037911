import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type SendTypeToggleProps = {
  onToggle: (isSelected: boolean) => void
  isSelected: boolean
}

export const SendTypeToggle = (props: SendTypeToggleProps) => {
  const { isSelected, onToggle } = props

  const handleToggle = () => {
    onToggle(!isSelected)
  }

  return (
    <Flex
      backgroundColor="#d0d0d029"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="circle"
      inset="x_5"
      width="100%"
      maxWidth="150px"
      onClick={handleToggle}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        backgroundColor={isSelected ? '#fff' : 'transparent'}
        inset={{ vertical: 'x_75', horizontal: 'x1_5' }}
        borderRadius="circle"
        cursor="pointer"
        opacity={isSelected ? 1 : 0.5}
        style={{
          transition: 'background-color 0.3s ease',
          backgroundColor: isSelected ? '#fff' : 'transparent',
        }}
      >
        <Text
          type="footnote"
          content="Instant"
          weight="semiBold"
          style={{ fontFamily: 'Montserrat, san-seriff' }}
        />
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        backgroundColor={!isSelected ? '#fff' : 'transparent'}
        inset={{ vertical: 'x_75', horizontal: 'x1_5' }}
        borderRadius="circle"
        cursor="pointer"
        opacity={!isSelected ? 1 : 0.5}
        style={{
          transition: 'background-color 0.3s ease',
          backgroundColor: !isSelected ? '#fff' : 'transparent',
        }}
      >
        <Text
          type="footnote"
          content="Printed"
          weight="semiBold"
          style={{ fontFamily: 'Montserrat, san-seriff' }}
        />
      </Flex>
    </Flex>
  )
}
