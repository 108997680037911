import { ThreeDCardPreview } from '@sendoutcards/editor'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { useRef } from 'react'
import { CardWithoutCostFragment, Panel } from 'src/graphql/generated/graphql'
import { mapPanelImages } from 'src/helpers/threeDCard/mapPanelImages'
import { useActions, useEffect, useSelector, useState } from 'src/hooks'
import { DuvetV2 } from '../DuvetV2/DuvetV2'
import useSnapPoints from 'src/hooks/useSnapPoints'
import { RecipientView } from '../RecipientView/RecipientView'
import { DuvetHeader } from '../DuvetHeader/DuvetHeader'

type ViewActProps = {
  card: CardWithoutCostFragment
}

const ViewAct: React.FC<ViewActProps> = props => {
  const { card } = props
  const controlContainerRef = useRef<HTMLDivElement>(null)
  const [shouldPortalControls, setShouldPortalControls] = useState(false)

  const actions = useActions()

  const { duvetState } = useSelector(state => state.act)

  const { snapPoints, openValue, handleSnap } = useSnapPoints(
    'recipientView',
    duvetState,
  )

  const dummyRecipient = {
    title: 'Happy Birthday Mom!',
    description:
      'Hey Mom, thought of you hope you enjoy this card, if you did should try making one, its super fun try it out',
    recipient: {
      firstname: 'Junior',
      lastname: 'Garcia',
      imageUrl:
        'https://images.unsplash.com/photo-1738739905613-590d01442686?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    primaryAction: {
      title: 'Request Printed Copy',
      onClick: () => console.log('copy i gues'),
    },
    secondaryAction: {
      title: 'Send Your Own',
      onClick: () => console.log('send it yourself'),
    },
  }

  const renderDrawerContent = <RecipientView {...dummyRecipient} />

  useEffect(() => {
    if (controlContainerRef.current) {
      setShouldPortalControls(true)
    }
  }, [])

  return (
    <div
      style={{
        height: 'calc(100dvh - 65px)',
        width: '100dvw',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FEFEFE',
      }}
    >
      <div style={{ width: '100%' }}>
        <div
          style={{
            width: '100%',
            padding: '0.75rem 0.5rem',
            backgroundColor: '#F5F6F7',
          }}
        >
          <Text
            type="largeBody"
            content={card.digitalCard?.shareMessageTitle}
            weight="extraBold"
            alignment="center"
          />
        </div>
      </div>
      {shouldPortalControls && (
        <Flex outset={{ top: 'x4' }}>
          <ThreeDCardPreview
            cardType={card.type}
            orientation={card.isHorizontal ? 'LANDSCAPE' : 'PORTRAIT'}
            panels={mapPanelImages(
              card.panels as Panel[],
              !!card.flapPreviewUrl,
            )}
            height="calc(100dvh - 210px)"
            controlType={{ type: 'Portal', portalId: 'controls3DCard' }}
          />
        </Flex>
      )}
      <div
        style={{
          marginTop: 'auto',
          marginBottom: '1rem',
          width: '100%',
          padding: '0 1rem',
          zIndex: 10,
          position: 'relative',
        }}
      >
        <div
          ref={controlContainerRef}
          id="controls3DCard"
          style={{ marginBottom: '2rem' }}
        />
      </div>
      <DuvetV2
        snapPoints={snapPoints}
        onSnap={handleSnap}
        onClose={() => {
          actions.setDuvetState('navigation')
        }}
        openValue={openValue}
        isOpen={true}
        shouldShowCloseButton={true}
      >
        <DuvetHeader
          shouldShowMoreDetails={false}
          onShowDetails={() => console.log('show you the world')}
        />
        {renderDrawerContent}
      </DuvetV2>
    </div>
  )
}

export default ViewAct
