import { Panel } from 'src/graphql/generated/graphql'

export const mapPanelImages = (panels: Panel[], isThreePanel: boolean) => {
  // TODO: What are the names of the flap panels
  return {
    Front: panels[0].previewUrl,
    InsideLeft: panels.find(panel => {
      return panel.name === 'Inside Left' && !panel.isFullBleed
    })?.previewUrl,
    InsideRight: panels.find(panel => panel.name === 'Inside Right')
      ?.previewUrl,
    Back: panels.find(panel => panel.name === 'Back')?.previewUrl,
    FlapIn: isThreePanel ? panels[4].previewUrl : '',
    FlapOut: isThreePanel ? panels[5].previewUrl : '',
  }
}
