import React from 'react'

import {
  useActions,
  useAppcues,
  useCallback,
  useEffect,
  useFlagWithUseQueries,
  useIsAddressValid,
  useMemo,
  useMutations,
  useSelector,
  useState,
} from 'src/hooks'

import { AddOrderCardRoute } from 'src/orders/routes/AddOrderCardRoute'

// import { perform } from 'src/app/api'
import { isMixedPurchase } from 'src/helpers/payments'
import {
  OrderPaymentInfo,
  PurchaseTypes,
} from 'src/payments/containers/Payment'
import { getBlankPanels, hasBlankPanels } from 'src/orders/panelValidation'
import { AddressBook, Groups } from 'src/contacts/components'

import {
  AccountInput,
  AddressFragment,
  AddressInput,
  Amount,
  CardFragment,
  CardPaperType,
  ContactFragment,
  Currency,
  DetailedOrderFragment,
  UserType,
} from 'src/graphql/generated/graphql'
import {
  getOrder,
  useCreditsBalance,
  useUpdateAccount,
  useUpdateCard,
  useUpdateOrder,
} from 'src/react_query'
import { drawerState } from './drawerState'

// relative imports

import { Set } from 'immutable'
import { buildUpdateOrderInput } from 'src/redux/reducers/orders'
import { areAddressesEqual } from 'src/helpers'
import { PartialLine } from 'src/helpers/multitouch'
import { AddCardToGiftRoute } from './routes/AddCardToGiftRoute'
import { AddGiftToCardRoute } from './routes/AddGiftToCardRoute'
import { EditOrderCardRoute } from './routes/EditOrderCardRoute'
import { ReplaceOrderCardRoute } from './routes/ReplaceOrderCardRoute'
import deepEqual from 'deep-equal'
import { didCardFlattenFail } from './helpers'
import omit from 'lodash/omit'

const toggle = (prevState: boolean): boolean => !prevState

const useApi = (orderId: string) => {
  const orders = useSelector(state => state.orders)
  const order = orders.order
  const mutations = useMutations()
  const updateAccountMutation = useUpdateAccount()
  const updateOrderMutation = useUpdateOrder()
  const updateCardMutation = useUpdateCard()

  const account = useSelector(state => state.user.account)
  const isAddressValid = useIsAddressValid(
    order?.returnAddress ? order.returnAddress : undefined,
  )

  const contacts = order?.contacts
  const groups = order?.groups

  const recipientIds = useMemo(
    () =>
      Set([
        ...(contacts?.map(contact => contact.id) ?? []),
        ...(groups?.flatMap(group => group.members.map(member => member.id)) ??
          []),
      ]),
    [groups, contacts],
  )

  const actions = useActions()

  const {
    bulkOrderFlow: canShowBulkOrderOptions,
    newCardEditor: canShowNewCardEditor,
  } = useFlagWithUseQueries()

  // Only the Rep type RC should have BD credits
  const shouldLoadCredits = !!account && account.type === UserType.Rc

  const {
    data: creditsResponse,
    isLoading: isLoadingCreditsBalance,
  } = useCreditsBalance(shouldLoadCredits)

  const isLoadingCredits = shouldLoadCredits && isLoadingCreditsBalance

  const [isSaveAsModalOpen, setIsSaveAsModalOpen] = useState(false)
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const [paymentInfo, setPaymentInfo] = useState<OrderPaymentInfo>()
  const [isUpsaleModalOpen, setIsUpsaleModalOpen] = useState(false)
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isImportAlertOpen, setIsImportAlertOpen] = useState(false)
  const [isPaidSuccessfully, setIsPaidSuccessfully] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const [isSendDelayFormOpen, setIsSendDelayFormOpen] = useState(false)
  const [isShowingCardPreview, setIsShowingCardPreview] = useState(false)
  const [
    shouldShowEditorChoiceModal,
    setShouldShowEditorChoiceModal,
  ] = useState(false)

  const [shouldBlink, setShouldBlink] = useState<{
    returnAddress?: boolean
    addRecipients?: boolean
  }>({
    returnAddress: false,
    addRecipients: false,
  })

  const didFlattenFail = async (): Promise<boolean> => {
    const orderCards = order?.lines.map(line => {
      return line.card
    })

    if (orderCards && orderCards.length > 0) {
      const checkArr = await Promise.all(
        orderCards.map(card => didCardFlattenFail(card)),
      )
      return checkArr.some(isUnavailable => isUnavailable)
    }
    return false
  }

  const credits = useCallback(
    (): Amount =>
      creditsResponse
        ? creditsResponse
        : {
            __typename: 'Amount',
            amount: 0,
            currency: Currency.Unavailable,
            description: '0.00',
          },
    [creditsResponse],
  )

  const createGroup = useCallback(
    async (group: { name: string; members: string[] }) => {
      try {
        const result = await mutations.createGroup({
          group: {
            name: group.name,
            members: group.members,
          },
        })
        actions.createdGroup(result.createGroup.group)
      } catch (error) {
        console.error(error)
      }
    },
    [mutations, actions],
  )

  const createCampaign = useCallback(
    async (name: string) => {
      try {
        mutations.createCampaign({
          campaign: {
            name,
            order: orderId,
          },
        })
        actions.saveOrder()
      } catch (error) {
        console.error(error)
      }
    },
    [mutations, orderId, actions],
  )

  const handleDelete = useCallback(() => {
    setIsDeleteModalOpen(toggle)
    setIsDeleting(true)
    mutations.deleteOrder({ id: orderId })
    actions.deleteOrder(true)
    setTimeout(() => {
      actions.openCatalog()
    }, 1000)
  }, [mutations, actions, orderId])

  // Drawer
  const drawerTriggerIcon = useMemo(() => {
    switch (orders.drawerState) {
      case drawerState.createAddress:
      case drawerState.addressDrawer:
        return ['ADDUSER', 'ADDUSER']
      default:
        return null
    }
  }, [orders.drawerState])

  const drawerTriggerText = useMemo(() => {
    switch (orders.drawerState) {
      case drawerState.createAddress:
      case drawerState.addressDrawer:
        return ['Add New Address']
      default:
        return ['']
    }
  }, [orders.drawerState])

  const drawerActionTop = useMemo(() => {
    switch (orders.drawerState) {
      case drawerState.createAddress:
      case drawerState.addressDrawer:
        return [actions.openCreateAddress]
      default:
        return []
    }
  }, [orders.drawerState, actions])

  const drawerContent = useMemo(() => {
    switch (orders.drawerState) {
      case drawerState.createAddress:
      case drawerState.addressDrawer:
        return <AddressBook />
      case drawerState.groupsDrawer:
        return <Groups />
      default:
        return null
    }
  }, [orders.drawerState])

  const onUpdateAddress = async (testAddress: AddressFragment) => {
    setIsAddressModalOpen(false)
    await updateAccountMutation.mutateAsync({
      account: {
        shippingAddress: {
          ...(account?.shippingAddress as AddressInput),
          ...testAddress,
        },
      },
    })
    saveReturnAddress(testAddress)
    handleOrderSend(false)
  }

  const removeLineFromOrder = (line: PartialLine) => {
    if (order) {
      if (order.lines.length > 1) {
        actions.removeLine(line)
      } else {
        actions.clearOrder()
        actions.openCatalog()
      }
    }
  }

  const handleRemoveCardFromLine = (line?: PartialLine) => {
    if (line) {
      const shouldNotRemoveEntireLine = !!line.giftVariation
      if (shouldNotRemoveEntireLine) {
        actions.removeCardFromLine(line)
      } else {
        removeLineFromOrder(line)
      }
    }
  }

  const handleOrderSend = useCallback(
    (shouldValidateAddress: boolean) => {
      if (!order) {
        return
      }
      if (!isAddressValid && shouldValidateAddress) {
        setIsAddressModalOpen(true)
      } else {
        const returnAddress = order && order.returnAddress
        const hasNoContactsAttached =
          order.contacts.length <= 0 &&
          order.groups.length <= 0 &&
          !order.isBulk

        const hasBulkShippingAddress =
          order.isBulk && order.bulkUpgradeShippingAddress

        if (hasNoContactsAttached && !order.isBulk) {
          setShouldBlink({
            addRecipients: true,
          })
        } else if (
          orders.isSaved &&
          account &&
          (!hasNoContactsAttached || hasBulkShippingAddress) &&
          !isLoadingCredits
        ) {
          setPaymentInfo({
            items: [order],
            purchaseType: PurchaseTypes.ORDER,
            currencyNeeded: isMixedPurchase(order, account, credits(), false),
          })
          setIsPaymentModalOpen(true)
        } else if (!returnAddress) {
          setShouldBlink({
            returnAddress: true,
          })
        }
      }
    },
    [account, order, orders.isSaved, credits, isAddressValid, isLoadingCredits],
  )

  const handleOrderVerification = useCallback(() => {
    if (!order) {
      return
    }
    const allValidatedPanels = order.lines.map(line =>
      getBlankPanels(line.card ?? null),
    )

    const hasSomeBlankPanels = allValidatedPanels.some(card =>
      hasBlankPanels(card),
    )

    if (hasSomeBlankPanels) {
      actions.setOrderUiContext('CONFIRM_BLANK_PANELS')
    } else {
      handleOrderSend(true)
    }
  }, [handleOrderSend, order, actions])

  const toggleAddressBlinker = useCallback((value: boolean | null) => {
    setShouldBlink(prevState => ({
      returnAddress: value !== null ? value : !prevState.returnAddress,
    }))
  }, [])

  const closeImportAlert = useCallback(() => {
    setIsImportAlertOpen(false)
  }, [])

  const handleImportContacts = useCallback(
    (contacts: ContactFragment[]) => {
      if (!order) {
        return
      }
      const mappedContacts = contacts.map((contact): {
        __typename: 'Contact'
        id: string
      } => ({
        __typename: 'Contact',
        id: contact.id,
      }))
      actions.updateRecipients(
        order.contacts.concat(mappedContacts),
        order.groups,
      )

      setIsImportModalOpen(toggle)
    },
    [order, actions],
  )

  const handleSubmit = useCallback(
    (names: {
      campaign: string
      group: {
        name: string
        members: string[]
      }
    }) => {
      if (names.campaign !== '') {
        createCampaign(names.campaign)
      }
      if (names.group.name !== '') {
        createGroup(names.group)
      }

      setIsSaveAsModalOpen(toggle)
    },
    [createCampaign, createGroup],
  )

  const handleUpdate = useCallback(
    (updatedOrder: DetailedOrderFragment) => {
      if (!order) {
        return
      }
      actions.savedOrder(updatedOrder)
    },
    [order, actions],
  )

  const handleUpdatePaperType = async (
    card: CardFragment,
    paperType: CardPaperType,
  ) => {
    await updateCardMutation.mutateAsync({ card: { id: card.id, paperType } })
    actions.updateOrderPaperType(paperType)
  }

  const handleAddGiftToOrderLine = (
    line: PartialLine,
    lineIndex: number,
    modifyOrder: (cb: () => void) => void,
  ) => {
    const lineIndexStr = lineIndex.toString()
    modifyOrder(() => {
      actions.setActiveLine(line, lineIndex)
      if (order) actions.openOrder(order.id, AddGiftToCardRoute(lineIndexStr))
    })
  }

  const handleRemoveGiftFromOrderLine = (
    line: PartialLine,
    modifyOrder: (cb: () => void) => void,
  ) =>
    modifyOrder(() => {
      const shouldNotRemoveEntireLine = !!line.card
      if (shouldNotRemoveEntireLine) {
        actions.deselectGiftFromLine(line)
      } else {
        removeLineFromOrder(line)
      }
    })

  const handleAddCardToOrderLine = (
    line: PartialLine,
    index: number,
    modifyOrder: (cb: () => void) => void,
  ) => {
    const indexStr = index.toString()
    modifyOrder(() => {
      actions.setActiveLine(line, index)
      if (order) actions.openOrder(order.id, AddCardToGiftRoute(indexStr))
    })
  }

  const toggleSendDelayModal = () =>
    setIsSendDelayFormOpen(!isSendDelayFormOpen)

  const saveReturnAddress = async (returnAddress: AddressFragment) => {
    actions.updateReturnAddress(returnAddress)
    const mutableAccountInput: AccountInput = {}
    if (
      returnAddress.firstName &&
      account?.firstName !== returnAddress.firstName
    ) {
      mutableAccountInput.firstName = returnAddress.firstName
    }
    if (
      returnAddress.lastName &&
      account?.lastName !== returnAddress.lastName
    ) {
      mutableAccountInput.lastName = returnAddress.lastName
    }
    if (
      !account?.shippingAddress ||
      !areAddressesEqual(returnAddress, account.shippingAddress)
    ) {
      mutableAccountInput.shippingAddress = omit(returnAddress, '__typename')
    }
    if (!deepEqual(mutableAccountInput, {} as AccountInput) && account) {
      try {
        const {
          updateAccount: { account: updatedAccount },
        } = await updateAccountMutation.mutateAsync({
          account: mutableAccountInput,
        })
        actions.updatedAccount(updatedAccount)
        window._cio.identify({
          id: account.id,
          first_name: updatedAccount.firstName,
          last_name: updatedAccount.lastName,
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleConvertCardAndUpdateOrder = async (
    newEditorCard: CardFragment,
    prevCardId: string,
  ) => {
    if (order) {
      try {
        const orderInput = buildUpdateOrderInput(order)
        const {
          updateOrder: { order: updatedOrder },
        } = await updateOrderMutation.mutateAsync({
          order: {
            ...orderInput,
            lines: (orderInput.lines || []).map(line => ({
              ...line,
              card: line.card === prevCardId ? newEditorCard.id : line.card,
              sendDelay: {
                ...line.sendDelay,
                __typename: undefined,
              },
            })),
          },
        })
        actions.openOrder(updatedOrder.id, EditOrderCardRoute(newEditorCard.id))
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleAddCard = () => {
    actions.openOrder(orderId, AddOrderCardRoute())
  }

  const handleReplaceCard = (cardId: string) => {
    actions.openOrder(orderId, ReplaceOrderCardRoute(cardId))
  }

  const handleOpenDrawer = useCallback(() => {
    actions.openAddressDrawer()
    const mutableContainer: HTMLElement | null = document.querySelector('body')
    if (mutableContainer) {
      mutableContainer.style.overflowY = 'hidden'
    }
  }, [actions])

  const handleCloseDrawer = useCallback(() => {
    const mutableContainer: HTMLElement | null = document.querySelector('body')
    if (mutableContainer) {
      mutableContainer.style.overflowY = 'initial'
    }
    actions.closeDrawer()
  }, [actions])

  useAppcues('-L9cxJNukeioPpZ2vcuZ')

  // TODO: React Query Refactor we should not need to manually trigger a reload anymore
  // update mutations should be invalidating the cache
  // This might cause issues since the order is in Redux!

  const reloadOrder = useCallback(() => {
    ;(async () => {
      const result = await getOrder({ id: orderId })
      actions.loadedOrder(result.order)
    })()
  }, [actions, orderId])

  const reloadAndOpenOrder = async () => {
    const result = await getOrder({ id: orderId })
    actions.loadedOrder(result.order)
    actions.openOrder(result.order.id)
  }

  const handleRemoveSendDelayRecipients = () => {
    if (!order || !contacts) return
    const invalidRecipients = order.invalidSendDelayContacts.map(
      contact => contact.id,
    )
    const updatedRecipients = contacts.filter(
      contact => !invalidRecipients.includes(contact.id),
    )
    actions.updateRecipients(updatedRecipients, [])
  }

  useEffect(() => {
    if (
      (!order && !orders.loadError && !isDeleting && orderId !== '') ||
      (order && order.id !== orderId) ||
      orders.isLoading
    ) {
      ;(async () => {
        try {
          const result = await getOrder({ id: orderId })
          actions.loadedOrder(result.order)
        } catch (error) {
          if (error instanceof Error) actions.loadedOrderError(error)
          setTimeout(() => {
            actions.clearOrder()
            actions.openCatalog()
          }, 2000)
        }
      })()
    }
  }, [order, orders.loadError, isDeleting, orders.isLoading, actions, orderId])

  useEffect(() => {
    ;(async () => {
      if (orders.isSaving && order) {
        try {
          const result = await updateOrderMutation.mutateAsync({
            order: buildUpdateOrderInput(order),
          })
          handleUpdate(result.updateOrder.order)
        } catch (error) {
          if (error instanceof Error) actions.savedOrderError(error)
        }
      }
    })()
  }, [orders, order, updateOrderMutation, handleUpdate, actions])

  useEffect(() => {
    if (order && contacts && order.invalidSendDelayContacts.length > 0) {
      actions.setShowSendDelayError(true)
    }
  }, [contacts, actions, order])

  return {
    order,
    orders,
    actions,
    reloadOrder, // TODO: React Query Refactor Remove this later
    reloadAndOpenOrder, // TODO: React Query Refactor Remove this later
    isLoadingCredits,
    isDeleting,
    handleAddCard,
    shouldBlink,
    handleOpenDrawer,
    setIsImportModalOpen,
    drawerTriggerText,
    drawerTriggerIcon,
    drawerActionTop,
    handleCloseDrawer,
    drawerContent,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    handleDelete,
    toggleAddressBlinker,
    handleOrderVerification,
    setIsSaveAsModalOpen,
    createGroup,
    handleOrderSend,
    isAddressModalOpen,
    setIsAddressModalOpen,
    onUpdateAddress,
    saveReturnAddress,
    isImportAlertOpen,
    closeImportAlert,
    setIsUpsaleModalOpen,
    isSaveAsModalOpen,
    handleSubmit,
    recipientIds,
    isPaymentModalOpen,
    setIsPaidSuccessfully,
    paymentInfo,
    setIsPaymentModalOpen,
    setPaymentInfo,
    isPaidSuccessfully,
    isUpsaleModalOpen,
    isImportModalOpen,
    handleImportContacts,
    account,
    handleRemoveSendDelayRecipients,
    canShowBulkOrderOptions,
    canShowNewCardEditor,
    handleUpdatePaperType,
    handleRemoveCardFromLine,
    handleAddGiftToOrderLine,
    handleRemoveGiftFromOrderLine,
    handleAddCardToOrderLine,
    handleConvertCardAndUpdateOrder,
    handleReplaceCard,
    toggleSendDelayModal,
    isSendDelayFormOpen,
    isShowingCardPreview,
    setIsShowingCardPreview,
    shouldShowEditorChoiceModal,
    setShouldShowEditorChoiceModal,
    didFlattenFail,
  }
}

export default useApi
export type OrderApiType = ReturnType<typeof useApi>
