import { ThreeDCardPreview } from '@sendoutcards/editor'
import React from 'react'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import { CardWithoutCostFragment, Panel } from 'src/graphql/generated/graphql'
import { mapPanelImages } from 'src/helpers/threeDCard/mapPanelImages'

export interface PriceProps {
  card?: CardWithoutCostFragment
  onClose: () => void
  isOpen?: boolean
  title?: string
}

const Simple3DPreviewDialog: React.FC<PriceProps> = props => {
  const { card, onClose, isOpen = true, title } = props

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      wrapperStyle={{ overflow: 'hidden' }}
      title={title}
    >
      {card && (
        <ThreeDCardPreview
          cardType={card.type}
          orientation={card.isHorizontal ? 'LANDSCAPE' : 'PORTRAIT'}
          panels={mapPanelImages(card.panels as Panel[], !!card.flapPreviewUrl)}
        />
      )}
    </Dialog>
  )
}

export default Simple3DPreviewDialog
