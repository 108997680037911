import { Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Button } from 'src/design_system/molecules/button/Button'

type CardPreviewFooterProps = {
  primaryAction: { title: string; onClick: () => void }
  secondaryAction: { title: string; onClick: () => void }
}

export const CardPreviewFooter = (props: CardPreviewFooterProps) => {
  const { primaryAction, secondaryAction } = props
  const primaryFont = 'Montserrat, san serif'

  return (
    <Flex flexDirection="row" columnGap="x1_5">
      <Button
        padding="8px 12px"
        borderRadius="50rem"
        fill="#fff"
        onClick={secondaryAction.onClick}
      >
        <Icon name="leftChevron" size="small" primaryColor="#6B7280" />
        <Text
          type="body"
          content={secondaryAction.title}
          style={{
            fontFamily: primaryFont,
            fontSize: '.75rem',
            fontWeight: 700,
            color: '#6B7280',
          }}
        />
      </Button>
      <Button
        padding="8px 12px"
        isFullWidth={true}
        borderRadius="50rem"
        fill="#404040"
        onClick={primaryAction.onClick}
      >
        <Text
          type="body"
          content={primaryAction.title}
          style={{
            fontFamily: primaryFont,
            fontSize: '.75rem',
            fontWeight: 700,
            color: '#fff',
          }}
        />
      </Button>
    </Flex>
  )
}
