import React from 'react'
import { ThreeDCardPreview } from '@sendoutcards/editor'
import { Flex, Input, Text } from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/components/Button/Button'
import { CardFragment, Panel } from 'src/graphql/generated/graphql'
import { mapPanelImages } from 'src/helpers/threeDCard/mapPanelImages'
import { useState } from 'src/hooks'
import {
  useCreateDigitalCardToken,
  useUpdateDigitalCard,
} from 'src/react_query'
import { ActSuccess } from '../ActSuccess/ActSuccess'
import { baseURL } from 'src/app/api'

type PrepareActProps = {
  card: CardFragment
  onBack: () => void
}

const PrepareAct: React.FC<PrepareActProps> = props => {
  const { card, onBack } = props

  const createDigitalCardTokenMutation = useCreateDigitalCardToken()
  const updateDigitalCardMutation = useUpdateDigitalCard()

  const digitalCardToken =
    card.digitalCard?.tokens && card.digitalCard?.tokens.length > 0
      ? card.digitalCard.tokens[0].token
      : undefined

  const [title, setTitle] = useState(card.detailedSendableCard?.title ?? '')
  const [shareMessage, setShareMessage] = useState('')
  const [shareToken, setShareToken] = useState<string | undefined>(
    digitalCardToken,
  )

  const createActShareLink = async () => {
    const digitalCardPk = card.digitalCard?.pk
    if (digitalCardPk) {
      await updateDigitalCardMutation.mutateAsync({
        digitalCardPk: digitalCardPk,
        input: { shareMessageTitle: title, shareMessageBody: shareMessage },
      })
      const tolkien = await createDigitalCardTokenMutation.mutateAsync({
        digitalCardPk: digitalCardPk,
      })
      setShareToken?.(tolkien.token)
    }
  }

  return shareToken ? (
    <div style={{ position: 'fixed', top: 0, bottom: 65 }}>
      <ActSuccess
        title="Cogratulations!"
        description="You are about to brighten John's life by following your promptings, with a card and gift that you have successfully purchased!"
        action={{
          title: 'Ready to Share',
          color: '#FFFFFF',
          backgroundColor: '#059669',
          onClick: () => {
            navigator.share({
              title: title,
              text: shareMessage,
              url: `${baseURL}/act/?t=${shareToken}`,
            })
          },
        }}
        cardPreview={{
          frontPanelImageUrl:
            card?.frontPreviewUrl ??
            'https://www.sendoutcards.com/thumbnail/0T0fE4zrS3J-vNGw-7SE8s4OsQU=/fit-in/320x0/system_images/2020/3/6/dea97eed-5a9c-4c73-8d7a-5bc1e3835435.png',
          isHorizontal: card?.isHorizontal ?? false,
        }}
        giftCard={undefined}
      />
    </div>
  ) : (
    <div
      style={{
        height: 'calc(100dvh - 65px)',
        width: '100dvw',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FEFEFE',
      }}
    >
      <div
        style={{
          width: '100%',
          boxShadow: '-1px 3px 12px 0px rgba(20,30,40,0.19)',
          backgroundColor: '#FFF',
          zIndex: 12,
          paddingBottom: '20px',
          position: 'relative',
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '0.75rem 0.5rem',
            backgroundColor: '#F5F6F7',
          }}
        >
          <div
            onClick={onBack}
            style={{ position: 'fixed', cursor: 'pointer' }}
          >
            <Text type="caption" content="< Back" />{' '}
          </div>
          <Text
            type="largeBody"
            content="Prepare to Send ACT"
            weight="extraBold"
            alignment="center"
          />
        </div>
        <div
          style={{
            width: '100%',
            padding: '0.5rem 1rem 0',
          }}
        >
          <Text type="caption" content="Title" weight="bold" />
          <Input
            type="text"
            value={title}
            onChange={setTitle}
            isFullWidth={true}
            height={40}
          />
        </div>
        <div
          style={{
            width: '100%',
            padding: '0.5rem 1rem 0',
          }}
        >
          <Text type="caption" content="Share Message" weight="bold" />
          <Input
            type="text"
            value={shareMessage}
            onChange={setShareMessage}
            isFullWidth={true}
            height={40}
          />
        </div>
      </div>

      <Flex outset={{ top: 'x4' }}>
        <ThreeDCardPreview
          cardType={card.type}
          orientation={card.isHorizontal ? 'LANDSCAPE' : 'PORTRAIT'}
          panels={mapPanelImages(card.panels as Panel[], !!card.flapPreviewUrl)}
          height="calc(100dvh - 410px)"
          controlType={{ type: 'Portal', portalId: 'controls3DCard' }}
        />
      </Flex>
      <div
        style={{
          marginTop: 'auto',
          marginBottom: '1rem',
          width: '100%',
          padding: '0 1rem',
          zIndex: 10,
          position: 'relative',
        }}
      >
        <div id="controls3DCard" style={{ marginBottom: '2rem' }} />
        <Button title="Share ACT" onClick={createActShareLink} width="100%" />
      </div>
    </div>
  )
}

export default PrepareAct
