import { FlagFragment } from 'src/graphql/generated/graphql'
import { useFlags } from 'src/react_query'

type Flag =
  | 'Awards Page'
  | 'New Navigation'
  | 'Usersnap'
  | 'New Card Editor'
  | 'New Dashboard'
  | 'BulkOrderFlow'
  | 'ActDisplay'
  | 'DoubleBonusDashboard'

type Flags = {
  awardPage: boolean
  newNavigation: boolean
  userSnap: boolean
  newCardEditor: boolean
  newDashboard: boolean
  bulkOrderFlow: boolean
  actDisplay: boolean
  doubleBonusDashboard: boolean
}

const find_flag = (flags: string[], flag: Flag): boolean =>
  flags.find(item => item === flag) !== undefined

// Use this if the component already uses useQueries
// const [flags] = useQueries(getFlags())
// const { newCardEditor } = useFlag(flags)
export const useFlag = (flags: FlagFragment[]): Flags => {
  const flagsNames: string[] = flags.map(value => value.name)
  return {
    awardPage: find_flag(flagsNames, 'Awards Page'),
    newNavigation: find_flag(flagsNames, 'New Navigation'),
    userSnap: find_flag(flagsNames, 'Usersnap'),
    newCardEditor: find_flag(flagsNames, 'New Card Editor'),
    newDashboard: find_flag(flagsNames, 'New Dashboard'),
    bulkOrderFlow: find_flag(flagsNames, 'BulkOrderFlow'),
    actDisplay: find_flag(flagsNames, 'ActDisplay'),
    doubleBonusDashboard: find_flag(flagsNames, 'DoubleBonusDashboard'),
  }
}

// Use this when not using useQueries in the component
export const useFlagWithUseQueries = (): Flags => {
  const { data: flags } = useFlags({ suspense: true })
  if (!flags) throw Error('Could not retrieve flags')
  return useFlag(flags)
}
