import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type DuveHeaderProps = {
  shouldShowMoreDetails: boolean
  onShowDetails: () => void
}

export const DuvetHeader = (props: DuveHeaderProps) => {
  const { shouldShowMoreDetails, onShowDetails } = props

  const fontFamily = 'Montserrat, sans-serif'

  const logoStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50px',
    background: 'linear-gradient(210deg, #007280 -33.74%, #50E4E4 179.57%)',
    width: '44px',
    height: '44px',
  }

  const buttonStyles = {
    display: 'flex',
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '8px 18px',
    fontFamily: fontFamily,
    color: '#363239',
    fontSize: '12px',
    fontWeight: 700,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
  }
  return (
    <Flex justifyContent="space-between" width="100%" alignItems="center">
      <Flex flexDirection="row" columnGap="x1" justifyContent="center">
        <div style={{ ...logoStyles }}>
          <Icon name="promptings_logo" size={32} color="#fff" />
        </div>
        <Flex flexDirection="column" justifyContent="center">
          <Text
            type="body"
            content="Promptings"
            style={{
              fontFamily: fontFamily,
              fontSize: '12px',
              fontWeight: 700,
              color: '#404040',
              lineHeight: 1.4,
            }}
          />
          <Text
            type="body"
            content="Powered by SendOutCards"
            style={{
              fontFamily: fontFamily,
              fontSize: '8px',
              fontWeight: 500,
              color: '#6B7280',
              lineHeight: 1.2,
            }}
          />
        </Flex>
      </Flex>
      <Div onClick={onShowDetails} style={{ ...buttonStyles }}>
        {shouldShowMoreDetails ? 'Hide Details' : 'More Details'}
      </Div>
    </Flex>
  )
}
