import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type PaperTypeTileProps = {
  title: string
  imageUrl: string
  price: string
  onSelect: (isSelected: boolean) => void
  isSelected: boolean
}

export const PaperTypeTile = (props: PaperTypeTileProps) => {
  const { title, imageUrl, price, onSelect, isSelected } = props

  const primaryFont = 'Montserrat, san serif'

  const handleSelect = () => {
    onSelect(isSelected)
  }

  return (
    <Div
      cursor="pointer"
      position="relative"
      height="5.25rem"
      width="5.25rem"
      onClick={handleSelect}
      opacity={isSelected ? 1 : 0.7}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: '.25rem',
        boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
      }}
    >
      <Flex
        position="absolute"
        width="fit-content"
        height="fit-content"
        right="6px"
        top="6px"
        backgroundColor={isSelected ? '#fff' : '#c4c4c4d9'}
        inset={{ horizontal: '.5rem' }}
        style={{ borderRadius: '30px' }}
      >
        <Text
          type="body"
          content={price}
          lineHeight={1.5}
          style={{
            fontFamily: primaryFont,
            fontSize: '.625rem',
            fontWeight: 700,
            color: '#404040',
          }}
        />
      </Flex>
      <Flex
        position="absolute"
        backgroundColor={isSelected ? '#fff' : '#c4c4c4d9'}
        justifyContent="center"
        bottom="0px"
        left="0px"
        right="0px"
        style={{
          margin: 'auto',
          borderBottomLeftRadius: '.25rem',
          borderBottomRightRadius: '.25rem',
        }}
      >
        <Text
          type="body"
          content={title}
          lineHeight={2.5}
          style={{
            fontFamily: primaryFont,
            fontSize: '.625rem',
            fontWeight: 700,
            color: '#404040',
          }}
        />
      </Flex>
    </Div>
  )
}
