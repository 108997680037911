import React from 'react'
import { AppleStore } from '../AppleStore'
import { AutomatedCard } from '../AutomatedCard'
import { BagPlus } from '../BagPlus'
import { Brochure } from '../Brochure'
import { BusinessPreneur } from '../BusinessPreneur'
import { CampaignCard } from '../CampaignCard'
import { Cancel } from '../Cancel'
import { Card } from '../Card'
import { CardError } from '../CardError'
import { CardPack } from '../CardPack'
import { Cards10 } from '../Cards10'
import { Cards15 } from '../Cards15'
import { Cards20 } from '../Cards20'
import { Cards25 } from '../Cards25'
import { Cards30 } from '../Cards30'
import { CatalogCard } from '../CatalogCard'
import { Check } from '../Check'
import { CheckMark } from '../CheckMark'
import { Contact } from '../Contact'
import { Danger } from '../Danger'
import { Database } from '../Database'
import { DoubleCheck } from '../DoubleCheck'
import { Download } from '../DownLoad'
import { Edit } from '../Edit'
import { EditDocument } from '../EditDocument'
import { EmptyGift } from '../EmptyGift'
import { EnhancedStorage } from '../EnhancedStorage'
import { EntrePreneur } from '../EntrePreneur'
import { EssentialPacks } from '../EssentialPacks'
import { ExpCalendar } from '../ExpCalendar'
import { Form } from '../Form'
import { GiftEmptyState } from '../GiftEmptyState'
import { Gift } from '../Gift'
import { GooglePlayStore } from '../GooglePlayStore'
import { GroupSendCard } from '../GroupSendCard'
import { Heartfelt } from '../Heartfelt'
import { IconProps } from '../types'
import { Info } from '../Info'
import { MultipleCards } from '../MultipleCards'
import { MultiTouchCard } from '../MultiTouchCard'
import { Package } from '../Package'
import { PaperPlane } from '../PaperPlane'
import { PaperPlaneFlying } from '../PaperPlaneFlying'
import { PaperPlaneWithHeart } from '../PaperPlaneWithHeart'
import { PersonalHandWriting } from '../PersonalHandWriting'
import { Person } from '../Person'
import { PersonalSignatures } from '../PersonalSignatures'
import { PhotoDropCard } from '../PhotoDropCard'
import { PremiumStorage } from '../PremiumStorage'
import { PromptingsLogo } from '../PromptingsLogo'
import { PromptingsLogoFull } from '../PromptingsLogoFull'
import { Replace } from '../Replace'
import { ScheduleCard } from '../ScheduleCard'
import { Search } from '../Search'
import { Send } from '../Send'
import { ShoppingCart } from '../ShoppingCart'
import { ShoppingCart2 } from '../ShoppingCart2'
import { SmileCard } from '../SmileCard'
import { SmileCardWHeart } from '../SmileCardWHeart'
import { SmileCardWHearts } from '../SmileCardWHearts'
import { SOCLogo } from '../SOCLogo'
import { SoloPreneur } from '../Solopreneur'
import { StopWatch } from '../StopWatch'
import { HomeIcon } from '../HomeIcon'
import { PaymentIcon } from '../PaymentIcon'
import { CardHistoryIcon } from '../CardHistoryIcon'
import { DraftsIcon } from '../DraftsIcon'
import { ShareSettingsIcon } from '../ShareSettingsIcon'
import { SettingsIcon } from '../SettingsIcon'
import { NotificationIcon } from '../NotificationIcon'
import { Ticket } from '../ticket'
import { TimeBlock } from '../TimeBlock'
import { Trash } from '../Trash'
import { Upload } from '../Upload'
import { User } from '../User'
import { VCheck } from '../VCheck'
import { Warning } from '../Warning'
import { X } from '../X'
import { Visa } from '../Visa'
import { Pin } from '../Pin'
import { GooglePay } from '../GooglePay'
import { ApplePayMinified } from '../ApplyPayMinified'
import { File } from '../File'
import { Revert } from '../Revert'
import { Envelopes } from '../Envelopes'
import { Envelope } from '../Envelope'
import { Award } from '../Award'
import { PromptingsPoweredBySocLogo } from '../PromptingsPoweredBySocLogo'
import { Chevron } from '../Chevron'
import { CopyRequest } from '../CopyRequest'
import { CardWHeart } from '../CardWHeart'

export const Icon = (props: IconProps) => {
  switch (props.name) {
    case '10_card':
      return <Cards10 {...props} />
    case '15_card':
      return <Cards15 {...props} />
    case '20_card':
      return <Cards20 {...props} />
    case '25_card':
      return <Cards25 {...props} />
    case '30_card':
      return <Cards30 {...props} />
    case 'bag_plus':
      return <BagPlus {...props} />
    case 'brochure':
      return <Brochure {...props} />
    case 'cancel':
      return <Cancel {...props} />
    case 'card':
      return <Card {...props} />
    case 'card_error':
      return <CardError {...props} />
    case 'check':
      return <Check {...props} />
    case 'contact':
      return <Contact {...props} />
    case 'danger':
      return <Danger {...props} />
    case 'database':
      return <Database {...props} />
    case 'double_check':
      return <DoubleCheck {...props} />
    case 'download':
      return <Download {...props} />
    case 'edit':
      return <Edit {...props} />
    case 'edit_document':
      return <EditDocument {...props} />
    case 'empty_gift':
      return <EmptyGift {...props} />
    case 'form':
      return <Form {...props} />
    case 'gift_empty_state':
      return <GiftEmptyState {...props} />
    case 'gift':
      return <Gift {...props} />
    case 'heartfelt':
      return <Heartfelt {...props} />
    case 'info':
      return <Info {...props} />
    case 'multiple_cards':
      return <MultipleCards {...props} />
    case 'package':
      return <Package {...props} />
    case 'paper_plane':
      return <PaperPlane {...props} />
    case 'paper_plane_flying':
      return <PaperPlaneFlying {...props} />
    case 'paper_plane_with_heart':
      return <PaperPlaneWithHeart {...props} />
    case 'replace':
      return <Replace {...props} />
    case 'search':
      return <Search {...props} />
    case 'shopping_cart':
      return <ShoppingCart {...props} />
    case 'shopping_cart_2':
      return <ShoppingCart2 {...props} />
    case 'trash':
      return <Trash {...props} />
    case 'upload':
      return <Upload {...props} />
    case 'user':
      return <User {...props} />
    case 'v_check':
      return <VCheck {...props} />
    case 'x':
      return <X {...props} />
    case 'smile_card':
      return <SmileCard {...props} />
    case 'smile_card_w_hearts':
      return <SmileCardWHearts {...props} />
    case 'smile_card_w_heart':
      return <SmileCardWHeart {...props} />
    case 'solopreneur':
      return <SoloPreneur {...props} />
    case 'businesspreneur':
      return <BusinessPreneur {...props} />
    case 'entrepreneur':
      return <EntrePreneur {...props} />
    case 'card_pack':
      return <CardPack {...props} />
    case 'time_block':
      return <TimeBlock {...props} />
    case 'exp_calendar':
      return <ExpCalendar {...props} />
    case 'check_mark':
      return <CheckMark {...props} />
    case 'ticket':
      return <Ticket {...props} />
    case 'automated_card':
      return <AutomatedCard {...props} />
    case 'campaign_card':
      return <CampaignCard {...props} />
    case 'group_send_card':
      return <GroupSendCard {...props} />
    case 'revert':
      return <Revert {...props} />
    case 'schedule_card':
      return <ScheduleCard {...props} />
    case 'multi_touch_card':
      return <MultiTouchCard {...props} />
    case 'catalog_card':
      return <CatalogCard {...props} />
    case 'enhanced_storage':
      return <EnhancedStorage {...props} />
    case 'premium_storage':
      return <PremiumStorage {...props} />
    case 'photo_drop_card':
      return <PhotoDropCard {...props} />
    case 'personal_signature':
      return <PersonalSignatures {...props} />
    case 'person':
      return <Person {...props} />
    case 'personal_hand_writing':
      return <PersonalHandWriting {...props} />
    case 'essential_packs':
      return <EssentialPacks {...props} />
    case 'apple_store':
      return <AppleStore {...props} />
    case 'google_play_store':
      return <GooglePlayStore {...props} />
    case 'stop_watch':
      return <StopWatch {...props} />
    case 'home':
      return <HomeIcon {...props} />
    case 'payment':
      return <PaymentIcon {...props} />
    case 'card_history':
      return <CardHistoryIcon {...props} />
    case 'drafts':
      return <DraftsIcon {...props} />
    case 'share_settings':
      return <ShareSettingsIcon {...props} />
    case 'settings':
      return <SettingsIcon {...props} />
    case 'notifications':
      return <NotificationIcon {...props} />
    case 'promptings_logo':
      return <PromptingsLogo {...props} />
    case 'warning':
      return <Warning {...props} />
    case 'soc_logo':
      return <SOCLogo {...props} />
    case 'promptings_logo_full':
      return <PromptingsLogoFull {...props} />
    case 'promptings_powered_by_soc_logo':
      return <PromptingsPoweredBySocLogo {...props} />
    case 'visa':
      return <Visa {...props} />
    case 'pin':
      return <Pin {...props} />
    case 'google_pay':
      return <GooglePay {...props} />
    case 'apple_pay_minified':
      return <ApplePayMinified {...props} />
    case 'file':
      return <File {...props} />
    case 'envelopes':
      return <Envelopes {...props} />
    case 'envelope':
      return <Envelope {...props} />
    case 'send':
      return <Send {...props} />
    case 'award':
      return <Award {...props} />
    case 'chevron':
      return <Chevron {...props} />
    case 'copyRequest':
      return <CopyRequest {...props} />
    case 'card_w_heart':
      return <CardWHeart {...props} />
  }
}
