import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type CardTypeTileProps = {
  title: string
  isSelected: boolean
  imageUrl: string
  onSelect: (isSelected: boolean) => void
}

export const CardTypeTile = (props: CardTypeTileProps) => {
  const { title, isSelected, imageUrl, onSelect } = props
  const primaryFont = 'Montserrat, san serif'

  const handleSelect = () => {
    onSelect(isSelected)
  }

  return (
    <Flex
      cursor="pointer"
      width="fit-content"
      height="fit-content"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      backgroundColor={isSelected ? '#fff' : '#0000000D'}
      inset={{ vertical: '.75rem', horizontal: '1rem' }}
      onClick={handleSelect}
      style={{
        borderRadius: '.25rem',
        boxShadow: isSelected
          ? '0px 4px 28px 0px rgba(0, 0, 0, 0.08)'
          : '0px 0px 34px 0px rgba(0, 0, 0, 0.08) inset',
        border: isSelected ? '2px solid rgba(233, 233, 233, 0.50)' : undefined,
      }}
    >
      <img
        src={imageUrl}
        alt="#"
        style={{
          width: '3.25rem',
          height: '4rem',
          boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
        }}
      />
      <Text
        type="body"
        content={title}
        style={{
          fontFamily: primaryFont,
          fontSize: '.625rem',
          fontWeight: 700,
          color: '#6B7280',
        }}
      />
    </Flex>
  )
}
