import useActions from './useActions'
import { DrawerState, DrawerType } from 'src/redux/types/act'
import { useCallback } from '.'

const useSnapPoints = (
  drawerType: DrawerType | null,
  drawerState: DrawerState,
) => {
  const action = useActions()

  const windowHeight = window.innerHeight
  const snapSettingsPreviewExpanded = windowHeight - 130
  const snapFullView = windowHeight

  /**
   * Generates an array with 4 snap points for duvet. If any optional snap point
   * is undefined, it repeats the previous snap point into that slot.
   *
   * @param snap1
   * @param snap2
   * @param snap3
   * @param snap4
   */
  const generateSnapPoints = (
    snap1: number,
    snap2?: number,
    snap3?: number,
    snap4?: number,
  ) => {
    const snaps = [snap1, snap2, snap3, snap4]

    return snaps.map(
      (snap, index) =>
        snap ?? snaps.slice(0, index).reverse().find(Boolean) ?? snap1,
    )
  }

  const getSnapPoints = (drawerType: DrawerType | null) => {
    switch (drawerType) {
      case 'recipientView':
        return generateSnapPoints(
          115,
          500,
          snapSettingsPreviewExpanded,
          snapFullView,
        )
      default:
        return generateSnapPoints(115)
    }
  }

  const snapPoints = getSnapPoints(drawerType)

  const getDrawerStatus = (status: DrawerState) => {
    const statuses = {
      navigation: snapPoints[0],
      previewCollapsed: snapPoints[1],
      previewExpanded: snapPoints[2],
      fullView: snapPoints[3],
    }
    return statuses[status] || 0
  }

  const openValue = getDrawerStatus(drawerState)

  const handleSnap = useCallback(
    (snapValue: number) => {
      const snapStates: Record<number, DrawerState> = {
        [snapPoints[0]]: 'navigation',
        [snapPoints[1]]: 'previewCollapsed',
        [snapPoints[2]]: 'previewExpanded',
        [snapPoints[3]]: 'fullView',
      }

      const newState = snapStates[snapValue]
      if (newState) {
        action.setDuvetState(newState)
      }
    },
    [action, snapPoints],
  )

  return {
    snapPoints,
    openValue,
    handleSnap,
    getDrawerStatus,
  }
}

export default useSnapPoints
