import { Dialog, Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { getPaperTypes } from 'src/app/constants'
import { cardTypes } from 'src/catalog/components/CardPreview/CardPreviewModal'
import { useSelector, useState } from 'src/hooks'
import { CardPaperType, CardType } from 'src/graphql/generated/graphql'
import {
  getRouteCardType,
  getRoutePaperType,
} from 'src/redux/selectors/catalog'
import { CardPreviewFooter } from './components/CardPreviewFooter'
import { CardPreviewHeader } from './components/CardPreviewHeader'
import { CardTypeTile } from './components/CardTypeTile'
import { PaperTypeTile } from './components/PaperTypeTile'
import { SendTypeCard } from './components/SendTypeCard'
import { SendTypeScreen } from './components/SendTypeScreen'
import { SendTypeToggle } from './components/SendTypeToggle'

export type CardPreviewSteps = 'SelectSendType' | 'CardDetails'

type CardPreviewProps = {
  initialStep: CardPreviewSteps
  onCardTypeSelect?: (selectedCardType: CardType) => void
  onPaperTypeSelect?: (selectedPaperType: CardPaperType) => void
  onStepChange?: (nextStep: CardPreviewSteps) => void
  cardImageUrl: string
  onClose: () => void
  onComplete: () => void
  onChangeSendType: () => void
  isDigitalSend: boolean
}

export const CardPreviewFlow = (props: CardPreviewProps) => {
  const {
    initialStep = 'SelectSendType',
    onCardTypeSelect,
    onPaperTypeSelect,
    onStepChange,
    cardImageUrl,
    onComplete,
    onClose,
    onChangeSendType,
    isDigitalSend,
  } = props
  const primaryFont = 'Montserrat, san serif'

  const routeCardType = useSelector(getRouteCardType)
  const routePaperType = useSelector(getRoutePaperType)

  const [selectedCardType, setSelectedCardType] = useState(
    routeCardType ?? CardType.TwoPanel,
  )
  const [selectedPaperType, setSelectedPaperType] = useState(
    routePaperType ?? CardPaperType.Std,
  )
  const [currentStep, setCurrentStep] = useState<CardPreviewSteps>(initialStep)

  const handleSelectCardType = (value: CardType) => {
    setSelectedCardType(value)
    onCardTypeSelect?.(value)
  }

  const handleSelectPaperType = (value: CardPaperType) => {
    setSelectedPaperType(value)
    onPaperTypeSelect?.(value)
  }

  const goToNextStep = () => {
    if (currentStep === 'CardDetails') {
      if (onComplete) {
        onComplete()
      }
    } else {
      const nextStep = 'CardDetails'
      setCurrentStep(nextStep)
      onStepChange?.(nextStep)
    }
  }

  const goBackStep = () => {
    switch (currentStep) {
      case 'CardDetails':
        setCurrentStep('SelectSendType')
        onStepChange?.('SelectSendType')
        break
      default:
        setCurrentStep('SelectSendType')
        onStepChange?.('SelectSendType')
    }
  }

  const cardTypeData = cardTypes

  const paperUpgradeCost = selectedCardType === CardType.TwoPanelBig ? 150 : 50

  const formattedPaperUpgrade = (() => {
    const paperCostString = paperUpgradeCost.toString()

    return paperUpgradeCost < 100
      ? `${paperUpgradeCost}¢`
      : `$${paperCostString.slice(0, 1)}.${paperCostString.slice(1)}`
  })()

  const paperTypes = getPaperTypes(selectedCardType).map(type => ({
    ...type,
    upgrade: type.upgrade ? formattedPaperUpgrade : type.upgrade,
  }))

  const filteredCardTypeData = isDigitalSend
    ? cardTypeData.filter(data =>
        ['Flat Card', '2-Panel', '3-Panel'].includes(data.label),
      )
    : cardTypeData

  const sendType = isDigitalSend
    ? {
        title: 'Immediate Delivery\nDigital Card',
        cost: '$1',
        imageUrl: cardImageUrl,
      }
    : {
        title: 'Standard Physical\nShipping Card',
        cost: '$2.50',
        imageUrl: cardImageUrl,
      }

  const stepContent = {
    SelectSendType: (
      <Flex flexDirection="column">
        <Text
          type="body"
          content="Select Send Type:"
          style={{
            fontFamily: primaryFont,
            fontSize: '.625rem',
            fontWeight: 500,
            color: '#6B7280',
          }}
        />
        <Div display="grid" style={{ gridTemplateColumns: 'repeat(2, 150px)' }}>
          <SendTypeScreen
            onSelect={onChangeSendType}
            title={'Immediate Delivery\nDigital Card'}
            imageUrl={cardImageUrl}
            isSelected={isDigitalSend}
            cost="$1"
          />
          <SendTypeScreen
            onSelect={onChangeSendType}
            title={'Standard Physical\nShipping Card'}
            imageUrl={cardImageUrl}
            isSelected={!isDigitalSend}
            cost="$2.50"
          />
        </Div>
      </Flex>
    ),
    CardDetails: (
      <Flex width="100%" flexDirection="column" rowGap="x2">
        <Flex width="100%" justifyContent="center">
          <SendTypeToggle
            onToggle={onChangeSendType}
            isSelected={isDigitalSend}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text
            type="body"
            content="Send Type:"
            style={{
              fontFamily: primaryFont,
              fontSize: '.625rem',
              fontWeight: 500,
              color: '#6B7280',
            }}
          />
          <SendTypeCard {...sendType} />
        </Flex>
        <Flex flexDirection="column">
          <Text
            type="body"
            content="Card Type:"
            style={{
              fontFamily: primaryFont,
              fontSize: '.625rem',
              fontWeight: 500,
              color: '#6B7280',
            }}
          />
          <Div
            display="grid"
            justifyItems="center"
            width="100%"
            rowGap="x1"
            style={{ gridTemplateColumns: 'repeat(3, 100px)' }}
          >
            {filteredCardTypeData.map(cardType => (
              <CardTypeTile
                key={cardType.value}
                title={cardType.label}
                imageUrl={cardImageUrl}
                isSelected={selectedCardType === cardType.value}
                onSelect={() => handleSelectCardType(cardType.value)}
              />
            ))}
          </Div>
        </Flex>
        {!isDigitalSend && (
          <Flex flexDirection="column">
            <Text
              type="body"
              content="Choose Paper Type"
              style={{
                fontFamily: primaryFont,
                fontSize: '.625rem',
                fontWeight: 500,
                color: '#6B7280',
              }}
            />
            <Div
              display="grid"
              justifyItems="center"
              width="100%"
              style={{ gridTemplateColumns: 'repeat(3, 100px)' }}
            >
              {paperTypes.map(paperType => (
                <PaperTypeTile
                  key={paperType.value}
                  title={paperType.label}
                  price={paperType.upgrade ?? ''}
                  imageUrl={cardImageUrl}
                  isSelected={selectedPaperType === paperType.value}
                  onSelect={() => handleSelectPaperType(paperType.value)}
                />
              ))}
            </Div>
          </Flex>
        )}
      </Flex>
    ),
  }

  return (
    <Dialog isOpen={true}>
      <Flex
        position="absolute"
        top="50%"
        left="50%"
        width="345px"
        inset={'1rem'}
        backgroundColor="#fff"
        flexDirection="column"
        rowGap="x2"
        style={{
          borderRadius: '1rem',
          boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <CardPreviewHeader title="Customize Card Options" onClose={onClose} />
        {stepContent[currentStep]}
        <CardPreviewFooter
          primaryAction={{
            title: 'Continue',
            onClick: goToNextStep,
          }}
          secondaryAction={{
            title: 'Back',
            onClick: goBackStep,
          }}
        />
      </Flex>
    </Dialog>
  )
}
