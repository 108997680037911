import { AccessLevel, Route } from 'src/routes/Route'
import { Subroute } from 'src/routes/Subroute'

const ActSubroute = Subroute()
export type ActSubroute = Subroute<typeof ActSubroute>

export const ActRoute = Route({
  path: '/act',
  accessLevel: AccessLevel.PUBLIC,
  init: (subroute?: ActSubroute) => ({
    subroute,
  }),
  fromPath: () => ({}),
  subroute: undefined,
  hideSidebar: true,
})
export type ActRoute = Route<typeof ActRoute>
