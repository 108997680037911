import React from 'react'
import { Div, Flex, Separator, Text } from '@sendoutcards/quantum-design-ui'

export type TotalsTableProps = {
  subtotal: string
  taxTotal: string
  orderTotal: string
  discounts?: string
  recurringAmount?: { amount: string; occurrence: string }
}

type PricingLineProps = {
  title: string
  price: string
  discounts?: string
}
const PricingLine: React.FC<PricingLineProps> = ({
  title,
  price,
  discounts,
}) => {
  return (
    <Flex width="100%" inset={{ vertical: 'x1' }}>
      <Div>
        <Text
          content={title}
          type="body"
          color="primaryHeading"
          weight="bold"
        />
      </Div>
      <Div style={{ marginLeft: 'auto' }}>
        <Text
          content={price}
          type="body"
          color="primaryHeading"
          weight="bold"
        />
      </Div>
    </Flex>
  )
}

export const TotalsTable = (props: TotalsTableProps) => {
  const { subtotal, discounts, taxTotal, orderTotal, recurringAmount } = props

  return (
    <Flex
      backgroundColor="foreground"
      flexDirection="column"
      width="100%"
      borderRadius="large"
      inset={{ vertical: 'x1', horizontal: 'x1' }}
    >
      <Flex
        flexDirection="column"
        backgroundColor="background"
        inset="x2"
        style={{ borderRadius: '16px' }}
      >
        <PricingLine title={'Subtotal'} price={`$${subtotal}`} />
        {discounts && (
          <PricingLine title={'Discounts & Savings'} price={`$${discounts}`} />
        )}
        <PricingLine title={'Taxes'} price={`$${taxTotal}`} />
      </Flex>
      <Flex flexDirection="column" inset="x2" style={{ paddingBottom: '5px' }}>
        <PricingLine title={'Total Due Today'} price={`$${orderTotal}`} />
        {recurringAmount && (
          <>
            <Div inset={{ horizontal: 'x1' }} outset={{ vertical: 'x1' }}>
              <Separator orientation="horizontal" />
            </Div>
            <PricingLine
              title="Total Ongoing"
              price={`$${recurringAmount.amount}/${recurringAmount.occurrence}`}
            />
          </>
        )}
      </Flex>
    </Flex>
  )
}
