import React from 'react'
// @src imports
import { DefaultError, Transition } from 'src/chrome'
import { CardOption } from 'src/catalog/components/CardOptions/CardOptions'
// relative imports
import {
  getRouteCardType,
  getRoutePaperType,
} from '../../../redux/selectors/catalog'
import { useActions, useEffect, useSelector, useState } from 'src/hooks'
import * as Result from 'src/utils/Result'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { CardPreviewFlow } from 'src/act/components/CardPreview/CardPreview'
import {
  useCreateCard,
  useCreateDigitalCard,
  useGetCard,
  useGetSendableCard,
} from 'src/react_query'
import {
  CardPaperType,
  CardType,
  CreateCardMutationVariables,
} from 'src/graphql/generated/graphql'

export const cardTypes: CardOption<CardType>[] = [
  {
    value: CardType.Flatcard,
    label: 'Flat Card',
    icon: 'FLATPANEL',
    iconSize: 30,
    viewBox: '0 0 25 32',
    canBeBulk: true,
  },
  {
    value: CardType.TwoPanel,
    label: '2-Panel',
    icon: 'STANDARDCARD',
    iconSize: 30,
    viewBox: '0 0 18 32',
    canBeBulk: true,
  },
  // {
  //   value: CardType.THREE_PANEL,
  //   label: '3-Panel',
  //   icon: 'TRIFOLD',
  //   iconSize: 30,
  //   viewBox: '0 0 27 32',
  //   canBeBulk: false,
  // },
  {
    value: CardType.TwoPanelBig,
    label: 'Big Card',
    icon: 'BIGCARD',
    iconSize: 30,
    viewBox: '0 0 41 32',
    upgrade: '$1.50',
    canBeBulk: false,
  },
  {
    value: CardType.Postcard,
    label: 'Post Card',
    icon: 'POSTCARD',
    iconSize: 30,
    viewBox: '0 0 48 32',
    canBeBulk: false,
  },
]

// const getVariations = (
//   sendableCard: DetailedSendableCardFragment,
// ): SendableCardWithTemplateFragment[] => {
//   return [sendableCard, ...sendableCard.variations]
// }

// const getSelectedVariation = (
//   sendableCard: DetailedSendableCardFragment,
//   variationId?: string,
// ): SendableCardVariationFragment =>
//   (variationId &&
//     getVariations(sendableCard).find(
//       variation => variation.id === variationId,
//     )) ||
//   sendableCard

// const isDefined = <T extends {} | undefined>(value: T) =>
//   typeof value !== 'undefined'

interface Props {
  onClose: () => void
  onSelect?: (id: string) => void
  type: 'SendableCard' | 'Card'
  id: string
}

const CardPreviewModal: React.FC<Props> = props => {
  const { onClose, onSelect, type, id: cardId } = props

  const actions = useActions()
  const routeCardType = useSelector(getRouteCardType)
  const routePaperType = useSelector(getRoutePaperType)

  const createCardMutation = useCreateCard()
  const createDigitalCardMutation = useCreateDigitalCard()

  const storedData = useSelector(state => state.context)
  const isDigital =
    storedData.kind === 'ACT_CONTEXT' ? storedData.isDigitalCard : false

  const handleIsDigital = () => {
    actions.setIsDigitalCard(!isDigital)
  }

  const getSendableCardQuery = useGetSendableCard(
    {
      id: type === 'SendableCard' ? cardId : undefined,
    },
    { suspense: true },
  )
  const getCardQuery = useGetCard(
    {
      id: type !== 'SendableCard' ? cardId : undefined,
    },
    { suspense: true },
  )
  const sendableCard = getSendableCardQuery.data?.sendableCard
  const card = type === 'SendableCard' ? sendableCard : getCardQuery.data?.card

  // const [isCardSelected, setIsCardSelected] = useState(
  //   isDefined(routeCardType) || isDefined(routePaperType),
  // )
  const [cardType, setCardType] = useState(routeCardType ?? CardType.TwoPanel)
  const [paperType, setPaperType] = useState(
    routePaperType ?? CardPaperType.Std,
  )
  // const [isCustomizeOpen, setIsCustomizeOpen] = useState(
  //   isDefined(routeCardType) || isDefined(routePaperType),
  // )

  // const handleSelectCard = useCallback(() => {
  //   setIsCardSelected(!isCardSelected)
  //   setIsCustomizeOpen(!isCustomizeOpen)
  // }, [isCardSelected, isCustomizeOpen])

  useEffect(() => {
    handleSetPaperType(
      // Postcards may only have standard paper type
      cardType === CardType.Postcard ? CardPaperType.Std : paperType,
    )
  }, [cardType, paperType])

  const handleSetCardType = (value: CardType) => setCardType(value)

  const handleSetPaperType = (value: CardPaperType) => setPaperType(value)

  // TODO: Need to update calling function to support custom cards
  // (we are currently considering everything sendable card, which throws an error)
  const createCard = async ({
    card,
    sendableCard,
    type,
    paperType,
    isNewEditorCard,
  }: CreateCardMutationVariables) => {
    try {
      const { createCard } = await createCardMutation.mutateAsync({
        card,
        sendableCard,
        type,
        paperType,
        isNewEditorCard,
      })

      if (isDigital && createCard.card.id) {
        await createDigitalCardMutation.mutateAsync({
          cardId: createCard.card.id,
          input: {},
        })
      }

      actions.loadedUser(Result.success(createCard.account))

      if (onSelect) {
        onSelect(createCard.card.id)
        return
      }
      actions.openCard(createCard.card.id)
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditCard = async (
    id: string,
    cardType: CardType,
    paperType: CardPaperType,
    isNewEditorCard: boolean,
  ) => {
    await createCard({
      sendableCard: id,
      type: cardType,
      paperType,
      isNewEditorCard,
    })
  }

  // const handleEditCustomCard = (id: string) => actions.openCard(id)

  // const handleSendCustomCard = async () => {
  //   await createCard({ card: cardId })
  // }

  if (!card) {
    throw new Error('Missing card')
  }

  const cardImageUrl =
    card.__typename === 'SendableCard'
      ? card.frontImage.mediumThumb
      : card.frontPreviewUrl

  return createCardMutation.isLoading ? (
    <Transition />
  ) : (
    <CardPreviewFlow
      onCardTypeSelect={(cardType: CardType) => {
        handleSetCardType(cardType)
        console.log(cardType, 'my new card type')
      }}
      onPaperTypeSelect={(paperType: CardPaperType) => {
        handleSetPaperType(paperType)
        console.log(paperType, 'papertype')
      }}
      cardImageUrl={cardImageUrl}
      initialStep="SelectSendType"
      onComplete={() => handleEditCard(card.id, cardType, paperType, true)}
      onChangeSendType={handleIsDigital}
      isDigitalSend={isDigital}
      onClose={onClose}
    />
  )
}

export default suspenseBoundary({
  component: CardPreviewModal,
  unresolved: (
    <Transition
      message={'Loading your selection...'}
      messageStyle={{ color: 'black', fontWeight: 500 }}
    />
  ),
  failure: DefaultError,
})
