/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: any; output: any }
  URL: { input: any; output: any }
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: any; output: any }
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: any; output: any }
  /** A long (64-bit integer). Serializes as a string due to GraphQL restrictions. */
  Long: { input: any; output: any }
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: { input: any; output: any }
}

export type Query = {
  __typename?: 'Query'
  account: Account
  affiliateCountries: Array<Country>
  aggregateReceipts: Array<AggregateReceipt>
  awardGroups: Array<AwardGroup>
  awards: Array<Award>
  banners: Array<MarketingBanner>
  bydesignLoginLink: Scalars['String']['output']
  campaign: Campaign
  campaigns: Array<Campaign>
  /** @deprecated Deprecated, use paginated campaign shares instead. */
  campaignShares: Array<CampaignShare>
  card: Card
  cardCategories: Array<CardCategory>
  cardCategory: CardCategory
  cards: Array<Card>
  collection: Collection
  collections: Array<Collection>
  contact: Contact
  contactRequestByToken?: Maybe<ContactRequest>
  contactRequests: Array<ContactRequest>
  contacts: Array<Contact>
  /** @deprecated Deprecated, use paginated contact shares instead */
  contactShares: Array<ContactShare>
  countries: Array<Scalars['String']['output']>
  dateTime: Scalars['DateTime']['output']
  debug?: Maybe<DjangoDebug>
  detailedCountries: Array<Country>
  devices: Array<Device>
  digitalCard: DigitalCard
  digitalCardByToken?: Maybe<DigitalCard>
  digitalCards: DigitalCardConnection
  doubleBonusReports: Array<DoubleBonusReportType>
  drafts: Array<Card>
  filters: Array<Filter>
  flags: Array<Flag>
  fonts: Array<Font>
  getRelease?: Maybe<Release>
  gift: Gift
  giftCategories: Array<GiftCategory>
  gifts: Array<Gift>
  group: Group
  groups: Array<Group>
  images: Array<Image>
  internationalBasicPostageCost: Scalars['Float']['output']
  item: Item
  itemCategories: Array<ItemCategory>
  itemCategory: ItemCategory
  items: Array<Item>
  layout: Layout
  layouts: Array<Layout>
  leaderBoards: Array<LeaderBoardType>
  legacyPicturePlusCards: Array<SendableCard>
  marketingUrl: MarketingUrl
  marketingUrls: Array<MarketingUrl>
  multiParagraph: MarketingMultiParagraph
  multiParagraphs: Array<MarketingMultiParagraph>
  nationalBasicPostageCost: Scalars['Float']['output']
  notificationCount: Scalars['Int']['output']
  /** @deprecated The 'notifications' field is deprecated. Please use the 'paginated_notifications' field instead. */
  notifications: Array<Notification>
  order: Order
  orders: Array<Order>
  paginatedCampaigns: PaginatedCampaigns
  paginatedCampaignShares: PaginatedCampaignShares
  paginatedContactRequests: PaginatedContactRequests
  paginatedContacts: PaginatedContacts
  paginatedContactShares: PaginatedContactShares
  paginatedGroups: PaginatedGroups
  paginatedInvites: PaginatedInvites
  paginatedNotifications: PaginatedNotifications
  paginatedOrders: PaginatedOrders
  paginatedRecipients: PaginatedRecipients
  paginatedReminders: PaginatedReminders
  paginatedSentCards: PaginatedSentCards
  paragraph: MarketingParagraph
  paragraphByTitle: MarketingParagraph
  paragraphs: Array<MarketingParagraph>
  /** All countries with valid calling codes. */
  phoneNumberCountries: Array<PhoneNumberCountry>
  planAddonsDescriptions: Array<PlansDescription>
  planMove: PlanMove
  plans: Array<Plan>
  plansDescriptions: Array<PlansDescription>
  planTrialsDescriptions: Array<PlansDescription>
  pricingPage: Array<PricingPageType>
  productionInfo: ProductionInfo
  receipts: Array<DetailReceipt>
  receivedPendingMembershipInvites: Array<MembershipInvite>
  reminders: Array<Reminder>
  sendableCard: SendableCard
  sendableCards: Array<SendableCard>
  sentCards: SentCards
  shouldVerifyEmail: Scalars['Boolean']['output']
  socProContact: SocProContact
  socProContacts: Array<SocProContact>
  sponsor: User
  /** @deprecated Use the users query instead and pass isAffiliate: true. */
  sponsors: Array<User>
  sticker?: Maybe<Sticker>
  stickerCategories?: Maybe<PaginatedStickerCategory>
  stickerCategory?: Maybe<StickerCategory>
  stickers: PaginatedStickers
  user: User
  userByGenealogyId: User
  userByUsername: User
  users: Array<User>
}

export type QueryAggregateReceiptsArgs = {
  aggregateOn?: InputMaybe<AggregateEnum>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  withPoints?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryAwardGroupsArgs = {
  filter?: InputMaybe<AwardsGroupFilter>
}

export type QueryAwardsArgs = {
  filter?: InputMaybe<AwardsFilter>
}

export type QueryCampaignArgs = {
  id: Scalars['ID']['input']
}

export type QueryCampaignsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryCampaignSharesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCardArgs = {
  id: Scalars['ID']['input']
}

export type QueryCardCategoryArgs = {
  id: Scalars['ID']['input']
}

export type QueryCardsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCollectionArgs = {
  id: Scalars['ID']['input']
}

export type QueryCollectionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  reasonToSend?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryContactArgs = {
  id: Scalars['ID']['input']
}

export type QueryContactRequestByTokenArgs = {
  token: Scalars['String']['input']
}

export type QueryContactRequestsArgs = {
  status?: InputMaybe<ContactRequestStatusEnum>
}

export type QueryContactsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryContactSharesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryDigitalCardArgs = {
  pk: Scalars['ID']['input']
}

export type QueryDigitalCardByTokenArgs = {
  token: Scalars['String']['input']
}

export type QueryDigitalCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryDraftsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryFontsArgs = {
  owner?: InputMaybe<Owner>
}

export type QueryGetReleaseArgs = {
  version: Scalars['String']['input']
}

export type QueryGiftArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['ID']['input']>
}

export type QueryGiftsArgs = {
  category?: InputMaybe<Scalars['ID']['input']>
  isEligibleFreeGift?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryGroupArgs = {
  id: Scalars['ID']['input']
}

export type QueryGroupsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryImagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  itemCode?: InputMaybe<Scalars['ID']['input']>
}

export type QueryItemCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryItemCategoryArgs = {
  id: Scalars['ID']['input']
}

export type QueryItemsArgs = {
  category?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryLayoutArgs = {
  id: Scalars['ID']['input']
}

export type QueryLayoutsArgs = {
  category: LayoutCategory
  dimensions?: InputMaybe<LayoutDimensions>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  owner?: InputMaybe<Owner>
}

export type QueryLegacyPicturePlusCardsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryMarketingUrlArgs = {
  title: Scalars['String']['input']
}

export type QueryMultiParagraphArgs = {
  id: Scalars['ID']['input']
}

export type QueryNotificationsArgs = {
  acknowledged?: InputMaybe<Scalars['Boolean']['input']>
  types?: InputMaybe<Array<InputMaybe<NotificationType>>>
}

export type QueryOrderArgs = {
  id: Scalars['ID']['input']
}

export type QueryOrdersArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  isSent?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryPaginatedCampaignsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  shareable?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryPaginatedCampaignSharesArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type QueryPaginatedContactRequestsArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
  status?: InputMaybe<ContactRequestStatusEnum>
}

export type QueryPaginatedContactsArgs = {
  anniversaryEndDate?: InputMaybe<Scalars['DateTime']['input']>
  anniversaryStartDate?: InputMaybe<Scalars['DateTime']['input']>
  birthdayEndDate?: InputMaybe<Scalars['DateTime']['input']>
  birthdayStartDate?: InputMaybe<Scalars['DateTime']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  contacts?: InputMaybe<Array<Scalars['ID']['input']>>
  groups?: InputMaybe<Array<Scalars['ID']['input']>>
  hasNoGroup?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  requestStatus?: InputMaybe<ContactRequestStatusEnum>
  search?: InputMaybe<Scalars['String']['input']>
  showSecondaryContacts?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryPaginatedContactSharesArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type QueryPaginatedGroupsArgs = {
  contacts?: InputMaybe<Array<Scalars['ID']['input']>>
  groups?: InputMaybe<Array<Scalars['ID']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryPaginatedInvitesArgs = {
  email?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<InvitesOrderBy>
  phone?: InputMaybe<Scalars['String']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  stream?: InputMaybe<StreamId>
}

export type QueryPaginatedNotificationsArgs = {
  filters?: InputMaybe<NotificationFilters>
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type QueryPaginatedOrdersArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  isSent?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryPaginatedRecipientsArgs = {
  contacts?: InputMaybe<Array<Scalars['ID']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  paymentFailures?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryPaginatedRemindersArgs = {
  filters?: InputMaybe<ReminderFilters>
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type QueryPaginatedSentCardsArgs = {
  contacts?: InputMaybe<Array<Scalars['ID']['input']>>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  sendType?: InputMaybe<SentCardSendType>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  status?: InputMaybe<CardStatus>
}

export type QueryParagraphArgs = {
  id: Scalars['ID']['input']
}

export type QueryParagraphByTitleArgs = {
  title: Scalars['String']['input']
}

export type QueryPlanMoveArgs = {
  planIdFrom?: InputMaybe<Scalars['Int']['input']>
  planIdTo?: InputMaybe<Scalars['Int']['input']>
}

export type QueryProductionInfoArgs = {
  contactId: Scalars['ID']['input']
  orderId: Scalars['ID']['input']
}

export type QueryReceiptsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  withPoints?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryRemindersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  types?: InputMaybe<Array<ReminderType>>
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>
}

export type QuerySendableCardArgs = {
  id: Scalars['ID']['input']
}

export type QuerySendableCardsArgs = {
  category?: InputMaybe<Scalars['ID']['input']>
  collection?: InputMaybe<Scalars['ID']['input']>
  favorite?: InputMaybe<Scalars['Boolean']['input']>
  featured?: InputMaybe<Scalars['Boolean']['input']>
  isPremium?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>
}

export type QuerySentCardsArgs = {
  endDate: Scalars['DateTime']['input']
  sendType?: InputMaybe<SentCardSendType>
  startDate: Scalars['DateTime']['input']
}

export type QuerySocProContactArgs = {
  id: Scalars['ID']['input']
}

export type QuerySponsorArgs = {
  id: Scalars['ID']['input']
}

export type QuerySponsorsArgs = {
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryStickerArgs = {
  id: Scalars['ID']['input']
}

export type QueryStickerCategoriesArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type QueryStickerCategoryArgs = {
  filters: StickerCategoryFilters
}

export type QueryStickersArgs = {
  filters?: InputMaybe<StickerFilters>
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type QueryUserArgs = {
  storefrontSlug: Scalars['String']['input']
}

export type QueryUserByGenealogyIdArgs = {
  genealogyId: Scalars['Int']['input']
}

export type QueryUserByUsernameArgs = {
  username: Scalars['String']['input']
}

export type QueryUsersArgs = {
  isAffiliate?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  search: Scalars['String']['input']
}

export type Account = {
  __typename?: 'Account'
  activePlansAndAddons: Array<Maybe<Plan>>
  actSends: Scalars['Int']['output']
  addPaymentSettingsDailyCap: AddPaymentSettingsDailyCap
  address?: Maybe<Address>
  affiliateUrl: Scalars['URL']['output']
  bulkCardCost: Scalars['Float']['output']
  cardCost: Scalars['Float']['output']
  cardPackageSends: Scalars['Int']['output']
  cardSends: Scalars['Int']['output']
  couldQualifyForFirstFreeCard: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  credits: Amount
  dailyHeartfeltCap: DailyHeartfeltCap
  dailyHeartfeltSends: Scalars['Int']['output']
  dateJoined: Scalars['DateTime']['output']
  detailedReferralCredits: Array<DetailedReferralCredits>
  email: Scalars['String']['output']
  employeeSends: Scalars['Int']['output']
  expense: Amount
  expenseReload?: Maybe<ExpenseReload>
  firstName: Scalars['String']['output']
  flags: Array<Flag>
  fullAccessFeatures: Array<FullAccessFeatureType>
  genealogyId: Scalars['Int']['output']
  giftDiscount: Scalars['Float']['output']
  giftSends: Scalars['Int']['output']
  giftShippingDiscount: Scalars['Float']['output']
  groupSends: Scalars['Int']['output']
  hasBundlePlan: Scalars['Boolean']['output']
  hasDailyHeartfeltSends: Scalars['Boolean']['output']
  hasEverHadAssetSlug: Scalars['Boolean']['output']
  /** @deprecated Use fullAccessFeatures or hasFullAccessFeature(featureType: CONTACT_MANAGER) instead. */
  hasFullAccessContactManager: Scalars['Boolean']['output']
  hasFullAccessFeature: Scalars['Boolean']['output']
  /** @deprecated Use fullAccessFeatures or hasFullAccessFeature(featureType: FREE_AFFILIATE) instead. */
  hasFullAccessFreeAffiliate: Scalars['Boolean']['output']
  hasRMCourse: Scalars['Boolean']['output']
  hasUnlimitedHeartfeltSends: Scalars['Boolean']['output']
  heartfeltDailyCap: HeartfeltDailyCap
  heartfeltSends: Scalars['Int']['output']
  heartfeltSystemSends: Scalars['Int']['output']
  id: Scalars['ID']['output']
  isAffiliate: Scalars['Boolean']['output']
  isBetterWayPromo: Scalars['Boolean']['output']
  /** @deprecated Use isAffiliate instead. */
  isDistributor: Scalars['Boolean']['output']
  isEligibleForFreeGsAffiliateAccount: Scalars['Boolean']['output']
  isEmailVerified: Scalars['Boolean']['output']
  isExpenseReloadRequired: Scalars['Boolean']['output']
  isNewUser: Scalars['Boolean']['output']
  isQualifiedForFirstFreeCard: Scalars['Boolean']['output']
  isRep: Scalars['Boolean']['output']
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']['output']
  isTrialAffiliate: Scalars['Boolean']['output']
  labels: Array<Label>
  lastName: Scalars['String']['output']
  loyaltyRewards: Scalars['Int']['output']
  phoneNumber: Scalars['String']['output']
  plan: Plan
  pointPrice: Scalars['Int']['output']
  points: Amount
  pointsTransitionSends: Scalars['Int']['output']
  possibleSponsors: Array<User>
  profileImageUrl?: Maybe<Scalars['URL']['output']>
  promptingProLoginUrl: Scalars['URL']['output']
  promptingsCoach?: Maybe<PromptingsCoach>
  promptinguJwt: Scalars['String']['output']
  promptinguLoginUrl?: Maybe<Scalars['URL']['output']>
  scheduledSends: Scalars['Int']['output']
  settings?: Maybe<UserSettings>
  shippingAddress?: Maybe<Address>
  shouldDisplayUpsale: Scalars['Boolean']['output']
  socialSharing: SocialSharing
  sponsor?: Maybe<User>
  storefront?: Maybe<Storefront>
  streamMarketingLoginUrl?: Maybe<Scalars['URL']['output']>
  stripeCustomerId?: Maybe<Scalars['String']['output']>
  stripeSource?: Maybe<StripeSource>
  stripeSubscription?: Maybe<StripeSubscription>
  subscriptions: Array<StripeSubscription>
  systemSends: Scalars['Int']['output']
  token: Scalars['ID']['output']
  type: UserType
  username: Scalars['String']['output']
  verifiedPhoneNumber?: Maybe<PhoneNumber>
}

export type AccountHasEverHadAssetSlugArgs = {
  slug: Scalars['String']['input']
}

export type AccountHasFullAccessFeatureArgs = {
  featureType: FullAccessFeatureType
}

export type AccountHasRmCourseArgs = {
  name: Scalars['String']['input']
}

export type AccountProfileImageUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type Plan = {
  __typename?: 'Plan'
  backgroundEndColor: Scalars['String']['output']
  backgroundStartColor: Scalars['String']['output']
  bulkCardCost: Scalars['Float']['output']
  cardCost: Scalars['Float']['output']
  color?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['JSONString']['output']>
  giftDiscount: Scalars['Float']['output']
  icon: PlansPlanIconChoices
  id: Scalars['ID']['output']
  isAddon: Scalars['Boolean']['output']
  isExpenseReloadRequired: Scalars['Boolean']['output']
  isTrial: Scalars['Boolean']['output']
  pillCopy: Scalars['String']['output']
  price: Amount
  relatedPackage?: Maybe<RelatedPlanPackage>
  stripeId: Scalars['String']['output']
  subtitle?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

/** An enumeration. */
export enum PlansPlanIconChoices {
  /** 10_card */
  A_10Card = 'A_10_CARD',
  /** 15_card */
  A_15Card = 'A_15_CARD',
  /** 20_card */
  A_20Card = 'A_20_CARD',
  /** 25_card */
  A_25Card = 'A_25_CARD',
  /** automated_card */
  AutomatedCard = 'AUTOMATED_CARD',
  /** brochure */
  Brochure = 'BROCHURE',
  /** businesspreneur */
  Businesspreneur = 'BUSINESSPRENEUR',
  /** campaign_card */
  CampaignCard = 'CAMPAIGN_CARD',
  /** cancel */
  Cancel = 'CANCEL',
  /** card */
  Card = 'CARD',
  /** card_error */
  CardError = 'CARD_ERROR',
  /** card_pack */
  CardPack = 'CARD_PACK',
  /** catalog_card */
  CatalogCard = 'CATALOG_CARD',
  /** check */
  Check = 'CHECK',
  /** check_mark */
  CheckMark = 'CHECK_MARK',
  /** contact */
  Contact = 'CONTACT',
  /** danger */
  Danger = 'DANGER',
  /** database */
  Database = 'DATABASE',
  /** double_check */
  DoubleCheck = 'DOUBLE_CHECK',
  /** edit */
  Edit = 'EDIT',
  /** empty_gift */
  EmptyGift = 'EMPTY_GIFT',
  /** enhanced_storage */
  EnhancedStorage = 'ENHANCED_STORAGE',
  /** entrepreneur */
  Entrepreneur = 'ENTREPRENEUR',
  /** essential_packs */
  EssentialPacks = 'ESSENTIAL_PACKS',
  /** exp_calendar */
  ExpCalendar = 'EXP_CALENDAR',
  /** gift */
  Gift = 'GIFT',
  /** group_send_card */
  GroupSendCard = 'GROUP_SEND_CARD',
  /** heartfelt */
  Heartfelt = 'HEARTFELT',
  /** info */
  Info = 'INFO',
  /** multi_touch_card */
  MultiTouchCard = 'MULTI_TOUCH_CARD',
  /** multiple_cards */
  MultipleCards = 'MULTIPLE_CARDS',
  /** package */
  Package = 'PACKAGE',
  /** paper_plane */
  PaperPlane = 'PAPER_PLANE',
  /** paper_plane_flying */
  PaperPlaneFlying = 'PAPER_PLANE_FLYING',
  /** paper_plane_with_heart */
  PaperPlaneWithHeart = 'PAPER_PLANE_WITH_HEART',
  /** personal_hand_writing */
  PersonalHandWriting = 'PERSONAL_HAND_WRITING',
  /** personal_signature */
  PersonalSignature = 'PERSONAL_SIGNATURE',
  /** photo_drop_card */
  PhotoDropCard = 'PHOTO_DROP_CARD',
  /** premium_storage */
  PremiumStorage = 'PREMIUM_STORAGE',
  /** replace */
  Replace = 'REPLACE',
  /** schedule_card */
  ScheduleCard = 'SCHEDULE_CARD',
  /** search */
  Search = 'SEARCH',
  /** shopping_cart */
  ShoppingCart = 'SHOPPING_CART',
  /** smile_card */
  SmileCard = 'SMILE_CARD',
  /** smile_card_w_heart */
  SmileCardWHeart = 'SMILE_CARD_W_HEART',
  /** smile_card_w_hearts */
  SmileCardWHearts = 'SMILE_CARD_W_HEARTS',
  /** solopreneur */
  Solopreneur = 'SOLOPRENEUR',
  /** ticket */
  Ticket = 'TICKET',
  /** time_block */
  TimeBlock = 'TIME_BLOCK',
  /** upload */
  Upload = 'UPLOAD',
  /** user */
  User = 'USER',
  /** v_check */
  VCheck = 'V_CHECK',
  /** x */
  X = 'X',
}

export type Amount = AmountInterface & {
  __typename?: 'Amount'
  amount: Scalars['Int']['output']
  currency: Currency
  description: Scalars['String']['output']
}

export type AmountInterface = {
  amount: Scalars['Int']['output']
  currency: Currency
  description: Scalars['String']['output']
}

export enum Currency {
  /** Points */
  Soc = 'SOC',
  /** Unavailable */
  Unavailable = 'UNAVAILABLE',
  /** Cents */
  Usd = 'USD',
}

export type RelatedPlanPackage = {
  __typename?: 'RelatedPlanPackage'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** This is a non-recurring package that matches the features of the subscription */
  saleorProductId: Scalars['String']['output']
  shouldShowByDefault: Scalars['Boolean']['output']
  title: Scalars['String']['output']
}

export type AddPaymentSettingsDailyCap = {
  __typename?: 'AddPaymentSettingsDailyCap'
  available: Scalars['Int']['output']
  cap: Scalars['Int']['output']
}

export type Address = {
  __typename?: 'Address'
  address1: Scalars['String']['output']
  address2: Scalars['String']['output']
  city: Scalars['String']['output']
  company: Scalars['String']['output']
  country: Scalars['String']['output']
  errorMessages: Array<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  postalCode: Scalars['String']['output']
  state: Scalars['String']['output']
  userVerified: Scalars['Boolean']['output']
}

export type DailyHeartfeltCap = {
  __typename?: 'DailyHeartfeltCap'
  available: Scalars['Int']['output']
  cap: Scalars['Int']['output']
}

export type DetailedReferralCredits = DetailedReferralCreditsInterface & {
  __typename?: 'DetailedReferralCredits'
  amount: Amount
  expirationDate: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type DetailedReferralCreditsInterface = {
  amount: Amount
  expirationDate: Scalars['String']['output']
  id: Scalars['Int']['output']
}

export type ExpenseReload = {
  __typename?: 'ExpenseReload'
  /** @deprecated Use isEnabled instead. */
  enabled: Scalars['Boolean']['output']
  isEnabled: Scalars['Boolean']['output']
  isRequired: Scalars['Boolean']['output']
  purchaseAmount: Scalars['Int']['output']
  threshold: Scalars['Int']['output']
}

export type Flag = {
  __typename?: 'Flag'
  id: Scalars['ID']['output']
  /** The human/computer readable name. */
  name: Scalars['String']['output']
}

/** An enumeration. */
export enum FullAccessFeatureType {
  CampaignStore = 'CAMPAIGN_STORE',
  CampaignStoreAccess = 'CAMPAIGN_STORE_ACCESS',
  CardCustomBacks = 'CARD_CUSTOM_BACKS',
  CardHistoryResends = 'CARD_HISTORY_RESENDS',
  CardMultiSends = 'CARD_MULTI_SENDS',
  CardScheduling = 'CARD_SCHEDULING',
  ContactManager = 'CONTACT_MANAGER',
  FreeAffiliate = 'FREE_AFFILIATE',
  FullPhotoStorage = 'FULL_PHOTO_STORAGE',
  LegacyPostageCost = 'LEGACY_POSTAGE_COST',
  PremiumSubscriptionOptions = 'PREMIUM_SUBSCRIPTION_OPTIONS',
}

export type HeartfeltDailyCap = {
  __typename?: 'HeartfeltDailyCap'
  available: Scalars['Int']['output']
  cap: Scalars['Int']['output']
}

/** An enumeration. */
export enum Label {
  Affiliate = 'AFFILIATE',
  AffiliateMissingW8 = 'AFFILIATE_MISSING_W8',
  AffiliateMissingW9 = 'AFFILIATE_MISSING_W9',
  GiftingCommittee = 'GIFTING_COMMITTEE',
  GsaSubscriptionPaymentFailure = 'GSA_SUBSCRIPTION_PAYMENT_FAILURE',
  HasDuplicateEmail = 'HAS_DUPLICATE_EMAIL',
  HasDuplicateEmailClaimed = 'HAS_DUPLICATE_EMAIL_CLAIMED',
  HasFreeCampaignStore = 'HAS_FREE_CAMPAIGN_STORE',
  HasInvalidEmail = 'HAS_INVALID_EMAIL',
  HasPointSubscription = 'HAS_POINT_SUBSCRIPTION',
  HasRelationshipMarketing = 'HAS_RELATIONSHIP_MARKETING',
  NeedsAffiliateAccInfo = 'NEEDS_AFFILIATE_ACC_INFO',
  NeedsCustomerReferralOptIn = 'NEEDS_CUSTOMER_REFERRAL_OPT_IN',
  NeedsGsAffiliateOptIn = 'NEEDS_GS_AFFILIATE_OPT_IN',
  NowsiteActive = 'NOWSITE_ACTIVE',
  NowsiteNeedSetup = 'NOWSITE_NEED_SETUP',
  PriorAffiliate = 'PRIOR_AFFILIATE',
  ProductLaunch = 'PRODUCT_LAUNCH',
  ShouldHideRevolutionOffice = 'SHOULD_HIDE_REVOLUTION_OFFICE',
  Staff = 'STAFF',
  ZeroPoint = 'ZERO_POINT',
}

export type User = {
  __typename?: 'User'
  address?: Maybe<Address>
  createdAt: Scalars['DateTime']['output']
  dateJoined: Scalars['DateTime']['output']
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  formattedPhoneNumber: Scalars['String']['output']
  genealogyId: Scalars['Int']['output']
  hasRMCourse: Scalars['Boolean']['output']
  hasUnlimitedCards: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isAffiliate: Scalars['Boolean']['output']
  isBetterWayPromo: Scalars['Boolean']['output']
  isEligibleForFreeGsAffiliateAccount: Scalars['Boolean']['output']
  isTrialAffiliate: Scalars['Boolean']['output']
  lastName: Scalars['String']['output']
  profileImageUrl?: Maybe<Scalars['URL']['output']>
  promptingsCoach?: Maybe<PromptingsCoachPublic>
  shippingAddress?: Maybe<Address>
  socialSharing: SocialSharing
  storefront?: Maybe<Storefront>
  username: Scalars['String']['output']
}

export type UserHasRmCourseArgs = {
  name: Scalars['String']['input']
}

export type UserProfileImageUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type PromptingsCoachPublic = {
  __typename?: 'PromptingsCoachPublic'
  dateAttendedCertification?: Maybe<Scalars['Date']['output']>
  dateCoachingPurchased?: Maybe<Scalars['Date']['output']>
  dateTrainingCompleted?: Maybe<Scalars['Date']['output']>
  dateTrainingExpires?: Maybe<Scalars['Date']['output']>
  dateTrainingPurchased?: Maybe<Scalars['Date']['output']>
  isCertified: Scalars['Boolean']['output']
  isEmailPublic: Scalars['Boolean']['output']
  isPhoneNumberPublic: Scalars['Boolean']['output']
  trainerEmail?: Maybe<Scalars['String']['output']>
  trainerId?: Maybe<Scalars['Int']['output']>
  trainingVideosCompleted: Scalars['Int']['output']
}

export type SocialSharing = {
  __typename?: 'SocialSharing'
  shareLink: Scalars['String']['output']
  welcomeMessage: Scalars['String']['output']
}

export type Storefront = {
  __typename?: 'Storefront'
  biography?: Maybe<Scalars['String']['output']>
  mugshot?: Maybe<Image>
  /**
   * An optional easy to remember path which will override the default
   * distrubutor number. For example,
   * ``sendoutcards.com/storefront/jordanadler`` instead of
   * ``sendoutcards.com/storefront/1234567``.
   */
  slug?: Maybe<Scalars['String']['output']>
}

export type Image = {
  __typename?: 'Image'
  createdAt: Scalars['DateTime']['output']
  height: Scalars['Int']['output']
  id: Scalars['ID']['output']
  isSticker: Scalars['Boolean']['output']
  /** @deprecated Use path instead. */
  name: Scalars['String']['output']
  path: Scalars['String']['output']
  stickerUrl?: Maybe<Scalars['URL']['output']>
  url: Scalars['URL']['output']
  width: Scalars['Int']['output']
}

export type ImageUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type PromptingsCoach = {
  __typename?: 'PromptingsCoach'
  dateAttendedCertification?: Maybe<Scalars['Date']['output']>
  dateCoachingPurchased?: Maybe<Scalars['Date']['output']>
  dateTrainingCompleted?: Maybe<Scalars['Date']['output']>
  dateTrainingExpires?: Maybe<Scalars['Date']['output']>
  dateTrainingPurchased?: Maybe<Scalars['Date']['output']>
  discounts: Array<PromptingsCoachDiscount>
  isCertified: Scalars['Boolean']['output']
  isEmailPublic: Scalars['Boolean']['output']
  isPhoneNumberPublic: Scalars['Boolean']['output']
  /** If true this will omit checks for certified coach count */
  shouldWaveCertifiedCoachRequirement: Scalars['Boolean']['output']
  trainees: Array<User>
  trainerEmail?: Maybe<Scalars['String']['output']>
  trainerId?: Maybe<Scalars['Int']['output']>
  trainingVideosCompleted: Scalars['Int']['output']
}

export type PromptingsCoachDiscount = {
  __typename?: 'PromptingsCoachDiscount'
  amountOff: Scalars['Float']['output']
  code: Scalars['String']['output']
  eventbriteGroupId: Scalars['String']['output']
  expirationDatetime: Scalars['DateTime']['output']
  /** This flag will be set to true when the user tries to fetch it but it has already been used. */
  isUsed: Scalars['Boolean']['output']
  percentOff: Scalars['Float']['output']
}

export type UserSettings = {
  __typename?: 'UserSettings'
  defaultBackPanel?: Maybe<Layout>
  isLeaderBoardHidden: Scalars['Boolean']['output']
  paymentHold: Scalars['Boolean']['output']
  printRecipientCompany: Scalars['Boolean']['output']
  receivesNotificationEmails: Scalars['Boolean']['output']
  receivesPromoEmails: Scalars['Boolean']['output']
  receivesReceiptEmails: Scalars['Boolean']['output']
  receivesReminderEmails: Scalars['Boolean']['output']
  remindersAdvanceNoticeDays: Scalars['Int']['output']
  remindersAdvanceNoticeDaysInternational: Scalars['Int']['output']
}

export type Layout = OwnerInterface & {
  __typename?: 'Layout'
  category?: Maybe<LayoutCategory>
  dimensions?: Maybe<LayoutDimensions>
  id: Scalars['ID']['output']
  owner: Owner
  panel: Panel
  thumbnail: Image
}

export type OwnerInterface = {
  owner: Owner
}

export enum Owner {
  System = 'SYSTEM',
  User = 'USER',
}

export enum LayoutCategory {
  BackPanels = 'BACK_PANELS',
  BasicLayouts = 'BASIC_LAYOUTS',
}

export enum LayoutDimensions {
  Horizontal = 'HORIZONTAL',
  Postcard = 'POSTCARD',
  Vertical = 'VERTICAL',
}

export type Panel = {
  __typename?: 'Panel'
  backgroundColor?: Maybe<Color>
  /** @deprecated Use backgroundColor instead */
  backgroundColorObject?: Maybe<Color>
  elements: Array<Element>
  height: Scalars['Int']['output']
  isFullBleed: Scalars['Boolean']['output']
  isLocked: Scalars['Boolean']['output']
  location: Scalars['Int']['output']
  name: Scalars['String']['output']
  previewUrl: Scalars['URL']['output']
  width: Scalars['Int']['output']
}

export type PanelPreviewUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type Color = {
  __typename?: 'Color'
  blue: Scalars['Int']['output']
  green: Scalars['Int']['output']
  red: Scalars['Int']['output']
}

export type Element = {
  __typename?: 'Element'
  draggable?: Maybe<Scalars['Boolean']['output']>
  height: Scalars['Float']['output']
  image?: Maybe<ElementPicture>
  locked: Scalars['Boolean']['output']
  rotation: Scalars['Float']['output']
  sticker?: Maybe<ElementSticker>
  text?: Maybe<ElementText>
  type: Scalars['String']['output']
  width: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
  z: Scalars['Int']['output']
}

export type ElementPicture = {
  __typename?: 'ElementPicture'
  filter?: Maybe<Filter>
  image?: Maybe<Image>
  isSticker: Scalars['Boolean']['output']
  position: Position
  scale: Scalars['Float']['output']
}

export type Filter = {
  __typename?: 'Filter'
  name: Scalars['String']['output']
  urlEncodedValues: Scalars['String']['output']
  values: Scalars['JSONString']['output']
}

export type Position = {
  __typename?: 'Position'
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type ElementSticker = {
  __typename?: 'ElementSticker'
  position: Position
  scale: Scalars['Float']['output']
  sticker?: Maybe<EditorImageType>
}

export type EditorImageType = {
  __typename?: 'EditorImageType'
  createdAt: Scalars['DateTime']['output']
  height: Scalars['Int']['output']
  id: Scalars['ID']['output']
  url: Scalars['String']['output']
  width: Scalars['Int']['output']
}

export type EditorImageTypeUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type ElementText = {
  __typename?: 'ElementText'
  paragraphs: Array<Paragraph>
}

export type Paragraph = {
  __typename?: 'Paragraph'
  alignment: TextAlignment
  textFragments: Array<TextFragment>
}

export enum TextAlignment {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export type TextFragment = {
  __typename?: 'TextFragment'
  font: Font
  fontSize: Scalars['Int']['output']
  isReplacement: Scalars['Boolean']['output']
  text: Scalars['String']['output']
  textColor: Color
}

export type Font = {
  __typename?: 'Font'
  displayName: Scalars['String']['output']
  fontName: Scalars['String']['output']
  id: Scalars['ID']['output']
  isCustomFont: Scalars['Boolean']['output']
  /** Check this box to prevent users from using this font. */
  isDisabled: Scalars['Boolean']['output']
  isSig: Scalars['Boolean']['output']
  postscriptName: Scalars['String']['output']
  ttfFile: Scalars['String']['output']
}

export type StripeSource = {
  id: Scalars['ID']['output']
}

export type StripeSubscription = {
  __typename?: 'StripeSubscription'
  currentPeriodEnd: Scalars['DateTime']['output']
  features?: Maybe<SubscriptionFeatures>
  id: Scalars['ID']['output']
  interval: Scalars['String']['output']
  intervalCount: Scalars['Int']['output']
  isRenewable: Scalars['Boolean']['output']
  metadata?: Maybe<SubscriptionMetadata>
  name: Scalars['String']['output']
  price: Scalars['Int']['output']
  setToCancel: Scalars['Boolean']['output']
  sku: Scalars['String']['output']
  startDate: Scalars['DateTime']['output']
  status: SubscriptionStatus
}

export type SubscriptionFeatures = {
  __typename?: 'SubscriptionFeatures'
  cardPackageSends?: Maybe<Scalars['Int']['output']>
  dailyHeartfeltSends?: Maybe<Scalars['Int']['output']>
  groupSends?: Maybe<Scalars['Int']['output']>
  heartfeltSends?: Maybe<Scalars['Int']['output']>
  loyaltyRewards?: Maybe<Scalars['Int']['output']>
  scheduledSends?: Maybe<Scalars['Int']['output']>
  systemSends?: Maybe<Scalars['Int']['output']>
}

export type SubscriptionMetadata = {
  __typename?: 'SubscriptionMetadata'
  planOnCancel?: Maybe<Scalars['String']['output']>
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  PastDue = 'PAST_DUE',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID',
}

/** An enumeration. */
export enum UserType {
  /** Business Bundle */
  Bbc = 'BBC',
  /** Business Bundle Distributor */
  Bbd = 'BBD',
  /** Pay-as-you-go Customer */
  C = 'C',
  /** Pay-as-you-go Distributor */
  D = 'D',
  /** Free Greener Still Affiliate */
  Fgsa = 'FGSA',
  /** Greener Still Affiliate */
  Gsa = 'GSA',
  /** Independent Distrib, Deprecated */
  Id = 'ID',
  /** Customer, Deprecated */
  Jo = 'JO',
  /** Marketing Bundle */
  Mbc = 'MBC',
  /** Marketing Bundle Distributor */
  Mbd = 'MBD',
  /** Marketing Distrib, Deprecated */
  Md = 'MD',
  /** Personal Bundle */
  Pbc = 'PBC',
  /** Personal Bundle Distributor */
  Pbd = 'PBD',
  /** Greener Still Customer */
  Rc = 'RC',
  /** Sample Account */
  S = 'S',
  /** Subaccount */
  Sa = 'SA',
  /** SP123 Temp Account */
  Sp123 = 'SP123',
  /** Silver Customer, Deprecated */
  Wcs = 'WCS',
}

/** A normalized representation of a valid international phone number. */
export type PhoneNumber = {
  __typename?: 'PhoneNumber'
  /** The phone number's country. */
  country: PhoneNumberCountry
  /** An optional extension up to 40 characters long that only contains digits 0-9. */
  extension?: Maybe<Scalars['String']['output']>
  /** A formatted version of this phone number. Defaults to the international format. */
  formattedNumber: Scalars['String']['output']
  /** The national phone number represented as a long (64-bit integer). */
  nationalNumber: Scalars['Long']['output']
  /**
   * The number of zeros proceeding the national number.
   *         This only applies in some countries and is typically zero.
   */
  numberOfLeadingZeros: Scalars['Int']['output']
}

/** A normalized representation of a valid international phone number. */
export type PhoneNumberFormattedNumberArgs = {
  format?: InputMaybe<PhoneNumberFormat>
}

/** A country with a calling code. */
export type PhoneNumberCountry = {
  __typename?: 'PhoneNumberCountry'
  /**
   * The country's calling code (e.g. 1 for the US and 44 for the UK).
   *          Multiple countries may share the same calling code.
   */
  callingCode: Scalars['Int']['output']
  /** The country's ISO 3166 alpha-2 code. */
  isoCode: Scalars['String']['output']
  /** The name of the country. */
  name: Scalars['String']['output']
}

/** An international phone number format. */
export enum PhoneNumberFormat {
  /** International number format with hyphens, spaces, etc. removed, e.g. '+41446681800'. */
  E164 = 'E164',
  /** Internationally dialable number format, e.g. '+41 44 668 1800'. */
  International = 'INTERNATIONAL',
  /** Domestically dialable number format, e.g. '044 668 1800'. */
  National = 'NATIONAL',
  /** RFC3966 compliant number format, e.g. 'tel:+41-44-668-1800'. */
  Rfc3966 = 'RFC3966',
}

export type Country = {
  __typename?: 'Country'
  /** ISO3166 alpha2 */
  alpha2: Scalars['String']['output']
  postalName: Scalars['String']['output']
  subdivisions: Array<Subdivision>
}

export type Subdivision = {
  __typename?: 'Subdivision'
  /** The abbreviation or name used to identify this subdivision. */
  abbreviationOrName: Scalars['String']['output']
  code: Scalars['String']['output']
  name: Scalars['String']['output']
  parent?: Maybe<Subdivision>
  type: Scalars['String']['output']
}

export enum AggregateEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR',
}

export type AggregateReceipt = {
  __typename?: 'AggregateReceipt'
  aggregateType: AggregateEnum
  date: Scalars['DateTime']['output']
  total: ItemizedCost
}

export type ItemizedCost = {
  __typename?: 'ItemizedCost'
  entries: Array<LabeledPrice>
  total: Array<Price>
}

/** Labeled variation of price */
export type LabeledPrice = LabeledInterface & {
  __typename?: 'LabeledPrice'
  amount: Scalars['Int']['output']
  asString: Scalars['String']['output']
  currency: CurrencyType
  /** @deprecated `asString` is prefered */
  description?: Maybe<Scalars['String']['output']>
  label: Scalars['String']['output']
}

/** Adds label field to a particular field. */
export type LabeledInterface = {
  label: Scalars['String']['output']
}

export enum CurrencyType {
  Cardtoken = 'CARDTOKEN',
  Credit = 'CREDIT',
  Expense = 'EXPENSE',
  Free = 'FREE',
  Point = 'POINT',
  Usd = 'USD',
}

export type Price = {
  __typename?: 'Price'
  amount: Scalars['Int']['output']
  asString: Scalars['String']['output']
  currency: CurrencyType
  /** @deprecated `asString` is prefered */
  description?: Maybe<Scalars['String']['output']>
}

export type AwardsGroupFilter = {
  by?: InputMaybe<AwardsGroupFilterBy>
}

export type AwardsGroupFilterBy = {
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type AwardGroup = {
  __typename?: 'AwardGroup'
  awards: Array<Award>
  imageUrl: Scalars['URL']['output']
  name: Scalars['String']['output']
}

export type AwardGroupAwardsArgs = {
  filter?: InputMaybe<AwardsFilter>
}

export type AwardGroupImageUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type AwardsFilter = {
  by?: InputMaybe<AwardsFilterBy>
}

export type AwardsFilterBy = {
  hasEarned?: InputMaybe<Scalars['Boolean']['input']>
}

export type Award = {
  __typename?: 'Award'
  completedDescription: Scalars['String']['output']
  description: Scalars['String']['output']
  graphicUrl: Scalars['URL']['output']
  iconUrl: Scalars['URL']['output']
  id: Scalars['ID']['output']
  isLocked: Scalars['Boolean']['output']
  link?: Maybe<MarketingUrl>
  order: Scalars['Int']['output']
  progress: Scalars['Float']['output']
  qualifications: Array<Maybe<Qualification>>
  threshold: Scalars['Int']['output']
  title: Scalars['String']['output']
}

export type AwardGraphicUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type AwardIconUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type MarketingUrl = {
  __typename?: 'MarketingURL'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  url: Scalars['String']['output']
}

export type Qualification = {
  __typename?: 'Qualification'
  description: Scalars['String']['output']
  graphic: Scalars['String']['output']
  id: Scalars['ID']['output']
  link?: Maybe<MarketingUrl>
  progress: Scalars['Float']['output']
  threshold: Scalars['Float']['output']
  title: Scalars['String']['output']
}

export type MarketingBanner = {
  __typename?: 'MarketingBanner'
  createdAt: Scalars['DateTime']['output']
  htmlContent: Scalars['String']['output']
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type Campaign = {
  __typename?: 'Campaign'
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  /** Can this campaign be shared by customers to other customers? */
  isShareable: Scalars['Boolean']['output']
  lines: Array<CampaignLine>
  name: Scalars['String']['output']
  orders: PaginatedCampaignOrders
  returnAddress: Address
  updatedAt: Scalars['DateTime']['output']
}

export type CampaignOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type CampaignLine = {
  __typename?: 'CampaignLine'
  card?: Maybe<Card>
  giftVariation?: Maybe<GiftVariation>
  name?: Maybe<Scalars['String']['output']>
  sendDelay: SendDelay
}

export type Card = {
  __typename?: 'Card'
  backPanelLocation: Scalars['Int']['output']
  backPreviewPath: Scalars['String']['output']
  backPreviewUrl: Scalars['URL']['output']
  cost: ItemizedCost
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  digitalCard?: Maybe<DigitalCard>
  flapPreviewPath?: Maybe<Scalars['String']['output']>
  flapPreviewUrl?: Maybe<Scalars['URL']['output']>
  frontPreviewPath: Scalars['String']['output']
  frontPreviewUrl: Scalars['URL']['output']
  id: Scalars['ID']['output']
  insidePreviewPath?: Maybe<Scalars['String']['output']>
  insidePreviewUrl?: Maybe<Scalars['URL']['output']>
  /** If card panels are wider then they are tall, it is a horizontal card. */
  isHorizontal: Scalars['Boolean']['output']
  /** Used to determine if card was created in the new card editor */
  isNewEditorCard: Scalars['Boolean']['output']
  /** @deprecated Use panels instead unless you need to optimize for performance */
  optimizedLegacyPanels: Array<Panel>
  panels: Array<Panel>
  paperType: CardPaperType
  paperUpgradeCosts: Array<PaperCosts>
  points: Amount
  price: Amount
  sendableCard?: Maybe<SendableCard>
  showInPicturePlus: Scalars['Boolean']['output']
  type: CardType
  updatedAt: Scalars['DateTime']['output']
}

export type CardBackPreviewUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type CardFlapPreviewUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type CardFrontPreviewUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type CardInsidePreviewUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type DigitalCard = Node & {
  __typename?: 'DigitalCard'
  card: Card
  createdAt: Scalars['DateTime']['output']
  expiresAt?: Maybe<Scalars['DateTime']['output']>
  /** The ID of the object */
  id: Scalars['ID']['output']
  isPrintable: Scalars['Boolean']['output']
  order?: Maybe<DigitalCardOrder>
  pk: Scalars['ID']['output']
  shareMessageBody: Scalars['String']['output']
  shareMessageTitle: Scalars['String']['output']
  shareStatus: DigitalcardDigitalCardShareStatusChoices
  sponsor: User
  tokens: Array<DigitalCardToken>
}

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output']
}

export type DigitalCardOrder = {
  __typename?: 'DigitalCardOrder'
  data: DigitalCardOrderData
  isPaid: Scalars['Boolean']['output']
}

export type DigitalCardOrderData = {
  __typename?: 'DigitalCardOrderData'
  items: Array<DigitalCardOrderDataItem>
  subtotal: Scalars['Int']['output']
  tax: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type DigitalCardOrderDataItem = {
  __typename?: 'DigitalCardOrderDataItem'
  amount: Scalars['Int']['output']
  discounts: Array<Maybe<DigitalCardOrderDataItemDiscount>>
  name: Scalars['String']['output']
}

export type DigitalCardOrderDataItemDiscount = {
  __typename?: 'DigitalCardOrderDataItemDiscount'
  amount: Scalars['Int']['output']
  name: Scalars['String']['output']
}

/** An enumeration. */
export enum DigitalcardDigitalCardShareStatusChoices {
  /** User has cancelled the digital card. */
  Cancelled = 'CANCELLED',
  /** User has created the digital card share link. */
  Created = 'CREATED',
  /** Share link has expired. */
  Expired = 'EXPIRED',
  /** Digital card has been refunded. */
  Refunded = 'REFUNDED',
  /** User has shared the digital card link. */
  Shared = 'SHARED',
}

export type DigitalCardToken = {
  __typename?: 'DigitalCardToken'
  code: Scalars['String']['output']
  permissions: TokenPermissions
  pk: Scalars['ID']['output']
  token: Scalars['String']['output']
}

/** An enumeration. */
export enum TokenPermissions {
  Private = 'PRIVATE',
  Protected = 'PROTECTED',
  Public = 'PUBLIC',
}

/** An enumeration. */
export enum CardPaperType {
  /** Heavy Paper Stock */
  Hvy = 'HVY',
  /** Premium Card Stock */
  Pre = 'PRE',
  /** Standard Card Stock */
  Std = 'STD',
}

export type PaperCosts = {
  __typename?: 'PaperCosts'
  upgrade: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type SendableCard = {
  __typename?: 'SendableCard'
  cardTemplate?: Maybe<Card>
  cost: ItemizedCost
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  designColors?: Maybe<Array<Maybe<Color>>>
  featured: Scalars['Boolean']['output']
  frontImage: Image
  frontText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  insideRightImage?: Maybe<Image>
  insideRightText?: Maybe<Scalars['String']['output']>
  isFavorite: Scalars['Boolean']['output']
  /** True if the front image is wider then it is tall. This is set automatically in the admin. */
  isHorizontal: Scalars['Boolean']['output']
  /** @deprecated No more premium cards. */
  isPremium: Scalars['Boolean']['output']
  tags: Array<Scalars['String']['output']>
  title: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  variationColor: Scalars['String']['output']
  variations: Array<SendableCard>
}

export type SendableCardCostArgs = {
  cardType?: InputMaybe<CardType>
  paperType?: InputMaybe<CardPaperType>
}

export type SendableCardDesignColorsArgs = {
  quality?: InputMaybe<Scalars['Int']['input']>
  quantity?: InputMaybe<Scalars['Int']['input']>
}

export enum CardType {
  Flatcard = 'FLATCARD',
  Postcard = 'POSTCARD',
  ThreePanel = 'THREE_PANEL',
  TwoPanel = 'TWO_PANEL',
  TwoPanelBig = 'TWO_PANEL_BIG',
}

export type GiftVariation = {
  __typename?: 'GiftVariation'
  /** Contents of the package */
  contents?: Maybe<Scalars['String']['output']>
  defaultImageUrl: Scalars['URL']['output']
  /** Longer description of this variation. Keep it 500 characters-ish */
  description?: Maybe<Scalars['String']['output']>
  gift: Gift
  id: Scalars['ID']['output']
  imageUrls: Array<Scalars['URL']['output']>
  /** When True, this gift can be sent for free if the user has free gift tokens */
  isEligibleFreeGift: Scalars['Boolean']['output']
  isSendable: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  packaging?: Maybe<Scalars['String']['output']>
  points: Amount
  price: Price
  quantity?: Maybe<Scalars['Int']['output']>
  /** Special shipping instructions/information */
  shipping?: Maybe<Scalars['String']['output']>
  sku: Scalars['String']['output']
}

export type GiftVariationDefaultImageUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type GiftVariationImageUrlsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type Gift = {
  __typename?: 'Gift'
  defaultImageUrl: Scalars['URL']['output']
  /** Automatically filled in. */
  defaultVariation?: Maybe<GiftVariation>
  id: Scalars['ID']['output']
  isOutOfStock: Scalars['Boolean']['output']
  /** Name of this product. e.x: Brownie 2-Pack, or Brownie */
  name: Scalars['String']['output']
  /** Generated for hot-links. */
  slug: Scalars['String']['output']
  variations: Array<GiftVariation>
}

export type GiftDefaultImageUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type SendDelay = {
  __typename?: 'SendDelay'
  delayNumber?: Maybe<Scalars['Int']['output']>
  delayType: SendDelayDelayType
  description: Scalars['String']['output']
  specificDate: Scalars['Date']['output']
  timeType: SendDelayTimeType
  type: SendDelayType
}

/** An enumeration. */
export enum SendDelayDelayType {
  /** Days */
  Day = 'DAY',
  /** Months */
  Mon = 'MON',
  /** Weeks */
  Wee = 'WEE',
}

/** An enumeration. */
export enum SendDelayTimeType {
  /** After */
  Aft = 'AFT',
  /** Before */
  Bef = 'BEF',
}

/** An enumeration. */
export enum SendDelayType {
  /** Anniversary */
  Ann = 'ANN',
  /** Birthday */
  Bir = 'BIR',
  /** Immediate */
  Imm = 'IMM',
  /** Specific Date */
  Spe = 'SPE',
}

export type PaginatedCampaignOrders = {
  __typename?: 'PaginatedCampaignOrders'
  count: Scalars['Int']['output']
  results: Array<Order>
}

export type Order = {
  __typename?: 'Order'
  bulkUpgradeCardQty?: Maybe<Scalars['Int']['output']>
  bulkUpgradeShippingAddress?: Maybe<Address>
  campaign?: Maybe<Campaign>
  contacts: Array<Contact>
  cost: ItemizedCost
  createdAt: Scalars['DateTime']['output']
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  /** @deprecated Use total */
  grandTotal: NamedTotal
  groups: Array<Group>
  id: Scalars['ID']['output']
  /** Returns contacts that are missing a name (i.e. firstName, lastName, companyName are all null) or where address1, city, postalCode or country are blank. If the country is the United States and a state is missing then it is also invalid. Also returns contacts without a birthday or anniversary if required by a send delay. */
  invalidContacts: Array<Contact>
  invalidSendDelayContacts: Array<Contact>
  isBulk: Scalars['Boolean']['output']
  isFree: Scalars['Boolean']['output']
  isSendable: Scalars['Boolean']['output']
  isSent: Scalars['Boolean']['output']
  lines: Array<Line>
  /** @deprecated Prices are now strongly typed */
  pointsAndExpense: Array<NamedTotal>
  /** Should the user be required to complete captcha to send order */
  requireCaptcha?: Maybe<Scalars['Boolean']['output']>
  returnAddress?: Maybe<Address>
  sendType: CardSendType
  /** @deprecated Use cost */
  totals: Array<NamedTotal>
  updatedAt: Scalars['DateTime']['output']
  warnings: Array<Error>
}

export type Contact = {
  __typename?: 'Contact'
  address1: Scalars['String']['output']
  address2: Scalars['String']['output']
  anniversary?: Maybe<OptionalYearDate>
  birthday?: Maybe<OptionalYearDate>
  /** @deprecated Use cellPhone instead. */
  cellNumber?: Maybe<Scalars['String']['output']>
  cellPhone?: Maybe<Scalars['String']['output']>
  children: Array<Contact>
  city: Scalars['String']['output']
  companyName: Scalars['String']['output']
  contactRequest?: Maybe<ContactRequest>
  country?: Maybe<Scalars['String']['output']>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  emailAddress?: Maybe<Scalars['String']['output']>
  faxNumber?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  groups: Array<Group>
  homePhone?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  invite?: Maybe<Invite>
  isPrimary: Scalars['Boolean']['output']
  lastName: Scalars['String']['output']
  notes: Array<Note>
  parent?: Maybe<Contact>
  postalCode: Scalars['String']['output']
  /** @deprecated Use isPrimary instead */
  primary: Scalars['Boolean']['output']
  spouse?: Maybe<Contact>
  state: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  website: Scalars['String']['output']
  /** @deprecated Use website instead */
  websites: Array<Website>
  workPhone?: Maybe<Scalars['String']['output']>
}

export type OptionalYearDate = {
  __typename?: 'OptionalYearDate'
  day: Scalars['Int']['output']
  month: Scalars['Int']['output']
  year?: Maybe<Scalars['Int']['output']>
}

export type ContactRequest = {
  __typename?: 'ContactRequest'
  contact?: Maybe<Contact>
  expiresAt: Scalars['DateTime']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  requester: User
  status: ContactRequestStatusEnum
  token: Scalars['String']['output']
}

export enum ContactRequestStatusEnum {
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
}

export type Group = {
  __typename?: 'Group'
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  members: Array<Contact>
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type Invite = {
  __typename?: 'Invite'
  /** Contact from sponsor's relationship manager. */
  contact?: Maybe<Contact>
  createdAt: Scalars['DateTime']['output']
  hasProspectJoined: Scalars['Boolean']['output']
  hasSentCard: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  prospect?: Maybe<Prospect>
  socProContact?: Maybe<SocProContact>
  sponsor: User
  utmCampaign?: Maybe<Scalars['String']['output']>
  utmContent?: Maybe<Scalars['String']['output']>
  utmMedium?: Maybe<Scalars['String']['output']>
  utmSource?: Maybe<Scalars['String']['output']>
  utmStream?: Maybe<Scalars['String']['output']>
  utmTerm?: Maybe<Scalars['String']['output']>
}

export type Prospect = {
  __typename?: 'Prospect'
  address?: Maybe<Address>
  createdAt: Scalars['DateTime']['output']
  dateJoined: Scalars['DateTime']['output']
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  genealogyId: Scalars['Int']['output']
  hasRMCourse: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isAffiliate: Scalars['Boolean']['output']
  isBetterWayPromo: Scalars['Boolean']['output']
  isEligibleForFreeGsAffiliateAccount: Scalars['Boolean']['output']
  isTrialAffiliate: Scalars['Boolean']['output']
  lastName: Scalars['String']['output']
  ordersSent: Scalars['Int']['output']
  phoneNumber: Scalars['String']['output']
  profileImageUrl?: Maybe<Scalars['URL']['output']>
  shippingAddress?: Maybe<Address>
  socialSharing: SocialSharing
  storefront?: Maybe<Storefront>
  username: Scalars['String']['output']
}

export type ProspectHasRmCourseArgs = {
  name: Scalars['String']['input']
}

export type ProspectProfileImageUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type SocProContact = {
  __typename?: 'SocProContact'
  address: Scalars['String']['output']
  address2: Scalars['String']['output']
  city: Scalars['String']['output']
  contactId: Scalars['String']['output']
  country: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  fullName: Scalars['String']['output']
  homePhone: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  metadataBlob: Scalars['JSONString']['output']
  smsPhone: Scalars['String']['output']
  /** Contact from sponsor's relationship manager. */
  socContact?: Maybe<Contact>
  state: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  workPhone: Scalars['String']['output']
  zip: Scalars['String']['output']
}

export type Note = {
  __typename?: 'Note'
  date?: Maybe<CalendarDate>
  id: Scalars['ID']['output']
  note: Scalars['String']['output']
}

export type CalendarDate = {
  __typename?: 'CalendarDate'
  day: Scalars['Int']['output']
  month: Scalars['Int']['output']
  year: Scalars['Int']['output']
}

export type Website = {
  __typename?: 'Website'
  url?: Maybe<Scalars['String']['output']>
}

export type NamedTotal = AmountInterface & {
  __typename?: 'NamedTotal'
  amount: Scalars['Int']['output']
  currency: Currency
  description: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type Line = {
  __typename?: 'Line'
  baseCost: ItemizedCost
  card?: Maybe<Card>
  cost: ItemizedCost
  giftVariation?: Maybe<GiftVariation>
  isSendable: Scalars['Boolean']['output']
  recipientCount: Scalars['Int']['output']
  sendDelay: SendDelay
}

export enum CardSendType {
  Heartfelt = 'HEARTFELT',
  System = 'SYSTEM',
}

export type Error = {
  __typename?: 'Error'
  code?: Maybe<Scalars['Int']['output']>
  message?: Maybe<Scalars['String']['output']>
}

export type CampaignShare = {
  __typename?: 'CampaignShare'
  campaign: Campaign
  dateShared: Scalars['DateTime']['output']
  fromName: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type CardCategory = {
  __typename?: 'CardCategory'
  cards: Array<SendableCard>
  children: Array<CardCategory>
  /** Any change made to any of these properties in here could take several minutes to be updated on the site. */
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  thumbnail: Scalars['String']['output']
}

export type CardCategoryCardsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type Collection = {
  __typename?: 'Collection'
  backgroundEndColor: Scalars['String']['output']
  backgroundImageUrl?: Maybe<Scalars['URL']['output']>
  backgroundStartColor: Scalars['String']['output']
  cards: Array<SendableCard>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  reasonToSend: Scalars['Boolean']['output']
  textColor: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type CollectionBackgroundImageUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type CollectionCardsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type ContactShare = {
  __typename?: 'ContactShare'
  contact: Contact
  createdAt: Scalars['DateTime']['output']
  fromUser: User
  id: Scalars['ID']['output']
}

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: 'DjangoDebug'
  /** Raise exceptions for this API query. */
  exceptions?: Maybe<Array<Maybe<DjangoDebugException>>>
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>
}

/** Represents a single exception raised. */
export type DjangoDebugException = {
  __typename?: 'DjangoDebugException'
  /** The class of the exception */
  excType: Scalars['String']['output']
  /** The message of the exception */
  message: Scalars['String']['output']
  /** The stack trace */
  stack: Scalars['String']['output']
}

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: 'DjangoDebugSQL'
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String']['output']
  /** Duration of this database query in seconds. */
  duration: Scalars['Float']['output']
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']['output']>
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']['output']>
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean']['output']
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean']['output']
  /** JSON encoded database query parameters. */
  params: Scalars['String']['output']
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String']['output']
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']['output']>
  /** Start time of this database query. */
  startTime: Scalars['Float']['output']
  /** Stop time of this database query. */
  stopTime: Scalars['Float']['output']
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']['output']>
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']['output']>
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String']['output']
}

export type Device = {
  __typename?: 'Device'
  notificationToken: Scalars['String']['output']
  type: DeviceType
}

export enum DeviceType {
  Android = 'ANDROID',
  Ios = 'IOS',
}

export type DigitalCardConnection = {
  __typename?: 'DigitalCardConnection'
  /** Contains the nodes in this connection. */
  edges: Array<DigitalCardEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `DigitalCard` and its cursor. */
export type DigitalCardEdge = {
  __typename?: 'DigitalCardEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: DigitalCard
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type DoubleBonusReportType = {
  __typename?: 'DoubleBonusReportType'
  additionalPqvNeededToHit100PercentBonus: Scalars['String']['output']
  additionalPqvNeededToHit50PercentBonus: Scalars['String']['output']
  bonusDescription: Scalars['String']['output']
  doubleBonusEarnings: Scalars['String']['output']
  doubleBonusPercentAchieved: Scalars['String']['output']
  earnedAffiliateCommissionsMonthToDate: Scalars['String']['output']
  personalQualifyingVolume: Scalars['String']['output']
}

export type Release = {
  __typename?: 'Release'
  datePublished: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  featureList: Array<Feature>
  id: Scalars['ID']['output']
  noteSections: Array<ReleaseNoteSection>
  releaseDate?: Maybe<Scalars['String']['output']>
  releaseTitle: Scalars['String']['output']
  versionNumber: Scalars['String']['output']
}

export type Feature = {
  __typename?: 'Feature'
  arrowLocation?: Maybe<ReleaseNotesFeatureArrowLocationChoices>
  description: Scalars['String']['output']
  elementLocationId: Scalars['String']['output']
  id: Scalars['ID']['output']
  imageUrl: Scalars['URL']['output']
  isDismissable: Scalars['Boolean']['output']
  title: Scalars['String']['output']
}

export type FeatureImageUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

/** An enumeration. */
export enum ReleaseNotesFeatureArrowLocationChoices {
  /** Bottom Left */
  BottomLeft = 'BOTTOM_LEFT',
  /** Bottom Middle */
  BottomMiddle = 'BOTTOM_MIDDLE',
  /** Bottom Right */
  BottomRight = 'BOTTOM_RIGHT',
  /** Left Bottom */
  LeftBottom = 'LEFT_BOTTOM',
  /** Left Middle */
  LeftMiddle = 'LEFT_MIDDLE',
  /** Left Top */
  LeftTop = 'LEFT_TOP',
  /** Right Bottom */
  RightBottom = 'RIGHT_BOTTOM',
  /** Right Middle */
  RightMiddle = 'RIGHT_MIDDLE',
  /** Right Top */
  RightTop = 'RIGHT_TOP',
  /** Top Left */
  TopLeft = 'TOP_LEFT',
  /** Top Middle */
  TopMiddle = 'TOP_MIDDLE',
  /** Top Right */
  TopRight = 'TOP_RIGHT',
}

export type ReleaseNoteSection = {
  __typename?: 'ReleaseNoteSection'
  notes: Array<Scalars['String']['output']>
  releaseType: ReleaseNotesReleaseNoteSectionReleaseTypeChoices
}

/** An enumeration. */
export enum ReleaseNotesReleaseNoteSectionReleaseTypeChoices {
  /** fix */
  Fix = 'FIX',
  /** new */
  New = 'NEW',
  /** note */
  Note = 'NOTE',
}

export type GiftCategory = {
  __typename?: 'GiftCategory'
  children: Array<GiftCategory>
  gifts: Array<Gift>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type GiftCategoryGiftsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type Item = {
  __typename?: 'Item'
  campaign?: Maybe<Campaign>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['String']['output']>
  itemCode: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
  price: Scalars['Float']['output']
  shippable: Scalars['Boolean']['output']
  shippingDom: Scalars['Float']['output']
  shippingInt: Scalars['Float']['output']
}

export type ItemImageArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type ItemCategory = {
  __typename?: 'ItemCategory'
  backgroundImageUrl?: Maybe<Scalars['String']['output']>
  children: Array<ItemCategory>
  id: Scalars['ID']['output']
  items: Array<Item>
  name: Scalars['String']['output']
}

export type ItemCategoryBackgroundImageUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type ItemCategoryItemsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type LeaderBoardType = {
  __typename?: 'LeaderBoardType'
  assetId: Scalars['String']['output']
  challenges: Array<ChallengeType>
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  gradient?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  leaderCount: Scalars['Int']['output']
  listTitle: Scalars['String']['output']
  metricTitle: Scalars['String']['output']
  rankTitle: Scalars['String']['output']
  shouldShowPastChallenge: Scalars['Boolean']['output']
  title: Scalars['String']['output']
  /** Determines which calculation type to use for the challenges */
  type: LeaderboardLeaderBoardTypeChoices
  updatedAt: Scalars['DateTime']['output']
  /** Determines which user type sees this leader board */
  userAccess: LeaderboardLeaderBoardUserAccessChoices
}

export type ChallengeType = {
  __typename?: 'ChallengeType'
  authUserEarnedCount: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  endDate: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  leaderBoard: LeaderBoardType
  leaders: Array<Leader>
  startDate: Scalars['DateTime']['output']
  status: LeaderboardChallengeStatusChoices
  updatedAt: Scalars['DateTime']['output']
  winners: Array<ChallengeWinnerType>
}

export type Leader = {
  __typename?: 'Leader'
  dateJoined: Scalars['String']['output']
  earnedCount: Scalars['Int']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  profileImageUrl?: Maybe<Scalars['URL']['output']>
  userId: Scalars['ID']['output']
}

export type LeaderProfileImageUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

/** An enumeration. */
export enum LeaderboardChallengeStatusChoices {
  /** Active */
  Active = 'ACTIVE',
  /** Completed */
  Completed = 'COMPLETED',
  /** Scheduled */
  Scheduled = 'SCHEDULED',
}

export type ChallengeWinnerType = {
  __typename?: 'ChallengeWinnerType'
  challenge: ChallengeType
  createdAt: Scalars['DateTime']['output']
  earnedCount: Scalars['Int']['output']
  id: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
  user: Account
}

/** An enumeration. */
export enum LeaderboardLeaderBoardTypeChoices {
  /** Total transactions for a specific asset, one per User. */
  Dist = 'DIST',
  /** Total new transactions for a specific asset. */
  First = 'FIRST',
  /** Total transactions for a specific asset. */
  Trans = 'TRANS',
}

/** An enumeration. */
export enum LeaderboardLeaderBoardUserAccessChoices {
  /** Show for All Users */
  All = 'ALL',
  /** Business Bundle */
  Bbc = 'BBC',
  /** Business Bundle Distributor */
  Bbd = 'BBD',
  /** Pay-as-you-go Customer */
  C = 'C',
  /** Pay-as-you-go Distributor */
  D = 'D',
  /** Free Greener Still Affiliate */
  Fgsa = 'FGSA',
  /** Greener Still Affiliate */
  Gsa = 'GSA',
  /** Independent Distrib, Deprecated */
  Id = 'ID',
  /** Customer, Deprecated */
  Jo = 'JO',
  /** Marketing Bundle */
  Mbc = 'MBC',
  /** Marketing Bundle Distributor */
  Mbd = 'MBD',
  /** Marketing Distrib, Deprecated */
  Md = 'MD',
  /** Personal Bundle */
  Pbc = 'PBC',
  /** Personal Bundle Distributor */
  Pbd = 'PBD',
  /** Greener Still Customer */
  Rc = 'RC',
  /** Show For Affiliate Only */
  Rep = 'REP',
  /** Show For Customer Only */
  Retail = 'RETAIL',
  /** Sample Account */
  S = 'S',
  /** Subaccount */
  Sa = 'SA',
  /** SP123 Temp Account */
  Sp123 = 'SP123',
  /** Show For Staff Only */
  Staff = 'STAFF',
  /** Silver Customer, Deprecated */
  Wcs = 'WCS',
}

export type MarketingMultiParagraph = {
  __typename?: 'MarketingMultiParagraph'
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export enum NotificationType {
  Anniversary = 'ANNIVERSARY',
  Birthday = 'BIRTHDAY',
  Campaign = 'CAMPAIGN',
  ContactRequestCompleted = 'CONTACT_REQUEST_COMPLETED',
  HeldProductionInfos = 'HELD_PRODUCTION_INFOS',
  RejectedCards = 'REJECTED_CARDS',
}

export type Notification = {
  __typename?: 'Notification'
  acceptText?: Maybe<Scalars['String']['output']>
  acknowledgedAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  data?: Maybe<Scalars['JSONString']['output']>
  denyText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  link?: Maybe<Scalars['String']['output']>
  linkText?: Maybe<Scalars['String']['output']>
  message?: Maybe<Scalars['String']['output']>
  type?: Maybe<NotificationType>
  updatedAt: Scalars['DateTime']['output']
}

export type PaginatedCampaigns = {
  __typename?: 'PaginatedCampaigns'
  campaigns: Array<Campaign>
  count: Scalars['Int']['output']
}

export type PaginatedCampaignShares = {
  __typename?: 'PaginatedCampaignShares'
  hasMore?: Maybe<Scalars['Boolean']['output']>
  results: Array<CampaignShare>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedContactRequests = {
  __typename?: 'PaginatedContactRequests'
  hasMore?: Maybe<Scalars['Boolean']['output']>
  results: Array<ContactRequest>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedContacts = {
  __typename?: 'PaginatedContacts'
  allContacts: Array<Contact>
  contacts: Array<Contact>
  count: Scalars['Int']['output']
}

export type PaginatedContactShares = {
  __typename?: 'PaginatedContactShares'
  hasMore?: Maybe<Scalars['Boolean']['output']>
  results: Array<ContactShare>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedGroups = {
  __typename?: 'PaginatedGroups'
  allGroups: Array<Group>
  count: Scalars['Int']['output']
  groups: Array<Group>
}

export enum InvitesOrderBy {
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  OrderSentAsc = 'ORDER_SENT_ASC',
  OrderSentDesc = 'ORDER_SENT_DESC',
  StreamAsc = 'STREAM_ASC',
  StreamDesc = 'STREAM_DESC',
}

export enum StreamId {
  BellaCadeaux = 'BELLA_CADEAUX',
  Blue_42 = 'BLUE_42',
  GreenerStill = 'GREENER_STILL',
  GsPro = 'GS_PRO',
  PromptingU = 'PROMPTING_U',
  Sendoutcards = 'SENDOUTCARDS',
  StreamMarketing = 'STREAM_MARKETING',
  StreamVa = 'STREAM_VA',
}

export type PaginatedInvites = {
  __typename?: 'PaginatedInvites'
  count: Scalars['Int']['output']
  results: Array<Invite>
}

export type NotificationFilters = {
  isAcknowledge?: InputMaybe<Scalars['Boolean']['input']>
  types?: InputMaybe<Array<InputMaybe<NotificationType>>>
}

export type PaginatedNotifications = {
  __typename?: 'PaginatedNotifications'
  hasMore?: Maybe<Scalars['Boolean']['output']>
  results: Array<Notification>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedOrders = {
  __typename?: 'PaginatedOrders'
  count: Scalars['Int']['output']
  orders: Array<Order>
}

export type PaginatedRecipients = {
  __typename?: 'PaginatedRecipients'
  count: Scalars['Int']['output']
  recipients: Array<Recipient>
}

export type Recipient = {
  __typename?: 'Recipient'
  address?: Maybe<Address>
  card?: Maybe<Card>
  cardQuantity: Scalars['Int']['output']
  contact?: Maybe<Contact>
  gift?: Maybe<GiftVariation>
  id: Scalars['ID']['output']
  paymentFailure?: Maybe<PaymentFailure>
  recipientLine?: Maybe<RecipientLine>
  status?: Maybe<CardStatus>
}

export type PaymentFailure = {
  __typename?: 'PaymentFailure'
  id: Scalars['ID']['output']
}

export type RecipientLine = {
  __typename?: 'RecipientLine'
  id: Scalars['ID']['output']
}

export enum CardStatus {
  AwaitingFulfillment = 'AWAITING_FULFILLMENT',
  Cancelled = 'CANCELLED',
  Fulfilled = 'FULFILLED',
  Held = 'HELD',
  PaymentError = 'PAYMENT_ERROR',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Rejected = 'REJECTED',
}

export type ReminderFilters = {
  type?: InputMaybe<Array<InputMaybe<ReminderType>>>
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>
}

export enum ReminderType {
  Anniversary = 'ANNIVERSARY',
  Birthday = 'BIRTHDAY',
  Project = 'PROJECT',
}

export type PaginatedReminders = {
  __typename?: 'PaginatedReminders'
  hasMore?: Maybe<Scalars['Boolean']['output']>
  results: Array<Reminder>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type Reminder = {
  __typename?: 'Reminder'
  campaign?: Maybe<Campaign>
  contact: Contact
  createdAt: Scalars['DateTime']['output']
  eventDate?: Maybe<Scalars['Date']['output']>
  expireDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  message?: Maybe<Scalars['String']['output']>
  newType: NewReminderType
  order?: Maybe<Order>
  title: Scalars['String']['output']
  /** @deprecated Use newType for now */
  type: ReminderType
  updatedAt: Scalars['DateTime']['output']
}

export enum NewReminderType {
  Anniversary = 'ANNIVERSARY',
  Birthday = 'BIRTHDAY',
  Campaign = 'CAMPAIGN',
}

export enum SentCardSendType {
  Heartfelt = 'HEARTFELT',
  System = 'SYSTEM',
}

export type PaginatedSentCards = {
  __typename?: 'PaginatedSentCards'
  count: Scalars['Int']['output']
  sentCards: Array<ProductionInfo>
}

export type ProductionInfo = {
  __typename?: 'ProductionInfo'
  /** The date this card is supposed to go out */
  dateToSend?: Maybe<Scalars['Date']['output']>
  frontPreviewUrl: Scalars['URL']['output']
  /** The date and time this card was fulfilled */
  fulfilled?: Maybe<Scalars['DateTime']['output']>
  hold: Scalars['Boolean']['output']
  holdReason: Scalars['String']['output']
  id: Scalars['ID']['output']
  recipient?: Maybe<Recipient>
  rejectedCards: Array<RejectedCard>
  rejectionReason?: Maybe<Scalars['String']['output']>
  status: CardStatus
  trackingInfo?: Maybe<TrackingInfo>
}

export type ProductionInfoFrontPreviewUrlArgs = {
  filter?: InputMaybe<Scalars['String']['input']>
  fitIn?: InputMaybe<Scalars['Boolean']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  productionId?: InputMaybe<Scalars['ID']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type RejectedCard = {
  __typename?: 'RejectedCard'
  id: Scalars['ID']['output']
}

export type TrackingInfo = {
  __typename?: 'TrackingInfo'
  trackingNumber: Scalars['String']['output']
  url?: Maybe<Scalars['URL']['output']>
}

export type MarketingParagraph = {
  __typename?: 'MarketingParagraph'
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type PlansDescription = {
  __typename?: 'PlansDescription'
  description: Scalars['JSONString']['output']
  id: Scalars['ID']['output']
}

export type PlanMove = {
  __typename?: 'PlanMove'
  isImmediate: Scalars['Boolean']['output']
  planFrom: Plan
  planTo: Plan
  type: PlansPlanMoveSettingsTypeChoices
}

/** An enumeration. */
export enum PlansPlanMoveSettingsTypeChoices {
  /** Downgrade */
  Down = 'DOWN',
  /** Maintain */
  Main = 'MAIN',
  /** Upgrade */
  Up = 'UP',
}

export type PricingPageType = {
  __typename?: 'PricingPageType'
  featuredChallengeId: PlansDescription
  heroDescription: Scalars['String']['output']
  heroSubtitle: Scalars['String']['output']
  heroTitle: Scalars['String']['output']
  id: Scalars['ID']['output']
  sections: Array<SectionType>
  sectionSet: Array<SectionType>
  uiBlobs?: Maybe<Scalars['JSONString']['output']>
}

export type SectionType = {
  __typename?: 'SectionType'
  description: Scalars['String']['output']
  disclaimer: Scalars['String']['output']
  id: Scalars['ID']['output']
  order: Scalars['Int']['output']
  plantilesection?: Maybe<PlanTileSectionType>
  pricinggrid?: Maybe<PricingGridType>
  pricingPage: PricingPageType
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type PlanTileSectionType = {
  __typename?: 'PlanTileSectionType'
  description: Scalars['String']['output']
  disclaimer: Scalars['String']['output']
  featureChart?: Maybe<Scalars['JSONString']['output']>
  order: Scalars['Int']['output']
  planIds: Array<PlansDescription>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
  uiBlobs?: Maybe<Scalars['JSONString']['output']>
}

export type PricingGridType = {
  __typename?: 'PricingGridType'
  description: Scalars['String']['output']
  disclaimer: Scalars['String']['output']
  order: Scalars['Int']['output']
  rows: Array<PricingGridRowType>
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
  uiBlobs?: Maybe<Scalars['JSONString']['output']>
}

export type PricingGridRowType = {
  __typename?: 'PricingGridRowType'
  items: Array<PricingGridRowItemType>
  title: Scalars['String']['output']
}

export type PricingGridRowItemType = {
  __typename?: 'PricingGridRowItemType'
  icon: MarketingPricingGridRowItemIconChoices
  itemDescription: Scalars['String']['output']
  title: Scalars['String']['output']
}

/** An enumeration. */
export enum MarketingPricingGridRowItemIconChoices {
  /** 10_card */
  A_10Card = 'A_10_CARD',
  /** 15_card */
  A_15Card = 'A_15_CARD',
  /** 20_card */
  A_20Card = 'A_20_CARD',
  /** 25_card */
  A_25Card = 'A_25_CARD',
  /** automated_card */
  AutomatedCard = 'AUTOMATED_CARD',
  /** brochure */
  Brochure = 'BROCHURE',
  /** businesspreneur */
  Businesspreneur = 'BUSINESSPRENEUR',
  /** campaign_card */
  CampaignCard = 'CAMPAIGN_CARD',
  /** cancel */
  Cancel = 'CANCEL',
  /** card */
  Card = 'CARD',
  /** card_error */
  CardError = 'CARD_ERROR',
  /** card_pack */
  CardPack = 'CARD_PACK',
  /** catalog_card */
  CatalogCard = 'CATALOG_CARD',
  /** check */
  Check = 'CHECK',
  /** check_mark */
  CheckMark = 'CHECK_MARK',
  /** contact */
  Contact = 'CONTACT',
  /** danger */
  Danger = 'DANGER',
  /** database */
  Database = 'DATABASE',
  /** double_check */
  DoubleCheck = 'DOUBLE_CHECK',
  /** edit */
  Edit = 'EDIT',
  /** empty_gift */
  EmptyGift = 'EMPTY_GIFT',
  /** enhanced_storage */
  EnhancedStorage = 'ENHANCED_STORAGE',
  /** entrepreneur */
  Entrepreneur = 'ENTREPRENEUR',
  /** essential_packs */
  EssentialPacks = 'ESSENTIAL_PACKS',
  /** exp_calendar */
  ExpCalendar = 'EXP_CALENDAR',
  /** gift */
  Gift = 'GIFT',
  /** group_send_card */
  GroupSendCard = 'GROUP_SEND_CARD',
  /** heartfelt */
  Heartfelt = 'HEARTFELT',
  /** info */
  Info = 'INFO',
  /** multi_touch_card */
  MultiTouchCard = 'MULTI_TOUCH_CARD',
  /** multiple_cards */
  MultipleCards = 'MULTIPLE_CARDS',
  /** package */
  Package = 'PACKAGE',
  /** paper_plane */
  PaperPlane = 'PAPER_PLANE',
  /** paper_plane_flying */
  PaperPlaneFlying = 'PAPER_PLANE_FLYING',
  /** paper_plane_with_heart */
  PaperPlaneWithHeart = 'PAPER_PLANE_WITH_HEART',
  /** personal_hand_writing */
  PersonalHandWriting = 'PERSONAL_HAND_WRITING',
  /** personal_signature */
  PersonalSignature = 'PERSONAL_SIGNATURE',
  /** photo_drop_card */
  PhotoDropCard = 'PHOTO_DROP_CARD',
  /** premium_storage */
  PremiumStorage = 'PREMIUM_STORAGE',
  /** replace */
  Replace = 'REPLACE',
  /** schedule_card */
  ScheduleCard = 'SCHEDULE_CARD',
  /** search */
  Search = 'SEARCH',
  /** shopping_cart */
  ShoppingCart = 'SHOPPING_CART',
  /** smile_card */
  SmileCard = 'SMILE_CARD',
  /** smile_card_w_heart */
  SmileCardWHeart = 'SMILE_CARD_W_HEART',
  /** smile_card_w_hearts */
  SmileCardWHearts = 'SMILE_CARD_W_HEARTS',
  /** solopreneur */
  Solopreneur = 'SOLOPRENEUR',
  /** ticket */
  Ticket = 'TICKET',
  /** time_block */
  TimeBlock = 'TIME_BLOCK',
  /** upload */
  Upload = 'UPLOAD',
  /** user */
  User = 'USER',
  /** v_check */
  VCheck = 'V_CHECK',
  /** x */
  X = 'X',
}

export type DetailReceipt = {
  __typename?: 'DetailReceipt'
  date: Scalars['DateTime']['output']
  orders: Array<ReceiptOrder>
  /** Stripe ID for the transaction ('POINTS' if no USD were charged). */
  stripeId?: Maybe<Scalars['String']['output']>
  total: ItemizedCost
  uuid: Scalars['UUID']['output']
}

export type ReceiptOrder = {
  __typename?: 'ReceiptOrder'
  id: Scalars['ID']['output']
  recipients: PaginatedRecipients
}

export type MembershipInvite = {
  __typename?: 'MembershipInvite'
  createdAt: Scalars['DateTime']['output']
  organizationName: Scalars['String']['output']
  organizationOwnerName?: Maybe<Scalars['String']['output']>
}

export type SentCards = {
  __typename?: 'SentCards'
  count: Scalars['Int']['output']
  sentCards: Array<SentCard>
}

export type SentCard = {
  __typename?: 'SentCard'
  cardsSent: Scalars['Int']['output']
  date: Scalars['DateTime']['output']
}

export type Sticker = {
  __typename?: 'Sticker'
  categories: Array<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  image: EditorImageType
  tags: Array<Scalars['String']['output']>
  title: Scalars['String']['output']
  type: StickersStickerTypeChoices
}

/** An enumeration. */
export enum StickersStickerTypeChoices {
  /** Premium */
  Premium = 'PREMIUM',
  /** Standard */
  Standard = 'STANDARD',
}

export type PaginatedStickerCategory = {
  __typename?: 'PaginatedStickerCategory'
  hasMore?: Maybe<Scalars['Boolean']['output']>
  results: Array<StickerCategory>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type StickerCategory = {
  __typename?: 'StickerCategory'
  children: Array<StickerCategory>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  parent?: Maybe<StickerCategory>
  slug: Scalars['String']['output']
  stickers: PaginatedStickers
}

export type StickerCategoryStickersArgs = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type PaginatedStickers = {
  __typename?: 'PaginatedStickers'
  hasMore?: Maybe<Scalars['Boolean']['output']>
  results: Array<Sticker>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type StickerCategoryFilters = {
  /** Filter by category ID */
  id?: InputMaybe<Scalars['ID']['input']>
  /** Filter by category name */
  name?: InputMaybe<Scalars['String']['input']>
}

export type StickerFilters = {
  categories?: InputMaybe<Array<InputMaybe<StickerCategoryFilters>>>
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  type?: InputMaybe<StickerTypeEnum>
}

/** An enumeration. */
export enum StickerTypeEnum {
  Premium = 'PREMIUM',
  Standard = 'STANDARD',
}

export type Mutation = {
  __typename?: 'Mutation'
  acceptCampaignShare: AcceptCampaignShare
  acceptContactShare: AcceptContactShare
  acceptMembershipInvite: AcceptMembershipInvite
  addFreeCampaign: AddFreeCampaign
  addPaymentMethod: AddPaymentMethod
  bulkDeleteCards: BulkDeleteCards
  bulkDeleteContacts: BulkDeleteContacts
  bulkDeleteOrders: BulkDeleteOrders
  /** Used to bulk delete (disable) sendable cards */
  bulkDeleteSendableCards: BulkDeleteSendableCards
  cancelPausedRecipients: CancelPausedRecipients
  cancelPendingCard: CancelPendingCard
  cancelPendingCards: CancelPendingCards
  cancelStripeSubscription: CancelStripeSubscription
  changeSubscription: ChangeSubscription
  commitPurchase: CommitPurchase
  completeTraining: CompleteTraining
  createAccount: CreateAccount
  createCampaign: CreateCampaign
  createCampaignOrder: CreateCampaignOrder
  createCard: CreateCard
  createContact: CreateContact
  createContactRequest: CreateContactRequest
  createContacts: CreateContacts
  createContactsWithInvites: CreateContactsWithInvites
  createContactsWithSocProContacts: CreateContactsWithSocProContacts
  createContactWithInvite: CreateContactWithInvite
  createContactWithSocProContact: CreateContactWithSocProContact
  createContactWithSpouseChildren: CreateContactWithSpouseChildren
  createDigitalCard: CreateDigitalCard
  createDigitalCardToken: CreateDigitalCardToken
  createDistributor: CreateDistributor
  createFreeAffiliate: CreateFreeAffiliate
  createGroup: CreateGroup
  createInvite: CreateInvite
  createNote: CreateNote
  /** @deprecated Use upsertOrder instead */
  createOrder: CreateOrder
  createOrUpdateBackPanel: CreateOrUpdateBackPanel
  createOrUpdateContact: CreateOrUpdateContact
  createPurchase: CreatePurchase
  createSocProContact: CreateSocProContact
  createSparseUser: CreateSparseUser
  createStripeOrder: CreateStripeOrder
  createUserLoginLink: CreateUserLoginLink
  declineMembershipInvite: DeclineMembershipInvite
  deleteCampaign: DeleteCampaign
  deleteCampaignShare: DeleteCampaignShare
  deleteContact: DeleteContact
  deleteContactRequest: DeleteContactRequest
  deleteContactShare: DeleteContactShare
  deleteDevice: DeleteDevice
  deleteGroup: DeleteGroup
  deleteImage: DeleteImage
  deleteNote: DeleteNote
  deleteOrder: DeleteOrder
  deletePanelLayout: DeletePanelLayout
  deleteReminder: DeleteReminder
  denyNotification: DenyNotification
  digitalCardOrderCreate: DigitalCardOrderCreate
  digitalCardOrderPay: DigitalCardOrderPay
  displayUpsaleSetting: DisplayUpsaleSetting
  exportContacts: ExportContacts
  favoriteSendableCard: FavoriteSendableCard
  getOrCreateEditableCardForRecipient: GetOrCreateEditableCardForRecipient
  getOrCreateImageForStickerUrl: GetOrCreateImageForStickerUrl
  incrementCoachTrainingVideosWatched: IncrementCoachTrainingVideosWatched
  leaderBoardVisibility: LeaderBoardVisibility
  loginUser: LoginUser
  logoutUser: LogoutUser
  purchaseProduct: PurchaseProduct
  registerDevice: RegisterDevice
  removePaymentMethod: RemovePaymentMethod
  requestEmailVerificationCode: RequestEmailVerificationResponse
  /** Request a verification code be sent to a phone number. */
  requestPhoneNumberVerification: RequestPhoneNumberVerificationResult
  revertBulkOrder: RevertBulkOrder
  sendMagicLink: SendMagicLink
  sendOrder: SendOrder
  sendPausedRecipients: SendPausedRecipients
  separateContact: SeparateContact
  shareCampaign: ShareCampaign
  shareContact: ShareContact
  shouldDisplayUpsaleTrigger: ShouldDisplayUpsaleTrigger
  unfavoriteSendableCard: UnfavoriteSendableCard
  updateAccount: UpdateAccount
  updateCampaign: UpdateCampaign
  updateCard: UpdateCard
  updateCoachPublicInfoDisplay: UpdateCoachPublicInfoDisplay
  /** @deprecated Deprecated in favor of createOrUpdateContact */
  updateContact: CreateOrUpdateContact
  updateDigitalCard: UpdateDigitalCard
  updateGroup: UpdateGroup
  updateInvite: UpdateInvite
  updateNote: UpdateNote
  /** @deprecated Use upsertOrder instead */
  updateOrder: UpdateOrder
  updateProductionInfo: UpdateProductionInfo
  updateRecipient: UpdateRecipient
  updateReminder: UpdateReminder
  updateRequestedContact: UpdateRequestedContact
  updateSourceBillingAddress: UpdateSourceBillingAddress
  updateStorefrontMugshot: UpdateStoreFrontMugshot
  updateUserLabelOverrides: UpdateUserLabelOverrides
  upgradeToAffiliate: UpgradeToAffiliate
  upgradeToGreenerStillAffiliate: UpgradeToGreenerStillAffiliate
  upgradeToReferringCustomer: UpgradeToReferringCustomer
  uploadImage: UploadImage
  upsertOrder: UpsertOrder
  validateEmail: ValidateEmail
  validateEmailVerificationCode: ValidateEmailVerificationResponse
  validatePhoneNumber: ValidatePhoneNumber
  validateUsername: ValidateUsername
  /** Verify a phone number with a verification code. */
  verifyPhoneNumber: VerifyPhoneNumberResult
}

export type MutationAcceptCampaignShareArgs = {
  campaignId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationAcceptContactShareArgs = {
  id: Scalars['ID']['input']
}

export type MutationAcceptMembershipInviteArgs = {
  organizationName: Scalars['String']['input']
}

export type MutationAddFreeCampaignArgs = {
  campaignId: Scalars['ID']['input']
}

export type MutationAddPaymentMethodArgs = {
  paymentMethod: PaymentMethodInput
  recaptchaToken: Scalars['String']['input']
}

export type MutationBulkDeleteCardsArgs = {
  cards: Array<Scalars['ID']['input']>
}

export type MutationBulkDeleteContactsArgs = {
  contacts: Array<Scalars['ID']['input']>
}

export type MutationBulkDeleteOrdersArgs = {
  orders: Array<Scalars['ID']['input']>
}

export type MutationBulkDeleteSendableCardsArgs = {
  cards: Array<Scalars['ID']['input']>
}

export type MutationCancelPausedRecipientsArgs = {
  recipientIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type MutationCancelPendingCardArgs = {
  productionId: Scalars['ID']['input']
}

export type MutationCancelPendingCardsArgs = {
  productionIds: Array<Scalars['ID']['input']>
}

export type MutationCancelStripeSubscriptionArgs = {
  id: Scalars['ID']['input']
}

export type MutationChangeSubscriptionArgs = {
  sku: Scalars['String']['input']
  subscriptionId: Scalars['String']['input']
}

export type MutationCommitPurchaseArgs = {
  purchaseIntentId: Scalars['String']['input']
}

export type MutationCompleteTrainingArgs = {
  userId: Scalars['Int']['input']
}

export type MutationCreateAccountArgs = {
  account: AccountInput
  recaptchaToken?: InputMaybe<Scalars['String']['input']>
  verificationCode?: InputMaybe<Scalars['String']['input']>
  verticalId?: InputMaybe<VerticalId>
}

export type MutationCreateCampaignArgs = {
  campaign: CreateCampaignInput
}

export type MutationCreateCampaignOrderArgs = {
  itemCode: Scalars['String']['input']
}

export type MutationCreateCardArgs = {
  card: CreateCardInput
  isNewEditorCard?: InputMaybe<Scalars['Boolean']['input']>
  isResend?: InputMaybe<Scalars['Boolean']['input']>
  sponsor?: InputMaybe<Scalars['ID']['input']>
}

export type MutationCreateContactArgs = {
  contact: CreateContactInput
}

export type MutationCreateContactRequestArgs = {
  contactUuid?: InputMaybe<Scalars['ID']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateContactsArgs = {
  contacts: Array<CreateContactInput>
}

export type MutationCreateContactsWithInvitesArgs = {
  inviteIds: Array<Scalars['String']['input']>
}

export type MutationCreateContactsWithSocProContactsArgs = {
  socProContactsIds: Array<Scalars['String']['input']>
}

export type MutationCreateContactWithInviteArgs = {
  inviteId: Scalars['String']['input']
}

export type MutationCreateContactWithSocProContactArgs = {
  socProContactId: Scalars['String']['input']
}

export type MutationCreateContactWithSpouseChildrenArgs = {
  contact: CreateContactInput
}

export type MutationCreateDigitalCardArgs = {
  cardId: Scalars['ID']['input']
  input: DigitalCardInput
}

export type MutationCreateDigitalCardTokenArgs = {
  digitalCardPk: Scalars['ID']['input']
  permissions?: InputMaybe<TokenPermissions>
}

export type MutationCreateDistributorArgs = {
  account: AccountInput
  affiliate?: InputMaybe<Scalars['Boolean']['input']>
  govId1: Scalars['String']['input']
  govId2?: InputMaybe<Scalars['String']['input']>
  stripeToken?: InputMaybe<Scalars['String']['input']>
  verificationCode?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateFreeAffiliateArgs = {
  account: AccountInput
  govId1: Scalars['String']['input']
  govId2?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateGroupArgs = {
  group: CreateGroupInput
}

export type MutationCreateInviteArgs = {
  prospectId?: InputMaybe<Scalars['ID']['input']>
  socProContactId?: InputMaybe<Scalars['ID']['input']>
  sponsorId: Scalars['ID']['input']
  utmCampaign?: InputMaybe<Scalars['String']['input']>
  utmContent?: InputMaybe<Scalars['String']['input']>
  utmMedium?: InputMaybe<Scalars['String']['input']>
  utmSource?: InputMaybe<Scalars['String']['input']>
  utmTerm?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateNoteArgs = {
  calendarDate?: InputMaybe<CalendarDateInput>
  date?: InputMaybe<CalendarDateInput>
  id: Scalars['ID']['input']
  text: Scalars['String']['input']
}

export type MutationCreateOrderArgs = {
  order: CreateOrderInput
}

export type MutationCreateOrUpdateBackPanelArgs = {
  createFromExisting?: InputMaybe<Scalars['Boolean']['input']>
  layoutId: Scalars['ID']['input']
  panel: PanelInput
}

export type MutationCreateOrUpdateContactArgs = {
  contact: UpdateContactInput
}

export type MutationCreatePurchaseArgs = {
  itemsToPurchase: Array<ItemObject>
}

export type MutationCreateSocProContactArgs = {
  socProContact: CreateSocProContactInput
}

export type MutationCreateSparseUserArgs = {
  inviteId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationCreateStripeOrderArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>
  itemsToPurchase: Array<ItemObject>
}

export type MutationCreateUserLoginLinkArgs = {
  args: Args
  verticalId: VerticalId
}

export type MutationDeclineMembershipInviteArgs = {
  organizationName: Scalars['String']['input']
}

export type MutationDeleteCampaignArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCampaignShareArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteContactArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteContactRequestArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteContactShareArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteDeviceArgs = {
  device: DeviceInput
}

export type MutationDeleteGroupArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteImageArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteNoteArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteOrderArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeletePanelLayoutArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteReminderArgs = {
  id: Scalars['ID']['input']
}

export type MutationDenyNotificationArgs = {
  id: Scalars['ID']['input']
}

export type MutationDigitalCardOrderCreateArgs = {
  digitalCardPk: Scalars['ID']['input']
}

export type MutationDigitalCardOrderPayArgs = {
  digitalCardPk: Scalars['ID']['input']
}

export type MutationDisplayUpsaleSettingArgs = {
  shouldNotDisplay: Scalars['Boolean']['input']
}

export type MutationExportContactsArgs = {
  contacts: Array<Scalars['ID']['input']>
  email: Scalars['String']['input']
}

export type MutationFavoriteSendableCardArgs = {
  id: Scalars['ID']['input']
}

export type MutationGetOrCreateEditableCardForRecipientArgs = {
  recipientId: Scalars['ID']['input']
}

export type MutationGetOrCreateImageForStickerUrlArgs = {
  url?: InputMaybe<Scalars['String']['input']>
}

export type MutationIncrementCoachTrainingVideosWatchedArgs = {
  userId: Scalars['String']['input']
  watchCount: Scalars['Int']['input']
}

export type MutationLeaderBoardVisibilityArgs = {
  isHidden: Scalars['Boolean']['input']
}

export type MutationLoginUserArgs = {
  password: Scalars['String']['input']
  username: Scalars['String']['input']
  withSession?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPurchaseProductArgs = {
  orderId: Scalars['String']['input']
  stripeToken?: InputMaybe<Scalars['String']['input']>
}

export type MutationRegisterDeviceArgs = {
  device: DeviceInput
}

export type MutationRequestEmailVerificationCodeArgs = {
  email: Scalars['String']['input']
}

export type MutationRequestPhoneNumberVerificationArgs = {
  phoneNumber: PhoneNumberInput
}

export type MutationRevertBulkOrderArgs = {
  id: Scalars['ID']['input']
}

export type MutationSendMagicLinkArgs = {
  email: Scalars['String']['input']
  redirectUri?: InputMaybe<Scalars['String']['input']>
  verticalId?: InputMaybe<VerticalId>
}

export type MutationSendOrderArgs = {
  buyPointsAndExpense?: InputMaybe<Scalars['Boolean']['input']>
  order: Scalars['ID']['input']
  shouldUseCredits?: InputMaybe<Scalars['Boolean']['input']>
  source?: InputMaybe<Scalars['ID']['input']>
}

export type MutationSendPausedRecipientsArgs = {
  recipientIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type MutationSeparateContactArgs = {
  childId?: InputMaybe<Scalars['String']['input']>
  spouseId?: InputMaybe<Scalars['String']['input']>
}

export type MutationShareCampaignArgs = {
  allowShare?: InputMaybe<Scalars['Boolean']['input']>
  campaign: Scalars['ID']['input']
  username: Scalars['String']['input']
}

export type MutationShareContactArgs = {
  contact: Scalars['ID']['input']
  username: Scalars['String']['input']
}

export type MutationUnfavoriteSendableCardArgs = {
  id: Scalars['ID']['input']
}

export type MutationUpdateAccountArgs = {
  account: AccountInput
  subscription?: InputMaybe<SubscriptionInput>
}

export type MutationUpdateCampaignArgs = {
  campaign: UpdateCampaignInput
}

export type MutationUpdateCardArgs = {
  card: UpdateCardInput
}

export type MutationUpdateCoachPublicInfoDisplayArgs = {
  publicContactInfo: UpdateCoachPublicInfoDisplayInput
}

export type MutationUpdateContactArgs = {
  contact: UpdateContactInput
}

export type MutationUpdateDigitalCardArgs = {
  digitalCardPk: Scalars['ID']['input']
  input: DigitalCardInput
}

export type MutationUpdateGroupArgs = {
  group: UpdateGroupInput
}

export type MutationUpdateInviteArgs = {
  invite: UpdateInviteInput
}

export type MutationUpdateNoteArgs = {
  calendarDate?: InputMaybe<CalendarDateInput>
  date?: InputMaybe<CalendarDateInput>
  id: Scalars['ID']['input']
  text: Scalars['String']['input']
}

export type MutationUpdateOrderArgs = {
  order: UpdateOrderInput
}

export type MutationUpdateProductionInfoArgs = {
  input: UpdateProductionInfoInput
}

export type MutationUpdateRecipientArgs = {
  recipient: RecipientInput
  updateContactToo: Scalars['Boolean']['input']
  userOverrideInvalidAddress: Scalars['Boolean']['input']
}

export type MutationUpdateReminderArgs = {
  reminder: UpdateReminderInput
}

export type MutationUpdateRequestedContactArgs = {
  contact: UpdateRequestedContactInput
  token: Scalars['String']['input']
}

export type MutationUpdateSourceBillingAddressArgs = {
  billingAddress: CardBillingAddressInput
}

export type MutationUpdateUserLabelOverridesArgs = {
  overrides: Array<UserLabelOverrideInput>
}

export type MutationUpgradeToAffiliateArgs = {
  govId1: Scalars['String']['input']
  govId2?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpgradeToGreenerStillAffiliateArgs = {
  govId1?: InputMaybe<Scalars['String']['input']>
  govId2?: InputMaybe<Scalars['String']['input']>
}

export type MutationUploadImageArgs = {
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  url?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpsertOrderArgs = {
  order: UpsertOrderInput
}

export type MutationValidateEmailArgs = {
  email: Scalars['String']['input']
}

export type MutationValidateEmailVerificationCodeArgs = {
  code: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type MutationValidatePhoneNumberArgs = {
  phoneNumber: PhoneNumberInput
}

export type MutationValidateUsernameArgs = {
  username: Scalars['String']['input']
}

export type MutationVerifyPhoneNumberArgs = {
  phoneNumber: PhoneNumberInput
  shouldNotCreateNewAccount?: InputMaybe<Scalars['Boolean']['input']>
  shouldReassignNumber?: InputMaybe<Scalars['Boolean']['input']>
  sponsorGenealogyIdOrSlug?: InputMaybe<Scalars['String']['input']>
  verificationCode: Scalars['String']['input']
}

export type AcceptCampaignShare = {
  __typename?: 'AcceptCampaignShare'
  campaign: Campaign
}

export type AcceptContactShare = {
  __typename?: 'AcceptContactShare'
  accepted: Scalars['Boolean']['output']
}

export type AcceptMembershipInvite = {
  __typename?: 'AcceptMembershipInvite'
  success: Scalars['Boolean']['output']
}

export type AddFreeCampaign = {
  __typename?: 'AddFreeCampaign'
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type PaymentMethodInput = {
  cardToken: Scalars['ID']['input']
}

export type AddPaymentMethod = {
  __typename?: 'AddPaymentMethod'
  success: Scalars['Boolean']['output']
}

export type BulkDeleteCards = {
  __typename?: 'BulkDeleteCards'
  /** @deprecated This will always be null. */
  failedCount?: Maybe<Scalars['Int']['output']>
  /** This will always return True. */
  successful: Scalars['Boolean']['output']
}

export type BulkDeleteContacts = {
  __typename?: 'BulkDeleteContacts'
  failedCount?: Maybe<Scalars['Int']['output']>
  successful: Scalars['Boolean']['output']
}

export type BulkDeleteOrders = {
  __typename?: 'BulkDeleteOrders'
  failedCount?: Maybe<Scalars['Int']['output']>
  successful: Scalars['Boolean']['output']
}

/** Used to bulk delete (disable) sendable cards */
export type BulkDeleteSendableCards = {
  __typename?: 'BulkDeleteSendableCards'
  /** @deprecated This will always be null. */
  failedCount?: Maybe<Scalars['Int']['output']>
  /** This will always return True. */
  successful: Scalars['Boolean']['output']
}

export type CancelPausedRecipients = {
  __typename?: 'CancelPausedRecipients'
  success?: Maybe<Scalars['Boolean']['output']>
}

export type CancelPendingCard = {
  __typename?: 'CancelPendingCard'
  canceled?: Maybe<Scalars['Boolean']['output']>
  refundInfo?: Maybe<RefundInfo>
}

export type RefundInfo = {
  __typename?: 'RefundInfo'
  expenseAfter?: Maybe<Scalars['String']['output']>
  expenseBefore?: Maybe<Scalars['String']['output']>
  pointsAfter?: Maybe<Scalars['Int']['output']>
  pointsBefore?: Maybe<Scalars['Int']['output']>
}

export type CancelPendingCards = {
  __typename?: 'CancelPendingCards'
  refundInfo: RefundInfo
}

export type CancelStripeSubscription = {
  __typename?: 'CancelStripeSubscription'
  account: Account
}

export type ChangeSubscription = {
  __typename?: 'ChangeSubscription'
  account: Account
}

export type CommitPurchase = {
  __typename?: 'CommitPurchase'
  paid: Scalars['Boolean']['output']
}

export type CompleteTraining = {
  __typename?: 'CompleteTraining'
  dateTrainingCompleted: Scalars['Date']['output']
}

export type AccountInput = {
  email?: InputMaybe<Scalars['String']['input']>
  expenseReload?: InputMaybe<ExpenseReloadInput>
  firstName?: InputMaybe<Scalars['String']['input']>
  isBetterWayPromo?: InputMaybe<Scalars['Boolean']['input']>
  keepBundlePlan?: InputMaybe<Scalars['Boolean']['input']>
  keepStreamMarketing?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  plan?: InputMaybe<Scalars['ID']['input']>
  planAddon?: InputMaybe<Scalars['ID']['input']>
  profileImage?: InputMaybe<Scalars['String']['input']>
  settings?: InputMaybe<AccountSettingsInput>
  shippingAddress?: InputMaybe<AddressInput>
  sponsor?: InputMaybe<Scalars['ID']['input']>
  sponsorGenealogyId?: InputMaybe<Scalars['ID']['input']>
  sponsorSlug?: InputMaybe<Scalars['String']['input']>
  storefront?: InputMaybe<UpdateStorefrontInput>
  stripeToken?: InputMaybe<Scalars['ID']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}

export type ExpenseReloadInput = {
  isEnabled: Scalars['Boolean']['input']
  purchaseAmount: Scalars['Int']['input']
  threshold: Scalars['Int']['input']
}

export type AccountSettingsInput = {
  defaultBackPanelId?: InputMaybe<Scalars['ID']['input']>
  defaultHorizontalBackPanelId?: InputMaybe<Scalars['ID']['input']>
  printRecipientCompany?: InputMaybe<Scalars['Boolean']['input']>
  receivesNotificationEmails?: InputMaybe<Scalars['Boolean']['input']>
  receivesPromoEmails?: InputMaybe<Scalars['Boolean']['input']>
  receivesReceiptEmails?: InputMaybe<Scalars['Boolean']['input']>
  receivesReminderEmails?: InputMaybe<Scalars['Boolean']['input']>
  remindersAdvanceNoticeDays?: InputMaybe<Scalars['Int']['input']>
  remindersAdvanceNoticeDaysInternational?: InputMaybe<Scalars['Int']['input']>
}

export type AddressInput = {
  address1: Scalars['String']['input']
  address2: Scalars['String']['input']
  city: Scalars['String']['input']
  company: Scalars['String']['input']
  country: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  postalCode: Scalars['String']['input']
  state: Scalars['String']['input']
}

export type UpdateStorefrontInput = {
  biography?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export enum VerticalId {
  BellaCadeaux = 'BELLA_CADEAUX',
  Blue_42 = 'BLUE_42',
  Bydesign = 'BYDESIGN',
  GreenerStill = 'GREENER_STILL',
  PromptingU = 'PROMPTING_U',
  Sendoutcards = 'SENDOUTCARDS',
  StreamMarketing = 'STREAM_MARKETING',
  StreamVa = 'STREAM_VA',
}

export type CreateAccount = {
  __typename?: 'CreateAccount'
  account?: Maybe<Account>
  validation?: Maybe<ValidateEmailVerificationResponse>
}

export type ValidateEmailVerificationResponse =
  | ValidateEmailVerificationCodeFailure
  | ValidateEmailVerificationCodeSuccess

export type ValidateEmailVerificationCodeFailure = {
  __typename?: 'ValidateEmailVerificationCodeFailure'
  failureType: ValidateEmailVerificationCodeFailureType
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export enum ValidateEmailVerificationCodeFailureType {
  Denied = 'DENIED',
  IncorrectCode = 'INCORRECT_CODE',
  NotFound = 'NOT_FOUND',
  TooManyAttempts = 'TOO_MANY_ATTEMPTS',
}

export type ValidateEmailVerificationCodeSuccess = {
  __typename?: 'ValidateEmailVerificationCodeSuccess'
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type CreateCampaignInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  isShareable?: InputMaybe<Scalars['Boolean']['input']>
  lines?: InputMaybe<Array<LineInput>>
  name: Scalars['String']['input']
  order?: InputMaybe<Scalars['ID']['input']>
  returnAddress?: InputMaybe<AddressInput>
}

export type LineInput = {
  card?: InputMaybe<Scalars['ID']['input']>
  giftVariation?: InputMaybe<Scalars['ID']['input']>
  sendDelay?: InputMaybe<SendDelayInput>
}

export type SendDelayInput = {
  delayNumber?: InputMaybe<Scalars['Int']['input']>
  delayType?: InputMaybe<SendDelayDelayType>
  specificDate?: InputMaybe<Scalars['String']['input']>
  timeType?: InputMaybe<SendDelayTimeType>
  type?: InputMaybe<SendDelayType>
}

export type CreateCampaign = {
  __typename?: 'CreateCampaign'
  campaign: Campaign
}

export type CreateCampaignOrder = {
  __typename?: 'CreateCampaignOrder'
  stripeOrder: StripeOrder
}

export type StripeOrder = {
  __typename?: 'StripeOrder'
  discount: Scalars['String']['output']
  id: Scalars['ID']['output']
  shipping: Scalars['String']['output']
  subtotal: Scalars['String']['output']
  tax: Scalars['String']['output']
  total: Scalars['String']['output']
}

export type CreateCardInput = {
  card?: InputMaybe<Scalars['ID']['input']>
  paperType?: InputMaybe<CardPaperType>
  sendableCard?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<CardType>
}

export type CreateCard = {
  __typename?: 'CreateCard'
  account: Account
  card: Card
}

export type CreateContactInput = {
  address1?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  anniversary?: InputMaybe<OptionalYearDateInput>
  birthday?: InputMaybe<OptionalYearDateInput>
  cellNumber?: InputMaybe<Scalars['String']['input']>
  cellPhone?: InputMaybe<Scalars['String']['input']>
  children?: InputMaybe<Array<InputMaybe<CreateSubContactInput>>>
  city?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  faxNumber?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  groupName?: InputMaybe<Scalars['String']['input']>
  groups?: InputMaybe<Array<Scalars['ID']['input']>>
  homePhone?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Array<NoteInput>>
  parent?: InputMaybe<Scalars['ID']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  spouse?: InputMaybe<Scalars['ID']['input']>
  spouseBirthday?: InputMaybe<OptionalYearDateInput>
  spouseContact?: InputMaybe<CreateSubContactInput>
  spouseName?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  websites?: InputMaybe<Array<Scalars['String']['input']>>
  workPhone?: InputMaybe<Scalars['String']['input']>
}

export type OptionalYearDateInput = {
  day: Scalars['Int']['input']
  month: Scalars['Int']['input']
  year?: InputMaybe<Scalars['Int']['input']>
}

export type CreateSubContactInput = {
  address1?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  anniversary?: InputMaybe<OptionalYearDateInput>
  birthday?: InputMaybe<OptionalYearDateInput>
  cellNumber?: InputMaybe<Scalars['String']['input']>
  cellPhone?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  faxNumber?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  groupName?: InputMaybe<Scalars['String']['input']>
  groups?: InputMaybe<Array<Scalars['ID']['input']>>
  homePhone?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Array<NoteInput>>
  parent?: InputMaybe<Scalars['ID']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  spouse?: InputMaybe<Scalars['ID']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  websites?: InputMaybe<Array<Scalars['String']['input']>>
  workPhone?: InputMaybe<Scalars['String']['input']>
}

export type NoteInput = {
  date?: InputMaybe<CalendarDateInput>
  id: Scalars['ID']['input']
  text: Scalars['String']['input']
}

export type CalendarDateInput = {
  day: Scalars['Int']['input']
  month: Scalars['Int']['input']
  year: Scalars['Int']['input']
}

export type CreateContact = {
  __typename?: 'CreateContact'
  contact: Contact
}

export type CreateContactRequest = {
  __typename?: 'CreateContactRequest'
  contactRequest: ContactRequest
}

export type CreateContacts = {
  __typename?: 'CreateContacts'
  contacts: Array<Contact>
  failedContacts: Array<Contact>
}

export type CreateContactsWithInvites = {
  __typename?: 'CreateContactsWithInvites'
  contactIds: Array<Scalars['String']['output']>
  failedInviteIds: Array<Scalars['String']['output']>
}

export type CreateContactsWithSocProContacts = {
  __typename?: 'CreateContactsWithSocProContacts'
  contactsIds: Array<Scalars['String']['output']>
  failedSocProContacts: Array<SocProContact>
}

export type CreateContactWithInvite = {
  __typename?: 'CreateContactWithInvite'
  contact: Contact
}

export type CreateContactWithSocProContact = {
  __typename?: 'CreateContactWithSocProContact'
  contact: Contact
}

export type CreateContactWithSpouseChildren = {
  __typename?: 'CreateContactWithSpouseChildren'
  contacts?: Maybe<Array<Contact>>
}

export type DigitalCardInput = {
  expiresAt?: InputMaybe<Scalars['String']['input']>
  isPrintable?: InputMaybe<Scalars['Boolean']['input']>
  shareMessageBody?: InputMaybe<Scalars['String']['input']>
  shareMessageTitle?: InputMaybe<Scalars['String']['input']>
}

export type CreateDigitalCard = {
  __typename?: 'CreateDigitalCard'
  digitalCard?: Maybe<DigitalCard>
}

export type CreateDigitalCardToken = {
  __typename?: 'CreateDigitalCardToken'
  digitalCardToken: DigitalCardToken
}

export type CreateDistributor = {
  __typename?: 'CreateDistributor'
  account?: Maybe<Account>
  error?: Maybe<Scalars['String']['output']>
  subscription?: Maybe<StripeSubscription>
  validation?: Maybe<ValidateEmailVerificationResponse>
}

export type CreateFreeAffiliate = {
  __typename?: 'CreateFreeAffiliate'
  account?: Maybe<Account>
  error?: Maybe<Scalars['String']['output']>
  subscription?: Maybe<StripeSubscription>
}

export type CreateGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>
  groups?: InputMaybe<Array<Scalars['ID']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  members?: InputMaybe<Array<Scalars['ID']['input']>>
  name: Scalars['String']['input']
}

export type CreateGroup = {
  __typename?: 'CreateGroup'
  group: Group
}

export type CreateInvite = {
  __typename?: 'CreateInvite'
  invite: Invite
}

export type CreateNote = {
  __typename?: 'CreateNote'
  contact: Contact
}

export type CreateOrderInput = {
  bulkUpgradeCardQty?: InputMaybe<Scalars['Int']['input']>
  bulkUpgradeShippingAddress?: InputMaybe<AddressInput>
  campaign?: InputMaybe<Scalars['ID']['input']>
  contacts?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Adds all contacts from the specified groups to the order. */
  contactsFromGroups?: InputMaybe<Array<Scalars['ID']['input']>>
  groups?: InputMaybe<Array<Scalars['ID']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  lines?: InputMaybe<Array<LineInput>>
  returnAddress?: InputMaybe<AddressInput>
}

export type CreateOrder = {
  __typename?: 'CreateOrder'
  order: Order
}

export type PanelInput = {
  backgroundColor?: InputMaybe<ColorInput>
  backgroundColorObject?: InputMaybe<ColorInput>
  elements: Array<ElementInput>
  isFullBleed: Scalars['Boolean']['input']
  location: Scalars['Int']['input']
}

export type ColorInput = {
  blue: Scalars['Int']['input']
  green: Scalars['Int']['input']
  red: Scalars['Int']['input']
}

export type ElementInput = {
  draggable?: InputMaybe<Scalars['Boolean']['input']>
  height: Scalars['Float']['input']
  image?: InputMaybe<ElementPictureInput>
  locked: Scalars['Boolean']['input']
  rotation: Scalars['Float']['input']
  sticker?: InputMaybe<ElementStickerInput>
  text?: InputMaybe<ElementTextInput>
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
  z: Scalars['Int']['input']
}

/** Coordinates x, y, width and height are deprecated. Use position and scale instead. */
export type ElementPictureInput = {
  filter?: InputMaybe<Scalars['String']['input']>
  image?: InputMaybe<Scalars['ID']['input']>
  position?: InputMaybe<PositionInput>
  scale?: InputMaybe<Scalars['Float']['input']>
}

/** { x: 0.5, y: 0.5 } represents a centered position */
export type PositionInput = {
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

/** Coordinates x, y, width and height are deprecated. Use position and scale instead. */
export type ElementStickerInput = {
  position?: InputMaybe<PositionInput>
  scale?: InputMaybe<Scalars['Float']['input']>
  sticker?: InputMaybe<Scalars['ID']['input']>
}

export type ElementTextInput = {
  paragraphs?: InputMaybe<Array<ParagraphInput>>
}

export type ParagraphInput = {
  alignment: TextAlignment
  textFragments: Array<TextFragmentInput>
}

export type TextFragmentInput = {
  font: Scalars['ID']['input']
  fontSize: Scalars['Int']['input']
  isReplacement: Scalars['Boolean']['input']
  text: Scalars['String']['input']
  textColor: ColorInput
}

export type CreateOrUpdateBackPanel = {
  __typename?: 'CreateOrUpdateBackPanel'
  layout?: Maybe<Layout>
}

export type UpdateContactInput = {
  address1?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  anniversary?: InputMaybe<OptionalYearDateInput>
  birthday?: InputMaybe<OptionalYearDateInput>
  cellNumber?: InputMaybe<Scalars['String']['input']>
  cellPhone?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  faxNumber?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  groupName?: InputMaybe<Scalars['String']['input']>
  groups?: InputMaybe<Array<Scalars['ID']['input']>>
  homePhone?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Array<NoteInput>>
  parent?: InputMaybe<Scalars['ID']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  spouse?: InputMaybe<Scalars['ID']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  websites?: InputMaybe<Array<Scalars['String']['input']>>
  workPhone?: InputMaybe<Scalars['String']['input']>
}

export type CreateOrUpdateContact = {
  __typename?: 'CreateOrUpdateContact'
  contact: Contact
}

export type ItemObject = {
  itemCode: Scalars['String']['input']
  quantity?: InputMaybe<Scalars['Int']['input']>
}

export type CreatePurchase = {
  __typename?: 'CreatePurchase'
  purchaseIntent: PurchaseIntent
}

export type PurchaseIntent = {
  __typename?: 'PurchaseIntent'
  id: Scalars['ID']['output']
  subtotal: Scalars['String']['output']
  tax: Scalars['String']['output']
  total: Scalars['String']['output']
}

export type CreateSocProContactInput = {
  contactId?: InputMaybe<Scalars['String']['input']>
  metadataBlob?: InputMaybe<Scalars['String']['input']>
}

export type CreateSocProContact = {
  __typename?: 'CreateSocProContact'
  socProContact: SocProContact
}

export type CreateSparseUser = {
  __typename?: 'CreateSparseUser'
  account: Account
}

export type CreateStripeOrder = {
  __typename?: 'CreateStripeOrder'
  stripeOrder: StripeOrder
}

export type Args = {
  isNewJoiningAffiliateFlow?: InputMaybe<Scalars['Boolean']['input']>
  postcardPromo?: InputMaybe<Scalars['Boolean']['input']>
  redirect_uri?: InputMaybe<Scalars['String']['input']>
}

export type CreateUserLoginLink = {
  __typename?: 'CreateUserLoginLink'
  link?: Maybe<Scalars['String']['output']>
}

export type DeclineMembershipInvite = {
  __typename?: 'DeclineMembershipInvite'
  success: Scalars['Boolean']['output']
}

export type DeleteCampaign = {
  __typename?: 'DeleteCampaign'
  deleted: Scalars['Boolean']['output']
}

export type DeleteCampaignShare = {
  __typename?: 'DeleteCampaignShare'
  deleted: Scalars['Boolean']['output']
}

export type DeleteContact = {
  __typename?: 'DeleteContact'
  deleted: Scalars['Boolean']['output']
}

export type DeleteContactRequest = {
  __typename?: 'DeleteContactRequest'
  success: Scalars['Boolean']['output']
}

export type DeleteContactShare = {
  __typename?: 'DeleteContactShare'
  deleted: Scalars['Boolean']['output']
}

export type DeviceInput = {
  notificationToken: Scalars['String']['input']
  type: DeviceType
}

export type DeleteDevice = {
  __typename?: 'DeleteDevice'
  deleted: Scalars['Boolean']['output']
}

export type DeleteGroup = {
  __typename?: 'DeleteGroup'
  deleted?: Maybe<Scalars['Boolean']['output']>
}

export type DeleteImage = {
  __typename?: 'DeleteImage'
  deleted: Scalars['Boolean']['output']
}

export type DeleteNote = {
  __typename?: 'DeleteNote'
  deleted?: Maybe<Scalars['Boolean']['output']>
}

export type DeleteOrder = {
  __typename?: 'DeleteOrder'
  deleted: Scalars['Boolean']['output']
}

export type DeletePanelLayout = {
  __typename?: 'DeletePanelLayout'
  deleted: Scalars['Boolean']['output']
}

export type DeleteReminder = {
  __typename?: 'DeleteReminder'
  deleted: Scalars['Boolean']['output']
}

export type DenyNotification = {
  __typename?: 'DenyNotification'
  success: Scalars['Boolean']['output']
}

export type DigitalCardOrderCreate = {
  __typename?: 'DigitalCardOrderCreate'
  order: DigitalCardOrder
}

export type DigitalCardOrderPay = {
  __typename?: 'DigitalCardOrderPay'
  order: DigitalCardOrder
}

export type DisplayUpsaleSetting = {
  __typename?: 'DisplayUpsaleSetting'
  shouldNotDisplay: Scalars['Boolean']['output']
}

export type ExportContacts = {
  __typename?: 'ExportContacts'
  success: Scalars['Boolean']['output']
}

export type FavoriteSendableCard = {
  __typename?: 'FavoriteSendableCard'
  sendableCard: SendableCard
}

export type GetOrCreateEditableCardForRecipient = {
  __typename?: 'GetOrCreateEditableCardForRecipient'
  card: Card
}

export type GetOrCreateImageForStickerUrl = {
  __typename?: 'GetOrCreateImageForStickerUrl'
  image: Image
}

export type IncrementCoachTrainingVideosWatched = {
  __typename?: 'IncrementCoachTrainingVideosWatched'
  promptingCoach: PromptingsCoach
}

export type LeaderBoardVisibility = {
  __typename?: 'LeaderBoardVisibility'
  isHidden: Scalars['Boolean']['output']
}

export type LoginUser = {
  __typename?: 'LoginUser'
  account?: Maybe<Account>
  message?: Maybe<Scalars['String']['output']>
  oldUser?: Maybe<Scalars['Boolean']['output']>
  success: Scalars['Boolean']['output']
  token?: Maybe<Scalars['String']['output']>
}

export type LogoutUser = {
  __typename?: 'LogoutUser'
  success: Scalars['Boolean']['output']
}

export type PurchaseProduct = {
  __typename?: 'PurchaseProduct'
  account: Account
  error?: Maybe<Scalars['String']['output']>
  purchased?: Maybe<Scalars['Boolean']['output']>
}

export type RegisterDevice = {
  __typename?: 'RegisterDevice'
  device: Device
}

export type RemovePaymentMethod = {
  __typename?: 'RemovePaymentMethod'
  success: Scalars['Boolean']['output']
}

export type RequestEmailVerificationResponse =
  | RequestEmailVerificationCodeFailure
  | RequestEmailVerificationCodeSuccess

export type RequestEmailVerificationCodeFailure = {
  __typename?: 'RequestEmailVerificationCodeFailure'
  failureType: RequestEmailVerificationFailureType
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export enum RequestEmailVerificationFailureType {
  InvalidEmail = 'INVALID_EMAIL',
  TooManyAttempts = 'TOO_MANY_ATTEMPTS',
}

export type RequestEmailVerificationCodeSuccess = {
  __typename?: 'RequestEmailVerificationCodeSuccess'
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

/** A normalized representation of a valid international phone number. */
export type PhoneNumberInput = {
  /** The ISO 3166 alpha-2 code of the phone number's country. */
  countryIsoCode: Scalars['String']['input']
  /** An optional extension up to 40 characters long that only contains digits 0-9. */
  extension?: InputMaybe<Scalars['String']['input']>
  /** The national phone number represented as a long (64-bit integer). */
  nationalNumber: Scalars['Long']['input']
  /**
   * The number of zeros proceeding the national number.
   *         This only applies in some countries and is typically zero.
   */
  numberOfLeadingZeros?: InputMaybe<Scalars['Int']['input']>
  /** The carrier selection code that is preferred when calling this number domestically. */
  preferredDomesticCarrierCode?: InputMaybe<Scalars['String']['input']>
}

export type RequestPhoneNumberVerificationResult =
  | RequestPhoneNumberVerificationFailure
  | RequestPhoneNumberVerificationSuccess

export type RequestPhoneNumberVerificationFailure = {
  __typename?: 'RequestPhoneNumberVerificationFailure'
  failureMessage: Scalars['String']['output']
  failureType: RequestPhoneNumberVerificationFailureType
}

export enum RequestPhoneNumberVerificationFailureType {
  GenericError = 'GENERIC_ERROR',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  TooManyAttempts = 'TOO_MANY_ATTEMPTS',
}

export type RequestPhoneNumberVerificationSuccess = {
  __typename?: 'RequestPhoneNumberVerificationSuccess'
  successMessage: Scalars['String']['output']
}

export type RevertBulkOrder = {
  __typename?: 'RevertBulkOrder'
  order: Order
}

export type SendMagicLink = {
  __typename?: 'SendMagicLink'
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type SendOrder = {
  __typename?: 'SendOrder'
  order: Order
}

export type SendPausedRecipients = {
  __typename?: 'SendPausedRecipients'
  success?: Maybe<Scalars['Boolean']['output']>
}

export type SeparateContact = {
  __typename?: 'SeparateContact'
  contacts?: Maybe<Array<Maybe<Contact>>>
}

export type ShareCampaign = {
  __typename?: 'ShareCampaign'
  shared: Scalars['Boolean']['output']
}

export type ShareContact = {
  __typename?: 'ShareContact'
  shared: Scalars['Boolean']['output']
}

export type ShouldDisplayUpsaleTrigger = {
  __typename?: 'ShouldDisplayUpsaleTrigger'
  shouldDisplayUpsale: Scalars['Boolean']['output']
}

export type UnfavoriteSendableCard = {
  __typename?: 'UnfavoriteSendableCard'
  sendableCard: SendableCard
}

export type SubscriptionInput = {
  cancelAtPeriodEnd?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  plan?: InputMaybe<Scalars['String']['input']>
}

export type UpdateAccount = {
  __typename?: 'UpdateAccount'
  account: Account
}

export type UpdateCampaignInput = {
  id: Scalars['ID']['input']
  lines?: InputMaybe<Array<LineInput>>
  name?: InputMaybe<Scalars['String']['input']>
  returnAddress?: InputMaybe<AddressInput>
}

export type UpdateCampaign = {
  __typename?: 'UpdateCampaign'
  campaign: Campaign
}

export type UpdateCardInput = {
  id: Scalars['ID']['input']
  isBackPanelHorizontal?: InputMaybe<Scalars['Boolean']['input']>
  panels?: InputMaybe<Array<PanelInput>>
  paperType?: InputMaybe<CardPaperType>
  recipient?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateCard = {
  __typename?: 'UpdateCard'
  card: Card
}

export type UpdateCoachPublicInfoDisplayInput = {
  isEmailPublic?: InputMaybe<Scalars['Boolean']['input']>
  isPhoneNumberPublic?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateCoachPublicInfoDisplay = {
  __typename?: 'UpdateCoachPublicInfoDisplay'
  promptingCoach: PromptingsCoach
}

export type UpdateDigitalCard = {
  __typename?: 'UpdateDigitalCard'
  digitalCard?: Maybe<DigitalCard>
}

export type UpdateGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  members?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  /** New contacts to add to (and not replace) the group's set of members. */
  newMembers?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UpdateGroup = {
  __typename?: 'UpdateGroup'
  group: Group
}

export type UpdateInviteInput = {
  contactId: Scalars['ID']['input']
  inviteId: Scalars['ID']['input']
}

export type UpdateInvite = {
  __typename?: 'UpdateInvite'
  invite: Invite
}

export type UpdateNote = {
  __typename?: 'UpdateNote'
  contact: Contact
}

export type UpdateOrderInput = {
  bulkUpgradeCardQty?: InputMaybe<Scalars['Int']['input']>
  bulkUpgradeShippingAddress?: InputMaybe<AddressInput>
  campaign?: InputMaybe<Scalars['ID']['input']>
  contacts?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Adds all contacts from the specified groups to the order. */
  contactsFromGroups?: InputMaybe<Array<Scalars['ID']['input']>>
  groups?: InputMaybe<Array<Scalars['ID']['input']>>
  id: Scalars['ID']['input']
  lines?: InputMaybe<Array<LineInput>>
  returnAddress?: InputMaybe<AddressInput>
}

export type UpdateOrder = {
  __typename?: 'UpdateOrder'
  order: Order
  updated: Scalars['Boolean']['output']
}

export type UpdateProductionInfoInput = {
  dateToSend?: InputMaybe<Scalars['Date']['input']>
  productionId: Scalars['ID']['input']
}

export type UpdateProductionInfo = {
  __typename?: 'UpdateProductionInfo'
  productionInfo: ProductionInfo
}

export type RecipientInput = {
  address: AddressInput
  id: Scalars['ID']['input']
}

export type UpdateRecipient = {
  __typename?: 'UpdateRecipient'
  recipient: Recipient
  success?: Maybe<Scalars['Boolean']['output']>
}

export type UpdateReminderInput = {
  id: Scalars['ID']['input']
  orderId: Scalars['ID']['input']
}

export type UpdateReminder = {
  __typename?: 'UpdateReminder'
  reminder: Reminder
}

export type UpdateRequestedContactInput = {
  address1?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export type UpdateRequestedContact = {
  __typename?: 'UpdateRequestedContact'
  contact: Contact
}

export type CardBillingAddressInput = {
  addressCity?: InputMaybe<Scalars['String']['input']>
  addressCountry?: InputMaybe<Scalars['String']['input']>
  addressLine1?: InputMaybe<Scalars['String']['input']>
  addressLine2?: InputMaybe<Scalars['String']['input']>
  addressState?: InputMaybe<Scalars['String']['input']>
  addressZip?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSourceBillingAddress = {
  __typename?: 'UpdateSourceBillingAddress'
  account: Account
}

export type UpdateStoreFrontMugshot = {
  __typename?: 'UpdateStoreFrontMugshot'
  storefront: Storefront
}

export type UserLabelOverrideInput = {
  action: UserLabelOverrideAction
  label: OverridableUserLabel
}

/** An enumeration. */
export enum UserLabelOverrideAction {
  /** Disable Override */
  Disable = 'DISABLE',
  /** Force Exclude */
  Exclude = 'EXCLUDE',
  /** Force Include */
  Include = 'INCLUDE',
}

/** An enumeration. */
export enum OverridableUserLabel {
  AffiliateMissingW8 = 'AFFILIATE_MISSING_W8',
  AffiliateMissingW9 = 'AFFILIATE_MISSING_W9',
  FreeCampaignStore = 'FREE_CAMPAIGN_STORE',
  GsaSubscriptionPaymentFailure = 'GSA_SUBSCRIPTION_PAYMENT_FAILURE',
  HasRelationshipMarketing = 'HAS_RELATIONSHIP_MARKETING',
  NeedsAffiliateAccInfo = 'NEEDS_AFFILIATE_ACC_INFO',
  NeedsCustomerReferralOptIn = 'NEEDS_CUSTOMER_REFERRAL_OPT_IN',
  NeedsGsAffiliateOptIn = 'NEEDS_GS_AFFILIATE_OPT_IN',
  PriorAffiliate = 'PRIOR_AFFILIATE',
  ShouldHideRevolutionOffice = 'SHOULD_HIDE_REVOLUTION_OFFICE',
}

export type UpdateUserLabelOverrides = {
  __typename?: 'UpdateUserLabelOverrides'
  overrides: Array<UserLabelOverride>
}

export type UserLabelOverride = {
  __typename?: 'UserLabelOverride'
  action: UserLabelOverrideAction
  label: OverridableUserLabel
}

export type UpgradeToAffiliate = {
  __typename?: 'UpgradeToAffiliate'
  account?: Maybe<Account>
}

export type UpgradeToGreenerStillAffiliate = {
  __typename?: 'UpgradeToGreenerStillAffiliate'
  account?: Maybe<Account>
  error?: Maybe<Scalars['String']['output']>
  subscription?: Maybe<StripeSubscription>
}

export type UpgradeToReferringCustomer = {
  __typename?: 'UpgradeToReferringCustomer'
  account?: Maybe<Account>
}

export type UploadImage = {
  __typename?: 'UploadImage'
  image: Image
}

export type UpsertOrderInput = {
  bulkUpgradeCardQty?: InputMaybe<Scalars['Int']['input']>
  bulkUpgradeShippingAddress?: InputMaybe<AddressInput>
  campaign?: InputMaybe<Scalars['ID']['input']>
  contacts?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Adds all contacts from the specified groups to the order. */
  contactsFromGroups?: InputMaybe<Array<Scalars['ID']['input']>>
  groups?: InputMaybe<Array<Scalars['ID']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  lines?: InputMaybe<Array<LineInput>>
  returnAddress?: InputMaybe<AddressInput>
}

export type UpsertOrder = {
  __typename?: 'UpsertOrder'
  order: Order
  upserted: Scalars['Boolean']['output']
}

export type ValidateEmail = {
  __typename?: 'ValidateEmail'
  errorMsg?: Maybe<Scalars['String']['output']>
  isValid: Scalars['Boolean']['output']
}

export type ValidatePhoneNumber = {
  __typename?: 'ValidatePhoneNumber'
  isValid: Scalars['Boolean']['output']
}

export type ValidateUsername = {
  __typename?: 'ValidateUsername'
  isValid: Scalars['Boolean']['output']
  /** @deprecated Use isValid instead. */
  valid: Scalars['Boolean']['output']
}

export type VerifyPhoneNumberResult =
  | VerifyPhoneNumberFailure
  | VerifyPhoneNumberSuccess

export type VerifyPhoneNumberFailure = {
  __typename?: 'VerifyPhoneNumberFailure'
  failureMessage: Scalars['String']['output']
  failureType: VerifyPhoneNumberFailureType
}

export enum VerifyPhoneNumberFailureType {
  AlreadyVerified = 'ALREADY_VERIFIED',
  CodeExpired = 'CODE_EXPIRED',
  IncorrectCode = 'INCORRECT_CODE',
  NoVerifiedAccountsFound = 'NO_VERIFIED_ACCOUNTS_FOUND',
  TooManyAttempts = 'TOO_MANY_ATTEMPTS',
  UnverifiedAccountFound = 'UNVERIFIED_ACCOUNT_FOUND',
}

export type VerifyPhoneNumberSuccess = {
  __typename?: 'VerifyPhoneNumberSuccess'
  successType: VerifyPhoneNumberSuccessType
  verifiedAccount: Account
}

export enum VerifyPhoneNumberSuccessType {
  CreatedNewAccount = 'CREATED_NEW_ACCOUNT',
  SignedIntoExistingAccount = 'SIGNED_INTO_EXISTING_ACCOUNT',
  UpdatedExistingAccount = 'UPDATED_EXISTING_ACCOUNT',
}

export type BankAccount = StripeSource & {
  __typename?: 'BankAccount'
  accountHolderName: Scalars['String']['output']
  bankName: Scalars['String']['output']
  id: Scalars['ID']['output']
  last4: Scalars['String']['output']
  object: Scalars['String']['output']
}

export type CardBillingAddress = {
  __typename?: 'CardBillingAddress'
  addressCity?: Maybe<Scalars['String']['output']>
  addressCountry?: Maybe<Scalars['String']['output']>
  addressLine1?: Maybe<Scalars['String']['output']>
  addressLine2?: Maybe<Scalars['String']['output']>
  addressState?: Maybe<Scalars['String']['output']>
  addressZip?: Maybe<Scalars['String']['output']>
}

export type CreditCard = StripeSource & {
  __typename?: 'CreditCard'
  billingAddress?: Maybe<CardBillingAddress>
  brand: Scalars['String']['output']
  expMonth: Scalars['Int']['output']
  expYear: Scalars['Int']['output']
  id: Scalars['ID']['output']
  last4: Scalars['String']['output']
  object: Scalars['String']['output']
}

export type AccountFragment = {
  __typename: 'Account'
  id: string
  username: string
  firstName: string
  lastName: string
  email: string
  profileImageUrl?: any | null
  isStaff: boolean
  phoneNumber: string
  isAffiliate: boolean
  isNewUser: boolean
  createdAt: any
  genealogyId: number
  stripeCustomerId?: string | null
  dailyHeartfeltSends: number
  hasUnlimitedHeartfeltSends: boolean
  fullAccessFeatures: Array<FullAccessFeatureType>
  loyaltyRewards: number
  groupSends: number
  heartfeltSends: number
  heartfeltSystemSends: number
  scheduledSends: number
  systemSends: number
  cardPackageSends: number
  pointsTransitionSends: number
  employeeSends: number
  cardSends: number
  giftSends: number
  pointPrice: number
  promptingProLoginUrl: any
  promptinguLoginUrl?: any | null
  streamMarketingLoginUrl?: any | null
  promptinguJwt: string
  token: string
  hasBundlePlan: boolean
  affiliateUrl: any
  couldQualifyForFirstFreeCard: boolean
  isQualifiedForFirstFreeCard: boolean
  isTrialAffiliate: boolean
  isEligibleForFreeGsAffiliateAccount: boolean
  isEmailVerified: boolean
  labels: Array<Label>
  type: UserType
  cardCost: number
  bulkCardCost: number
  giftDiscount: number
  giftShippingDiscount: number
  isExpenseReloadRequired: boolean
  isRep: boolean
  dateJoined: any
  hasFullAccessContactManager: boolean
  hasFullAccessFreeAffiliate: boolean
  shouldDisplayUpsale: boolean
  hasEverHadSamplePack: boolean
  activePlansAndAddons: Array<{
    __typename: 'Plan'
    id: string
    title: string
    subtitle?: string | null
    stripeId: string
    description?: any | null
    isAddon: boolean
    cardCost: number
    bulkCardCost: number
    giftDiscount: number
    isExpenseReloadRequired: boolean
    color?: string | null
    backgroundStartColor: string
    backgroundEndColor: string
    icon: PlansPlanIconChoices
    price: {
      __typename: 'Amount'
      amount: number
      currency: Currency
      description: string
    }
    relatedPackage?: {
      __typename?: 'RelatedPlanPackage'
      saleorProductId: string
      title: string
      description: string
      shouldShowByDefault: boolean
    } | null
  } | null>
  settings?: {
    __typename: 'UserSettings'
    remindersAdvanceNoticeDays: number
    remindersAdvanceNoticeDaysInternational: number
    paymentHold: boolean
    shouldReceivePromoEmails: boolean
    shouldReceiveNotificationEmails: boolean
    shouldReceiveReceiptEmails: boolean
    shouldReceiveReminderEmails: boolean
    shouldPrintRecipientCompany: boolean
    defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
  } | null
  shippingAddress?: {
    __typename: 'Address'
    firstName: string
    lastName: string
    company: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    country: string
  } | null
  sponsor?: {
    __typename: 'User'
    id: string
    firstName: string
    lastName: string
    email: string
    genealogyId: number
    storefront?: {
      __typename: 'Storefront'
      biography?: string | null
      slug?: string | null
      mugshot?: { __typename?: 'Image'; url: any } | null
    } | null
  } | null
  storefront?: {
    __typename: 'Storefront'
    biography?: string | null
    slug?: string | null
    mugshot?: { __typename?: 'Image'; url: any } | null
  } | null
  plan: {
    __typename: 'Plan'
    id: string
    title: string
    subtitle?: string | null
    stripeId: string
    description?: any | null
    isAddon: boolean
    cardCost: number
    bulkCardCost: number
    giftDiscount: number
    isExpenseReloadRequired: boolean
    color?: string | null
    backgroundStartColor: string
    backgroundEndColor: string
    icon: PlansPlanIconChoices
    price: {
      __typename: 'Amount'
      amount: number
      currency: Currency
      description: string
    }
    relatedPackage?: {
      __typename?: 'RelatedPlanPackage'
      saleorProductId: string
      title: string
      description: string
      shouldShowByDefault: boolean
    } | null
  }
  stripeSource?:
    | {
        __typename: 'BankAccount'
        id: string
        bankName: string
        object: string
        last4: string
        accountHolderName: string
      }
    | {
        __typename: 'CreditCard'
        id: string
        brand: string
        last4: string
        expMonth: number
        expYear: number
        object: string
        billingAddress?: {
          __typename?: 'CardBillingAddress'
          city?: string | null
          country?: string | null
          line1?: string | null
          line2?: string | null
          postalcode?: string | null
          state?: string | null
        } | null
      }
    | null
  stripeSubscription?: {
    __typename: 'StripeSubscription'
    id: string
    name: string
    status: SubscriptionStatus
    startDate: any
    currentPeriodEnd: any
    interval: string
    intervalCount: number
    price: number
    isRenewable: boolean
    sku: string
    isSetToCancel: boolean
    features?: {
      __typename: 'SubscriptionFeatures'
      groupSends?: number | null
      scheduledSends?: number | null
      loyaltyRewards?: number | null
      systemSends?: number | null
      heartfeltSends?: number | null
      cardPackageSends?: number | null
    } | null
    metadata?: {
      __typename: 'SubscriptionMetadata'
      planOnCancel?: string | null
    } | null
  } | null
  subscriptions: Array<{
    __typename: 'StripeSubscription'
    id: string
    name: string
    status: SubscriptionStatus
    startDate: any
    currentPeriodEnd: any
    interval: string
    intervalCount: number
    price: number
    isRenewable: boolean
    sku: string
    isSetToCancel: boolean
    features?: {
      __typename: 'SubscriptionFeatures'
      groupSends?: number | null
      scheduledSends?: number | null
      loyaltyRewards?: number | null
      systemSends?: number | null
      heartfeltSends?: number | null
      cardPackageSends?: number | null
    } | null
    metadata?: {
      __typename: 'SubscriptionMetadata'
      planOnCancel?: string | null
    } | null
  }>
  points: {
    __typename: 'Amount'
    amount: number
    currency: Currency
    description: string
  }
  expense: {
    __typename: 'Amount'
    amount: number
    currency: Currency
    description: string
  }
  expenseReload?: {
    __typename: 'ExpenseReload'
    purchaseAmount: number
    threshold: number
    isEnabled: boolean
    isRequired: boolean
  } | null
  verifiedPhoneNumber?: {
    __typename: 'PhoneNumber'
    nationalNumber: any
  } | null
  promptingsCoach?: {
    __typename?: 'PromptingsCoach'
    trainerId?: number | null
    dateTrainingPurchased?: any | null
    dateTrainingCompleted?: any | null
    dateTrainingExpires?: any | null
    dateCoachingPurchased?: any | null
    dateAttendedCertification?: any | null
    isEmailPublic: boolean
    isPhoneNumberPublic: boolean
    isCertified: boolean
    trainerEmail?: string | null
  } | null
}

export type AddressFragment = {
  __typename: 'Address'
  firstName: string
  lastName: string
  company: string
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  country: string
}

export type AmountFragment = {
  __typename: 'Amount'
  amount: number
  currency: Currency
  description: string
}

export type AwardFragment = {
  __typename: 'Award'
  id: string
  title: string
  iconUrl: any
  description: string
  progress: number
  threshold: number
  graphicUrl: any
  isLocked: boolean
  qualifications: Array<{
    __typename?: 'Qualification'
    id: string
    title: string
    description: string
    graphic: string
    threshold: number
    progress: number
    link?: { __typename?: 'MarketingURL'; title: string; url: string } | null
  } | null>
  link?: { __typename?: 'MarketingURL'; title: string; url: string } | null
}

export type BankAccountFragment = {
  __typename: 'BankAccount'
  id: string
  bankName: string
  object: string
  last4: string
  accountHolderName: string
}

export type CalendarDateFragment = {
  __typename: 'CalendarDate'
  day: number
  month: number
  year: number
}

export type CampaignFragment = {
  __typename: 'Campaign'
  id: string
  name: string
  isShareable: boolean
  lines: Array<{
    __typename: 'CampaignLine'
    name?: string | null
    card?: {
      __typename: 'Card'
      id: string
      type: CardType
      isHorizontal: boolean
      isNewEditorCard: boolean
      paperType: CardPaperType
      frontPreviewUrl: any
      insidePreviewUrl?: any | null
      flapPreviewUrl?: any | null
      backPreviewUrl: any
      tinyThumb: any
      sendableCard?: {
        __typename: 'SendableCard'
        id: string
        title: string
      } | null
      minimalPanels: Array<{
        __typename: 'Panel'
        name: string
        previewUrl: any
        isFullBleed: boolean
        width: number
        height: number
      }>
    } | null
    giftVariation?: {
      __typename: 'GiftVariation'
      id: string
      isSendable: boolean
      isEligibleFreeGift: boolean
      name: string
      description?: string | null
      contents?: string | null
      shipping?: string | null
      imageUrls: Array<any>
      packaging?: string | null
      defaultImageUrl: any
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      price: {
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }
    } | null
    sendDelay: {
      __typename: 'SendDelay'
      type: SendDelayType
      delayNumber?: number | null
      delayType: SendDelayDelayType
      timeType: SendDelayTimeType
      specificDate: any
    }
  }>
}

export type CampaignLineFragment = {
  __typename: 'CampaignLine'
  name?: string | null
  card?: {
    __typename: 'Card'
    id: string
    type: CardType
    isHorizontal: boolean
    isNewEditorCard: boolean
    paperType: CardPaperType
    frontPreviewUrl: any
    insidePreviewUrl?: any | null
    flapPreviewUrl?: any | null
    backPreviewUrl: any
    tinyThumb: any
    sendableCard?: {
      __typename: 'SendableCard'
      id: string
      title: string
    } | null
    minimalPanels: Array<{
      __typename: 'Panel'
      name: string
      previewUrl: any
      isFullBleed: boolean
      width: number
      height: number
    }>
  } | null
  giftVariation?: {
    __typename: 'GiftVariation'
    id: string
    isSendable: boolean
    isEligibleFreeGift: boolean
    name: string
    description?: string | null
    contents?: string | null
    shipping?: string | null
    imageUrls: Array<any>
    packaging?: string | null
    defaultImageUrl: any
    points: {
      __typename: 'Amount'
      amount: number
      currency: Currency
      description: string
    }
    price: {
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }
  } | null
  sendDelay: {
    __typename: 'SendDelay'
    type: SendDelayType
    delayNumber?: number | null
    delayType: SendDelayDelayType
    timeType: SendDelayTimeType
    specificDate: any
  }
}

export type CampaignShareFragment = {
  __typename: 'CampaignShare'
  id: string
  fromName: string
  dateShared: any
  campaign: { __typename: 'Campaign'; id: string; name: string }
}

export type CardFragment = {
  __typename: 'Card'
  id: string
  type: CardType
  paperType: CardPaperType
  isHorizontal: boolean
  isNewEditorCard: boolean
  backPanelLocation: number
  frontPreviewUrl: any
  insidePreviewUrl?: any | null
  flapPreviewUrl?: any | null
  backPreviewUrl: any
  showInPicturePlus: boolean
  tinyThumb: any
  detailedSendableCard?: {
    __typename: 'SendableCard'
    id: string
    title: string
    description: string
    variationColor: string
    isFavorite: boolean
    isHorizontal: boolean
    isPremium: boolean
    variations: Array<{
      __typename: 'SendableCard'
      id: string
      title: string
      description: string
      variationColor: string
      isFavorite: boolean
      isHorizontal: boolean
      isPremium: boolean
      cardTemplate?: {
        __typename: 'Card'
        id: string
        backPanelLocation: number
        panels: Array<{
          __typename: 'Panel'
          location: number
          isFullBleed: boolean
          isLocked: boolean
          name: string
          width: number
          height: number
          previewUrl: any
          backgroundColor?: {
            __typename: 'Color'
            red: number
            green: number
            blue: number
          } | null
          elements: Array<{
            __typename: 'Element'
            x: number
            y: number
            z: number
            rotation: number
            width: number
            height: number
            locked: boolean
            draggable?: boolean | null
            sticker?: {
              __typename: 'ElementSticker'
              scale: number
              sticker?: {
                __typename: 'EditorImageType'
                id: string
                url: string
                width: number
                height: number
                thumbnailUrl: string
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
            } | null
            image?: {
              __typename: 'ElementPicture'
              scale: number
              image?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
              filter?: { __typename?: 'Filter'; name: string } | null
            } | null
            text?: {
              __typename?: 'ElementText'
              paragraphs: Array<{
                __typename: 'Paragraph'
                alignment: TextAlignment
                textFragments: Array<{
                  __typename: 'TextFragment'
                  text: string
                  fontSize: number
                  isReplacement: boolean
                  textColor: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  }
                  font: { __typename: 'Font'; id: string; fontName: string }
                }>
              }>
            } | null
          }>
        }>
      } | null
      frontImage: {
        __typename: 'Image'
        id: string
        url: any
        width: number
        height: number
        smallThumb: any
        mediumThumb: any
      }
      insideRightImage?: {
        __typename: 'Image'
        id: string
        url: any
        width: number
        height: number
        smallThumb: any
        mediumThumb: any
      } | null
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
    }>
    cardTemplate?: {
      __typename: 'Card'
      id: string
      backPanelLocation: number
      panels: Array<{
        __typename: 'Panel'
        location: number
        isFullBleed: boolean
        isLocked: boolean
        name: string
        width: number
        height: number
        previewUrl: any
        backgroundColor?: {
          __typename: 'Color'
          red: number
          green: number
          blue: number
        } | null
        elements: Array<{
          __typename: 'Element'
          x: number
          y: number
          z: number
          rotation: number
          width: number
          height: number
          locked: boolean
          draggable?: boolean | null
          sticker?: {
            __typename: 'ElementSticker'
            scale: number
            sticker?: {
              __typename: 'EditorImageType'
              id: string
              url: string
              width: number
              height: number
              thumbnailUrl: string
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
          } | null
          image?: {
            __typename: 'ElementPicture'
            scale: number
            image?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
            filter?: { __typename?: 'Filter'; name: string } | null
          } | null
          text?: {
            __typename?: 'ElementText'
            paragraphs: Array<{
              __typename: 'Paragraph'
              alignment: TextAlignment
              textFragments: Array<{
                __typename: 'TextFragment'
                text: string
                fontSize: number
                isReplacement: boolean
                textColor: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                }
                font: { __typename: 'Font'; id: string; fontName: string }
              }>
            }>
          } | null
        }>
      }>
    } | null
    frontImage: {
      __typename: 'Image'
      id: string
      url: any
      width: number
      height: number
      smallThumb: any
      mediumThumb: any
    }
    insideRightImage?: {
      __typename: 'Image'
      id: string
      url: any
      width: number
      height: number
      smallThumb: any
      mediumThumb: any
    } | null
    cost: {
      __typename?: 'ItemizedCost'
      total: Array<{
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }>
      entries: Array<{
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }>
    }
  } | null
  panels: Array<{
    __typename: 'Panel'
    location: number
    isFullBleed: boolean
    isLocked: boolean
    name: string
    width: number
    height: number
    previewUrl: any
    backgroundColor?: {
      __typename: 'Color'
      red: number
      green: number
      blue: number
    } | null
    elements: Array<{
      __typename: 'Element'
      x: number
      y: number
      z: number
      rotation: number
      width: number
      height: number
      locked: boolean
      draggable?: boolean | null
      sticker?: {
        __typename: 'ElementSticker'
        scale: number
        sticker?: {
          __typename: 'EditorImageType'
          id: string
          url: string
          width: number
          height: number
          thumbnailUrl: string
        } | null
        position: { __typename?: 'Position'; x: number; y: number }
      } | null
      image?: {
        __typename: 'ElementPicture'
        scale: number
        image?: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
        } | null
        position: { __typename?: 'Position'; x: number; y: number }
        filter?: { __typename?: 'Filter'; name: string } | null
      } | null
      text?: {
        __typename?: 'ElementText'
        paragraphs: Array<{
          __typename: 'Paragraph'
          alignment: TextAlignment
          textFragments: Array<{
            __typename: 'TextFragment'
            text: string
            fontSize: number
            isReplacement: boolean
            textColor: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            }
            font: { __typename: 'Font'; id: string; fontName: string }
          }>
        }>
      } | null
    }>
  }>
  cost: {
    __typename?: 'ItemizedCost'
    total: Array<{
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }>
    entries: Array<{
      __typename: 'LabeledPrice'
      label: string
      asString: string
      currency: CurrencyType
      amount: number
    }>
  }
  paperUpgradeCosts: Array<{
    __typename?: 'PaperCosts'
    value: string
    upgrade: string
  }>
  digitalCard?: {
    __typename?: 'DigitalCard'
    pk: string
    shareMessageTitle: string
    shareMessageBody: string
    tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
  } | null
}

export type CardWithoutCostFragment = {
  __typename: 'Card'
  id: string
  type: CardType
  paperType: CardPaperType
  isHorizontal: boolean
  isNewEditorCard: boolean
  backPanelLocation: number
  frontPreviewUrl: any
  insidePreviewUrl?: any | null
  flapPreviewUrl?: any | null
  backPreviewUrl: any
  showInPicturePlus: boolean
  tinyThumb: any
  detailedSendableCard?: {
    __typename: 'SendableCard'
    id: string
    title: string
    description: string
    variationColor: string
    isFavorite: boolean
    isHorizontal: boolean
    isPremium: boolean
    variations: Array<{
      __typename: 'SendableCard'
      id: string
      title: string
      description: string
      variationColor: string
      isFavorite: boolean
      isHorizontal: boolean
      isPremium: boolean
      cardTemplate?: {
        __typename: 'Card'
        id: string
        backPanelLocation: number
        panels: Array<{
          __typename: 'Panel'
          location: number
          isFullBleed: boolean
          isLocked: boolean
          name: string
          width: number
          height: number
          previewUrl: any
          backgroundColor?: {
            __typename: 'Color'
            red: number
            green: number
            blue: number
          } | null
          elements: Array<{
            __typename: 'Element'
            x: number
            y: number
            z: number
            rotation: number
            width: number
            height: number
            locked: boolean
            draggable?: boolean | null
            sticker?: {
              __typename: 'ElementSticker'
              scale: number
              sticker?: {
                __typename: 'EditorImageType'
                id: string
                url: string
                width: number
                height: number
                thumbnailUrl: string
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
            } | null
            image?: {
              __typename: 'ElementPicture'
              scale: number
              image?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
              filter?: { __typename?: 'Filter'; name: string } | null
            } | null
            text?: {
              __typename?: 'ElementText'
              paragraphs: Array<{
                __typename: 'Paragraph'
                alignment: TextAlignment
                textFragments: Array<{
                  __typename: 'TextFragment'
                  text: string
                  fontSize: number
                  isReplacement: boolean
                  textColor: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  }
                  font: { __typename: 'Font'; id: string; fontName: string }
                }>
              }>
            } | null
          }>
        }>
      } | null
      frontImage: {
        __typename: 'Image'
        id: string
        url: any
        width: number
        height: number
        smallThumb: any
        mediumThumb: any
      }
      insideRightImage?: {
        __typename: 'Image'
        id: string
        url: any
        width: number
        height: number
        smallThumb: any
        mediumThumb: any
      } | null
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
    }>
    cardTemplate?: {
      __typename: 'Card'
      id: string
      backPanelLocation: number
      panels: Array<{
        __typename: 'Panel'
        location: number
        isFullBleed: boolean
        isLocked: boolean
        name: string
        width: number
        height: number
        previewUrl: any
        backgroundColor?: {
          __typename: 'Color'
          red: number
          green: number
          blue: number
        } | null
        elements: Array<{
          __typename: 'Element'
          x: number
          y: number
          z: number
          rotation: number
          width: number
          height: number
          locked: boolean
          draggable?: boolean | null
          sticker?: {
            __typename: 'ElementSticker'
            scale: number
            sticker?: {
              __typename: 'EditorImageType'
              id: string
              url: string
              width: number
              height: number
              thumbnailUrl: string
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
          } | null
          image?: {
            __typename: 'ElementPicture'
            scale: number
            image?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
            filter?: { __typename?: 'Filter'; name: string } | null
          } | null
          text?: {
            __typename?: 'ElementText'
            paragraphs: Array<{
              __typename: 'Paragraph'
              alignment: TextAlignment
              textFragments: Array<{
                __typename: 'TextFragment'
                text: string
                fontSize: number
                isReplacement: boolean
                textColor: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                }
                font: { __typename: 'Font'; id: string; fontName: string }
              }>
            }>
          } | null
        }>
      }>
    } | null
    frontImage: {
      __typename: 'Image'
      id: string
      url: any
      width: number
      height: number
      smallThumb: any
      mediumThumb: any
    }
    insideRightImage?: {
      __typename: 'Image'
      id: string
      url: any
      width: number
      height: number
      smallThumb: any
      mediumThumb: any
    } | null
    cost: {
      __typename?: 'ItemizedCost'
      total: Array<{
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }>
      entries: Array<{
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }>
    }
  } | null
  panels: Array<{
    __typename: 'Panel'
    location: number
    isFullBleed: boolean
    isLocked: boolean
    name: string
    width: number
    height: number
    previewUrl: any
    backgroundColor?: {
      __typename: 'Color'
      red: number
      green: number
      blue: number
    } | null
    elements: Array<{
      __typename: 'Element'
      x: number
      y: number
      z: number
      rotation: number
      width: number
      height: number
      locked: boolean
      draggable?: boolean | null
      sticker?: {
        __typename: 'ElementSticker'
        scale: number
        sticker?: {
          __typename: 'EditorImageType'
          id: string
          url: string
          width: number
          height: number
          thumbnailUrl: string
        } | null
        position: { __typename?: 'Position'; x: number; y: number }
      } | null
      image?: {
        __typename: 'ElementPicture'
        scale: number
        image?: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
        } | null
        position: { __typename?: 'Position'; x: number; y: number }
        filter?: { __typename?: 'Filter'; name: string } | null
      } | null
      text?: {
        __typename?: 'ElementText'
        paragraphs: Array<{
          __typename: 'Paragraph'
          alignment: TextAlignment
          textFragments: Array<{
            __typename: 'TextFragment'
            text: string
            fontSize: number
            isReplacement: boolean
            textColor: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            }
            font: { __typename: 'Font'; id: string; fontName: string }
          }>
        }>
      } | null
    }>
  }>
  paperUpgradeCosts: Array<{
    __typename?: 'PaperCosts'
    value: string
    upgrade: string
  }>
  digitalCard?: {
    __typename?: 'DigitalCard'
    pk: string
    shareMessageTitle: string
    shareMessageBody: string
    tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
  } | null
}

export type ColorFragment = {
  __typename: 'Color'
  red: number
  green: number
  blue: number
}

export type ContactFragment = {
  __typename: 'Contact'
  id: string
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  country?: string | null
  isPrimary: boolean
  company: string
  birthday?: {
    __typename: 'OptionalYearDate'
    day: number
    month: number
    year?: number | null
  } | null
  anniversary?: {
    __typename: 'OptionalYearDate'
    day: number
    month: number
    year?: number | null
  } | null
}

export type ContactShareFragment = {
  __typename: 'ContactShare'
  id: string
  createdAt: any
  contact: {
    __typename: 'Contact'
    id: string
    firstName: string
    lastName: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    country?: string | null
    isPrimary: boolean
    company: string
    birthday?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
    anniversary?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
  }
  fromUser: {
    __typename: 'User'
    id: string
    username: string
    firstName: string
    lastName: string
    email: string
  }
}

export type CreditCardFragment = {
  __typename: 'CreditCard'
  id: string
  brand: string
  last4: string
  expMonth: number
  expYear: number
  object: string
  billingAddress?: {
    __typename?: 'CardBillingAddress'
    city?: string | null
    country?: string | null
    line1?: string | null
    line2?: string | null
    postalcode?: string | null
    state?: string | null
  } | null
}

export type CreditCardBillingAddressFragment = {
  __typename?: 'CardBillingAddress'
  city?: string | null
  country?: string | null
  line1?: string | null
  line2?: string | null
  postalcode?: string | null
  state?: string | null
}

export type DetailedContactFragment = {
  __typename: 'Contact'
  homePhone?: string | null
  cellPhone?: string | null
  workPhone?: string | null
  faxNumber?: string | null
  website: string
  emailAddress?: string | null
  id: string
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  country?: string | null
  isPrimary: boolean
  company: string
  parent?: {
    __typename?: 'Contact'
    id: string
    firstName: string
    lastName: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    country?: string | null
    isPrimary: boolean
    homePhone?: string | null
    cellPhone?: string | null
    workPhone?: string | null
    faxNumber?: string | null
    website: string
    emailAddress?: string | null
    company: string
    birthday?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
    anniversary?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
  } | null
  spouse?: {
    __typename?: 'Contact'
    id: string
    firstName: string
    lastName: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    country?: string | null
    isPrimary: boolean
    homePhone?: string | null
    cellPhone?: string | null
    workPhone?: string | null
    faxNumber?: string | null
    website: string
    emailAddress?: string | null
    company: string
    birthday?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
    anniversary?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
  } | null
  children: Array<{
    __typename?: 'Contact'
    id: string
    firstName: string
    lastName: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    country?: string | null
    isPrimary: boolean
    homePhone?: string | null
    cellPhone?: string | null
    workPhone?: string | null
    faxNumber?: string | null
    website: string
    emailAddress?: string | null
    company: string
    birthday?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
    anniversary?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
  }>
  groups: Array<{ __typename: 'Group'; id: string; name: string }>
  notes: Array<{
    __typename: 'Note'
    id: string
    text: string
    date?: {
      __typename: 'CalendarDate'
      day: number
      month: number
      year: number
    } | null
  }>
  birthday?: {
    __typename: 'OptionalYearDate'
    day: number
    month: number
    year?: number | null
  } | null
  anniversary?: {
    __typename: 'OptionalYearDate'
    day: number
    month: number
    year?: number | null
  } | null
}

export type DetailedCountryFragment = {
  __typename: 'Country'
  postalName: string
  alpha2: string
  subdivisions: Array<{ __typename: 'Subdivision'; abbreviationOrName: string }>
}

export type DetailedLayoutFragment = {
  __typename: 'Layout'
  id: string
  category?: LayoutCategory | null
  owner: Owner
  dimensions?: LayoutDimensions | null
  panel: {
    __typename: 'Panel'
    location: number
    isFullBleed: boolean
    isLocked: boolean
    name: string
    width: number
    height: number
    previewUrl: any
    backgroundColor?: {
      __typename: 'Color'
      red: number
      green: number
      blue: number
    } | null
    elements: Array<{
      __typename: 'Element'
      x: number
      y: number
      z: number
      rotation: number
      width: number
      height: number
      locked: boolean
      draggable?: boolean | null
      sticker?: {
        __typename: 'ElementSticker'
        scale: number
        sticker?: {
          __typename: 'EditorImageType'
          id: string
          url: string
          width: number
          height: number
          thumbnailUrl: string
        } | null
        position: { __typename?: 'Position'; x: number; y: number }
      } | null
      image?: {
        __typename: 'ElementPicture'
        scale: number
        image?: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
        } | null
        position: { __typename?: 'Position'; x: number; y: number }
        filter?: { __typename?: 'Filter'; name: string } | null
      } | null
      text?: {
        __typename?: 'ElementText'
        paragraphs: Array<{
          __typename: 'Paragraph'
          alignment: TextAlignment
          textFragments: Array<{
            __typename: 'TextFragment'
            text: string
            fontSize: number
            isReplacement: boolean
            textColor: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            }
            font: { __typename: 'Font'; id: string; fontName: string }
          }>
        }>
      } | null
    }>
  }
  thumbnail: { __typename?: 'Image'; url: any }
}

export type DetailedOrderFragment = {
  __typename: 'Order'
  id: string
  isSendable: boolean
  createdAt: any
  isSent: boolean
  sendType: CardSendType
  isBulk: boolean
  bulkUpgradeCardQty?: number | null
  lines: Array<{
    __typename: 'Line'
    recipientCount: number
    card?: {
      __typename: 'Card'
      id: string
      type: CardType
      paperType: CardPaperType
      isHorizontal: boolean
      isNewEditorCard: boolean
      backPanelLocation: number
      frontPreviewUrl: any
      insidePreviewUrl?: any | null
      flapPreviewUrl?: any | null
      backPreviewUrl: any
      showInPicturePlus: boolean
      tinyThumb: any
      detailedSendableCard?: {
        __typename: 'SendableCard'
        id: string
        title: string
        description: string
        variationColor: string
        isFavorite: boolean
        isHorizontal: boolean
        isPremium: boolean
        variations: Array<{
          __typename: 'SendableCard'
          id: string
          title: string
          description: string
          variationColor: string
          isFavorite: boolean
          isHorizontal: boolean
          isPremium: boolean
          cardTemplate?: {
            __typename: 'Card'
            id: string
            backPanelLocation: number
            panels: Array<{
              __typename: 'Panel'
              location: number
              isFullBleed: boolean
              isLocked: boolean
              name: string
              width: number
              height: number
              previewUrl: any
              backgroundColor?: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              } | null
              elements: Array<{
                __typename: 'Element'
                x: number
                y: number
                z: number
                rotation: number
                width: number
                height: number
                locked: boolean
                draggable?: boolean | null
                sticker?: {
                  __typename: 'ElementSticker'
                  scale: number
                  sticker?: {
                    __typename: 'EditorImageType'
                    id: string
                    url: string
                    width: number
                    height: number
                    thumbnailUrl: string
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                } | null
                image?: {
                  __typename: 'ElementPicture'
                  scale: number
                  image?: {
                    __typename: 'Image'
                    id: string
                    url: any
                    width: number
                    height: number
                    smallThumb: any
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                  filter?: { __typename?: 'Filter'; name: string } | null
                } | null
                text?: {
                  __typename?: 'ElementText'
                  paragraphs: Array<{
                    __typename: 'Paragraph'
                    alignment: TextAlignment
                    textFragments: Array<{
                      __typename: 'TextFragment'
                      text: string
                      fontSize: number
                      isReplacement: boolean
                      textColor: {
                        __typename: 'Color'
                        red: number
                        green: number
                        blue: number
                      }
                      font: { __typename: 'Font'; id: string; fontName: string }
                    }>
                  }>
                } | null
              }>
            }>
          } | null
          frontImage: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          }
          insideRightImage?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          } | null
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
        }>
        cardTemplate?: {
          __typename: 'Card'
          id: string
          backPanelLocation: number
          panels: Array<{
            __typename: 'Panel'
            location: number
            isFullBleed: boolean
            isLocked: boolean
            name: string
            width: number
            height: number
            previewUrl: any
            backgroundColor?: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            } | null
            elements: Array<{
              __typename: 'Element'
              x: number
              y: number
              z: number
              rotation: number
              width: number
              height: number
              locked: boolean
              draggable?: boolean | null
              sticker?: {
                __typename: 'ElementSticker'
                scale: number
                sticker?: {
                  __typename: 'EditorImageType'
                  id: string
                  url: string
                  width: number
                  height: number
                  thumbnailUrl: string
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
              } | null
              image?: {
                __typename: 'ElementPicture'
                scale: number
                image?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
                filter?: { __typename?: 'Filter'; name: string } | null
              } | null
              text?: {
                __typename?: 'ElementText'
                paragraphs: Array<{
                  __typename: 'Paragraph'
                  alignment: TextAlignment
                  textFragments: Array<{
                    __typename: 'TextFragment'
                    text: string
                    fontSize: number
                    isReplacement: boolean
                    textColor: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    }
                    font: { __typename: 'Font'; id: string; fontName: string }
                  }>
                }>
              } | null
            }>
          }>
        } | null
        frontImage: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        }
        insideRightImage?: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        } | null
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
      } | null
      panels: Array<{
        __typename: 'Panel'
        location: number
        isFullBleed: boolean
        isLocked: boolean
        name: string
        width: number
        height: number
        previewUrl: any
        backgroundColor?: {
          __typename: 'Color'
          red: number
          green: number
          blue: number
        } | null
        elements: Array<{
          __typename: 'Element'
          x: number
          y: number
          z: number
          rotation: number
          width: number
          height: number
          locked: boolean
          draggable?: boolean | null
          sticker?: {
            __typename: 'ElementSticker'
            scale: number
            sticker?: {
              __typename: 'EditorImageType'
              id: string
              url: string
              width: number
              height: number
              thumbnailUrl: string
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
          } | null
          image?: {
            __typename: 'ElementPicture'
            scale: number
            image?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
            filter?: { __typename?: 'Filter'; name: string } | null
          } | null
          text?: {
            __typename?: 'ElementText'
            paragraphs: Array<{
              __typename: 'Paragraph'
              alignment: TextAlignment
              textFragments: Array<{
                __typename: 'TextFragment'
                text: string
                fontSize: number
                isReplacement: boolean
                textColor: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                }
                font: { __typename: 'Font'; id: string; fontName: string }
              }>
            }>
          } | null
        }>
      }>
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
      paperUpgradeCosts: Array<{
        __typename?: 'PaperCosts'
        value: string
        upgrade: string
      }>
      digitalCard?: {
        __typename?: 'DigitalCard'
        pk: string
        shareMessageTitle: string
        shareMessageBody: string
        tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
      } | null
    } | null
    giftVariation?: {
      __typename: 'GiftVariation'
      id: string
      isSendable: boolean
      isEligibleFreeGift: boolean
      name: string
      description?: string | null
      contents?: string | null
      shipping?: string | null
      imageUrls: Array<any>
      packaging?: string | null
      defaultImageUrl: any
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      price: {
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }
    } | null
    sendDelay: {
      __typename: 'SendDelay'
      type: SendDelayType
      delayNumber?: number | null
      delayType: SendDelayDelayType
      timeType: SendDelayTimeType
      specificDate: any
    }
    cost: {
      __typename?: 'ItemizedCost'
      total: Array<{
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }>
      entries: Array<{
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }>
    }
    baseCost: {
      __typename?: 'ItemizedCost'
      total: Array<{
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }>
      entries: Array<{
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }>
    }
  }>
  contacts: Array<{ __typename: 'Contact'; id: string }>
  groups: Array<{
    __typename: 'Group'
    id: string
    name: string
    members: Array<{ __typename: 'Contact'; id: string }>
  }>
  returnAddress?: {
    __typename: 'Address'
    firstName: string
    lastName: string
    company: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    country: string
  } | null
  campaign?: { __typename: 'Campaign'; id: string; name: string } | null
  cost: {
    __typename?: 'ItemizedCost'
    total: Array<{
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }>
    entries: Array<{
      __typename: 'LabeledPrice'
      label: string
      asString: string
      currency: CurrencyType
      amount: number
    }>
  }
  warnings: Array<{
    __typename: 'Error'
    code?: number | null
    message?: string | null
  }>
  invalidContacts: Array<{ __typename: 'Contact'; id: string }>
  invalidSendDelayContacts: Array<{ __typename: 'Contact'; id: string }>
  bulkUpgradeShippingAddress?: {
    __typename: 'Address'
    firstName: string
    lastName: string
    company: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    country: string
  } | null
}

export type DetailedSendableCardFragment = {
  __typename: 'SendableCard'
  description: string
  variationColor: string
  id: string
  title: string
  isFavorite: boolean
  isHorizontal: boolean
  isPremium: boolean
  insideRightImage?: {
    __typename: 'Image'
    id: string
    url: any
    width: number
    height: number
    smallThumb: any
    mediumThumb: any
  } | null
  variations: Array<{
    __typename: 'SendableCard'
    id: string
    title: string
    description: string
    variationColor: string
    isFavorite: boolean
    isHorizontal: boolean
    isPremium: boolean
    cardTemplate?: {
      __typename: 'Card'
      id: string
      backPanelLocation: number
      panels: Array<{
        __typename: 'Panel'
        location: number
        isFullBleed: boolean
        isLocked: boolean
        name: string
        width: number
        height: number
        previewUrl: any
        backgroundColor?: {
          __typename: 'Color'
          red: number
          green: number
          blue: number
        } | null
        elements: Array<{
          __typename: 'Element'
          x: number
          y: number
          z: number
          rotation: number
          width: number
          height: number
          locked: boolean
          draggable?: boolean | null
          sticker?: {
            __typename: 'ElementSticker'
            scale: number
            sticker?: {
              __typename: 'EditorImageType'
              id: string
              url: string
              width: number
              height: number
              thumbnailUrl: string
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
          } | null
          image?: {
            __typename: 'ElementPicture'
            scale: number
            image?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
            filter?: { __typename?: 'Filter'; name: string } | null
          } | null
          text?: {
            __typename?: 'ElementText'
            paragraphs: Array<{
              __typename: 'Paragraph'
              alignment: TextAlignment
              textFragments: Array<{
                __typename: 'TextFragment'
                text: string
                fontSize: number
                isReplacement: boolean
                textColor: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                }
                font: { __typename: 'Font'; id: string; fontName: string }
              }>
            }>
          } | null
        }>
      }>
    } | null
    frontImage: {
      __typename: 'Image'
      id: string
      url: any
      width: number
      height: number
      smallThumb: any
      mediumThumb: any
    }
    insideRightImage?: {
      __typename: 'Image'
      id: string
      url: any
      width: number
      height: number
      smallThumb: any
      mediumThumb: any
    } | null
    cost: {
      __typename?: 'ItemizedCost'
      total: Array<{
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }>
      entries: Array<{
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }>
    }
  }>
  cardTemplate?: {
    __typename: 'Card'
    id: string
    backPanelLocation: number
    panels: Array<{
      __typename: 'Panel'
      location: number
      isFullBleed: boolean
      isLocked: boolean
      name: string
      width: number
      height: number
      previewUrl: any
      backgroundColor?: {
        __typename: 'Color'
        red: number
        green: number
        blue: number
      } | null
      elements: Array<{
        __typename: 'Element'
        x: number
        y: number
        z: number
        rotation: number
        width: number
        height: number
        locked: boolean
        draggable?: boolean | null
        sticker?: {
          __typename: 'ElementSticker'
          scale: number
          sticker?: {
            __typename: 'EditorImageType'
            id: string
            url: string
            width: number
            height: number
            thumbnailUrl: string
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
        } | null
        image?: {
          __typename: 'ElementPicture'
          scale: number
          image?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
          filter?: { __typename?: 'Filter'; name: string } | null
        } | null
        text?: {
          __typename?: 'ElementText'
          paragraphs: Array<{
            __typename: 'Paragraph'
            alignment: TextAlignment
            textFragments: Array<{
              __typename: 'TextFragment'
              text: string
              fontSize: number
              isReplacement: boolean
              textColor: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              }
              font: { __typename: 'Font'; id: string; fontName: string }
            }>
          }>
        } | null
      }>
    }>
  } | null
  cost: {
    __typename?: 'ItemizedCost'
    total: Array<{
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }>
    entries: Array<{
      __typename: 'LabeledPrice'
      label: string
      asString: string
      currency: CurrencyType
      amount: number
    }>
  }
  frontImage: {
    __typename: 'Image'
    id: string
    url: any
    width: number
    height: number
    smallThumb: any
    mediumThumb: any
  }
}

export type DraftedCardFragment = {
  __typename: 'Card'
  id: string
  frontPreviewUrl: any
  insidePreviewUrl?: any | null
  isHorizontal: boolean
  updatedAt: any
}

export type EditorImageFragment = {
  __typename: 'EditorImageType'
  id: string
  url: string
  width: number
  height: number
  thumbnailUrl: string
}

export type ElementFragment = {
  __typename: 'Element'
  x: number
  y: number
  z: number
  rotation: number
  width: number
  height: number
  locked: boolean
  draggable?: boolean | null
  sticker?: {
    __typename: 'ElementSticker'
    scale: number
    sticker?: {
      __typename: 'EditorImageType'
      id: string
      url: string
      width: number
      height: number
      thumbnailUrl: string
    } | null
    position: { __typename?: 'Position'; x: number; y: number }
  } | null
  image?: {
    __typename: 'ElementPicture'
    scale: number
    image?: {
      __typename: 'Image'
      id: string
      url: any
      width: number
      height: number
      smallThumb: any
    } | null
    position: { __typename?: 'Position'; x: number; y: number }
    filter?: { __typename?: 'Filter'; name: string } | null
  } | null
  text?: {
    __typename?: 'ElementText'
    paragraphs: Array<{
      __typename: 'Paragraph'
      alignment: TextAlignment
      textFragments: Array<{
        __typename: 'TextFragment'
        text: string
        fontSize: number
        isReplacement: boolean
        textColor: {
          __typename: 'Color'
          red: number
          green: number
          blue: number
        }
        font: { __typename: 'Font'; id: string; fontName: string }
      }>
    }>
  } | null
}

export type ElementPictureFragment = {
  __typename: 'ElementPicture'
  scale: number
  image?: {
    __typename: 'Image'
    id: string
    url: any
    width: number
    height: number
    smallThumb: any
  } | null
  position: { __typename?: 'Position'; x: number; y: number }
  filter?: { __typename?: 'Filter'; name: string } | null
}

export type ElementStickerFragment = {
  __typename: 'ElementSticker'
  scale: number
  sticker?: {
    __typename: 'EditorImageType'
    id: string
    url: string
    width: number
    height: number
    thumbnailUrl: string
  } | null
  position: { __typename?: 'Position'; x: number; y: number }
}

export type ElementTextFragment = {
  __typename?: 'ElementText'
  paragraphs: Array<{
    __typename: 'Paragraph'
    alignment: TextAlignment
    textFragments: Array<{
      __typename: 'TextFragment'
      text: string
      fontSize: number
      isReplacement: boolean
      textColor: {
        __typename: 'Color'
        red: number
        green: number
        blue: number
      }
      font: { __typename: 'Font'; id: string; fontName: string }
    }>
  }>
}

export type ErrorFragment = {
  __typename: 'Error'
  code?: number | null
  message?: string | null
}

export type ExpenseReloadFragment = {
  __typename: 'ExpenseReload'
  purchaseAmount: number
  threshold: number
  isEnabled: boolean
  isRequired: boolean
}

export type FlagFragment = { __typename: 'Flag'; id: string; name: string }

export type FontFragment = {
  __typename: 'Font'
  id: string
  displayName: string
  fontName: string
  ttfFile: string
  isSig: boolean
  isCustomFont: boolean
  isDisabled: boolean
}

export type GiftFragment = {
  __typename: 'Gift'
  name: string
  defaultImageUrl: any
  id: string
  realId: string
  defaultVariation?: {
    __typename: 'GiftVariation'
    id: string
    isSendable: boolean
    isEligibleFreeGift: boolean
    name: string
    description?: string | null
    contents?: string | null
    shipping?: string | null
    imageUrls: Array<any>
    packaging?: string | null
    defaultImageUrl: any
    points: {
      __typename: 'Amount'
      amount: number
      currency: Currency
      description: string
    }
    price: {
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }
  } | null
}

export type GiftVariationFragment = {
  __typename: 'GiftVariation'
  id: string
  isSendable: boolean
  isEligibleFreeGift: boolean
  name: string
  description?: string | null
  contents?: string | null
  shipping?: string | null
  imageUrls: Array<any>
  packaging?: string | null
  defaultImageUrl: any
  points: {
    __typename: 'Amount'
    amount: number
    currency: Currency
    description: string
  }
  price: {
    __typename: 'Price'
    asString: string
    currency: CurrencyType
    amount: number
  }
}

export type ImageFragment = {
  __typename: 'Image'
  id: string
  url: any
  width: number
  height: number
  smallThumb: any
}

export type InviteFragment = {
  __typename: 'Invite'
  id: string
  createdAt: any
  utmStream?: string | null
  hasProspectJoined: boolean
  hasSentCard: boolean
  prospect?: {
    __typename: 'Prospect'
    id: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    ordersSent: number
    address?: {
      __typename: 'Address'
      firstName: string
      lastName: string
      company: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country: string
    } | null
  } | null
  socProContact?: {
    __typename: 'SocProContact'
    id: string
    contactId: string
    email: string
    firstName: string
    lastName: string
    fullName: string
    smsPhone: string
    homePhone: string
    workPhone: string
    address: string
    address2: string
    zip: string
    city: string
    state: string
    country: string
    metadataBlob: any
    socContact?: {
      __typename: 'Contact'
      homePhone?: string | null
      cellPhone?: string | null
      workPhone?: string | null
      faxNumber?: string | null
      website: string
      emailAddress?: string | null
      id: string
      firstName: string
      lastName: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country?: string | null
      isPrimary: boolean
      company: string
      parent?: {
        __typename?: 'Contact'
        id: string
        firstName: string
        lastName: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country?: string | null
        isPrimary: boolean
        homePhone?: string | null
        cellPhone?: string | null
        workPhone?: string | null
        faxNumber?: string | null
        website: string
        emailAddress?: string | null
        company: string
        birthday?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
        anniversary?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
      } | null
      spouse?: {
        __typename?: 'Contact'
        id: string
        firstName: string
        lastName: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country?: string | null
        isPrimary: boolean
        homePhone?: string | null
        cellPhone?: string | null
        workPhone?: string | null
        faxNumber?: string | null
        website: string
        emailAddress?: string | null
        company: string
        birthday?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
        anniversary?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
      } | null
      children: Array<{
        __typename?: 'Contact'
        id: string
        firstName: string
        lastName: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country?: string | null
        isPrimary: boolean
        homePhone?: string | null
        cellPhone?: string | null
        workPhone?: string | null
        faxNumber?: string | null
        website: string
        emailAddress?: string | null
        company: string
        birthday?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
        anniversary?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
      }>
      groups: Array<{ __typename: 'Group'; id: string; name: string }>
      notes: Array<{
        __typename: 'Note'
        id: string
        text: string
        date?: {
          __typename: 'CalendarDate'
          day: number
          month: number
          year: number
        } | null
      }>
      birthday?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
      anniversary?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
    } | null
  } | null
  contact?: {
    __typename: 'Contact'
    homePhone?: string | null
    cellPhone?: string | null
    workPhone?: string | null
    faxNumber?: string | null
    website: string
    emailAddress?: string | null
    id: string
    firstName: string
    lastName: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    country?: string | null
    isPrimary: boolean
    company: string
    parent?: {
      __typename?: 'Contact'
      id: string
      firstName: string
      lastName: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country?: string | null
      isPrimary: boolean
      homePhone?: string | null
      cellPhone?: string | null
      workPhone?: string | null
      faxNumber?: string | null
      website: string
      emailAddress?: string | null
      company: string
      birthday?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
      anniversary?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
    } | null
    spouse?: {
      __typename?: 'Contact'
      id: string
      firstName: string
      lastName: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country?: string | null
      isPrimary: boolean
      homePhone?: string | null
      cellPhone?: string | null
      workPhone?: string | null
      faxNumber?: string | null
      website: string
      emailAddress?: string | null
      company: string
      birthday?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
      anniversary?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
    } | null
    children: Array<{
      __typename?: 'Contact'
      id: string
      firstName: string
      lastName: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country?: string | null
      isPrimary: boolean
      homePhone?: string | null
      cellPhone?: string | null
      workPhone?: string | null
      faxNumber?: string | null
      website: string
      emailAddress?: string | null
      company: string
      birthday?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
      anniversary?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
    }>
    groups: Array<{ __typename: 'Group'; id: string; name: string }>
    notes: Array<{
      __typename: 'Note'
      id: string
      text: string
      date?: {
        __typename: 'CalendarDate'
        day: number
        month: number
        year: number
      } | null
    }>
    birthday?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
    anniversary?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
  } | null
}

export type ItemFragment = {
  __typename: 'Item'
  id: string
  description?: string | null
  itemCode: string
  price: number
  name?: string | null
  image?: string | null
  campaign?: {
    __typename: 'Campaign'
    id: string
    name: string
    isShareable: boolean
    lines: Array<{
      __typename: 'CampaignLine'
      name?: string | null
      card?: {
        __typename: 'Card'
        id: string
        type: CardType
        isHorizontal: boolean
        isNewEditorCard: boolean
        paperType: CardPaperType
        frontPreviewUrl: any
        insidePreviewUrl?: any | null
        flapPreviewUrl?: any | null
        backPreviewUrl: any
        tinyThumb: any
        sendableCard?: {
          __typename: 'SendableCard'
          id: string
          title: string
        } | null
        minimalPanels: Array<{
          __typename: 'Panel'
          name: string
          previewUrl: any
          isFullBleed: boolean
          width: number
          height: number
        }>
      } | null
      giftVariation?: {
        __typename: 'GiftVariation'
        id: string
        isSendable: boolean
        isEligibleFreeGift: boolean
        name: string
        description?: string | null
        contents?: string | null
        shipping?: string | null
        imageUrls: Array<any>
        packaging?: string | null
        defaultImageUrl: any
        points: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        price: {
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }
      } | null
      sendDelay: {
        __typename: 'SendDelay'
        type: SendDelayType
        delayNumber?: number | null
        delayType: SendDelayDelayType
        timeType: SendDelayTimeType
        specificDate: any
      }
    }>
  } | null
}

export type ItemCategoryFragment = {
  __typename: 'ItemCategory'
  id: string
  name: string
  backgroundImageUrl?: string | null
  items: Array<{
    __typename: 'Item'
    id: string
    description?: string | null
    itemCode: string
    price: number
    name?: string | null
    image?: string | null
    campaign?: {
      __typename: 'Campaign'
      id: string
      name: string
      isShareable: boolean
      lines: Array<{
        __typename: 'CampaignLine'
        name?: string | null
        card?: {
          __typename: 'Card'
          id: string
          type: CardType
          isHorizontal: boolean
          isNewEditorCard: boolean
          paperType: CardPaperType
          frontPreviewUrl: any
          insidePreviewUrl?: any | null
          flapPreviewUrl?: any | null
          backPreviewUrl: any
          tinyThumb: any
          sendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
          } | null
          minimalPanels: Array<{
            __typename: 'Panel'
            name: string
            previewUrl: any
            isFullBleed: boolean
            width: number
            height: number
          }>
        } | null
        giftVariation?: {
          __typename: 'GiftVariation'
          id: string
          isSendable: boolean
          isEligibleFreeGift: boolean
          name: string
          description?: string | null
          contents?: string | null
          shipping?: string | null
          imageUrls: Array<any>
          packaging?: string | null
          defaultImageUrl: any
          points: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          price: {
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }
        } | null
        sendDelay: {
          __typename: 'SendDelay'
          type: SendDelayType
          delayNumber?: number | null
          delayType: SendDelayDelayType
          timeType: SendDelayTimeType
          specificDate: any
        }
      }>
    } | null
  }>
}

export type ItemizedCostFragment = {
  __typename?: 'ItemizedCost'
  total: Array<{
    __typename: 'Price'
    asString: string
    currency: CurrencyType
    amount: number
  }>
  entries: Array<{
    __typename: 'LabeledPrice'
    label: string
    asString: string
    currency: CurrencyType
    amount: number
  }>
}

export type LabeledPriceFragment = {
  __typename: 'LabeledPrice'
  label: string
  asString: string
  currency: CurrencyType
  amount: number
}

export type LargeMinimalSendableCardFragment = {
  __typename: 'SendableCard'
  id: string
  title: string
  variationColor: string
  isFavorite: boolean
  isHorizontal: boolean
  isPremium: boolean
  frontImage: {
    __typename: 'Image'
    id: string
    url: any
    width: number
    height: number
    smallThumb: any
    mediumThumb: any
  }
}

export type LayoutFragment = {
  __typename: 'Layout'
  id: string
  category?: LayoutCategory | null
  owner: Owner
  dimensions?: LayoutDimensions | null
  thumbnail: { __typename?: 'Image'; url: any }
  panel: {
    __typename: 'Panel'
    location: number
    isFullBleed: boolean
    isLocked: boolean
    name: string
    width: number
    height: number
    previewUrl: any
    backgroundColor?: {
      __typename: 'Color'
      red: number
      green: number
      blue: number
    } | null
    elements: Array<{
      __typename: 'Element'
      x: number
      y: number
      z: number
      rotation: number
      width: number
      height: number
      locked: boolean
      draggable?: boolean | null
      sticker?: {
        __typename: 'ElementSticker'
        scale: number
        sticker?: {
          __typename: 'EditorImageType'
          id: string
          url: string
          width: number
          height: number
          thumbnailUrl: string
        } | null
        position: { __typename?: 'Position'; x: number; y: number }
      } | null
      image?: {
        __typename: 'ElementPicture'
        scale: number
        image?: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
        } | null
        position: { __typename?: 'Position'; x: number; y: number }
        filter?: { __typename?: 'Filter'; name: string } | null
      } | null
      text?: {
        __typename?: 'ElementText'
        paragraphs: Array<{
          __typename: 'Paragraph'
          alignment: TextAlignment
          textFragments: Array<{
            __typename: 'TextFragment'
            text: string
            fontSize: number
            isReplacement: boolean
            textColor: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            }
            font: { __typename: 'Font'; id: string; fontName: string }
          }>
        }>
      } | null
    }>
  }
}

export type LeaderFragment = {
  __typename: 'Leader'
  profileImageUrl?: any | null
  firstName: string
  lastName: string
  earnedCount: number
  dateJoined: string
}

export type LeaderBoardFragment = {
  __typename?: 'LeaderBoardType'
  title: string
  description: string
  rankTitle: string
  metricTitle: string
  listTitle: string
  shouldShowPastChallenge: boolean
  gradient?: string | null
  userAccess: LeaderboardLeaderBoardUserAccessChoices
  type: LeaderboardLeaderBoardTypeChoices
  challenges: Array<{
    __typename?: 'ChallengeType'
    id: string
    status: LeaderboardChallengeStatusChoices
    startDate: any
    endDate: any
    authUserEarnedCount: number
    leaders: Array<{
      __typename: 'Leader'
      profileImageUrl?: any | null
      firstName: string
      lastName: string
      earnedCount: number
      dateJoined: string
    }>
  }>
}

export type LineFragment = {
  __typename: 'Line'
  recipientCount: number
  card?: {
    __typename: 'Card'
    id: string
    type: CardType
    paperType: CardPaperType
    isHorizontal: boolean
    isNewEditorCard: boolean
    backPanelLocation: number
    frontPreviewUrl: any
    insidePreviewUrl?: any | null
    flapPreviewUrl?: any | null
    backPreviewUrl: any
    showInPicturePlus: boolean
    tinyThumb: any
    detailedSendableCard?: {
      __typename: 'SendableCard'
      id: string
      title: string
      description: string
      variationColor: string
      isFavorite: boolean
      isHorizontal: boolean
      isPremium: boolean
      variations: Array<{
        __typename: 'SendableCard'
        id: string
        title: string
        description: string
        variationColor: string
        isFavorite: boolean
        isHorizontal: boolean
        isPremium: boolean
        cardTemplate?: {
          __typename: 'Card'
          id: string
          backPanelLocation: number
          panels: Array<{
            __typename: 'Panel'
            location: number
            isFullBleed: boolean
            isLocked: boolean
            name: string
            width: number
            height: number
            previewUrl: any
            backgroundColor?: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            } | null
            elements: Array<{
              __typename: 'Element'
              x: number
              y: number
              z: number
              rotation: number
              width: number
              height: number
              locked: boolean
              draggable?: boolean | null
              sticker?: {
                __typename: 'ElementSticker'
                scale: number
                sticker?: {
                  __typename: 'EditorImageType'
                  id: string
                  url: string
                  width: number
                  height: number
                  thumbnailUrl: string
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
              } | null
              image?: {
                __typename: 'ElementPicture'
                scale: number
                image?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
                filter?: { __typename?: 'Filter'; name: string } | null
              } | null
              text?: {
                __typename?: 'ElementText'
                paragraphs: Array<{
                  __typename: 'Paragraph'
                  alignment: TextAlignment
                  textFragments: Array<{
                    __typename: 'TextFragment'
                    text: string
                    fontSize: number
                    isReplacement: boolean
                    textColor: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    }
                    font: { __typename: 'Font'; id: string; fontName: string }
                  }>
                }>
              } | null
            }>
          }>
        } | null
        frontImage: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        }
        insideRightImage?: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        } | null
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
      }>
      cardTemplate?: {
        __typename: 'Card'
        id: string
        backPanelLocation: number
        panels: Array<{
          __typename: 'Panel'
          location: number
          isFullBleed: boolean
          isLocked: boolean
          name: string
          width: number
          height: number
          previewUrl: any
          backgroundColor?: {
            __typename: 'Color'
            red: number
            green: number
            blue: number
          } | null
          elements: Array<{
            __typename: 'Element'
            x: number
            y: number
            z: number
            rotation: number
            width: number
            height: number
            locked: boolean
            draggable?: boolean | null
            sticker?: {
              __typename: 'ElementSticker'
              scale: number
              sticker?: {
                __typename: 'EditorImageType'
                id: string
                url: string
                width: number
                height: number
                thumbnailUrl: string
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
            } | null
            image?: {
              __typename: 'ElementPicture'
              scale: number
              image?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
              filter?: { __typename?: 'Filter'; name: string } | null
            } | null
            text?: {
              __typename?: 'ElementText'
              paragraphs: Array<{
                __typename: 'Paragraph'
                alignment: TextAlignment
                textFragments: Array<{
                  __typename: 'TextFragment'
                  text: string
                  fontSize: number
                  isReplacement: boolean
                  textColor: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  }
                  font: { __typename: 'Font'; id: string; fontName: string }
                }>
              }>
            } | null
          }>
        }>
      } | null
      frontImage: {
        __typename: 'Image'
        id: string
        url: any
        width: number
        height: number
        smallThumb: any
        mediumThumb: any
      }
      insideRightImage?: {
        __typename: 'Image'
        id: string
        url: any
        width: number
        height: number
        smallThumb: any
        mediumThumb: any
      } | null
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
    } | null
    panels: Array<{
      __typename: 'Panel'
      location: number
      isFullBleed: boolean
      isLocked: boolean
      name: string
      width: number
      height: number
      previewUrl: any
      backgroundColor?: {
        __typename: 'Color'
        red: number
        green: number
        blue: number
      } | null
      elements: Array<{
        __typename: 'Element'
        x: number
        y: number
        z: number
        rotation: number
        width: number
        height: number
        locked: boolean
        draggable?: boolean | null
        sticker?: {
          __typename: 'ElementSticker'
          scale: number
          sticker?: {
            __typename: 'EditorImageType'
            id: string
            url: string
            width: number
            height: number
            thumbnailUrl: string
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
        } | null
        image?: {
          __typename: 'ElementPicture'
          scale: number
          image?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
          filter?: { __typename?: 'Filter'; name: string } | null
        } | null
        text?: {
          __typename?: 'ElementText'
          paragraphs: Array<{
            __typename: 'Paragraph'
            alignment: TextAlignment
            textFragments: Array<{
              __typename: 'TextFragment'
              text: string
              fontSize: number
              isReplacement: boolean
              textColor: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              }
              font: { __typename: 'Font'; id: string; fontName: string }
            }>
          }>
        } | null
      }>
    }>
    cost: {
      __typename?: 'ItemizedCost'
      total: Array<{
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }>
      entries: Array<{
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }>
    }
    paperUpgradeCosts: Array<{
      __typename?: 'PaperCosts'
      value: string
      upgrade: string
    }>
    digitalCard?: {
      __typename?: 'DigitalCard'
      pk: string
      shareMessageTitle: string
      shareMessageBody: string
      tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
    } | null
  } | null
  giftVariation?: {
    __typename: 'GiftVariation'
    id: string
    isSendable: boolean
    isEligibleFreeGift: boolean
    name: string
    description?: string | null
    contents?: string | null
    shipping?: string | null
    imageUrls: Array<any>
    packaging?: string | null
    defaultImageUrl: any
    points: {
      __typename: 'Amount'
      amount: number
      currency: Currency
      description: string
    }
    price: {
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }
  } | null
  sendDelay: {
    __typename: 'SendDelay'
    type: SendDelayType
    delayNumber?: number | null
    delayType: SendDelayDelayType
    timeType: SendDelayTimeType
    specificDate: any
  }
  cost: {
    __typename?: 'ItemizedCost'
    total: Array<{
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }>
    entries: Array<{
      __typename: 'LabeledPrice'
      label: string
      asString: string
      currency: CurrencyType
      amount: number
    }>
  }
  baseCost: {
    __typename?: 'ItemizedCost'
    total: Array<{
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }>
    entries: Array<{
      __typename: 'LabeledPrice'
      label: string
      asString: string
      currency: CurrencyType
      amount: number
    }>
  }
}

export type MarketingMultiParagraphFragment = {
  __typename: 'MarketingMultiParagraph'
  createdAt: any
  updatedAt: any
  id: string
  title: string
  content: string
}

export type MarketingParagraphFragment = {
  __typename: 'MarketingParagraph'
  createdAt: any
  updatedAt: any
  id: string
  title: string
  content: string
}

export type MembershipInviteFragment = {
  __typename: 'MembershipInvite'
  organizationName: string
  organizationOwnerName?: string | null
  createdAt: any
}

export type MinimalPanelFragment = {
  __typename: 'Panel'
  name: string
  previewUrl: any
  isFullBleed: boolean
  width: number
  height: number
}

export type MinimalReminderFragment = {
  __typename: 'Reminder'
  id: string
  type: ReminderType
  newType: NewReminderType
  message?: string | null
  title: string
  eventDate?: any | null
  campaign?: { __typename?: 'Campaign'; id: string; name: string } | null
  contact: {
    __typename?: 'Contact'
    id: string
    firstName: string
    lastName: string
    country?: string | null
    birthday?: {
      __typename?: 'OptionalYearDate'
      day: number
      month: number
    } | null
    anniversary?: {
      __typename?: 'OptionalYearDate'
      day: number
      month: number
    } | null
  }
  order?: {
    __typename: 'Order'
    id: string
    isSendable: boolean
    createdAt: any
    isSent: boolean
    sendType: CardSendType
    isBulk: boolean
    bulkUpgradeCardQty?: number | null
    lines: Array<{
      __typename: 'Line'
      recipientCount: number
      card?: {
        __typename: 'Card'
        id: string
        type: CardType
        paperType: CardPaperType
        isHorizontal: boolean
        isNewEditorCard: boolean
        backPanelLocation: number
        frontPreviewUrl: any
        insidePreviewUrl?: any | null
        flapPreviewUrl?: any | null
        backPreviewUrl: any
        showInPicturePlus: boolean
        tinyThumb: any
        detailedSendableCard?: {
          __typename: 'SendableCard'
          id: string
          title: string
          description: string
          variationColor: string
          isFavorite: boolean
          isHorizontal: boolean
          isPremium: boolean
          variations: Array<{
            __typename: 'SendableCard'
            id: string
            title: string
            description: string
            variationColor: string
            isFavorite: boolean
            isHorizontal: boolean
            isPremium: boolean
            cardTemplate?: {
              __typename: 'Card'
              id: string
              backPanelLocation: number
              panels: Array<{
                __typename: 'Panel'
                location: number
                isFullBleed: boolean
                isLocked: boolean
                name: string
                width: number
                height: number
                previewUrl: any
                backgroundColor?: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                } | null
                elements: Array<{
                  __typename: 'Element'
                  x: number
                  y: number
                  z: number
                  rotation: number
                  width: number
                  height: number
                  locked: boolean
                  draggable?: boolean | null
                  sticker?: {
                    __typename: 'ElementSticker'
                    scale: number
                    sticker?: {
                      __typename: 'EditorImageType'
                      id: string
                      url: string
                      width: number
                      height: number
                      thumbnailUrl: string
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                  } | null
                  image?: {
                    __typename: 'ElementPicture'
                    scale: number
                    image?: {
                      __typename: 'Image'
                      id: string
                      url: any
                      width: number
                      height: number
                      smallThumb: any
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                    filter?: { __typename?: 'Filter'; name: string } | null
                  } | null
                  text?: {
                    __typename?: 'ElementText'
                    paragraphs: Array<{
                      __typename: 'Paragraph'
                      alignment: TextAlignment
                      textFragments: Array<{
                        __typename: 'TextFragment'
                        text: string
                        fontSize: number
                        isReplacement: boolean
                        textColor: {
                          __typename: 'Color'
                          red: number
                          green: number
                          blue: number
                        }
                        font: {
                          __typename: 'Font'
                          id: string
                          fontName: string
                        }
                      }>
                    }>
                  } | null
                }>
              }>
            } | null
            frontImage: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            }
            insideRightImage?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            } | null
            cost: {
              __typename?: 'ItemizedCost'
              total: Array<{
                __typename: 'Price'
                asString: string
                currency: CurrencyType
                amount: number
              }>
              entries: Array<{
                __typename: 'LabeledPrice'
                label: string
                asString: string
                currency: CurrencyType
                amount: number
              }>
            }
          }>
          cardTemplate?: {
            __typename: 'Card'
            id: string
            backPanelLocation: number
            panels: Array<{
              __typename: 'Panel'
              location: number
              isFullBleed: boolean
              isLocked: boolean
              name: string
              width: number
              height: number
              previewUrl: any
              backgroundColor?: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              } | null
              elements: Array<{
                __typename: 'Element'
                x: number
                y: number
                z: number
                rotation: number
                width: number
                height: number
                locked: boolean
                draggable?: boolean | null
                sticker?: {
                  __typename: 'ElementSticker'
                  scale: number
                  sticker?: {
                    __typename: 'EditorImageType'
                    id: string
                    url: string
                    width: number
                    height: number
                    thumbnailUrl: string
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                } | null
                image?: {
                  __typename: 'ElementPicture'
                  scale: number
                  image?: {
                    __typename: 'Image'
                    id: string
                    url: any
                    width: number
                    height: number
                    smallThumb: any
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                  filter?: { __typename?: 'Filter'; name: string } | null
                } | null
                text?: {
                  __typename?: 'ElementText'
                  paragraphs: Array<{
                    __typename: 'Paragraph'
                    alignment: TextAlignment
                    textFragments: Array<{
                      __typename: 'TextFragment'
                      text: string
                      fontSize: number
                      isReplacement: boolean
                      textColor: {
                        __typename: 'Color'
                        red: number
                        green: number
                        blue: number
                      }
                      font: { __typename: 'Font'; id: string; fontName: string }
                    }>
                  }>
                } | null
              }>
            }>
          } | null
          frontImage: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          }
          insideRightImage?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          } | null
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
        } | null
        panels: Array<{
          __typename: 'Panel'
          location: number
          isFullBleed: boolean
          isLocked: boolean
          name: string
          width: number
          height: number
          previewUrl: any
          backgroundColor?: {
            __typename: 'Color'
            red: number
            green: number
            blue: number
          } | null
          elements: Array<{
            __typename: 'Element'
            x: number
            y: number
            z: number
            rotation: number
            width: number
            height: number
            locked: boolean
            draggable?: boolean | null
            sticker?: {
              __typename: 'ElementSticker'
              scale: number
              sticker?: {
                __typename: 'EditorImageType'
                id: string
                url: string
                width: number
                height: number
                thumbnailUrl: string
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
            } | null
            image?: {
              __typename: 'ElementPicture'
              scale: number
              image?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
              filter?: { __typename?: 'Filter'; name: string } | null
            } | null
            text?: {
              __typename?: 'ElementText'
              paragraphs: Array<{
                __typename: 'Paragraph'
                alignment: TextAlignment
                textFragments: Array<{
                  __typename: 'TextFragment'
                  text: string
                  fontSize: number
                  isReplacement: boolean
                  textColor: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  }
                  font: { __typename: 'Font'; id: string; fontName: string }
                }>
              }>
            } | null
          }>
        }>
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
        paperUpgradeCosts: Array<{
          __typename?: 'PaperCosts'
          value: string
          upgrade: string
        }>
        digitalCard?: {
          __typename?: 'DigitalCard'
          pk: string
          shareMessageTitle: string
          shareMessageBody: string
          tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
        } | null
      } | null
      giftVariation?: {
        __typename: 'GiftVariation'
        id: string
        isSendable: boolean
        isEligibleFreeGift: boolean
        name: string
        description?: string | null
        contents?: string | null
        shipping?: string | null
        imageUrls: Array<any>
        packaging?: string | null
        defaultImageUrl: any
        points: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        price: {
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }
      } | null
      sendDelay: {
        __typename: 'SendDelay'
        type: SendDelayType
        delayNumber?: number | null
        delayType: SendDelayDelayType
        timeType: SendDelayTimeType
        specificDate: any
      }
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
      baseCost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
    }>
    contacts: Array<{ __typename: 'Contact'; id: string }>
    groups: Array<{
      __typename: 'Group'
      id: string
      name: string
      members: Array<{ __typename: 'Contact'; id: string }>
    }>
    returnAddress?: {
      __typename: 'Address'
      firstName: string
      lastName: string
      company: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country: string
    } | null
    campaign?: { __typename: 'Campaign'; id: string; name: string } | null
    cost: {
      __typename?: 'ItemizedCost'
      total: Array<{
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }>
      entries: Array<{
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }>
    }
    warnings: Array<{
      __typename: 'Error'
      code?: number | null
      message?: string | null
    }>
    invalidContacts: Array<{ __typename: 'Contact'; id: string }>
    invalidSendDelayContacts: Array<{ __typename: 'Contact'; id: string }>
    bulkUpgradeShippingAddress?: {
      __typename: 'Address'
      firstName: string
      lastName: string
      company: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country: string
    } | null
  } | null
}

export type MinimalSponsorFragment = {
  __typename: 'User'
  id: string
  firstName: string
  lastName: string
  email: string
  genealogyId: number
  storefront?: {
    __typename: 'Storefront'
    biography?: string | null
    slug?: string | null
    mugshot?: { __typename?: 'Image'; url: any } | null
  } | null
}

export type NoteFragment = {
  __typename: 'Note'
  id: string
  text: string
  date?: {
    __typename: 'CalendarDate'
    day: number
    month: number
    year: number
  } | null
}

export type NotificationFragment = {
  __typename: 'Notification'
  id: string
  message?: string | null
  createdAt: any
  acceptText?: string | null
  denyText?: string | null
  link?: string | null
  linkText?: string | null
  data?: any | null
  type?: NotificationType | null
}

export type OptionalYearDateFragment = {
  __typename: 'OptionalYearDate'
  day: number
  month: number
  year?: number | null
}

export type PanelFragment = {
  __typename: 'Panel'
  location: number
  isFullBleed: boolean
  isLocked: boolean
  name: string
  width: number
  height: number
  previewUrl: any
  backgroundColor?: {
    __typename: 'Color'
    red: number
    green: number
    blue: number
  } | null
  elements: Array<{
    __typename: 'Element'
    x: number
    y: number
    z: number
    rotation: number
    width: number
    height: number
    locked: boolean
    draggable?: boolean | null
    sticker?: {
      __typename: 'ElementSticker'
      scale: number
      sticker?: {
        __typename: 'EditorImageType'
        id: string
        url: string
        width: number
        height: number
        thumbnailUrl: string
      } | null
      position: { __typename?: 'Position'; x: number; y: number }
    } | null
    image?: {
      __typename: 'ElementPicture'
      scale: number
      image?: {
        __typename: 'Image'
        id: string
        url: any
        width: number
        height: number
        smallThumb: any
      } | null
      position: { __typename?: 'Position'; x: number; y: number }
      filter?: { __typename?: 'Filter'; name: string } | null
    } | null
    text?: {
      __typename?: 'ElementText'
      paragraphs: Array<{
        __typename: 'Paragraph'
        alignment: TextAlignment
        textFragments: Array<{
          __typename: 'TextFragment'
          text: string
          fontSize: number
          isReplacement: boolean
          textColor: {
            __typename: 'Color'
            red: number
            green: number
            blue: number
          }
          font: { __typename: 'Font'; id: string; fontName: string }
        }>
      }>
    } | null
  }>
}

export type PaperCostsFragment = {
  __typename?: 'PaperCosts'
  value: string
  upgrade: string
}

export type ParagraphFragment = {
  __typename: 'Paragraph'
  alignment: TextAlignment
  textFragments: Array<{
    __typename: 'TextFragment'
    text: string
    fontSize: number
    isReplacement: boolean
    textColor: { __typename: 'Color'; red: number; green: number; blue: number }
    font: { __typename: 'Font'; id: string; fontName: string }
  }>
}

export type PlanFragment = {
  __typename: 'Plan'
  id: string
  title: string
  subtitle?: string | null
  stripeId: string
  description?: any | null
  isAddon: boolean
  cardCost: number
  bulkCardCost: number
  giftDiscount: number
  isExpenseReloadRequired: boolean
  color?: string | null
  backgroundStartColor: string
  backgroundEndColor: string
  icon: PlansPlanIconChoices
  price: {
    __typename: 'Amount'
    amount: number
    currency: Currency
    description: string
  }
  relatedPackage?: {
    __typename?: 'RelatedPlanPackage'
    saleorProductId: string
    title: string
    description: string
    shouldShowByDefault: boolean
  } | null
}

export type PriceFragment = {
  __typename: 'Price'
  asString: string
  currency: CurrencyType
  amount: number
}

export type ProductionInfoFragment = {
  __typename: 'ProductionInfo'
  id: string
  dateToSend?: any | null
  status: CardStatus
  hold: boolean
  fulfilled?: any | null
  rejectionReason?: string | null
  frontPreviewUrl: any
  rejectedCards: Array<{ __typename: 'RejectedCard'; id: string }>
  trackingInfo?: {
    __typename?: 'TrackingInfo'
    trackingNumber: string
    url?: any | null
  } | null
  recipient?: {
    __typename: 'Recipient'
    id: string
    cardQuantity: number
    paymentFailure?: { __typename?: 'PaymentFailure'; id: string } | null
    contact?: {
      __typename: 'Contact'
      id: string
      firstName: string
      lastName: string
    } | null
    address?: {
      __typename: 'Address'
      userVerified: boolean
      errorMessages: Array<string>
      firstName: string
      lastName: string
      company: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country: string
    } | null
    gift?: {
      __typename?: 'GiftVariation'
      name: string
      defaultImageUrl: any
    } | null
    card?: {
      __typename: 'Card'
      frontPreviewUrl: any
      isHorizontal: boolean
      id: string
      sendableCard?: {
        __typename: 'SendableCard'
        id: string
        title: string
      } | null
    } | null
  } | null
}

export type PromptingsCoachFragment = {
  __typename?: 'PromptingsCoach'
  trainerId?: number | null
  dateTrainingPurchased?: any | null
  dateTrainingCompleted?: any | null
  dateTrainingExpires?: any | null
  dateCoachingPurchased?: any | null
  dateAttendedCertification?: any | null
  isEmailPublic: boolean
  isPhoneNumberPublic: boolean
  isCertified: boolean
  trainerEmail?: string | null
}

export type PromptingsCoachDiscountFragment = {
  __typename: 'PromptingsCoachDiscount'
  code: string
  eventbriteGroupId: string
  expirationDatetime: any
  isUsed: boolean
  amountOff: number
  percentOff: number
}

export type ProspectFragment = {
  __typename: 'Prospect'
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  ordersSent: number
  address?: {
    __typename: 'Address'
    firstName: string
    lastName: string
    company: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    country: string
  } | null
}

export type QualificationFragment = {
  __typename?: 'Qualification'
  id: string
  title: string
  description: string
  graphic: string
  threshold: number
  progress: number
  link?: { __typename?: 'MarketingURL'; title: string; url: string } | null
}

export type RecipientFragment = {
  __typename: 'Recipient'
  id: string
  cardQuantity: number
  paymentFailure?: { __typename?: 'PaymentFailure'; id: string } | null
  contact?: {
    __typename: 'Contact'
    id: string
    firstName: string
    lastName: string
  } | null
  address?: {
    __typename: 'Address'
    userVerified: boolean
    errorMessages: Array<string>
    firstName: string
    lastName: string
    company: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    country: string
  } | null
  gift?: {
    __typename?: 'GiftVariation'
    name: string
    defaultImageUrl: any
  } | null
  card?: {
    __typename: 'Card'
    frontPreviewUrl: any
    isHorizontal: boolean
    id: string
    sendableCard?: {
      __typename: 'SendableCard'
      id: string
      title: string
    } | null
  } | null
}

export type RelatedContactFragment = {
  __typename?: 'Contact'
  id: string
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  country?: string | null
  isPrimary: boolean
  homePhone?: string | null
  cellPhone?: string | null
  workPhone?: string | null
  faxNumber?: string | null
  website: string
  emailAddress?: string | null
  company: string
  birthday?: {
    __typename: 'OptionalYearDate'
    day: number
    month: number
    year?: number | null
  } | null
  anniversary?: {
    __typename: 'OptionalYearDate'
    day: number
    month: number
    year?: number | null
  } | null
}

export type SendDelayFragment = {
  __typename: 'SendDelay'
  type: SendDelayType
  delayNumber?: number | null
  delayType: SendDelayDelayType
  timeType: SendDelayTimeType
  specificDate: any
}

export type SendableCardFragment = {
  __typename: 'SendableCard'
  id: string
  title: string
  description: string
  variationColor: string
  isFavorite: boolean
  isHorizontal: boolean
  isPremium: boolean
  frontImage: {
    __typename: 'Image'
    id: string
    url: any
    width: number
    height: number
    smallThumb: any
    mediumThumb: any
  }
  insideRightImage?: {
    __typename: 'Image'
    id: string
    url: any
    width: number
    height: number
    smallThumb: any
    mediumThumb: any
  } | null
  cost: {
    __typename?: 'ItemizedCost'
    total: Array<{
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }>
    entries: Array<{
      __typename: 'LabeledPrice'
      label: string
      asString: string
      currency: CurrencyType
      amount: number
    }>
  }
}

export type SendableCardImageFragment = {
  __typename: 'Image'
  id: string
  url: any
  width: number
  height: number
  smallThumb: any
  mediumThumb: any
}

export type SendableCardWithTemplateFragment = {
  __typename: 'SendableCard'
  id: string
  title: string
  description: string
  variationColor: string
  isFavorite: boolean
  isHorizontal: boolean
  isPremium: boolean
  cardTemplate?: {
    __typename: 'Card'
    id: string
    backPanelLocation: number
    panels: Array<{
      __typename: 'Panel'
      location: number
      isFullBleed: boolean
      isLocked: boolean
      name: string
      width: number
      height: number
      previewUrl: any
      backgroundColor?: {
        __typename: 'Color'
        red: number
        green: number
        blue: number
      } | null
      elements: Array<{
        __typename: 'Element'
        x: number
        y: number
        z: number
        rotation: number
        width: number
        height: number
        locked: boolean
        draggable?: boolean | null
        sticker?: {
          __typename: 'ElementSticker'
          scale: number
          sticker?: {
            __typename: 'EditorImageType'
            id: string
            url: string
            width: number
            height: number
            thumbnailUrl: string
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
        } | null
        image?: {
          __typename: 'ElementPicture'
          scale: number
          image?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
          filter?: { __typename?: 'Filter'; name: string } | null
        } | null
        text?: {
          __typename?: 'ElementText'
          paragraphs: Array<{
            __typename: 'Paragraph'
            alignment: TextAlignment
            textFragments: Array<{
              __typename: 'TextFragment'
              text: string
              fontSize: number
              isReplacement: boolean
              textColor: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              }
              font: { __typename: 'Font'; id: string; fontName: string }
            }>
          }>
        } | null
      }>
    }>
  } | null
  frontImage: {
    __typename: 'Image'
    id: string
    url: any
    width: number
    height: number
    smallThumb: any
    mediumThumb: any
  }
  insideRightImage?: {
    __typename: 'Image'
    id: string
    url: any
    width: number
    height: number
    smallThumb: any
    mediumThumb: any
  } | null
  cost: {
    __typename?: 'ItemizedCost'
    total: Array<{
      __typename: 'Price'
      asString: string
      currency: CurrencyType
      amount: number
    }>
    entries: Array<{
      __typename: 'LabeledPrice'
      label: string
      asString: string
      currency: CurrencyType
      amount: number
    }>
  }
}

export type SocProContactFragment = {
  __typename: 'SocProContact'
  id: string
  contactId: string
  email: string
  firstName: string
  lastName: string
  fullName: string
  smsPhone: string
  homePhone: string
  workPhone: string
  address: string
  address2: string
  zip: string
  city: string
  state: string
  country: string
  metadataBlob: any
  socContact?: {
    __typename: 'Contact'
    homePhone?: string | null
    cellPhone?: string | null
    workPhone?: string | null
    faxNumber?: string | null
    website: string
    emailAddress?: string | null
    id: string
    firstName: string
    lastName: string
    address1: string
    address2: string
    city: string
    state: string
    postalCode: string
    country?: string | null
    isPrimary: boolean
    company: string
    parent?: {
      __typename?: 'Contact'
      id: string
      firstName: string
      lastName: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country?: string | null
      isPrimary: boolean
      homePhone?: string | null
      cellPhone?: string | null
      workPhone?: string | null
      faxNumber?: string | null
      website: string
      emailAddress?: string | null
      company: string
      birthday?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
      anniversary?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
    } | null
    spouse?: {
      __typename?: 'Contact'
      id: string
      firstName: string
      lastName: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country?: string | null
      isPrimary: boolean
      homePhone?: string | null
      cellPhone?: string | null
      workPhone?: string | null
      faxNumber?: string | null
      website: string
      emailAddress?: string | null
      company: string
      birthday?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
      anniversary?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
    } | null
    children: Array<{
      __typename?: 'Contact'
      id: string
      firstName: string
      lastName: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country?: string | null
      isPrimary: boolean
      homePhone?: string | null
      cellPhone?: string | null
      workPhone?: string | null
      faxNumber?: string | null
      website: string
      emailAddress?: string | null
      company: string
      birthday?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
      anniversary?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
    }>
    groups: Array<{ __typename: 'Group'; id: string; name: string }>
    notes: Array<{
      __typename: 'Note'
      id: string
      text: string
      date?: {
        __typename: 'CalendarDate'
        day: number
        month: number
        year: number
      } | null
    }>
    birthday?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
    anniversary?: {
      __typename: 'OptionalYearDate'
      day: number
      month: number
      year?: number | null
    } | null
  } | null
}

export type SponsorFragmentFragment = {
  __typename: 'User'
  id: string
  firstName: string
  lastName: string
  email: string
  genealogyId: number
  storefront?: {
    __typename: 'Storefront'
    biography?: string | null
    slug?: string | null
    mugshot?: { __typename?: 'Image'; url: any } | null
  } | null
  shippingAddress?: {
    __typename?: 'Address'
    city: string
    state: string
    country: string
  } | null
}

export type StickerFragment = {
  __typename: 'Sticker'
  id: string
  title: string
  createdAt: any
  image: {
    __typename: 'EditorImageType'
    id: string
    url: string
    width: number
    height: number
    createdAt: any
    thumbnailUrl: string
  }
}

export type StickerCategoryFragment = {
  __typename: 'StickerCategory'
  id: string
  name: string
}

export type StorefrontFragment = {
  __typename: 'Storefront'
  biography?: string | null
  slug?: string | null
  mugshot?: { __typename?: 'Image'; url: any } | null
}

type StripeSource_BankAccount_Fragment = {
  __typename: 'BankAccount'
  id: string
  bankName: string
  object: string
  last4: string
  accountHolderName: string
}

type StripeSource_CreditCard_Fragment = {
  __typename: 'CreditCard'
  id: string
  brand: string
  last4: string
  expMonth: number
  expYear: number
  object: string
  billingAddress?: {
    __typename?: 'CardBillingAddress'
    city?: string | null
    country?: string | null
    line1?: string | null
    line2?: string | null
    postalcode?: string | null
    state?: string | null
  } | null
}

export type StripeSourceFragment =
  | StripeSource_BankAccount_Fragment
  | StripeSource_CreditCard_Fragment

export type StripeSubscriptionFragment = {
  __typename: 'StripeSubscription'
  id: string
  name: string
  status: SubscriptionStatus
  startDate: any
  currentPeriodEnd: any
  interval: string
  intervalCount: number
  price: number
  isRenewable: boolean
  sku: string
  isSetToCancel: boolean
  features?: {
    __typename: 'SubscriptionFeatures'
    groupSends?: number | null
    scheduledSends?: number | null
    loyaltyRewards?: number | null
    systemSends?: number | null
    heartfeltSends?: number | null
    cardPackageSends?: number | null
  } | null
  metadata?: {
    __typename: 'SubscriptionMetadata'
    planOnCancel?: string | null
  } | null
}

export type SubscriptionFeaturesFragment = {
  __typename: 'SubscriptionFeatures'
  groupSends?: number | null
  scheduledSends?: number | null
  loyaltyRewards?: number | null
  systemSends?: number | null
  heartfeltSends?: number | null
  cardPackageSends?: number | null
}

export type SubscriptionMetadataFragment = {
  __typename: 'SubscriptionMetadata'
  planOnCancel?: string | null
}

export type TextFragmentFragment = {
  __typename: 'TextFragment'
  text: string
  fontSize: number
  isReplacement: boolean
  textColor: { __typename: 'Color'; red: number; green: number; blue: number }
  font: { __typename: 'Font'; id: string; fontName: string }
}

export type UserImageFragment = {
  __typename: 'Image'
  createdAt: any
  id: string
  url: any
  width: number
  height: number
  smallThumb: any
}

export type UserSettingsFragment = {
  __typename: 'UserSettings'
  remindersAdvanceNoticeDays: number
  remindersAdvanceNoticeDaysInternational: number
  paymentHold: boolean
  shouldReceivePromoEmails: boolean
  shouldReceiveNotificationEmails: boolean
  shouldReceiveReceiptEmails: boolean
  shouldReceiveReminderEmails: boolean
  shouldPrintRecipientCompany: boolean
  defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
}

export type VerifyPhoneNumberFailureFragment = {
  __typename: 'VerifyPhoneNumberFailure'
  failureType: VerifyPhoneNumberFailureType
  failureMessage: string
}

export type VerifyPhoneNumberSuccessFragment = {
  __typename: 'VerifyPhoneNumberSuccess'
  successType: VerifyPhoneNumberSuccessType
  verifiedAccount: {
    __typename: 'Account'
    id: string
    username: string
    firstName: string
    lastName: string
    email: string
    profileImageUrl?: any | null
    isStaff: boolean
    phoneNumber: string
    isAffiliate: boolean
    isNewUser: boolean
    createdAt: any
    genealogyId: number
    stripeCustomerId?: string | null
    dailyHeartfeltSends: number
    hasUnlimitedHeartfeltSends: boolean
    fullAccessFeatures: Array<FullAccessFeatureType>
    loyaltyRewards: number
    groupSends: number
    heartfeltSends: number
    heartfeltSystemSends: number
    scheduledSends: number
    systemSends: number
    cardPackageSends: number
    pointsTransitionSends: number
    employeeSends: number
    cardSends: number
    giftSends: number
    pointPrice: number
    promptingProLoginUrl: any
    promptinguLoginUrl?: any | null
    streamMarketingLoginUrl?: any | null
    promptinguJwt: string
    token: string
    hasBundlePlan: boolean
    affiliateUrl: any
    couldQualifyForFirstFreeCard: boolean
    isQualifiedForFirstFreeCard: boolean
    isTrialAffiliate: boolean
    isEligibleForFreeGsAffiliateAccount: boolean
    isEmailVerified: boolean
    labels: Array<Label>
    type: UserType
    cardCost: number
    bulkCardCost: number
    giftDiscount: number
    giftShippingDiscount: number
    isExpenseReloadRequired: boolean
    isRep: boolean
    dateJoined: any
    hasFullAccessContactManager: boolean
    hasFullAccessFreeAffiliate: boolean
    shouldDisplayUpsale: boolean
    hasEverHadSamplePack: boolean
    activePlansAndAddons: Array<{
      __typename: 'Plan'
      id: string
      title: string
      subtitle?: string | null
      stripeId: string
      description?: any | null
      isAddon: boolean
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      isExpenseReloadRequired: boolean
      color?: string | null
      backgroundStartColor: string
      backgroundEndColor: string
      icon: PlansPlanIconChoices
      price: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      relatedPackage?: {
        __typename?: 'RelatedPlanPackage'
        saleorProductId: string
        title: string
        description: string
        shouldShowByDefault: boolean
      } | null
    } | null>
    settings?: {
      __typename: 'UserSettings'
      remindersAdvanceNoticeDays: number
      remindersAdvanceNoticeDaysInternational: number
      paymentHold: boolean
      shouldReceivePromoEmails: boolean
      shouldReceiveNotificationEmails: boolean
      shouldReceiveReceiptEmails: boolean
      shouldReceiveReminderEmails: boolean
      shouldPrintRecipientCompany: boolean
      defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
    } | null
    shippingAddress?: {
      __typename: 'Address'
      firstName: string
      lastName: string
      company: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country: string
    } | null
    sponsor?: {
      __typename: 'User'
      id: string
      firstName: string
      lastName: string
      email: string
      genealogyId: number
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
    } | null
    storefront?: {
      __typename: 'Storefront'
      biography?: string | null
      slug?: string | null
      mugshot?: { __typename?: 'Image'; url: any } | null
    } | null
    plan: {
      __typename: 'Plan'
      id: string
      title: string
      subtitle?: string | null
      stripeId: string
      description?: any | null
      isAddon: boolean
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      isExpenseReloadRequired: boolean
      color?: string | null
      backgroundStartColor: string
      backgroundEndColor: string
      icon: PlansPlanIconChoices
      price: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      relatedPackage?: {
        __typename?: 'RelatedPlanPackage'
        saleorProductId: string
        title: string
        description: string
        shouldShowByDefault: boolean
      } | null
    }
    stripeSource?:
      | {
          __typename: 'BankAccount'
          id: string
          bankName: string
          object: string
          last4: string
          accountHolderName: string
        }
      | {
          __typename: 'CreditCard'
          id: string
          brand: string
          last4: string
          expMonth: number
          expYear: number
          object: string
          billingAddress?: {
            __typename?: 'CardBillingAddress'
            city?: string | null
            country?: string | null
            line1?: string | null
            line2?: string | null
            postalcode?: string | null
            state?: string | null
          } | null
        }
      | null
    stripeSubscription?: {
      __typename: 'StripeSubscription'
      id: string
      name: string
      status: SubscriptionStatus
      startDate: any
      currentPeriodEnd: any
      interval: string
      intervalCount: number
      price: number
      isRenewable: boolean
      sku: string
      isSetToCancel: boolean
      features?: {
        __typename: 'SubscriptionFeatures'
        groupSends?: number | null
        scheduledSends?: number | null
        loyaltyRewards?: number | null
        systemSends?: number | null
        heartfeltSends?: number | null
        cardPackageSends?: number | null
      } | null
      metadata?: {
        __typename: 'SubscriptionMetadata'
        planOnCancel?: string | null
      } | null
    } | null
    subscriptions: Array<{
      __typename: 'StripeSubscription'
      id: string
      name: string
      status: SubscriptionStatus
      startDate: any
      currentPeriodEnd: any
      interval: string
      intervalCount: number
      price: number
      isRenewable: boolean
      sku: string
      isSetToCancel: boolean
      features?: {
        __typename: 'SubscriptionFeatures'
        groupSends?: number | null
        scheduledSends?: number | null
        loyaltyRewards?: number | null
        systemSends?: number | null
        heartfeltSends?: number | null
        cardPackageSends?: number | null
      } | null
      metadata?: {
        __typename: 'SubscriptionMetadata'
        planOnCancel?: string | null
      } | null
    }>
    points: {
      __typename: 'Amount'
      amount: number
      currency: Currency
      description: string
    }
    expense: {
      __typename: 'Amount'
      amount: number
      currency: Currency
      description: string
    }
    expenseReload?: {
      __typename: 'ExpenseReload'
      purchaseAmount: number
      threshold: number
      isEnabled: boolean
      isRequired: boolean
    } | null
    verifiedPhoneNumber?: {
      __typename: 'PhoneNumber'
      nationalNumber: any
    } | null
    promptingsCoach?: {
      __typename?: 'PromptingsCoach'
      trainerId?: number | null
      dateTrainingPurchased?: any | null
      dateTrainingCompleted?: any | null
      dateTrainingExpires?: any | null
      dateCoachingPurchased?: any | null
      dateAttendedCertification?: any | null
      isEmailPublic: boolean
      isPhoneNumberPublic: boolean
      isCertified: boolean
      trainerEmail?: string | null
    } | null
  }
}

export type AcceptCampaignShareMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type AcceptCampaignShareMutation = {
  __typename?: 'Mutation'
  acceptCampaignShare: {
    __typename?: 'AcceptCampaignShare'
    campaign: {
      __typename: 'Campaign'
      id: string
      name: string
      isShareable: boolean
      lines: Array<{
        __typename: 'CampaignLine'
        name?: string | null
        card?: {
          __typename: 'Card'
          id: string
          type: CardType
          isHorizontal: boolean
          isNewEditorCard: boolean
          paperType: CardPaperType
          frontPreviewUrl: any
          insidePreviewUrl?: any | null
          flapPreviewUrl?: any | null
          backPreviewUrl: any
          tinyThumb: any
          sendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
          } | null
          minimalPanels: Array<{
            __typename: 'Panel'
            name: string
            previewUrl: any
            isFullBleed: boolean
            width: number
            height: number
          }>
        } | null
        giftVariation?: {
          __typename: 'GiftVariation'
          id: string
          isSendable: boolean
          isEligibleFreeGift: boolean
          name: string
          description?: string | null
          contents?: string | null
          shipping?: string | null
          imageUrls: Array<any>
          packaging?: string | null
          defaultImageUrl: any
          points: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          price: {
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }
        } | null
        sendDelay: {
          __typename: 'SendDelay'
          type: SendDelayType
          delayNumber?: number | null
          delayType: SendDelayDelayType
          timeType: SendDelayTimeType
          specificDate: any
        }
      }>
    }
  }
}

export type AcceptSharedContactMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type AcceptSharedContactMutation = {
  __typename?: 'Mutation'
  acceptContactShare: { __typename?: 'AcceptContactShare'; accepted: boolean }
}

export type AddFreeCampaignMutationVariables = Exact<{
  campaignId: Scalars['ID']['input']
}>

export type AddFreeCampaignMutation = {
  __typename?: 'Mutation'
  addFreeCampaign: {
    __typename?: 'AddFreeCampaign'
    message: string
    success: boolean
  }
}

export type CancelStripeSubscriptionMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CancelStripeSubscriptionMutation = {
  __typename?: 'Mutation'
  cancelStripeSubscription: {
    __typename?: 'CancelStripeSubscription'
    account: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    }
  }
}

export type ChangeSubscriptionMutationVariables = Exact<{
  sku: Scalars['String']['input']
  subscriptionId: Scalars['String']['input']
}>

export type ChangeSubscriptionMutation = {
  __typename?: 'Mutation'
  changeSubscription: {
    __typename?: 'ChangeSubscription'
    account: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    }
  }
}

export type CreateAccountMutationVariables = Exact<{
  account: AccountInput
  verificationCode: Scalars['String']['input']
  verticalId?: InputMaybe<VerticalId>
  recaptchaToken?: InputMaybe<Scalars['String']['input']>
}>

export type CreateAccountMutation = {
  __typename?: 'Mutation'
  createAccount: {
    __typename?: 'CreateAccount'
    validation?:
      | {
          __typename: 'ValidateEmailVerificationCodeFailure'
          failureType: ValidateEmailVerificationCodeFailureType
          message: string
          success: boolean
        }
      | {
          __typename: 'ValidateEmailVerificationCodeSuccess'
          success: boolean
          message: string
        }
      | null
    account?: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    } | null
  }
}

export type CreateAffiliateMutationVariables = Exact<{
  account: AccountInput
  govId1: Scalars['String']['input']
  govId2?: InputMaybe<Scalars['String']['input']>
  stripeToken?: InputMaybe<Scalars['String']['input']>
  affiliate?: InputMaybe<Scalars['Boolean']['input']>
  verticalId: VerticalId
  queryParams: Args
  verificationCode?: InputMaybe<Scalars['String']['input']>
}>

export type CreateAffiliateMutation = {
  __typename?: 'Mutation'
  createDistributor: {
    __typename?: 'CreateDistributor'
    error?: string | null
    validation?:
      | {
          __typename: 'ValidateEmailVerificationCodeFailure'
          failureType: ValidateEmailVerificationCodeFailureType
          message: string
          success: boolean
        }
      | {
          __typename: 'ValidateEmailVerificationCodeSuccess'
          success: boolean
          message: string
        }
      | null
    account?: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    } | null
    subscription?: {
      __typename?: 'StripeSubscription'
      status: SubscriptionStatus
      currentPeriodEnd: any
    } | null
  }
  createUserLoginLink: {
    __typename?: 'CreateUserLoginLink'
    link?: string | null
  }
}

export type CreateCampaignMutationVariables = Exact<{
  campaign: CreateCampaignInput
}>

export type CreateCampaignMutation = {
  __typename?: 'Mutation'
  createCampaign: {
    __typename?: 'CreateCampaign'
    campaign: {
      __typename: 'Campaign'
      id: string
      name: string
      isShareable: boolean
      lines: Array<{
        __typename: 'CampaignLine'
        name?: string | null
        card?: {
          __typename: 'Card'
          id: string
          type: CardType
          isHorizontal: boolean
          isNewEditorCard: boolean
          paperType: CardPaperType
          frontPreviewUrl: any
          insidePreviewUrl?: any | null
          flapPreviewUrl?: any | null
          backPreviewUrl: any
          tinyThumb: any
          sendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
          } | null
          minimalPanels: Array<{
            __typename: 'Panel'
            name: string
            previewUrl: any
            isFullBleed: boolean
            width: number
            height: number
          }>
        } | null
        giftVariation?: {
          __typename: 'GiftVariation'
          id: string
          isSendable: boolean
          isEligibleFreeGift: boolean
          name: string
          description?: string | null
          contents?: string | null
          shipping?: string | null
          imageUrls: Array<any>
          packaging?: string | null
          defaultImageUrl: any
          points: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          price: {
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }
        } | null
        sendDelay: {
          __typename: 'SendDelay'
          type: SendDelayType
          delayNumber?: number | null
          delayType: SendDelayDelayType
          timeType: SendDelayTimeType
          specificDate: any
        }
      }>
    }
  }
}

export type CreateCardMutationVariables = Exact<{
  sendableCard?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<CardType>
  paperType?: InputMaybe<CardPaperType>
  card?: InputMaybe<Scalars['ID']['input']>
  sponsor?: InputMaybe<Scalars['ID']['input']>
  isResend?: InputMaybe<Scalars['Boolean']['input']>
  isNewEditorCard?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CreateCardMutation = {
  __typename?: 'Mutation'
  createCard: {
    __typename?: 'CreateCard'
    card: {
      __typename: 'Card'
      id: string
      type: CardType
      paperType: CardPaperType
      isHorizontal: boolean
      isNewEditorCard: boolean
      backPanelLocation: number
      frontPreviewUrl: any
      insidePreviewUrl?: any | null
      flapPreviewUrl?: any | null
      backPreviewUrl: any
      showInPicturePlus: boolean
      tinyThumb: any
      detailedSendableCard?: {
        __typename: 'SendableCard'
        id: string
        title: string
        description: string
        variationColor: string
        isFavorite: boolean
        isHorizontal: boolean
        isPremium: boolean
        variations: Array<{
          __typename: 'SendableCard'
          id: string
          title: string
          description: string
          variationColor: string
          isFavorite: boolean
          isHorizontal: boolean
          isPremium: boolean
          cardTemplate?: {
            __typename: 'Card'
            id: string
            backPanelLocation: number
            panels: Array<{
              __typename: 'Panel'
              location: number
              isFullBleed: boolean
              isLocked: boolean
              name: string
              width: number
              height: number
              previewUrl: any
              backgroundColor?: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              } | null
              elements: Array<{
                __typename: 'Element'
                x: number
                y: number
                z: number
                rotation: number
                width: number
                height: number
                locked: boolean
                draggable?: boolean | null
                sticker?: {
                  __typename: 'ElementSticker'
                  scale: number
                  sticker?: {
                    __typename: 'EditorImageType'
                    id: string
                    url: string
                    width: number
                    height: number
                    thumbnailUrl: string
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                } | null
                image?: {
                  __typename: 'ElementPicture'
                  scale: number
                  image?: {
                    __typename: 'Image'
                    id: string
                    url: any
                    width: number
                    height: number
                    smallThumb: any
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                  filter?: { __typename?: 'Filter'; name: string } | null
                } | null
                text?: {
                  __typename?: 'ElementText'
                  paragraphs: Array<{
                    __typename: 'Paragraph'
                    alignment: TextAlignment
                    textFragments: Array<{
                      __typename: 'TextFragment'
                      text: string
                      fontSize: number
                      isReplacement: boolean
                      textColor: {
                        __typename: 'Color'
                        red: number
                        green: number
                        blue: number
                      }
                      font: { __typename: 'Font'; id: string; fontName: string }
                    }>
                  }>
                } | null
              }>
            }>
          } | null
          frontImage: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          }
          insideRightImage?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          } | null
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
        }>
        cardTemplate?: {
          __typename: 'Card'
          id: string
          backPanelLocation: number
          panels: Array<{
            __typename: 'Panel'
            location: number
            isFullBleed: boolean
            isLocked: boolean
            name: string
            width: number
            height: number
            previewUrl: any
            backgroundColor?: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            } | null
            elements: Array<{
              __typename: 'Element'
              x: number
              y: number
              z: number
              rotation: number
              width: number
              height: number
              locked: boolean
              draggable?: boolean | null
              sticker?: {
                __typename: 'ElementSticker'
                scale: number
                sticker?: {
                  __typename: 'EditorImageType'
                  id: string
                  url: string
                  width: number
                  height: number
                  thumbnailUrl: string
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
              } | null
              image?: {
                __typename: 'ElementPicture'
                scale: number
                image?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
                filter?: { __typename?: 'Filter'; name: string } | null
              } | null
              text?: {
                __typename?: 'ElementText'
                paragraphs: Array<{
                  __typename: 'Paragraph'
                  alignment: TextAlignment
                  textFragments: Array<{
                    __typename: 'TextFragment'
                    text: string
                    fontSize: number
                    isReplacement: boolean
                    textColor: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    }
                    font: { __typename: 'Font'; id: string; fontName: string }
                  }>
                }>
              } | null
            }>
          }>
        } | null
        frontImage: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        }
        insideRightImage?: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        } | null
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
      } | null
      panels: Array<{
        __typename: 'Panel'
        location: number
        isFullBleed: boolean
        isLocked: boolean
        name: string
        width: number
        height: number
        previewUrl: any
        backgroundColor?: {
          __typename: 'Color'
          red: number
          green: number
          blue: number
        } | null
        elements: Array<{
          __typename: 'Element'
          x: number
          y: number
          z: number
          rotation: number
          width: number
          height: number
          locked: boolean
          draggable?: boolean | null
          sticker?: {
            __typename: 'ElementSticker'
            scale: number
            sticker?: {
              __typename: 'EditorImageType'
              id: string
              url: string
              width: number
              height: number
              thumbnailUrl: string
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
          } | null
          image?: {
            __typename: 'ElementPicture'
            scale: number
            image?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
            filter?: { __typename?: 'Filter'; name: string } | null
          } | null
          text?: {
            __typename?: 'ElementText'
            paragraphs: Array<{
              __typename: 'Paragraph'
              alignment: TextAlignment
              textFragments: Array<{
                __typename: 'TextFragment'
                text: string
                fontSize: number
                isReplacement: boolean
                textColor: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                }
                font: { __typename: 'Font'; id: string; fontName: string }
              }>
            }>
          } | null
        }>
      }>
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
      paperUpgradeCosts: Array<{
        __typename?: 'PaperCosts'
        value: string
        upgrade: string
      }>
      digitalCard?: {
        __typename?: 'DigitalCard'
        pk: string
        shareMessageTitle: string
        shareMessageBody: string
        tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
      } | null
    }
    account: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    }
  }
}

export type CreateContactRequestMutationVariables = Exact<{
  contactUuid?: InputMaybe<Scalars['ID']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
}>

export type CreateContactRequestMutation = {
  __typename?: 'Mutation'
  createContactRequest: {
    __typename?: 'CreateContactRequest'
    contactRequest: {
      __typename?: 'ContactRequest'
      id: string
      token: string
      expiresAt: any
      firstName: string
      lastName: string
      status: ContactRequestStatusEnum
      contact?: {
        __typename: 'Contact'
        id: string
        firstName: string
        lastName: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country?: string | null
        isPrimary: boolean
        company: string
        birthday?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
        anniversary?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
      } | null
    }
  }
}

export type CreateDigitalCardMutationVariables = Exact<{
  cardId: Scalars['ID']['input']
  input: DigitalCardInput
}>

export type CreateDigitalCardMutation = {
  __typename?: 'Mutation'
  createDigitalCard: {
    __typename?: 'CreateDigitalCard'
    digitalCard?: {
      __typename?: 'DigitalCard'
      pk: string
      card: { __typename?: 'Card'; id: string }
    } | null
  }
}

export type CreateDigitalCardTokenMutationVariables = Exact<{
  digitalCardPk: Scalars['ID']['input']
}>

export type CreateDigitalCardTokenMutation = {
  __typename?: 'Mutation'
  createDigitalCardToken: {
    __typename?: 'CreateDigitalCardToken'
    digitalCardToken: {
      __typename?: 'DigitalCardToken'
      pk: string
      token: string
      permissions: TokenPermissions
    }
  }
}

export type CreateFreeAffiliateMutationVariables = Exact<{
  account: AccountInput
  govId1: Scalars['String']['input']
  govId2?: InputMaybe<Scalars['String']['input']>
  verticalId: VerticalId
  queryParams: Args
}>

export type CreateFreeAffiliateMutation = {
  __typename?: 'Mutation'
  createFreeAffiliate: {
    __typename?: 'CreateFreeAffiliate'
    error?: string | null
    account?: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    } | null
    subscription?: {
      __typename?: 'StripeSubscription'
      status: SubscriptionStatus
      currentPeriodEnd: any
    } | null
  }
  createUserLoginLink: {
    __typename?: 'CreateUserLoginLink'
    link?: string | null
  }
}

export type CreateOrUpdateBackPanelMutationVariables = Exact<{
  panel: PanelInput
  layoutId: Scalars['ID']['input']
  createFromExisting?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CreateOrUpdateBackPanelMutation = {
  __typename?: 'Mutation'
  createOrUpdateBackPanel: {
    __typename?: 'CreateOrUpdateBackPanel'
    layout?: {
      __typename: 'Layout'
      id: string
      category?: LayoutCategory | null
      owner: Owner
      dimensions?: LayoutDimensions | null
      thumbnail: { __typename?: 'Image'; url: any }
      panel: {
        __typename: 'Panel'
        location: number
        isFullBleed: boolean
        isLocked: boolean
        name: string
        width: number
        height: number
        previewUrl: any
        backgroundColor?: {
          __typename: 'Color'
          red: number
          green: number
          blue: number
        } | null
        elements: Array<{
          __typename: 'Element'
          x: number
          y: number
          z: number
          rotation: number
          width: number
          height: number
          locked: boolean
          draggable?: boolean | null
          sticker?: {
            __typename: 'ElementSticker'
            scale: number
            sticker?: {
              __typename: 'EditorImageType'
              id: string
              url: string
              width: number
              height: number
              thumbnailUrl: string
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
          } | null
          image?: {
            __typename: 'ElementPicture'
            scale: number
            image?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
            filter?: { __typename?: 'Filter'; name: string } | null
          } | null
          text?: {
            __typename?: 'ElementText'
            paragraphs: Array<{
              __typename: 'Paragraph'
              alignment: TextAlignment
              textFragments: Array<{
                __typename: 'TextFragment'
                text: string
                fontSize: number
                isReplacement: boolean
                textColor: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                }
                font: { __typename: 'Font'; id: string; fontName: string }
              }>
            }>
          } | null
        }>
      }
    } | null
  }
}

export type CreateOrderMutationVariables = Exact<{
  order: CreateOrderInput
}>

export type CreateOrderMutation = {
  __typename?: 'Mutation'
  createOrder: {
    __typename?: 'CreateOrder'
    order: {
      __typename: 'Order'
      id: string
      isSendable: boolean
      createdAt: any
      isSent: boolean
      sendType: CardSendType
      isBulk: boolean
      bulkUpgradeCardQty?: number | null
      lines: Array<{
        __typename: 'Line'
        recipientCount: number
        card?: {
          __typename: 'Card'
          id: string
          type: CardType
          paperType: CardPaperType
          isHorizontal: boolean
          isNewEditorCard: boolean
          backPanelLocation: number
          frontPreviewUrl: any
          insidePreviewUrl?: any | null
          flapPreviewUrl?: any | null
          backPreviewUrl: any
          showInPicturePlus: boolean
          tinyThumb: any
          detailedSendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
            description: string
            variationColor: string
            isFavorite: boolean
            isHorizontal: boolean
            isPremium: boolean
            variations: Array<{
              __typename: 'SendableCard'
              id: string
              title: string
              description: string
              variationColor: string
              isFavorite: boolean
              isHorizontal: boolean
              isPremium: boolean
              cardTemplate?: {
                __typename: 'Card'
                id: string
                backPanelLocation: number
                panels: Array<{
                  __typename: 'Panel'
                  location: number
                  isFullBleed: boolean
                  isLocked: boolean
                  name: string
                  width: number
                  height: number
                  previewUrl: any
                  backgroundColor?: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  } | null
                  elements: Array<{
                    __typename: 'Element'
                    x: number
                    y: number
                    z: number
                    rotation: number
                    width: number
                    height: number
                    locked: boolean
                    draggable?: boolean | null
                    sticker?: {
                      __typename: 'ElementSticker'
                      scale: number
                      sticker?: {
                        __typename: 'EditorImageType'
                        id: string
                        url: string
                        width: number
                        height: number
                        thumbnailUrl: string
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                    } | null
                    image?: {
                      __typename: 'ElementPicture'
                      scale: number
                      image?: {
                        __typename: 'Image'
                        id: string
                        url: any
                        width: number
                        height: number
                        smallThumb: any
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                      filter?: { __typename?: 'Filter'; name: string } | null
                    } | null
                    text?: {
                      __typename?: 'ElementText'
                      paragraphs: Array<{
                        __typename: 'Paragraph'
                        alignment: TextAlignment
                        textFragments: Array<{
                          __typename: 'TextFragment'
                          text: string
                          fontSize: number
                          isReplacement: boolean
                          textColor: {
                            __typename: 'Color'
                            red: number
                            green: number
                            blue: number
                          }
                          font: {
                            __typename: 'Font'
                            id: string
                            fontName: string
                          }
                        }>
                      }>
                    } | null
                  }>
                }>
              } | null
              frontImage: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              }
              insideRightImage?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              } | null
              cost: {
                __typename?: 'ItemizedCost'
                total: Array<{
                  __typename: 'Price'
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
                entries: Array<{
                  __typename: 'LabeledPrice'
                  label: string
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
              }
            }>
            cardTemplate?: {
              __typename: 'Card'
              id: string
              backPanelLocation: number
              panels: Array<{
                __typename: 'Panel'
                location: number
                isFullBleed: boolean
                isLocked: boolean
                name: string
                width: number
                height: number
                previewUrl: any
                backgroundColor?: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                } | null
                elements: Array<{
                  __typename: 'Element'
                  x: number
                  y: number
                  z: number
                  rotation: number
                  width: number
                  height: number
                  locked: boolean
                  draggable?: boolean | null
                  sticker?: {
                    __typename: 'ElementSticker'
                    scale: number
                    sticker?: {
                      __typename: 'EditorImageType'
                      id: string
                      url: string
                      width: number
                      height: number
                      thumbnailUrl: string
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                  } | null
                  image?: {
                    __typename: 'ElementPicture'
                    scale: number
                    image?: {
                      __typename: 'Image'
                      id: string
                      url: any
                      width: number
                      height: number
                      smallThumb: any
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                    filter?: { __typename?: 'Filter'; name: string } | null
                  } | null
                  text?: {
                    __typename?: 'ElementText'
                    paragraphs: Array<{
                      __typename: 'Paragraph'
                      alignment: TextAlignment
                      textFragments: Array<{
                        __typename: 'TextFragment'
                        text: string
                        fontSize: number
                        isReplacement: boolean
                        textColor: {
                          __typename: 'Color'
                          red: number
                          green: number
                          blue: number
                        }
                        font: {
                          __typename: 'Font'
                          id: string
                          fontName: string
                        }
                      }>
                    }>
                  } | null
                }>
              }>
            } | null
            frontImage: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            }
            insideRightImage?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            } | null
            cost: {
              __typename?: 'ItemizedCost'
              total: Array<{
                __typename: 'Price'
                asString: string
                currency: CurrencyType
                amount: number
              }>
              entries: Array<{
                __typename: 'LabeledPrice'
                label: string
                asString: string
                currency: CurrencyType
                amount: number
              }>
            }
          } | null
          panels: Array<{
            __typename: 'Panel'
            location: number
            isFullBleed: boolean
            isLocked: boolean
            name: string
            width: number
            height: number
            previewUrl: any
            backgroundColor?: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            } | null
            elements: Array<{
              __typename: 'Element'
              x: number
              y: number
              z: number
              rotation: number
              width: number
              height: number
              locked: boolean
              draggable?: boolean | null
              sticker?: {
                __typename: 'ElementSticker'
                scale: number
                sticker?: {
                  __typename: 'EditorImageType'
                  id: string
                  url: string
                  width: number
                  height: number
                  thumbnailUrl: string
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
              } | null
              image?: {
                __typename: 'ElementPicture'
                scale: number
                image?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
                filter?: { __typename?: 'Filter'; name: string } | null
              } | null
              text?: {
                __typename?: 'ElementText'
                paragraphs: Array<{
                  __typename: 'Paragraph'
                  alignment: TextAlignment
                  textFragments: Array<{
                    __typename: 'TextFragment'
                    text: string
                    fontSize: number
                    isReplacement: boolean
                    textColor: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    }
                    font: { __typename: 'Font'; id: string; fontName: string }
                  }>
                }>
              } | null
            }>
          }>
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
          paperUpgradeCosts: Array<{
            __typename?: 'PaperCosts'
            value: string
            upgrade: string
          }>
          digitalCard?: {
            __typename?: 'DigitalCard'
            pk: string
            shareMessageTitle: string
            shareMessageBody: string
            tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
          } | null
        } | null
        giftVariation?: {
          __typename: 'GiftVariation'
          id: string
          isSendable: boolean
          isEligibleFreeGift: boolean
          name: string
          description?: string | null
          contents?: string | null
          shipping?: string | null
          imageUrls: Array<any>
          packaging?: string | null
          defaultImageUrl: any
          points: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          price: {
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }
        } | null
        sendDelay: {
          __typename: 'SendDelay'
          type: SendDelayType
          delayNumber?: number | null
          delayType: SendDelayDelayType
          timeType: SendDelayTimeType
          specificDate: any
        }
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
        baseCost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
      }>
      contacts: Array<{ __typename: 'Contact'; id: string }>
      groups: Array<{
        __typename: 'Group'
        id: string
        name: string
        members: Array<{ __typename: 'Contact'; id: string }>
      }>
      returnAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      campaign?: { __typename: 'Campaign'; id: string; name: string } | null
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
      warnings: Array<{
        __typename: 'Error'
        code?: number | null
        message?: string | null
      }>
      invalidContacts: Array<{ __typename: 'Contact'; id: string }>
      invalidSendDelayContacts: Array<{ __typename: 'Contact'; id: string }>
      bulkUpgradeShippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
    }
  }
}

export type CreateSparseUserMutationVariables = Exact<{
  inviteId?: InputMaybe<Scalars['ID']['input']>
}>

export type CreateSparseUserMutation = {
  __typename?: 'Mutation'
  createSparseUser: {
    __typename?: 'CreateSparseUser'
    account: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    }
  }
}

export type CreateUserLoginLinkMutationVariables = Exact<{
  verticalId: VerticalId
  queryParams: Args
}>

export type CreateUserLoginLinkMutation = {
  __typename?: 'Mutation'
  createUserLoginLink: {
    __typename?: 'CreateUserLoginLink'
    link?: string | null
  }
}

export type DeleteCampaignShareMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteCampaignShareMutation = {
  __typename?: 'Mutation'
  deleteCampaignShare: { __typename?: 'DeleteCampaignShare'; deleted: boolean }
}

export type DeleteContactRequestMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteContactRequestMutation = {
  __typename?: 'Mutation'
  deleteContactRequest: {
    __typename?: 'DeleteContactRequest'
    success: boolean
  }
}

export type DeleteContactShareMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteContactShareMutation = {
  __typename?: 'Mutation'
  deleteContactShare: { __typename?: 'DeleteContactShare'; deleted: boolean }
}

export type DeletePanelLayoutMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeletePanelLayoutMutation = {
  __typename?: 'Mutation'
  deletePanelLayout: { __typename?: 'DeletePanelLayout'; deleted: boolean }
}

export type DeleteReminderMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteReminderMutation = {
  __typename?: 'Mutation'
  deleteReminder: { __typename?: 'DeleteReminder'; deleted: boolean }
}

export type DenyNotificationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DenyNotificationMutation = {
  __typename?: 'Mutation'
  denyNotification: { __typename?: 'DenyNotification'; success: boolean }
}

export type GetOrCreateEditableCardForRecipientMutationVariables = Exact<{
  recipientId: Scalars['ID']['input']
  productionId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetOrCreateEditableCardForRecipientMutation = {
  __typename?: 'Mutation'
  getOrCreateEditableCardForRecipient: {
    __typename?: 'GetOrCreateEditableCardForRecipient'
    card: {
      __typename: 'Card'
      id: string
      type: CardType
      isHorizontal: boolean
      frontPreviewUrl: any
      insidePreviewUrl?: any | null
      flapPreviewUrl?: any | null
      backPreviewUrl: any
      isNewEditorCard: boolean
      panels: Array<{
        __typename: 'Panel'
        location: number
        isFullBleed: boolean
        isLocked: boolean
        name: string
        width: number
        height: number
        previewUrl: any
        backgroundColor?: {
          __typename: 'Color'
          red: number
          green: number
          blue: number
        } | null
        elements: Array<{
          __typename: 'Element'
          x: number
          y: number
          z: number
          rotation: number
          width: number
          height: number
          locked: boolean
          draggable?: boolean | null
          sticker?: {
            __typename: 'ElementSticker'
            scale: number
            sticker?: {
              __typename: 'EditorImageType'
              id: string
              url: string
              width: number
              height: number
              thumbnailUrl: string
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
          } | null
          image?: {
            __typename: 'ElementPicture'
            scale: number
            image?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
            filter?: { __typename?: 'Filter'; name: string } | null
          } | null
          text?: {
            __typename?: 'ElementText'
            paragraphs: Array<{
              __typename: 'Paragraph'
              alignment: TextAlignment
              textFragments: Array<{
                __typename: 'TextFragment'
                text: string
                fontSize: number
                isReplacement: boolean
                textColor: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                }
                font: { __typename: 'Font'; id: string; fontName: string }
              }>
            }>
          } | null
        }>
      }>
    }
  }
}

export type LoginUserMutationVariables = Exact<{
  username: Scalars['String']['input']
  password: Scalars['String']['input']
  verticalId: VerticalId
  queryParams: Args
}>

export type LoginUserMutation = {
  __typename?: 'Mutation'
  loginUser: {
    __typename?: 'LoginUser'
    success: boolean
    message?: string | null
    oldUser?: boolean | null
    account?: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    } | null
  }
  createUserLoginLink: {
    __typename?: 'CreateUserLoginLink'
    link?: string | null
  }
}

export type PurchaseProductMutationVariables = Exact<{
  orderId: Scalars['String']['input']
  stripeToken?: InputMaybe<Scalars['String']['input']>
}>

export type PurchaseProductMutation = {
  __typename?: 'Mutation'
  purchaseProduct: {
    __typename?: 'PurchaseProduct'
    purchased?: boolean | null
    error?: string | null
    account: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    }
  }
}

export type RevertBulkOrderMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type RevertBulkOrderMutation = {
  __typename?: 'Mutation'
  revertBulkOrder: {
    __typename?: 'RevertBulkOrder'
    order: {
      __typename: 'Order'
      id: string
      isSendable: boolean
      createdAt: any
      isSent: boolean
      sendType: CardSendType
      isBulk: boolean
      bulkUpgradeCardQty?: number | null
      lines: Array<{
        __typename: 'Line'
        recipientCount: number
        card?: {
          __typename: 'Card'
          id: string
          type: CardType
          paperType: CardPaperType
          isHorizontal: boolean
          isNewEditorCard: boolean
          backPanelLocation: number
          frontPreviewUrl: any
          insidePreviewUrl?: any | null
          flapPreviewUrl?: any | null
          backPreviewUrl: any
          showInPicturePlus: boolean
          tinyThumb: any
          detailedSendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
            description: string
            variationColor: string
            isFavorite: boolean
            isHorizontal: boolean
            isPremium: boolean
            variations: Array<{
              __typename: 'SendableCard'
              id: string
              title: string
              description: string
              variationColor: string
              isFavorite: boolean
              isHorizontal: boolean
              isPremium: boolean
              cardTemplate?: {
                __typename: 'Card'
                id: string
                backPanelLocation: number
                panels: Array<{
                  __typename: 'Panel'
                  location: number
                  isFullBleed: boolean
                  isLocked: boolean
                  name: string
                  width: number
                  height: number
                  previewUrl: any
                  backgroundColor?: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  } | null
                  elements: Array<{
                    __typename: 'Element'
                    x: number
                    y: number
                    z: number
                    rotation: number
                    width: number
                    height: number
                    locked: boolean
                    draggable?: boolean | null
                    sticker?: {
                      __typename: 'ElementSticker'
                      scale: number
                      sticker?: {
                        __typename: 'EditorImageType'
                        id: string
                        url: string
                        width: number
                        height: number
                        thumbnailUrl: string
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                    } | null
                    image?: {
                      __typename: 'ElementPicture'
                      scale: number
                      image?: {
                        __typename: 'Image'
                        id: string
                        url: any
                        width: number
                        height: number
                        smallThumb: any
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                      filter?: { __typename?: 'Filter'; name: string } | null
                    } | null
                    text?: {
                      __typename?: 'ElementText'
                      paragraphs: Array<{
                        __typename: 'Paragraph'
                        alignment: TextAlignment
                        textFragments: Array<{
                          __typename: 'TextFragment'
                          text: string
                          fontSize: number
                          isReplacement: boolean
                          textColor: {
                            __typename: 'Color'
                            red: number
                            green: number
                            blue: number
                          }
                          font: {
                            __typename: 'Font'
                            id: string
                            fontName: string
                          }
                        }>
                      }>
                    } | null
                  }>
                }>
              } | null
              frontImage: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              }
              insideRightImage?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              } | null
              cost: {
                __typename?: 'ItemizedCost'
                total: Array<{
                  __typename: 'Price'
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
                entries: Array<{
                  __typename: 'LabeledPrice'
                  label: string
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
              }
            }>
            cardTemplate?: {
              __typename: 'Card'
              id: string
              backPanelLocation: number
              panels: Array<{
                __typename: 'Panel'
                location: number
                isFullBleed: boolean
                isLocked: boolean
                name: string
                width: number
                height: number
                previewUrl: any
                backgroundColor?: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                } | null
                elements: Array<{
                  __typename: 'Element'
                  x: number
                  y: number
                  z: number
                  rotation: number
                  width: number
                  height: number
                  locked: boolean
                  draggable?: boolean | null
                  sticker?: {
                    __typename: 'ElementSticker'
                    scale: number
                    sticker?: {
                      __typename: 'EditorImageType'
                      id: string
                      url: string
                      width: number
                      height: number
                      thumbnailUrl: string
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                  } | null
                  image?: {
                    __typename: 'ElementPicture'
                    scale: number
                    image?: {
                      __typename: 'Image'
                      id: string
                      url: any
                      width: number
                      height: number
                      smallThumb: any
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                    filter?: { __typename?: 'Filter'; name: string } | null
                  } | null
                  text?: {
                    __typename?: 'ElementText'
                    paragraphs: Array<{
                      __typename: 'Paragraph'
                      alignment: TextAlignment
                      textFragments: Array<{
                        __typename: 'TextFragment'
                        text: string
                        fontSize: number
                        isReplacement: boolean
                        textColor: {
                          __typename: 'Color'
                          red: number
                          green: number
                          blue: number
                        }
                        font: {
                          __typename: 'Font'
                          id: string
                          fontName: string
                        }
                      }>
                    }>
                  } | null
                }>
              }>
            } | null
            frontImage: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            }
            insideRightImage?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            } | null
            cost: {
              __typename?: 'ItemizedCost'
              total: Array<{
                __typename: 'Price'
                asString: string
                currency: CurrencyType
                amount: number
              }>
              entries: Array<{
                __typename: 'LabeledPrice'
                label: string
                asString: string
                currency: CurrencyType
                amount: number
              }>
            }
          } | null
          panels: Array<{
            __typename: 'Panel'
            location: number
            isFullBleed: boolean
            isLocked: boolean
            name: string
            width: number
            height: number
            previewUrl: any
            backgroundColor?: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            } | null
            elements: Array<{
              __typename: 'Element'
              x: number
              y: number
              z: number
              rotation: number
              width: number
              height: number
              locked: boolean
              draggable?: boolean | null
              sticker?: {
                __typename: 'ElementSticker'
                scale: number
                sticker?: {
                  __typename: 'EditorImageType'
                  id: string
                  url: string
                  width: number
                  height: number
                  thumbnailUrl: string
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
              } | null
              image?: {
                __typename: 'ElementPicture'
                scale: number
                image?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
                filter?: { __typename?: 'Filter'; name: string } | null
              } | null
              text?: {
                __typename?: 'ElementText'
                paragraphs: Array<{
                  __typename: 'Paragraph'
                  alignment: TextAlignment
                  textFragments: Array<{
                    __typename: 'TextFragment'
                    text: string
                    fontSize: number
                    isReplacement: boolean
                    textColor: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    }
                    font: { __typename: 'Font'; id: string; fontName: string }
                  }>
                }>
              } | null
            }>
          }>
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
          paperUpgradeCosts: Array<{
            __typename?: 'PaperCosts'
            value: string
            upgrade: string
          }>
          digitalCard?: {
            __typename?: 'DigitalCard'
            pk: string
            shareMessageTitle: string
            shareMessageBody: string
            tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
          } | null
        } | null
        giftVariation?: {
          __typename: 'GiftVariation'
          id: string
          isSendable: boolean
          isEligibleFreeGift: boolean
          name: string
          description?: string | null
          contents?: string | null
          shipping?: string | null
          imageUrls: Array<any>
          packaging?: string | null
          defaultImageUrl: any
          points: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          price: {
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }
        } | null
        sendDelay: {
          __typename: 'SendDelay'
          type: SendDelayType
          delayNumber?: number | null
          delayType: SendDelayDelayType
          timeType: SendDelayTimeType
          specificDate: any
        }
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
        baseCost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
      }>
      contacts: Array<{ __typename: 'Contact'; id: string }>
      groups: Array<{
        __typename: 'Group'
        id: string
        name: string
        members: Array<{ __typename: 'Contact'; id: string }>
      }>
      returnAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      campaign?: { __typename: 'Campaign'; id: string; name: string } | null
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
      warnings: Array<{
        __typename: 'Error'
        code?: number | null
        message?: string | null
      }>
      invalidContacts: Array<{ __typename: 'Contact'; id: string }>
      invalidSendDelayContacts: Array<{ __typename: 'Contact'; id: string }>
      bulkUpgradeShippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
    }
  }
}

export type SendMagicLinkMutationVariables = Exact<{
  email: Scalars['String']['input']
  verticalId?: InputMaybe<VerticalId>
  redirectUri?: InputMaybe<Scalars['String']['input']>
}>

export type SendMagicLinkMutation = {
  __typename?: 'Mutation'
  sendMagicLink: {
    __typename?: 'SendMagicLink'
    success: boolean
    message?: string | null
  }
}

export type UpdateAccountMutationVariables = Exact<{
  account: AccountInput
  subscription?: InputMaybe<SubscriptionInput>
}>

export type UpdateAccountMutation = {
  __typename?: 'Mutation'
  updateAccount: {
    __typename?: 'UpdateAccount'
    account: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    }
  }
}

export type UpdateCampaignMutationVariables = Exact<{
  campaign: UpdateCampaignInput
}>

export type UpdateCampaignMutation = {
  __typename?: 'Mutation'
  updateCampaign: {
    __typename?: 'UpdateCampaign'
    campaign: {
      __typename: 'Campaign'
      id: string
      name: string
      isShareable: boolean
      lines: Array<{
        __typename: 'CampaignLine'
        name?: string | null
        card?: {
          __typename: 'Card'
          id: string
          type: CardType
          isHorizontal: boolean
          isNewEditorCard: boolean
          paperType: CardPaperType
          frontPreviewUrl: any
          insidePreviewUrl?: any | null
          flapPreviewUrl?: any | null
          backPreviewUrl: any
          tinyThumb: any
          sendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
          } | null
          minimalPanels: Array<{
            __typename: 'Panel'
            name: string
            previewUrl: any
            isFullBleed: boolean
            width: number
            height: number
          }>
        } | null
        giftVariation?: {
          __typename: 'GiftVariation'
          id: string
          isSendable: boolean
          isEligibleFreeGift: boolean
          name: string
          description?: string | null
          contents?: string | null
          shipping?: string | null
          imageUrls: Array<any>
          packaging?: string | null
          defaultImageUrl: any
          points: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          price: {
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }
        } | null
        sendDelay: {
          __typename: 'SendDelay'
          type: SendDelayType
          delayNumber?: number | null
          delayType: SendDelayDelayType
          timeType: SendDelayTimeType
          specificDate: any
        }
      }>
    }
  }
}

export type UpdateCardMutationVariables = Exact<{
  card: UpdateCardInput
}>

export type UpdateCardMutation = {
  __typename?: 'Mutation'
  updateCard: {
    __typename?: 'UpdateCard'
    card: {
      __typename: 'Card'
      id: string
      type: CardType
      paperType: CardPaperType
      isHorizontal: boolean
      isNewEditorCard: boolean
      backPanelLocation: number
      frontPreviewUrl: any
      insidePreviewUrl?: any | null
      flapPreviewUrl?: any | null
      backPreviewUrl: any
      showInPicturePlus: boolean
      tinyThumb: any
      detailedSendableCard?: {
        __typename: 'SendableCard'
        id: string
        title: string
        description: string
        variationColor: string
        isFavorite: boolean
        isHorizontal: boolean
        isPremium: boolean
        variations: Array<{
          __typename: 'SendableCard'
          id: string
          title: string
          description: string
          variationColor: string
          isFavorite: boolean
          isHorizontal: boolean
          isPremium: boolean
          cardTemplate?: {
            __typename: 'Card'
            id: string
            backPanelLocation: number
            panels: Array<{
              __typename: 'Panel'
              location: number
              isFullBleed: boolean
              isLocked: boolean
              name: string
              width: number
              height: number
              previewUrl: any
              backgroundColor?: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              } | null
              elements: Array<{
                __typename: 'Element'
                x: number
                y: number
                z: number
                rotation: number
                width: number
                height: number
                locked: boolean
                draggable?: boolean | null
                sticker?: {
                  __typename: 'ElementSticker'
                  scale: number
                  sticker?: {
                    __typename: 'EditorImageType'
                    id: string
                    url: string
                    width: number
                    height: number
                    thumbnailUrl: string
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                } | null
                image?: {
                  __typename: 'ElementPicture'
                  scale: number
                  image?: {
                    __typename: 'Image'
                    id: string
                    url: any
                    width: number
                    height: number
                    smallThumb: any
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                  filter?: { __typename?: 'Filter'; name: string } | null
                } | null
                text?: {
                  __typename?: 'ElementText'
                  paragraphs: Array<{
                    __typename: 'Paragraph'
                    alignment: TextAlignment
                    textFragments: Array<{
                      __typename: 'TextFragment'
                      text: string
                      fontSize: number
                      isReplacement: boolean
                      textColor: {
                        __typename: 'Color'
                        red: number
                        green: number
                        blue: number
                      }
                      font: { __typename: 'Font'; id: string; fontName: string }
                    }>
                  }>
                } | null
              }>
            }>
          } | null
          frontImage: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          }
          insideRightImage?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          } | null
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
        }>
        cardTemplate?: {
          __typename: 'Card'
          id: string
          backPanelLocation: number
          panels: Array<{
            __typename: 'Panel'
            location: number
            isFullBleed: boolean
            isLocked: boolean
            name: string
            width: number
            height: number
            previewUrl: any
            backgroundColor?: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            } | null
            elements: Array<{
              __typename: 'Element'
              x: number
              y: number
              z: number
              rotation: number
              width: number
              height: number
              locked: boolean
              draggable?: boolean | null
              sticker?: {
                __typename: 'ElementSticker'
                scale: number
                sticker?: {
                  __typename: 'EditorImageType'
                  id: string
                  url: string
                  width: number
                  height: number
                  thumbnailUrl: string
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
              } | null
              image?: {
                __typename: 'ElementPicture'
                scale: number
                image?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
                filter?: { __typename?: 'Filter'; name: string } | null
              } | null
              text?: {
                __typename?: 'ElementText'
                paragraphs: Array<{
                  __typename: 'Paragraph'
                  alignment: TextAlignment
                  textFragments: Array<{
                    __typename: 'TextFragment'
                    text: string
                    fontSize: number
                    isReplacement: boolean
                    textColor: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    }
                    font: { __typename: 'Font'; id: string; fontName: string }
                  }>
                }>
              } | null
            }>
          }>
        } | null
        frontImage: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        }
        insideRightImage?: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        } | null
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
      } | null
      panels: Array<{
        __typename: 'Panel'
        location: number
        isFullBleed: boolean
        isLocked: boolean
        name: string
        width: number
        height: number
        previewUrl: any
        backgroundColor?: {
          __typename: 'Color'
          red: number
          green: number
          blue: number
        } | null
        elements: Array<{
          __typename: 'Element'
          x: number
          y: number
          z: number
          rotation: number
          width: number
          height: number
          locked: boolean
          draggable?: boolean | null
          sticker?: {
            __typename: 'ElementSticker'
            scale: number
            sticker?: {
              __typename: 'EditorImageType'
              id: string
              url: string
              width: number
              height: number
              thumbnailUrl: string
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
          } | null
          image?: {
            __typename: 'ElementPicture'
            scale: number
            image?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
            filter?: { __typename?: 'Filter'; name: string } | null
          } | null
          text?: {
            __typename?: 'ElementText'
            paragraphs: Array<{
              __typename: 'Paragraph'
              alignment: TextAlignment
              textFragments: Array<{
                __typename: 'TextFragment'
                text: string
                fontSize: number
                isReplacement: boolean
                textColor: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                }
                font: { __typename: 'Font'; id: string; fontName: string }
              }>
            }>
          } | null
        }>
      }>
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
      paperUpgradeCosts: Array<{
        __typename?: 'PaperCosts'
        value: string
        upgrade: string
      }>
      digitalCard?: {
        __typename?: 'DigitalCard'
        pk: string
        shareMessageTitle: string
        shareMessageBody: string
        tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
      } | null
    }
  }
}

export type UpdateDigitalCardMutationVariables = Exact<{
  digitalCardPk: Scalars['ID']['input']
  input: DigitalCardInput
}>

export type UpdateDigitalCardMutation = {
  __typename?: 'Mutation'
  updateDigitalCard: {
    __typename?: 'UpdateDigitalCard'
    digitalCard?: {
      __typename?: 'DigitalCard'
      pk: string
      shareMessageTitle: string
    } | null
  }
}

export type UpdateOrderMutationVariables = Exact<{
  order: UpdateOrderInput
}>

export type UpdateOrderMutation = {
  __typename?: 'Mutation'
  updateOrder: {
    __typename?: 'UpdateOrder'
    order: {
      __typename: 'Order'
      id: string
      isSendable: boolean
      createdAt: any
      isSent: boolean
      sendType: CardSendType
      isBulk: boolean
      bulkUpgradeCardQty?: number | null
      lines: Array<{
        __typename: 'Line'
        recipientCount: number
        card?: {
          __typename: 'Card'
          id: string
          type: CardType
          paperType: CardPaperType
          isHorizontal: boolean
          isNewEditorCard: boolean
          backPanelLocation: number
          frontPreviewUrl: any
          insidePreviewUrl?: any | null
          flapPreviewUrl?: any | null
          backPreviewUrl: any
          showInPicturePlus: boolean
          tinyThumb: any
          detailedSendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
            description: string
            variationColor: string
            isFavorite: boolean
            isHorizontal: boolean
            isPremium: boolean
            variations: Array<{
              __typename: 'SendableCard'
              id: string
              title: string
              description: string
              variationColor: string
              isFavorite: boolean
              isHorizontal: boolean
              isPremium: boolean
              cardTemplate?: {
                __typename: 'Card'
                id: string
                backPanelLocation: number
                panels: Array<{
                  __typename: 'Panel'
                  location: number
                  isFullBleed: boolean
                  isLocked: boolean
                  name: string
                  width: number
                  height: number
                  previewUrl: any
                  backgroundColor?: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  } | null
                  elements: Array<{
                    __typename: 'Element'
                    x: number
                    y: number
                    z: number
                    rotation: number
                    width: number
                    height: number
                    locked: boolean
                    draggable?: boolean | null
                    sticker?: {
                      __typename: 'ElementSticker'
                      scale: number
                      sticker?: {
                        __typename: 'EditorImageType'
                        id: string
                        url: string
                        width: number
                        height: number
                        thumbnailUrl: string
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                    } | null
                    image?: {
                      __typename: 'ElementPicture'
                      scale: number
                      image?: {
                        __typename: 'Image'
                        id: string
                        url: any
                        width: number
                        height: number
                        smallThumb: any
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                      filter?: { __typename?: 'Filter'; name: string } | null
                    } | null
                    text?: {
                      __typename?: 'ElementText'
                      paragraphs: Array<{
                        __typename: 'Paragraph'
                        alignment: TextAlignment
                        textFragments: Array<{
                          __typename: 'TextFragment'
                          text: string
                          fontSize: number
                          isReplacement: boolean
                          textColor: {
                            __typename: 'Color'
                            red: number
                            green: number
                            blue: number
                          }
                          font: {
                            __typename: 'Font'
                            id: string
                            fontName: string
                          }
                        }>
                      }>
                    } | null
                  }>
                }>
              } | null
              frontImage: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              }
              insideRightImage?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              } | null
              cost: {
                __typename?: 'ItemizedCost'
                total: Array<{
                  __typename: 'Price'
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
                entries: Array<{
                  __typename: 'LabeledPrice'
                  label: string
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
              }
            }>
            cardTemplate?: {
              __typename: 'Card'
              id: string
              backPanelLocation: number
              panels: Array<{
                __typename: 'Panel'
                location: number
                isFullBleed: boolean
                isLocked: boolean
                name: string
                width: number
                height: number
                previewUrl: any
                backgroundColor?: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                } | null
                elements: Array<{
                  __typename: 'Element'
                  x: number
                  y: number
                  z: number
                  rotation: number
                  width: number
                  height: number
                  locked: boolean
                  draggable?: boolean | null
                  sticker?: {
                    __typename: 'ElementSticker'
                    scale: number
                    sticker?: {
                      __typename: 'EditorImageType'
                      id: string
                      url: string
                      width: number
                      height: number
                      thumbnailUrl: string
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                  } | null
                  image?: {
                    __typename: 'ElementPicture'
                    scale: number
                    image?: {
                      __typename: 'Image'
                      id: string
                      url: any
                      width: number
                      height: number
                      smallThumb: any
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                    filter?: { __typename?: 'Filter'; name: string } | null
                  } | null
                  text?: {
                    __typename?: 'ElementText'
                    paragraphs: Array<{
                      __typename: 'Paragraph'
                      alignment: TextAlignment
                      textFragments: Array<{
                        __typename: 'TextFragment'
                        text: string
                        fontSize: number
                        isReplacement: boolean
                        textColor: {
                          __typename: 'Color'
                          red: number
                          green: number
                          blue: number
                        }
                        font: {
                          __typename: 'Font'
                          id: string
                          fontName: string
                        }
                      }>
                    }>
                  } | null
                }>
              }>
            } | null
            frontImage: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            }
            insideRightImage?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            } | null
            cost: {
              __typename?: 'ItemizedCost'
              total: Array<{
                __typename: 'Price'
                asString: string
                currency: CurrencyType
                amount: number
              }>
              entries: Array<{
                __typename: 'LabeledPrice'
                label: string
                asString: string
                currency: CurrencyType
                amount: number
              }>
            }
          } | null
          panels: Array<{
            __typename: 'Panel'
            location: number
            isFullBleed: boolean
            isLocked: boolean
            name: string
            width: number
            height: number
            previewUrl: any
            backgroundColor?: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            } | null
            elements: Array<{
              __typename: 'Element'
              x: number
              y: number
              z: number
              rotation: number
              width: number
              height: number
              locked: boolean
              draggable?: boolean | null
              sticker?: {
                __typename: 'ElementSticker'
                scale: number
                sticker?: {
                  __typename: 'EditorImageType'
                  id: string
                  url: string
                  width: number
                  height: number
                  thumbnailUrl: string
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
              } | null
              image?: {
                __typename: 'ElementPicture'
                scale: number
                image?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
                filter?: { __typename?: 'Filter'; name: string } | null
              } | null
              text?: {
                __typename?: 'ElementText'
                paragraphs: Array<{
                  __typename: 'Paragraph'
                  alignment: TextAlignment
                  textFragments: Array<{
                    __typename: 'TextFragment'
                    text: string
                    fontSize: number
                    isReplacement: boolean
                    textColor: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    }
                    font: { __typename: 'Font'; id: string; fontName: string }
                  }>
                }>
              } | null
            }>
          }>
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
          paperUpgradeCosts: Array<{
            __typename?: 'PaperCosts'
            value: string
            upgrade: string
          }>
          digitalCard?: {
            __typename?: 'DigitalCard'
            pk: string
            shareMessageTitle: string
            shareMessageBody: string
            tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
          } | null
        } | null
        giftVariation?: {
          __typename: 'GiftVariation'
          id: string
          isSendable: boolean
          isEligibleFreeGift: boolean
          name: string
          description?: string | null
          contents?: string | null
          shipping?: string | null
          imageUrls: Array<any>
          packaging?: string | null
          defaultImageUrl: any
          points: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          price: {
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }
        } | null
        sendDelay: {
          __typename: 'SendDelay'
          type: SendDelayType
          delayNumber?: number | null
          delayType: SendDelayDelayType
          timeType: SendDelayTimeType
          specificDate: any
        }
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
        baseCost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
      }>
      contacts: Array<{ __typename: 'Contact'; id: string }>
      groups: Array<{
        __typename: 'Group'
        id: string
        name: string
        members: Array<{ __typename: 'Contact'; id: string }>
      }>
      returnAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      campaign?: { __typename: 'Campaign'; id: string; name: string } | null
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
      warnings: Array<{
        __typename: 'Error'
        code?: number | null
        message?: string | null
      }>
      invalidContacts: Array<{ __typename: 'Contact'; id: string }>
      invalidSendDelayContacts: Array<{ __typename: 'Contact'; id: string }>
      bulkUpgradeShippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
    }
  }
}

export type UpdateProductionInfoMutationVariables = Exact<{
  input: UpdateProductionInfoInput
}>

export type UpdateProductionInfoMutation = {
  __typename?: 'Mutation'
  updateProductionInfo: {
    __typename?: 'UpdateProductionInfo'
    productionInfo: {
      __typename: 'ProductionInfo'
      id: string
      dateToSend?: any | null
      status: CardStatus
      hold: boolean
      fulfilled?: any | null
      rejectionReason?: string | null
      frontPreviewUrl: any
      rejectedCards: Array<{ __typename: 'RejectedCard'; id: string }>
      trackingInfo?: {
        __typename?: 'TrackingInfo'
        trackingNumber: string
        url?: any | null
      } | null
      recipient?: {
        __typename: 'Recipient'
        id: string
        cardQuantity: number
        paymentFailure?: { __typename?: 'PaymentFailure'; id: string } | null
        contact?: {
          __typename: 'Contact'
          id: string
          firstName: string
          lastName: string
        } | null
        address?: {
          __typename: 'Address'
          userVerified: boolean
          errorMessages: Array<string>
          firstName: string
          lastName: string
          company: string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string
          country: string
        } | null
        gift?: {
          __typename?: 'GiftVariation'
          name: string
          defaultImageUrl: any
        } | null
        card?: {
          __typename: 'Card'
          frontPreviewUrl: any
          isHorizontal: boolean
          id: string
          sendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
          } | null
        } | null
      } | null
    }
  }
}

export type UpdateRequestedContactMutationVariables = Exact<{
  token: Scalars['String']['input']
  contact: UpdateRequestedContactInput
}>

export type UpdateRequestedContactMutation = {
  __typename?: 'Mutation'
  updateRequestedContact: {
    __typename?: 'UpdateRequestedContact'
    contact: { __typename?: 'Contact'; id: string }
  }
}

export type UpdateUserLabelOverridesMutationVariables = Exact<{
  overrides: Array<UserLabelOverrideInput> | UserLabelOverrideInput
}>

export type UpdateUserLabelOverridesMutation = {
  __typename?: 'Mutation'
  updateUserLabelOverrides: {
    __typename?: 'UpdateUserLabelOverrides'
    overrides: Array<{
      __typename?: 'UserLabelOverride'
      label: OverridableUserLabel
      action: UserLabelOverrideAction
    }>
  }
}

export type UpgradeToGreenerStillAffiliateMutationVariables = Exact<{
  govId1?: InputMaybe<Scalars['String']['input']>
  govId2?: InputMaybe<Scalars['String']['input']>
}>

export type UpgradeToGreenerStillAffiliateMutation = {
  __typename?: 'Mutation'
  upgradeToGreenerStillAffiliate: {
    __typename?: 'UpgradeToGreenerStillAffiliate'
    error?: string | null
    account?: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    } | null
    subscription?: {
      __typename?: 'StripeSubscription'
      status: SubscriptionStatus
      currentPeriodEnd: any
    } | null
  }
}

export type UpgradeToAffiliateMutationVariables = Exact<{
  govId1: Scalars['String']['input']
  govId2?: InputMaybe<Scalars['String']['input']>
}>

export type UpgradeToAffiliateMutation = {
  __typename?: 'Mutation'
  upgradeToAffiliate: {
    __typename?: 'UpgradeToAffiliate'
    account?: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    } | null
  }
}

export type UpgradeToReferringCustomerMutationVariables = Exact<{
  [key: string]: never
}>

export type UpgradeToReferringCustomerMutation = {
  __typename?: 'Mutation'
  upgradeToReferringCustomer: {
    __typename?: 'UpgradeToReferringCustomer'
    account?: {
      __typename: 'Account'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
      profileImageUrl?: any | null
      isStaff: boolean
      phoneNumber: string
      isAffiliate: boolean
      isNewUser: boolean
      createdAt: any
      genealogyId: number
      stripeCustomerId?: string | null
      dailyHeartfeltSends: number
      hasUnlimitedHeartfeltSends: boolean
      fullAccessFeatures: Array<FullAccessFeatureType>
      loyaltyRewards: number
      groupSends: number
      heartfeltSends: number
      heartfeltSystemSends: number
      scheduledSends: number
      systemSends: number
      cardPackageSends: number
      pointsTransitionSends: number
      employeeSends: number
      cardSends: number
      giftSends: number
      pointPrice: number
      promptingProLoginUrl: any
      promptinguLoginUrl?: any | null
      streamMarketingLoginUrl?: any | null
      promptinguJwt: string
      token: string
      hasBundlePlan: boolean
      affiliateUrl: any
      couldQualifyForFirstFreeCard: boolean
      isQualifiedForFirstFreeCard: boolean
      isTrialAffiliate: boolean
      isEligibleForFreeGsAffiliateAccount: boolean
      isEmailVerified: boolean
      labels: Array<Label>
      type: UserType
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      giftShippingDiscount: number
      isExpenseReloadRequired: boolean
      isRep: boolean
      dateJoined: any
      hasFullAccessContactManager: boolean
      hasFullAccessFreeAffiliate: boolean
      shouldDisplayUpsale: boolean
      hasEverHadSamplePack: boolean
      activePlansAndAddons: Array<{
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      } | null>
      settings?: {
        __typename: 'UserSettings'
        remindersAdvanceNoticeDays: number
        remindersAdvanceNoticeDaysInternational: number
        paymentHold: boolean
        shouldReceivePromoEmails: boolean
        shouldReceiveNotificationEmails: boolean
        shouldReceiveReceiptEmails: boolean
        shouldReceiveReminderEmails: boolean
        shouldPrintRecipientCompany: boolean
        defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
      } | null
      shippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      sponsor?: {
        __typename: 'User'
        id: string
        firstName: string
        lastName: string
        email: string
        genealogyId: number
        storefront?: {
          __typename: 'Storefront'
          biography?: string | null
          slug?: string | null
          mugshot?: { __typename?: 'Image'; url: any } | null
        } | null
      } | null
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
      plan: {
        __typename: 'Plan'
        id: string
        title: string
        subtitle?: string | null
        stripeId: string
        description?: any | null
        isAddon: boolean
        cardCost: number
        bulkCardCost: number
        giftDiscount: number
        isExpenseReloadRequired: boolean
        color?: string | null
        backgroundStartColor: string
        backgroundEndColor: string
        icon: PlansPlanIconChoices
        price: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        relatedPackage?: {
          __typename?: 'RelatedPlanPackage'
          saleorProductId: string
          title: string
          description: string
          shouldShowByDefault: boolean
        } | null
      }
      stripeSource?:
        | {
            __typename: 'BankAccount'
            id: string
            bankName: string
            object: string
            last4: string
            accountHolderName: string
          }
        | {
            __typename: 'CreditCard'
            id: string
            brand: string
            last4: string
            expMonth: number
            expYear: number
            object: string
            billingAddress?: {
              __typename?: 'CardBillingAddress'
              city?: string | null
              country?: string | null
              line1?: string | null
              line2?: string | null
              postalcode?: string | null
              state?: string | null
            } | null
          }
        | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      } | null
      subscriptions: Array<{
        __typename: 'StripeSubscription'
        id: string
        name: string
        status: SubscriptionStatus
        startDate: any
        currentPeriodEnd: any
        interval: string
        intervalCount: number
        price: number
        isRenewable: boolean
        sku: string
        isSetToCancel: boolean
        features?: {
          __typename: 'SubscriptionFeatures'
          groupSends?: number | null
          scheduledSends?: number | null
          loyaltyRewards?: number | null
          systemSends?: number | null
          heartfeltSends?: number | null
          cardPackageSends?: number | null
        } | null
        metadata?: {
          __typename: 'SubscriptionMetadata'
          planOnCancel?: string | null
        } | null
      }>
      points: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expense: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      expenseReload?: {
        __typename: 'ExpenseReload'
        purchaseAmount: number
        threshold: number
        isEnabled: boolean
        isRequired: boolean
      } | null
      verifiedPhoneNumber?: {
        __typename: 'PhoneNumber'
        nationalNumber: any
      } | null
      promptingsCoach?: {
        __typename?: 'PromptingsCoach'
        trainerId?: number | null
        dateTrainingPurchased?: any | null
        dateTrainingCompleted?: any | null
        dateTrainingExpires?: any | null
        dateCoachingPurchased?: any | null
        dateAttendedCertification?: any | null
        isEmailPublic: boolean
        isPhoneNumberPublic: boolean
        isCertified: boolean
        trainerEmail?: string | null
      } | null
    } | null
  }
}

export type ValidateEmailVerificationCodeMutationVariables = Exact<{
  email: Scalars['String']['input']
  code: Scalars['String']['input']
}>

export type ValidateEmailVerificationCodeMutation = {
  __typename?: 'Mutation'
  validateEmailVerificationCode:
    | {
        __typename: 'ValidateEmailVerificationCodeFailure'
        failureType: ValidateEmailVerificationCodeFailureType
        message: string
        success: boolean
      }
    | {
        __typename: 'ValidateEmailVerificationCodeSuccess'
        success: boolean
        message: string
      }
}

export type VerifyPhoneNumberMutationVariables = Exact<{
  phoneNumber: PhoneNumberInput
  verificationCode: Scalars['String']['input']
  sponsorGenealogyIdOrSlug?: InputMaybe<Scalars['String']['input']>
  shouldNotCreateNewAccount?: InputMaybe<Scalars['Boolean']['input']>
  shouldReassignNumber?: InputMaybe<Scalars['Boolean']['input']>
  verticalId: VerticalId
  queryParams: Args
}>

export type VerifyPhoneNumberMutation = {
  __typename?: 'Mutation'
  verifyPhoneNumber:
    | {
        __typename: 'VerifyPhoneNumberFailure'
        failureType: VerifyPhoneNumberFailureType
        failureMessage: string
      }
    | {
        __typename: 'VerifyPhoneNumberSuccess'
        successType: VerifyPhoneNumberSuccessType
        verifiedAccount: {
          __typename: 'Account'
          id: string
          username: string
          firstName: string
          lastName: string
          email: string
          profileImageUrl?: any | null
          isStaff: boolean
          phoneNumber: string
          isAffiliate: boolean
          isNewUser: boolean
          createdAt: any
          genealogyId: number
          stripeCustomerId?: string | null
          dailyHeartfeltSends: number
          hasUnlimitedHeartfeltSends: boolean
          fullAccessFeatures: Array<FullAccessFeatureType>
          loyaltyRewards: number
          groupSends: number
          heartfeltSends: number
          heartfeltSystemSends: number
          scheduledSends: number
          systemSends: number
          cardPackageSends: number
          pointsTransitionSends: number
          employeeSends: number
          cardSends: number
          giftSends: number
          pointPrice: number
          promptingProLoginUrl: any
          promptinguLoginUrl?: any | null
          streamMarketingLoginUrl?: any | null
          promptinguJwt: string
          token: string
          hasBundlePlan: boolean
          affiliateUrl: any
          couldQualifyForFirstFreeCard: boolean
          isQualifiedForFirstFreeCard: boolean
          isTrialAffiliate: boolean
          isEligibleForFreeGsAffiliateAccount: boolean
          isEmailVerified: boolean
          labels: Array<Label>
          type: UserType
          cardCost: number
          bulkCardCost: number
          giftDiscount: number
          giftShippingDiscount: number
          isExpenseReloadRequired: boolean
          isRep: boolean
          dateJoined: any
          hasFullAccessContactManager: boolean
          hasFullAccessFreeAffiliate: boolean
          shouldDisplayUpsale: boolean
          hasEverHadSamplePack: boolean
          activePlansAndAddons: Array<{
            __typename: 'Plan'
            id: string
            title: string
            subtitle?: string | null
            stripeId: string
            description?: any | null
            isAddon: boolean
            cardCost: number
            bulkCardCost: number
            giftDiscount: number
            isExpenseReloadRequired: boolean
            color?: string | null
            backgroundStartColor: string
            backgroundEndColor: string
            icon: PlansPlanIconChoices
            price: {
              __typename: 'Amount'
              amount: number
              currency: Currency
              description: string
            }
            relatedPackage?: {
              __typename?: 'RelatedPlanPackage'
              saleorProductId: string
              title: string
              description: string
              shouldShowByDefault: boolean
            } | null
          } | null>
          settings?: {
            __typename: 'UserSettings'
            remindersAdvanceNoticeDays: number
            remindersAdvanceNoticeDaysInternational: number
            paymentHold: boolean
            shouldReceivePromoEmails: boolean
            shouldReceiveNotificationEmails: boolean
            shouldReceiveReceiptEmails: boolean
            shouldReceiveReminderEmails: boolean
            shouldPrintRecipientCompany: boolean
            defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
          } | null
          shippingAddress?: {
            __typename: 'Address'
            firstName: string
            lastName: string
            company: string
            address1: string
            address2: string
            city: string
            state: string
            postalCode: string
            country: string
          } | null
          sponsor?: {
            __typename: 'User'
            id: string
            firstName: string
            lastName: string
            email: string
            genealogyId: number
            storefront?: {
              __typename: 'Storefront'
              biography?: string | null
              slug?: string | null
              mugshot?: { __typename?: 'Image'; url: any } | null
            } | null
          } | null
          storefront?: {
            __typename: 'Storefront'
            biography?: string | null
            slug?: string | null
            mugshot?: { __typename?: 'Image'; url: any } | null
          } | null
          plan: {
            __typename: 'Plan'
            id: string
            title: string
            subtitle?: string | null
            stripeId: string
            description?: any | null
            isAddon: boolean
            cardCost: number
            bulkCardCost: number
            giftDiscount: number
            isExpenseReloadRequired: boolean
            color?: string | null
            backgroundStartColor: string
            backgroundEndColor: string
            icon: PlansPlanIconChoices
            price: {
              __typename: 'Amount'
              amount: number
              currency: Currency
              description: string
            }
            relatedPackage?: {
              __typename?: 'RelatedPlanPackage'
              saleorProductId: string
              title: string
              description: string
              shouldShowByDefault: boolean
            } | null
          }
          stripeSource?:
            | {
                __typename: 'BankAccount'
                id: string
                bankName: string
                object: string
                last4: string
                accountHolderName: string
              }
            | {
                __typename: 'CreditCard'
                id: string
                brand: string
                last4: string
                expMonth: number
                expYear: number
                object: string
                billingAddress?: {
                  __typename?: 'CardBillingAddress'
                  city?: string | null
                  country?: string | null
                  line1?: string | null
                  line2?: string | null
                  postalcode?: string | null
                  state?: string | null
                } | null
              }
            | null
          stripeSubscription?: {
            __typename: 'StripeSubscription'
            id: string
            name: string
            status: SubscriptionStatus
            startDate: any
            currentPeriodEnd: any
            interval: string
            intervalCount: number
            price: number
            isRenewable: boolean
            sku: string
            isSetToCancel: boolean
            features?: {
              __typename: 'SubscriptionFeatures'
              groupSends?: number | null
              scheduledSends?: number | null
              loyaltyRewards?: number | null
              systemSends?: number | null
              heartfeltSends?: number | null
              cardPackageSends?: number | null
            } | null
            metadata?: {
              __typename: 'SubscriptionMetadata'
              planOnCancel?: string | null
            } | null
          } | null
          subscriptions: Array<{
            __typename: 'StripeSubscription'
            id: string
            name: string
            status: SubscriptionStatus
            startDate: any
            currentPeriodEnd: any
            interval: string
            intervalCount: number
            price: number
            isRenewable: boolean
            sku: string
            isSetToCancel: boolean
            features?: {
              __typename: 'SubscriptionFeatures'
              groupSends?: number | null
              scheduledSends?: number | null
              loyaltyRewards?: number | null
              systemSends?: number | null
              heartfeltSends?: number | null
              cardPackageSends?: number | null
            } | null
            metadata?: {
              __typename: 'SubscriptionMetadata'
              planOnCancel?: string | null
            } | null
          }>
          points: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          expense: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          expenseReload?: {
            __typename: 'ExpenseReload'
            purchaseAmount: number
            threshold: number
            isEnabled: boolean
            isRequired: boolean
          } | null
          verifiedPhoneNumber?: {
            __typename: 'PhoneNumber'
            nationalNumber: any
          } | null
          promptingsCoach?: {
            __typename?: 'PromptingsCoach'
            trainerId?: number | null
            dateTrainingPurchased?: any | null
            dateTrainingCompleted?: any | null
            dateTrainingExpires?: any | null
            dateCoachingPurchased?: any | null
            dateAttendedCertification?: any | null
            isEmailPublic: boolean
            isPhoneNumberPublic: boolean
            isCertified: boolean
            trainerEmail?: string | null
          } | null
        }
      }
  createUserLoginLink: {
    __typename?: 'CreateUserLoginLink'
    link?: string | null
  }
}

export type GetAccountQueryVariables = Exact<{ [key: string]: never }>

export type GetAccountQuery = {
  __typename?: 'Query'
  account: {
    __typename: 'Account'
    id: string
    username: string
    firstName: string
    lastName: string
    email: string
    profileImageUrl?: any | null
    isStaff: boolean
    phoneNumber: string
    isAffiliate: boolean
    isNewUser: boolean
    createdAt: any
    genealogyId: number
    stripeCustomerId?: string | null
    dailyHeartfeltSends: number
    hasUnlimitedHeartfeltSends: boolean
    fullAccessFeatures: Array<FullAccessFeatureType>
    loyaltyRewards: number
    groupSends: number
    heartfeltSends: number
    heartfeltSystemSends: number
    scheduledSends: number
    systemSends: number
    cardPackageSends: number
    pointsTransitionSends: number
    employeeSends: number
    cardSends: number
    giftSends: number
    pointPrice: number
    promptingProLoginUrl: any
    promptinguLoginUrl?: any | null
    streamMarketingLoginUrl?: any | null
    promptinguJwt: string
    token: string
    hasBundlePlan: boolean
    affiliateUrl: any
    couldQualifyForFirstFreeCard: boolean
    isQualifiedForFirstFreeCard: boolean
    isTrialAffiliate: boolean
    isEligibleForFreeGsAffiliateAccount: boolean
    isEmailVerified: boolean
    labels: Array<Label>
    type: UserType
    cardCost: number
    bulkCardCost: number
    giftDiscount: number
    giftShippingDiscount: number
    isExpenseReloadRequired: boolean
    isRep: boolean
    dateJoined: any
    hasFullAccessContactManager: boolean
    hasFullAccessFreeAffiliate: boolean
    shouldDisplayUpsale: boolean
    hasEverHadSamplePack: boolean
    activePlansAndAddons: Array<{
      __typename: 'Plan'
      id: string
      title: string
      subtitle?: string | null
      stripeId: string
      description?: any | null
      isAddon: boolean
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      isExpenseReloadRequired: boolean
      color?: string | null
      backgroundStartColor: string
      backgroundEndColor: string
      icon: PlansPlanIconChoices
      price: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      relatedPackage?: {
        __typename?: 'RelatedPlanPackage'
        saleorProductId: string
        title: string
        description: string
        shouldShowByDefault: boolean
      } | null
    } | null>
    settings?: {
      __typename: 'UserSettings'
      remindersAdvanceNoticeDays: number
      remindersAdvanceNoticeDaysInternational: number
      paymentHold: boolean
      shouldReceivePromoEmails: boolean
      shouldReceiveNotificationEmails: boolean
      shouldReceiveReceiptEmails: boolean
      shouldReceiveReminderEmails: boolean
      shouldPrintRecipientCompany: boolean
      defaultBackPanel?: { __typename?: 'Layout'; id: string } | null
    } | null
    shippingAddress?: {
      __typename: 'Address'
      firstName: string
      lastName: string
      company: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country: string
    } | null
    sponsor?: {
      __typename: 'User'
      id: string
      firstName: string
      lastName: string
      email: string
      genealogyId: number
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
    } | null
    storefront?: {
      __typename: 'Storefront'
      biography?: string | null
      slug?: string | null
      mugshot?: { __typename?: 'Image'; url: any } | null
    } | null
    plan: {
      __typename: 'Plan'
      id: string
      title: string
      subtitle?: string | null
      stripeId: string
      description?: any | null
      isAddon: boolean
      cardCost: number
      bulkCardCost: number
      giftDiscount: number
      isExpenseReloadRequired: boolean
      color?: string | null
      backgroundStartColor: string
      backgroundEndColor: string
      icon: PlansPlanIconChoices
      price: {
        __typename: 'Amount'
        amount: number
        currency: Currency
        description: string
      }
      relatedPackage?: {
        __typename?: 'RelatedPlanPackage'
        saleorProductId: string
        title: string
        description: string
        shouldShowByDefault: boolean
      } | null
    }
    stripeSource?:
      | {
          __typename: 'BankAccount'
          id: string
          bankName: string
          object: string
          last4: string
          accountHolderName: string
        }
      | {
          __typename: 'CreditCard'
          id: string
          brand: string
          last4: string
          expMonth: number
          expYear: number
          object: string
          billingAddress?: {
            __typename?: 'CardBillingAddress'
            city?: string | null
            country?: string | null
            line1?: string | null
            line2?: string | null
            postalcode?: string | null
            state?: string | null
          } | null
        }
      | null
    stripeSubscription?: {
      __typename: 'StripeSubscription'
      id: string
      name: string
      status: SubscriptionStatus
      startDate: any
      currentPeriodEnd: any
      interval: string
      intervalCount: number
      price: number
      isRenewable: boolean
      sku: string
      isSetToCancel: boolean
      features?: {
        __typename: 'SubscriptionFeatures'
        groupSends?: number | null
        scheduledSends?: number | null
        loyaltyRewards?: number | null
        systemSends?: number | null
        heartfeltSends?: number | null
        cardPackageSends?: number | null
      } | null
      metadata?: {
        __typename: 'SubscriptionMetadata'
        planOnCancel?: string | null
      } | null
    } | null
    subscriptions: Array<{
      __typename: 'StripeSubscription'
      id: string
      name: string
      status: SubscriptionStatus
      startDate: any
      currentPeriodEnd: any
      interval: string
      intervalCount: number
      price: number
      isRenewable: boolean
      sku: string
      isSetToCancel: boolean
      features?: {
        __typename: 'SubscriptionFeatures'
        groupSends?: number | null
        scheduledSends?: number | null
        loyaltyRewards?: number | null
        systemSends?: number | null
        heartfeltSends?: number | null
        cardPackageSends?: number | null
      } | null
      metadata?: {
        __typename: 'SubscriptionMetadata'
        planOnCancel?: string | null
      } | null
    }>
    points: {
      __typename: 'Amount'
      amount: number
      currency: Currency
      description: string
    }
    expense: {
      __typename: 'Amount'
      amount: number
      currency: Currency
      description: string
    }
    expenseReload?: {
      __typename: 'ExpenseReload'
      purchaseAmount: number
      threshold: number
      isEnabled: boolean
      isRequired: boolean
    } | null
    verifiedPhoneNumber?: {
      __typename: 'PhoneNumber'
      nationalNumber: any
    } | null
    promptingsCoach?: {
      __typename?: 'PromptingsCoach'
      trainerId?: number | null
      dateTrainingPurchased?: any | null
      dateTrainingCompleted?: any | null
      dateTrainingExpires?: any | null
      dateCoachingPurchased?: any | null
      dateAttendedCertification?: any | null
      isEmailPublic: boolean
      isPhoneNumberPublic: boolean
      isCertified: boolean
      trainerEmail?: string | null
    } | null
  }
}

export type GetAccountCreatedContentsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAccountCreatedContentsQuery = {
  __typename?: 'Query'
  accountCreatedTitle: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  accountCreatedBody: {
    __typename: 'MarketingMultiParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
}

export type GetAddPaymentSettingsDailyCapQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAddPaymentSettingsDailyCapQuery = {
  __typename?: 'Query'
  account: {
    __typename?: 'Account'
    addPaymentSettingsDailyCap: {
      __typename?: 'AddPaymentSettingsDailyCap'
      available: number
      cap: number
    }
  }
}

export type GetAffiliateCountriesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAffiliateCountriesQuery = {
  __typename?: 'Query'
  affiliateCountries: Array<{
    __typename: 'Country'
    postalName: string
    alpha2: string
    subdivisions: Array<{
      __typename: 'Subdivision'
      abbreviationOrName: string
    }>
  }>
}

export type GetAffiliateJoinMarketingContentQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAffiliateJoinMarketingContentQuery = {
  __typename?: 'Query'
  priceCost: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  priceCostFreeAffiliate: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  agreement: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  agreementFreeAffiliate: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
}

export type GetAlertsQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
  notificationFilters?: InputMaybe<NotificationFilters>
  reminderFilters?: InputMaybe<ReminderFilters>
}>

export type GetAlertsQuery = {
  __typename?: 'Query'
  paginatedNotifications: {
    __typename?: 'PaginatedNotifications'
    totalCount?: number | null
    hasMore?: boolean | null
    results: Array<{
      __typename: 'Notification'
      id: string
      message?: string | null
      createdAt: any
      acceptText?: string | null
      denyText?: string | null
      link?: string | null
      linkText?: string | null
      data?: any | null
      type?: NotificationType | null
    }>
  }
  paginatedReminders: {
    __typename?: 'PaginatedReminders'
    totalCount?: number | null
    hasMore?: boolean | null
    results: Array<{
      __typename: 'Reminder'
      id: string
      type: ReminderType
      newType: NewReminderType
      message?: string | null
      title: string
      eventDate?: any | null
      campaign?: { __typename?: 'Campaign'; id: string; name: string } | null
      contact: {
        __typename?: 'Contact'
        id: string
        firstName: string
        lastName: string
        country?: string | null
        birthday?: {
          __typename?: 'OptionalYearDate'
          day: number
          month: number
        } | null
        anniversary?: {
          __typename?: 'OptionalYearDate'
          day: number
          month: number
        } | null
      }
      order?: {
        __typename: 'Order'
        id: string
        isSendable: boolean
        createdAt: any
        isSent: boolean
        sendType: CardSendType
        isBulk: boolean
        bulkUpgradeCardQty?: number | null
        lines: Array<{
          __typename: 'Line'
          recipientCount: number
          card?: {
            __typename: 'Card'
            id: string
            type: CardType
            paperType: CardPaperType
            isHorizontal: boolean
            isNewEditorCard: boolean
            backPanelLocation: number
            frontPreviewUrl: any
            insidePreviewUrl?: any | null
            flapPreviewUrl?: any | null
            backPreviewUrl: any
            showInPicturePlus: boolean
            tinyThumb: any
            detailedSendableCard?: {
              __typename: 'SendableCard'
              id: string
              title: string
              description: string
              variationColor: string
              isFavorite: boolean
              isHorizontal: boolean
              isPremium: boolean
              variations: Array<{
                __typename: 'SendableCard'
                id: string
                title: string
                description: string
                variationColor: string
                isFavorite: boolean
                isHorizontal: boolean
                isPremium: boolean
                cardTemplate?: {
                  __typename: 'Card'
                  id: string
                  backPanelLocation: number
                  panels: Array<{
                    __typename: 'Panel'
                    location: number
                    isFullBleed: boolean
                    isLocked: boolean
                    name: string
                    width: number
                    height: number
                    previewUrl: any
                    backgroundColor?: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    } | null
                    elements: Array<{
                      __typename: 'Element'
                      x: number
                      y: number
                      z: number
                      rotation: number
                      width: number
                      height: number
                      locked: boolean
                      draggable?: boolean | null
                      sticker?: {
                        __typename: 'ElementSticker'
                        scale: number
                        sticker?: {
                          __typename: 'EditorImageType'
                          id: string
                          url: string
                          width: number
                          height: number
                          thumbnailUrl: string
                        } | null
                        position: {
                          __typename?: 'Position'
                          x: number
                          y: number
                        }
                      } | null
                      image?: {
                        __typename: 'ElementPicture'
                        scale: number
                        image?: {
                          __typename: 'Image'
                          id: string
                          url: any
                          width: number
                          height: number
                          smallThumb: any
                        } | null
                        position: {
                          __typename?: 'Position'
                          x: number
                          y: number
                        }
                        filter?: { __typename?: 'Filter'; name: string } | null
                      } | null
                      text?: {
                        __typename?: 'ElementText'
                        paragraphs: Array<{
                          __typename: 'Paragraph'
                          alignment: TextAlignment
                          textFragments: Array<{
                            __typename: 'TextFragment'
                            text: string
                            fontSize: number
                            isReplacement: boolean
                            textColor: {
                              __typename: 'Color'
                              red: number
                              green: number
                              blue: number
                            }
                            font: {
                              __typename: 'Font'
                              id: string
                              fontName: string
                            }
                          }>
                        }>
                      } | null
                    }>
                  }>
                } | null
                frontImage: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                  mediumThumb: any
                }
                insideRightImage?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                  mediumThumb: any
                } | null
                cost: {
                  __typename?: 'ItemizedCost'
                  total: Array<{
                    __typename: 'Price'
                    asString: string
                    currency: CurrencyType
                    amount: number
                  }>
                  entries: Array<{
                    __typename: 'LabeledPrice'
                    label: string
                    asString: string
                    currency: CurrencyType
                    amount: number
                  }>
                }
              }>
              cardTemplate?: {
                __typename: 'Card'
                id: string
                backPanelLocation: number
                panels: Array<{
                  __typename: 'Panel'
                  location: number
                  isFullBleed: boolean
                  isLocked: boolean
                  name: string
                  width: number
                  height: number
                  previewUrl: any
                  backgroundColor?: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  } | null
                  elements: Array<{
                    __typename: 'Element'
                    x: number
                    y: number
                    z: number
                    rotation: number
                    width: number
                    height: number
                    locked: boolean
                    draggable?: boolean | null
                    sticker?: {
                      __typename: 'ElementSticker'
                      scale: number
                      sticker?: {
                        __typename: 'EditorImageType'
                        id: string
                        url: string
                        width: number
                        height: number
                        thumbnailUrl: string
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                    } | null
                    image?: {
                      __typename: 'ElementPicture'
                      scale: number
                      image?: {
                        __typename: 'Image'
                        id: string
                        url: any
                        width: number
                        height: number
                        smallThumb: any
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                      filter?: { __typename?: 'Filter'; name: string } | null
                    } | null
                    text?: {
                      __typename?: 'ElementText'
                      paragraphs: Array<{
                        __typename: 'Paragraph'
                        alignment: TextAlignment
                        textFragments: Array<{
                          __typename: 'TextFragment'
                          text: string
                          fontSize: number
                          isReplacement: boolean
                          textColor: {
                            __typename: 'Color'
                            red: number
                            green: number
                            blue: number
                          }
                          font: {
                            __typename: 'Font'
                            id: string
                            fontName: string
                          }
                        }>
                      }>
                    } | null
                  }>
                }>
              } | null
              frontImage: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              }
              insideRightImage?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              } | null
              cost: {
                __typename?: 'ItemizedCost'
                total: Array<{
                  __typename: 'Price'
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
                entries: Array<{
                  __typename: 'LabeledPrice'
                  label: string
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
              }
            } | null
            panels: Array<{
              __typename: 'Panel'
              location: number
              isFullBleed: boolean
              isLocked: boolean
              name: string
              width: number
              height: number
              previewUrl: any
              backgroundColor?: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              } | null
              elements: Array<{
                __typename: 'Element'
                x: number
                y: number
                z: number
                rotation: number
                width: number
                height: number
                locked: boolean
                draggable?: boolean | null
                sticker?: {
                  __typename: 'ElementSticker'
                  scale: number
                  sticker?: {
                    __typename: 'EditorImageType'
                    id: string
                    url: string
                    width: number
                    height: number
                    thumbnailUrl: string
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                } | null
                image?: {
                  __typename: 'ElementPicture'
                  scale: number
                  image?: {
                    __typename: 'Image'
                    id: string
                    url: any
                    width: number
                    height: number
                    smallThumb: any
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                  filter?: { __typename?: 'Filter'; name: string } | null
                } | null
                text?: {
                  __typename?: 'ElementText'
                  paragraphs: Array<{
                    __typename: 'Paragraph'
                    alignment: TextAlignment
                    textFragments: Array<{
                      __typename: 'TextFragment'
                      text: string
                      fontSize: number
                      isReplacement: boolean
                      textColor: {
                        __typename: 'Color'
                        red: number
                        green: number
                        blue: number
                      }
                      font: { __typename: 'Font'; id: string; fontName: string }
                    }>
                  }>
                } | null
              }>
            }>
            cost: {
              __typename?: 'ItemizedCost'
              total: Array<{
                __typename: 'Price'
                asString: string
                currency: CurrencyType
                amount: number
              }>
              entries: Array<{
                __typename: 'LabeledPrice'
                label: string
                asString: string
                currency: CurrencyType
                amount: number
              }>
            }
            paperUpgradeCosts: Array<{
              __typename?: 'PaperCosts'
              value: string
              upgrade: string
            }>
            digitalCard?: {
              __typename?: 'DigitalCard'
              pk: string
              shareMessageTitle: string
              shareMessageBody: string
              tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
            } | null
          } | null
          giftVariation?: {
            __typename: 'GiftVariation'
            id: string
            isSendable: boolean
            isEligibleFreeGift: boolean
            name: string
            description?: string | null
            contents?: string | null
            shipping?: string | null
            imageUrls: Array<any>
            packaging?: string | null
            defaultImageUrl: any
            points: {
              __typename: 'Amount'
              amount: number
              currency: Currency
              description: string
            }
            price: {
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }
          } | null
          sendDelay: {
            __typename: 'SendDelay'
            type: SendDelayType
            delayNumber?: number | null
            delayType: SendDelayDelayType
            timeType: SendDelayTimeType
            specificDate: any
          }
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
          baseCost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
        }>
        contacts: Array<{ __typename: 'Contact'; id: string }>
        groups: Array<{
          __typename: 'Group'
          id: string
          name: string
          members: Array<{ __typename: 'Contact'; id: string }>
        }>
        returnAddress?: {
          __typename: 'Address'
          firstName: string
          lastName: string
          company: string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string
          country: string
        } | null
        campaign?: { __typename: 'Campaign'; id: string; name: string } | null
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
        warnings: Array<{
          __typename: 'Error'
          code?: number | null
          message?: string | null
        }>
        invalidContacts: Array<{ __typename: 'Contact'; id: string }>
        invalidSendDelayContacts: Array<{ __typename: 'Contact'; id: string }>
        bulkUpgradeShippingAddress?: {
          __typename: 'Address'
          firstName: string
          lastName: string
          company: string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string
          country: string
        } | null
      } | null
    }>
  }
  paginatedContactShares: {
    __typename?: 'PaginatedContactShares'
    totalCount?: number | null
    hasMore?: boolean | null
    results: Array<{
      __typename: 'ContactShare'
      id: string
      createdAt: any
      contact: {
        __typename: 'Contact'
        id: string
        firstName: string
        lastName: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country?: string | null
        isPrimary: boolean
        company: string
        birthday?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
        anniversary?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
      }
      fromUser: {
        __typename: 'User'
        id: string
        username: string
        firstName: string
        lastName: string
        email: string
      }
    }>
  }
  paginatedCampaignShares: {
    __typename?: 'PaginatedCampaignShares'
    totalCount?: number | null
    hasMore?: boolean | null
    results: Array<{
      __typename: 'CampaignShare'
      id: string
      fromName: string
      dateShared: any
      campaign: { __typename: 'Campaign'; id: string; name: string }
    }>
  }
}

export type GetAvailableDailyHeartfeltQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAvailableDailyHeartfeltQuery = {
  __typename?: 'Query'
  account: {
    __typename: 'Account'
    hasDailyHeartfeltSends: boolean
    dailyHeartfeltCap: {
      __typename?: 'DailyHeartfeltCap'
      available: number
      cap: number
    }
  }
}

export type GetAvailableUnlimitedDailyHeartfeltQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAvailableUnlimitedDailyHeartfeltQuery = {
  __typename?: 'Query'
  account: {
    __typename: 'Account'
    hasUnlimitedHeartfeltSends: boolean
    heartfeltDailyCap: {
      __typename?: 'HeartfeltDailyCap'
      available: number
      cap: number
    }
  }
}

export type GetByDesignLoginLinkQueryVariables = Exact<{ [key: string]: never }>

export type GetByDesignLoginLinkQuery = {
  __typename?: 'Query'
  bydesignLoginLink: string
}

export type GetCampaignQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetCampaignQuery = {
  __typename?: 'Query'
  campaign: {
    __typename: 'Campaign'
    id: string
    name: string
    isShareable: boolean
    lines: Array<{
      __typename: 'CampaignLine'
      name?: string | null
      card?: {
        __typename: 'Card'
        id: string
        type: CardType
        isHorizontal: boolean
        isNewEditorCard: boolean
        paperType: CardPaperType
        frontPreviewUrl: any
        insidePreviewUrl?: any | null
        flapPreviewUrl?: any | null
        backPreviewUrl: any
        tinyThumb: any
        sendableCard?: {
          __typename: 'SendableCard'
          id: string
          title: string
        } | null
        minimalPanels: Array<{
          __typename: 'Panel'
          name: string
          previewUrl: any
          isFullBleed: boolean
          width: number
          height: number
        }>
      } | null
      giftVariation?: {
        __typename: 'GiftVariation'
        id: string
        isSendable: boolean
        isEligibleFreeGift: boolean
        name: string
        description?: string | null
        contents?: string | null
        shipping?: string | null
        imageUrls: Array<any>
        packaging?: string | null
        defaultImageUrl: any
        points: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        price: {
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }
      } | null
      sendDelay: {
        __typename: 'SendDelay'
        type: SendDelayType
        delayNumber?: number | null
        delayType: SendDelayDelayType
        timeType: SendDelayTimeType
        specificDate: any
      }
    }>
  }
}

export type GetCampaignOrdersQueryVariables = Exact<{
  id: Scalars['ID']['input']
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
}>

export type GetCampaignOrdersQuery = {
  __typename?: 'Query'
  campaign: {
    __typename?: 'Campaign'
    orders: {
      __typename?: 'PaginatedCampaignOrders'
      count: number
      results: Array<{
        __typename: 'Order'
        id: string
        isSendable: boolean
        createdAt: any
        isSent: boolean
        sendType: CardSendType
        isBulk: boolean
        bulkUpgradeCardQty?: number | null
        lines: Array<{
          __typename: 'Line'
          recipientCount: number
          card?: {
            __typename: 'Card'
            id: string
            type: CardType
            paperType: CardPaperType
            isHorizontal: boolean
            isNewEditorCard: boolean
            backPanelLocation: number
            frontPreviewUrl: any
            insidePreviewUrl?: any | null
            flapPreviewUrl?: any | null
            backPreviewUrl: any
            showInPicturePlus: boolean
            tinyThumb: any
            detailedSendableCard?: {
              __typename: 'SendableCard'
              id: string
              title: string
              description: string
              variationColor: string
              isFavorite: boolean
              isHorizontal: boolean
              isPremium: boolean
              variations: Array<{
                __typename: 'SendableCard'
                id: string
                title: string
                description: string
                variationColor: string
                isFavorite: boolean
                isHorizontal: boolean
                isPremium: boolean
                cardTemplate?: {
                  __typename: 'Card'
                  id: string
                  backPanelLocation: number
                  panels: Array<{
                    __typename: 'Panel'
                    location: number
                    isFullBleed: boolean
                    isLocked: boolean
                    name: string
                    width: number
                    height: number
                    previewUrl: any
                    backgroundColor?: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    } | null
                    elements: Array<{
                      __typename: 'Element'
                      x: number
                      y: number
                      z: number
                      rotation: number
                      width: number
                      height: number
                      locked: boolean
                      draggable?: boolean | null
                      sticker?: {
                        __typename: 'ElementSticker'
                        scale: number
                        sticker?: {
                          __typename: 'EditorImageType'
                          id: string
                          url: string
                          width: number
                          height: number
                          thumbnailUrl: string
                        } | null
                        position: {
                          __typename?: 'Position'
                          x: number
                          y: number
                        }
                      } | null
                      image?: {
                        __typename: 'ElementPicture'
                        scale: number
                        image?: {
                          __typename: 'Image'
                          id: string
                          url: any
                          width: number
                          height: number
                          smallThumb: any
                        } | null
                        position: {
                          __typename?: 'Position'
                          x: number
                          y: number
                        }
                        filter?: { __typename?: 'Filter'; name: string } | null
                      } | null
                      text?: {
                        __typename?: 'ElementText'
                        paragraphs: Array<{
                          __typename: 'Paragraph'
                          alignment: TextAlignment
                          textFragments: Array<{
                            __typename: 'TextFragment'
                            text: string
                            fontSize: number
                            isReplacement: boolean
                            textColor: {
                              __typename: 'Color'
                              red: number
                              green: number
                              blue: number
                            }
                            font: {
                              __typename: 'Font'
                              id: string
                              fontName: string
                            }
                          }>
                        }>
                      } | null
                    }>
                  }>
                } | null
                frontImage: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                  mediumThumb: any
                }
                insideRightImage?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                  mediumThumb: any
                } | null
                cost: {
                  __typename?: 'ItemizedCost'
                  total: Array<{
                    __typename: 'Price'
                    asString: string
                    currency: CurrencyType
                    amount: number
                  }>
                  entries: Array<{
                    __typename: 'LabeledPrice'
                    label: string
                    asString: string
                    currency: CurrencyType
                    amount: number
                  }>
                }
              }>
              cardTemplate?: {
                __typename: 'Card'
                id: string
                backPanelLocation: number
                panels: Array<{
                  __typename: 'Panel'
                  location: number
                  isFullBleed: boolean
                  isLocked: boolean
                  name: string
                  width: number
                  height: number
                  previewUrl: any
                  backgroundColor?: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  } | null
                  elements: Array<{
                    __typename: 'Element'
                    x: number
                    y: number
                    z: number
                    rotation: number
                    width: number
                    height: number
                    locked: boolean
                    draggable?: boolean | null
                    sticker?: {
                      __typename: 'ElementSticker'
                      scale: number
                      sticker?: {
                        __typename: 'EditorImageType'
                        id: string
                        url: string
                        width: number
                        height: number
                        thumbnailUrl: string
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                    } | null
                    image?: {
                      __typename: 'ElementPicture'
                      scale: number
                      image?: {
                        __typename: 'Image'
                        id: string
                        url: any
                        width: number
                        height: number
                        smallThumb: any
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                      filter?: { __typename?: 'Filter'; name: string } | null
                    } | null
                    text?: {
                      __typename?: 'ElementText'
                      paragraphs: Array<{
                        __typename: 'Paragraph'
                        alignment: TextAlignment
                        textFragments: Array<{
                          __typename: 'TextFragment'
                          text: string
                          fontSize: number
                          isReplacement: boolean
                          textColor: {
                            __typename: 'Color'
                            red: number
                            green: number
                            blue: number
                          }
                          font: {
                            __typename: 'Font'
                            id: string
                            fontName: string
                          }
                        }>
                      }>
                    } | null
                  }>
                }>
              } | null
              frontImage: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              }
              insideRightImage?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              } | null
              cost: {
                __typename?: 'ItemizedCost'
                total: Array<{
                  __typename: 'Price'
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
                entries: Array<{
                  __typename: 'LabeledPrice'
                  label: string
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
              }
            } | null
            panels: Array<{
              __typename: 'Panel'
              location: number
              isFullBleed: boolean
              isLocked: boolean
              name: string
              width: number
              height: number
              previewUrl: any
              backgroundColor?: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              } | null
              elements: Array<{
                __typename: 'Element'
                x: number
                y: number
                z: number
                rotation: number
                width: number
                height: number
                locked: boolean
                draggable?: boolean | null
                sticker?: {
                  __typename: 'ElementSticker'
                  scale: number
                  sticker?: {
                    __typename: 'EditorImageType'
                    id: string
                    url: string
                    width: number
                    height: number
                    thumbnailUrl: string
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                } | null
                image?: {
                  __typename: 'ElementPicture'
                  scale: number
                  image?: {
                    __typename: 'Image'
                    id: string
                    url: any
                    width: number
                    height: number
                    smallThumb: any
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                  filter?: { __typename?: 'Filter'; name: string } | null
                } | null
                text?: {
                  __typename?: 'ElementText'
                  paragraphs: Array<{
                    __typename: 'Paragraph'
                    alignment: TextAlignment
                    textFragments: Array<{
                      __typename: 'TextFragment'
                      text: string
                      fontSize: number
                      isReplacement: boolean
                      textColor: {
                        __typename: 'Color'
                        red: number
                        green: number
                        blue: number
                      }
                      font: { __typename: 'Font'; id: string; fontName: string }
                    }>
                  }>
                } | null
              }>
            }>
            cost: {
              __typename?: 'ItemizedCost'
              total: Array<{
                __typename: 'Price'
                asString: string
                currency: CurrencyType
                amount: number
              }>
              entries: Array<{
                __typename: 'LabeledPrice'
                label: string
                asString: string
                currency: CurrencyType
                amount: number
              }>
            }
            paperUpgradeCosts: Array<{
              __typename?: 'PaperCosts'
              value: string
              upgrade: string
            }>
            digitalCard?: {
              __typename?: 'DigitalCard'
              pk: string
              shareMessageTitle: string
              shareMessageBody: string
              tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
            } | null
          } | null
          giftVariation?: {
            __typename: 'GiftVariation'
            id: string
            isSendable: boolean
            isEligibleFreeGift: boolean
            name: string
            description?: string | null
            contents?: string | null
            shipping?: string | null
            imageUrls: Array<any>
            packaging?: string | null
            defaultImageUrl: any
            points: {
              __typename: 'Amount'
              amount: number
              currency: Currency
              description: string
            }
            price: {
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }
          } | null
          sendDelay: {
            __typename: 'SendDelay'
            type: SendDelayType
            delayNumber?: number | null
            delayType: SendDelayDelayType
            timeType: SendDelayTimeType
            specificDate: any
          }
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
          baseCost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
        }>
        contacts: Array<{ __typename: 'Contact'; id: string }>
        groups: Array<{
          __typename: 'Group'
          id: string
          name: string
          members: Array<{ __typename: 'Contact'; id: string }>
        }>
        returnAddress?: {
          __typename: 'Address'
          firstName: string
          lastName: string
          company: string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string
          country: string
        } | null
        campaign?: { __typename: 'Campaign'; id: string; name: string } | null
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
        warnings: Array<{
          __typename: 'Error'
          code?: number | null
          message?: string | null
        }>
        invalidContacts: Array<{ __typename: 'Contact'; id: string }>
        invalidSendDelayContacts: Array<{ __typename: 'Contact'; id: string }>
        bulkUpgradeShippingAddress?: {
          __typename: 'Address'
          firstName: string
          lastName: string
          company: string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string
          country: string
        } | null
      }>
    }
  }
}

export type GetCardQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetCardQuery = {
  __typename?: 'Query'
  card: {
    __typename: 'Card'
    id: string
    type: CardType
    paperType: CardPaperType
    isHorizontal: boolean
    isNewEditorCard: boolean
    backPanelLocation: number
    frontPreviewUrl: any
    insidePreviewUrl?: any | null
    flapPreviewUrl?: any | null
    backPreviewUrl: any
    showInPicturePlus: boolean
    tinyThumb: any
    detailedSendableCard?: {
      __typename: 'SendableCard'
      id: string
      title: string
      description: string
      variationColor: string
      isFavorite: boolean
      isHorizontal: boolean
      isPremium: boolean
      variations: Array<{
        __typename: 'SendableCard'
        id: string
        title: string
        description: string
        variationColor: string
        isFavorite: boolean
        isHorizontal: boolean
        isPremium: boolean
        cardTemplate?: {
          __typename: 'Card'
          id: string
          backPanelLocation: number
          panels: Array<{
            __typename: 'Panel'
            location: number
            isFullBleed: boolean
            isLocked: boolean
            name: string
            width: number
            height: number
            previewUrl: any
            backgroundColor?: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            } | null
            elements: Array<{
              __typename: 'Element'
              x: number
              y: number
              z: number
              rotation: number
              width: number
              height: number
              locked: boolean
              draggable?: boolean | null
              sticker?: {
                __typename: 'ElementSticker'
                scale: number
                sticker?: {
                  __typename: 'EditorImageType'
                  id: string
                  url: string
                  width: number
                  height: number
                  thumbnailUrl: string
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
              } | null
              image?: {
                __typename: 'ElementPicture'
                scale: number
                image?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
                filter?: { __typename?: 'Filter'; name: string } | null
              } | null
              text?: {
                __typename?: 'ElementText'
                paragraphs: Array<{
                  __typename: 'Paragraph'
                  alignment: TextAlignment
                  textFragments: Array<{
                    __typename: 'TextFragment'
                    text: string
                    fontSize: number
                    isReplacement: boolean
                    textColor: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    }
                    font: { __typename: 'Font'; id: string; fontName: string }
                  }>
                }>
              } | null
            }>
          }>
        } | null
        frontImage: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        }
        insideRightImage?: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        } | null
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
      }>
      cardTemplate?: {
        __typename: 'Card'
        id: string
        backPanelLocation: number
        panels: Array<{
          __typename: 'Panel'
          location: number
          isFullBleed: boolean
          isLocked: boolean
          name: string
          width: number
          height: number
          previewUrl: any
          backgroundColor?: {
            __typename: 'Color'
            red: number
            green: number
            blue: number
          } | null
          elements: Array<{
            __typename: 'Element'
            x: number
            y: number
            z: number
            rotation: number
            width: number
            height: number
            locked: boolean
            draggable?: boolean | null
            sticker?: {
              __typename: 'ElementSticker'
              scale: number
              sticker?: {
                __typename: 'EditorImageType'
                id: string
                url: string
                width: number
                height: number
                thumbnailUrl: string
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
            } | null
            image?: {
              __typename: 'ElementPicture'
              scale: number
              image?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
              filter?: { __typename?: 'Filter'; name: string } | null
            } | null
            text?: {
              __typename?: 'ElementText'
              paragraphs: Array<{
                __typename: 'Paragraph'
                alignment: TextAlignment
                textFragments: Array<{
                  __typename: 'TextFragment'
                  text: string
                  fontSize: number
                  isReplacement: boolean
                  textColor: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  }
                  font: { __typename: 'Font'; id: string; fontName: string }
                }>
              }>
            } | null
          }>
        }>
      } | null
      frontImage: {
        __typename: 'Image'
        id: string
        url: any
        width: number
        height: number
        smallThumb: any
        mediumThumb: any
      }
      insideRightImage?: {
        __typename: 'Image'
        id: string
        url: any
        width: number
        height: number
        smallThumb: any
        mediumThumb: any
      } | null
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
    } | null
    panels: Array<{
      __typename: 'Panel'
      location: number
      isFullBleed: boolean
      isLocked: boolean
      name: string
      width: number
      height: number
      previewUrl: any
      backgroundColor?: {
        __typename: 'Color'
        red: number
        green: number
        blue: number
      } | null
      elements: Array<{
        __typename: 'Element'
        x: number
        y: number
        z: number
        rotation: number
        width: number
        height: number
        locked: boolean
        draggable?: boolean | null
        sticker?: {
          __typename: 'ElementSticker'
          scale: number
          sticker?: {
            __typename: 'EditorImageType'
            id: string
            url: string
            width: number
            height: number
            thumbnailUrl: string
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
        } | null
        image?: {
          __typename: 'ElementPicture'
          scale: number
          image?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
          filter?: { __typename?: 'Filter'; name: string } | null
        } | null
        text?: {
          __typename?: 'ElementText'
          paragraphs: Array<{
            __typename: 'Paragraph'
            alignment: TextAlignment
            textFragments: Array<{
              __typename: 'TextFragment'
              text: string
              fontSize: number
              isReplacement: boolean
              textColor: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              }
              font: { __typename: 'Font'; id: string; fontName: string }
            }>
          }>
        } | null
      }>
    }>
    cost: {
      __typename?: 'ItemizedCost'
      total: Array<{
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }>
      entries: Array<{
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }>
    }
    paperUpgradeCosts: Array<{
      __typename?: 'PaperCosts'
      value: string
      upgrade: string
    }>
    digitalCard?: {
      __typename?: 'DigitalCard'
      pk: string
      shareMessageTitle: string
      shareMessageBody: string
      tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
    } | null
  }
}

export type GetCoachingBadgesQueryVariables = Exact<{
  hasEarned?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetCoachingBadgesQuery = {
  __typename?: 'Query'
  awardGroups: Array<{
    __typename?: 'AwardGroup'
    awards: Array<{
      __typename: 'Award'
      id: string
      title: string
      iconUrl: any
      description: string
      progress: number
      threshold: number
      graphicUrl: any
      isLocked: boolean
      qualifications: Array<{
        __typename?: 'Qualification'
        id: string
        title: string
        description: string
        graphic: string
        threshold: number
        progress: number
        link?: {
          __typename?: 'MarketingURL'
          title: string
          url: string
        } | null
      } | null>
      link?: { __typename?: 'MarketingURL'; title: string; url: string } | null
    }>
  }>
}

export type GetCoachingDashboardMarketingContentQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCoachingDashboardMarketingContentQuery = {
  __typename?: 'Query'
  boostPotentialMoreInfo: { __typename: 'MarketingURL'; url: string }
  boostPotentialUnltdProductPath: { __typename: 'MarketingURL'; url: string }
  boostPotentialLtdProductPath: { __typename: 'MarketingURL'; url: string }
  keepPlayingProductPath: { __typename: 'MarketingURL'; url: string }
}

export type GetContactRequestByTokenQueryVariables = Exact<{
  token: Scalars['String']['input']
}>

export type GetContactRequestByTokenQuery = {
  __typename?: 'Query'
  contactRequestByToken?: {
    __typename?: 'ContactRequest'
    firstName: string
    lastName: string
    status: ContactRequestStatusEnum
    requester: {
      __typename: 'User'
      id: string
      firstName: string
      lastName: string
      email: string
      genealogyId: number
      storefront?: {
        __typename: 'Storefront'
        biography?: string | null
        slug?: string | null
        mugshot?: { __typename?: 'Image'; url: any } | null
      } | null
    }
  } | null
}

export type GetContactRequestMarketingContentQueryVariables = Exact<{
  [key: string]: never
}>

export type GetContactRequestMarketingContentQuery = {
  __typename?: 'Query'
  newContactRequestSubmitMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  viewContactRequestMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
}

export type GetContactRequestsQueryVariables = Exact<{ [key: string]: never }>

export type GetContactRequestsQuery = {
  __typename?: 'Query'
  contactRequests: Array<{
    __typename?: 'ContactRequest'
    id: string
    firstName: string
    lastName: string
    status: ContactRequestStatusEnum
    expiresAt: any
    token: string
    contact?: {
      __typename?: 'Contact'
      id: string
      firstName: string
      lastName: string
    } | null
  }>
}

export type GetCreditCardManagerMarketingContentQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCreditCardManagerMarketingContentQuery = {
  __typename?: 'Query'
  creditCardUpdateRateLimitReached: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  lastCardRemovalDisclaimerTitle: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  lastCardRemovalDisclaimerMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  lastCardRemovalDisclaimerDecline: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  lastCardRemovalDisclaimerAccept: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
}

export type GetCreditsBalanceQueryVariables = Exact<{ [key: string]: never }>

export type GetCreditsBalanceQuery = {
  __typename?: 'Query'
  account: {
    __typename?: 'Account'
    credits: {
      __typename: 'Amount'
      amount: number
      currency: Currency
      description: string
    }
  }
}

export type GetDetailedCountriesQueryVariables = Exact<{ [key: string]: never }>

export type GetDetailedCountriesQuery = {
  __typename?: 'Query'
  detailedCountries: Array<{
    __typename: 'Country'
    postalName: string
    alpha2: string
    subdivisions: Array<{
      __typename: 'Subdivision'
      abbreviationOrName: string
    }>
  }>
}

export type GetDigitalCardByTokenQueryVariables = Exact<{
  token: Scalars['String']['input']
}>

export type GetDigitalCardByTokenQuery = {
  __typename?: 'Query'
  digitalCardByToken?: {
    __typename?: 'DigitalCard'
    pk: string
    card: {
      __typename: 'Card'
      id: string
      type: CardType
      paperType: CardPaperType
      isHorizontal: boolean
      isNewEditorCard: boolean
      backPanelLocation: number
      frontPreviewUrl: any
      insidePreviewUrl?: any | null
      flapPreviewUrl?: any | null
      backPreviewUrl: any
      showInPicturePlus: boolean
      tinyThumb: any
      detailedSendableCard?: {
        __typename: 'SendableCard'
        id: string
        title: string
        description: string
        variationColor: string
        isFavorite: boolean
        isHorizontal: boolean
        isPremium: boolean
        variations: Array<{
          __typename: 'SendableCard'
          id: string
          title: string
          description: string
          variationColor: string
          isFavorite: boolean
          isHorizontal: boolean
          isPremium: boolean
          cardTemplate?: {
            __typename: 'Card'
            id: string
            backPanelLocation: number
            panels: Array<{
              __typename: 'Panel'
              location: number
              isFullBleed: boolean
              isLocked: boolean
              name: string
              width: number
              height: number
              previewUrl: any
              backgroundColor?: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              } | null
              elements: Array<{
                __typename: 'Element'
                x: number
                y: number
                z: number
                rotation: number
                width: number
                height: number
                locked: boolean
                draggable?: boolean | null
                sticker?: {
                  __typename: 'ElementSticker'
                  scale: number
                  sticker?: {
                    __typename: 'EditorImageType'
                    id: string
                    url: string
                    width: number
                    height: number
                    thumbnailUrl: string
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                } | null
                image?: {
                  __typename: 'ElementPicture'
                  scale: number
                  image?: {
                    __typename: 'Image'
                    id: string
                    url: any
                    width: number
                    height: number
                    smallThumb: any
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                  filter?: { __typename?: 'Filter'; name: string } | null
                } | null
                text?: {
                  __typename?: 'ElementText'
                  paragraphs: Array<{
                    __typename: 'Paragraph'
                    alignment: TextAlignment
                    textFragments: Array<{
                      __typename: 'TextFragment'
                      text: string
                      fontSize: number
                      isReplacement: boolean
                      textColor: {
                        __typename: 'Color'
                        red: number
                        green: number
                        blue: number
                      }
                      font: { __typename: 'Font'; id: string; fontName: string }
                    }>
                  }>
                } | null
              }>
            }>
          } | null
          frontImage: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          }
          insideRightImage?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          } | null
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
        }>
        cardTemplate?: {
          __typename: 'Card'
          id: string
          backPanelLocation: number
          panels: Array<{
            __typename: 'Panel'
            location: number
            isFullBleed: boolean
            isLocked: boolean
            name: string
            width: number
            height: number
            previewUrl: any
            backgroundColor?: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            } | null
            elements: Array<{
              __typename: 'Element'
              x: number
              y: number
              z: number
              rotation: number
              width: number
              height: number
              locked: boolean
              draggable?: boolean | null
              sticker?: {
                __typename: 'ElementSticker'
                scale: number
                sticker?: {
                  __typename: 'EditorImageType'
                  id: string
                  url: string
                  width: number
                  height: number
                  thumbnailUrl: string
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
              } | null
              image?: {
                __typename: 'ElementPicture'
                scale: number
                image?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
                filter?: { __typename?: 'Filter'; name: string } | null
              } | null
              text?: {
                __typename?: 'ElementText'
                paragraphs: Array<{
                  __typename: 'Paragraph'
                  alignment: TextAlignment
                  textFragments: Array<{
                    __typename: 'TextFragment'
                    text: string
                    fontSize: number
                    isReplacement: boolean
                    textColor: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    }
                    font: { __typename: 'Font'; id: string; fontName: string }
                  }>
                }>
              } | null
            }>
          }>
        } | null
        frontImage: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        }
        insideRightImage?: {
          __typename: 'Image'
          id: string
          url: any
          width: number
          height: number
          smallThumb: any
          mediumThumb: any
        } | null
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
      } | null
      panels: Array<{
        __typename: 'Panel'
        location: number
        isFullBleed: boolean
        isLocked: boolean
        name: string
        width: number
        height: number
        previewUrl: any
        backgroundColor?: {
          __typename: 'Color'
          red: number
          green: number
          blue: number
        } | null
        elements: Array<{
          __typename: 'Element'
          x: number
          y: number
          z: number
          rotation: number
          width: number
          height: number
          locked: boolean
          draggable?: boolean | null
          sticker?: {
            __typename: 'ElementSticker'
            scale: number
            sticker?: {
              __typename: 'EditorImageType'
              id: string
              url: string
              width: number
              height: number
              thumbnailUrl: string
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
          } | null
          image?: {
            __typename: 'ElementPicture'
            scale: number
            image?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
            filter?: { __typename?: 'Filter'; name: string } | null
          } | null
          text?: {
            __typename?: 'ElementText'
            paragraphs: Array<{
              __typename: 'Paragraph'
              alignment: TextAlignment
              textFragments: Array<{
                __typename: 'TextFragment'
                text: string
                fontSize: number
                isReplacement: boolean
                textColor: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                }
                font: { __typename: 'Font'; id: string; fontName: string }
              }>
            }>
          } | null
        }>
      }>
      paperUpgradeCosts: Array<{
        __typename?: 'PaperCosts'
        value: string
        upgrade: string
      }>
      digitalCard?: {
        __typename?: 'DigitalCard'
        pk: string
        shareMessageTitle: string
        shareMessageBody: string
        tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
      } | null
    }
  } | null
}

export type GetDigitalCardsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}>

export type GetDigitalCardsQuery = {
  __typename?: 'Query'
  digitalCards: {
    __typename?: 'DigitalCardConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: 'DigitalCardEdge'
      cursor: string
      node: {
        __typename?: 'DigitalCard'
        pk: string
        createdAt: any
        shareMessageTitle: string
        shareMessageBody: string
        tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
        card: {
          __typename: 'Card'
          id: string
          type: CardType
          paperType: CardPaperType
          isHorizontal: boolean
          isNewEditorCard: boolean
          backPanelLocation: number
          frontPreviewUrl: any
          insidePreviewUrl?: any | null
          flapPreviewUrl?: any | null
          backPreviewUrl: any
          showInPicturePlus: boolean
          tinyThumb: any
          detailedSendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
            description: string
            variationColor: string
            isFavorite: boolean
            isHorizontal: boolean
            isPremium: boolean
            variations: Array<{
              __typename: 'SendableCard'
              id: string
              title: string
              description: string
              variationColor: string
              isFavorite: boolean
              isHorizontal: boolean
              isPremium: boolean
              cardTemplate?: {
                __typename: 'Card'
                id: string
                backPanelLocation: number
                panels: Array<{
                  __typename: 'Panel'
                  location: number
                  isFullBleed: boolean
                  isLocked: boolean
                  name: string
                  width: number
                  height: number
                  previewUrl: any
                  backgroundColor?: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  } | null
                  elements: Array<{
                    __typename: 'Element'
                    x: number
                    y: number
                    z: number
                    rotation: number
                    width: number
                    height: number
                    locked: boolean
                    draggable?: boolean | null
                    sticker?: {
                      __typename: 'ElementSticker'
                      scale: number
                      sticker?: {
                        __typename: 'EditorImageType'
                        id: string
                        url: string
                        width: number
                        height: number
                        thumbnailUrl: string
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                    } | null
                    image?: {
                      __typename: 'ElementPicture'
                      scale: number
                      image?: {
                        __typename: 'Image'
                        id: string
                        url: any
                        width: number
                        height: number
                        smallThumb: any
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                      filter?: { __typename?: 'Filter'; name: string } | null
                    } | null
                    text?: {
                      __typename?: 'ElementText'
                      paragraphs: Array<{
                        __typename: 'Paragraph'
                        alignment: TextAlignment
                        textFragments: Array<{
                          __typename: 'TextFragment'
                          text: string
                          fontSize: number
                          isReplacement: boolean
                          textColor: {
                            __typename: 'Color'
                            red: number
                            green: number
                            blue: number
                          }
                          font: {
                            __typename: 'Font'
                            id: string
                            fontName: string
                          }
                        }>
                      }>
                    } | null
                  }>
                }>
              } | null
              frontImage: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              }
              insideRightImage?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              } | null
              cost: {
                __typename?: 'ItemizedCost'
                total: Array<{
                  __typename: 'Price'
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
                entries: Array<{
                  __typename: 'LabeledPrice'
                  label: string
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
              }
            }>
            cardTemplate?: {
              __typename: 'Card'
              id: string
              backPanelLocation: number
              panels: Array<{
                __typename: 'Panel'
                location: number
                isFullBleed: boolean
                isLocked: boolean
                name: string
                width: number
                height: number
                previewUrl: any
                backgroundColor?: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                } | null
                elements: Array<{
                  __typename: 'Element'
                  x: number
                  y: number
                  z: number
                  rotation: number
                  width: number
                  height: number
                  locked: boolean
                  draggable?: boolean | null
                  sticker?: {
                    __typename: 'ElementSticker'
                    scale: number
                    sticker?: {
                      __typename: 'EditorImageType'
                      id: string
                      url: string
                      width: number
                      height: number
                      thumbnailUrl: string
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                  } | null
                  image?: {
                    __typename: 'ElementPicture'
                    scale: number
                    image?: {
                      __typename: 'Image'
                      id: string
                      url: any
                      width: number
                      height: number
                      smallThumb: any
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                    filter?: { __typename?: 'Filter'; name: string } | null
                  } | null
                  text?: {
                    __typename?: 'ElementText'
                    paragraphs: Array<{
                      __typename: 'Paragraph'
                      alignment: TextAlignment
                      textFragments: Array<{
                        __typename: 'TextFragment'
                        text: string
                        fontSize: number
                        isReplacement: boolean
                        textColor: {
                          __typename: 'Color'
                          red: number
                          green: number
                          blue: number
                        }
                        font: {
                          __typename: 'Font'
                          id: string
                          fontName: string
                        }
                      }>
                    }>
                  } | null
                }>
              }>
            } | null
            frontImage: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            }
            insideRightImage?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            } | null
            cost: {
              __typename?: 'ItemizedCost'
              total: Array<{
                __typename: 'Price'
                asString: string
                currency: CurrencyType
                amount: number
              }>
              entries: Array<{
                __typename: 'LabeledPrice'
                label: string
                asString: string
                currency: CurrencyType
                amount: number
              }>
            }
          } | null
          panels: Array<{
            __typename: 'Panel'
            location: number
            isFullBleed: boolean
            isLocked: boolean
            name: string
            width: number
            height: number
            previewUrl: any
            backgroundColor?: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            } | null
            elements: Array<{
              __typename: 'Element'
              x: number
              y: number
              z: number
              rotation: number
              width: number
              height: number
              locked: boolean
              draggable?: boolean | null
              sticker?: {
                __typename: 'ElementSticker'
                scale: number
                sticker?: {
                  __typename: 'EditorImageType'
                  id: string
                  url: string
                  width: number
                  height: number
                  thumbnailUrl: string
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
              } | null
              image?: {
                __typename: 'ElementPicture'
                scale: number
                image?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
                filter?: { __typename?: 'Filter'; name: string } | null
              } | null
              text?: {
                __typename?: 'ElementText'
                paragraphs: Array<{
                  __typename: 'Paragraph'
                  alignment: TextAlignment
                  textFragments: Array<{
                    __typename: 'TextFragment'
                    text: string
                    fontSize: number
                    isReplacement: boolean
                    textColor: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    }
                    font: { __typename: 'Font'; id: string; fontName: string }
                  }>
                }>
              } | null
            }>
          }>
          paperUpgradeCosts: Array<{
            __typename?: 'PaperCosts'
            value: string
            upgrade: string
          }>
          digitalCard?: {
            __typename?: 'DigitalCard'
            pk: string
            shareMessageTitle: string
            shareMessageBody: string
            tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
          } | null
        }
      }
    }>
  }
}

export type GetDraftedCardsQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
}>

export type GetDraftedCardsQuery = {
  __typename?: 'Query'
  drafts: Array<{
    __typename: 'Card'
    id: string
    frontPreviewUrl: any
    insidePreviewUrl?: any | null
    isHorizontal: boolean
    updatedAt: any
  }>
}

export type GetFlagsQueryVariables = Exact<{ [key: string]: never }>

export type GetFlagsQuery = {
  __typename?: 'Query'
  flags: Array<{ __typename: 'Flag'; id: string; name: string }>
}

export type GetFontsQueryVariables = Exact<{ [key: string]: never }>

export type GetFontsQuery = {
  __typename?: 'Query'
  fonts: Array<{
    __typename: 'Font'
    id: string
    displayName: string
    fontName: string
    ttfFile: string
    isSig: boolean
    isCustomFont: boolean
    isDisabled: boolean
  }>
}

export type GetGratitudeDayMarketingContentQueryVariables = Exact<{
  [key: string]: never
}>

export type GetGratitudeDayMarketingContentQuery = {
  __typename?: 'Query'
  worldGratitudeImageUrl: { __typename: 'MarketingURL'; url: string }
}

export type GetImagesQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
}>

export type GetImagesQuery = {
  __typename?: 'Query'
  images: Array<{
    __typename: 'Image'
    createdAt: any
    id: string
    url: any
    width: number
    height: number
    smallThumb: any
  }>
}

export type GetItemQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>
  itemCode?: InputMaybe<Scalars['ID']['input']>
}>

export type GetItemQuery = {
  __typename?: 'Query'
  item: {
    __typename: 'Item'
    id: string
    description?: string | null
    itemCode: string
    price: number
    name?: string | null
    image?: string | null
    campaign?: {
      __typename: 'Campaign'
      id: string
      name: string
      isShareable: boolean
      lines: Array<{
        __typename: 'CampaignLine'
        name?: string | null
        card?: {
          __typename: 'Card'
          id: string
          type: CardType
          isHorizontal: boolean
          isNewEditorCard: boolean
          paperType: CardPaperType
          frontPreviewUrl: any
          insidePreviewUrl?: any | null
          flapPreviewUrl?: any | null
          backPreviewUrl: any
          tinyThumb: any
          sendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
          } | null
          minimalPanels: Array<{
            __typename: 'Panel'
            name: string
            previewUrl: any
            isFullBleed: boolean
            width: number
            height: number
          }>
        } | null
        giftVariation?: {
          __typename: 'GiftVariation'
          id: string
          isSendable: boolean
          isEligibleFreeGift: boolean
          name: string
          description?: string | null
          contents?: string | null
          shipping?: string | null
          imageUrls: Array<any>
          packaging?: string | null
          defaultImageUrl: any
          points: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          price: {
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }
        } | null
        sendDelay: {
          __typename: 'SendDelay'
          type: SendDelayType
          delayNumber?: number | null
          delayType: SendDelayDelayType
          timeType: SendDelayTimeType
          specificDate: any
        }
      }>
    } | null
  }
}

export type GetItemCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetItemCategoriesQuery = {
  __typename?: 'Query'
  itemCategories: Array<{
    __typename: 'ItemCategory'
    id: string
    name: string
    backgroundImageUrl?: string | null
    children: Array<{
      __typename: 'ItemCategory'
      id: string
      name: string
      backgroundImageUrl?: string | null
      items: Array<{
        __typename: 'Item'
        id: string
        description?: string | null
        itemCode: string
        price: number
        name?: string | null
        image?: string | null
        campaign?: {
          __typename: 'Campaign'
          id: string
          name: string
          isShareable: boolean
          lines: Array<{
            __typename: 'CampaignLine'
            name?: string | null
            card?: {
              __typename: 'Card'
              id: string
              type: CardType
              isHorizontal: boolean
              isNewEditorCard: boolean
              paperType: CardPaperType
              frontPreviewUrl: any
              insidePreviewUrl?: any | null
              flapPreviewUrl?: any | null
              backPreviewUrl: any
              tinyThumb: any
              sendableCard?: {
                __typename: 'SendableCard'
                id: string
                title: string
              } | null
              minimalPanels: Array<{
                __typename: 'Panel'
                name: string
                previewUrl: any
                isFullBleed: boolean
                width: number
                height: number
              }>
            } | null
            giftVariation?: {
              __typename: 'GiftVariation'
              id: string
              isSendable: boolean
              isEligibleFreeGift: boolean
              name: string
              description?: string | null
              contents?: string | null
              shipping?: string | null
              imageUrls: Array<any>
              packaging?: string | null
              defaultImageUrl: any
              points: {
                __typename: 'Amount'
                amount: number
                currency: Currency
                description: string
              }
              price: {
                __typename: 'Price'
                asString: string
                currency: CurrencyType
                amount: number
              }
            } | null
            sendDelay: {
              __typename: 'SendDelay'
              type: SendDelayType
              delayNumber?: number | null
              delayType: SendDelayDelayType
              timeType: SendDelayTimeType
              specificDate: any
            }
          }>
        } | null
      }>
    }>
    items: Array<{
      __typename: 'Item'
      id: string
      description?: string | null
      itemCode: string
      price: number
      name?: string | null
      image?: string | null
      campaign?: {
        __typename: 'Campaign'
        id: string
        name: string
        isShareable: boolean
        lines: Array<{
          __typename: 'CampaignLine'
          name?: string | null
          card?: {
            __typename: 'Card'
            id: string
            type: CardType
            isHorizontal: boolean
            isNewEditorCard: boolean
            paperType: CardPaperType
            frontPreviewUrl: any
            insidePreviewUrl?: any | null
            flapPreviewUrl?: any | null
            backPreviewUrl: any
            tinyThumb: any
            sendableCard?: {
              __typename: 'SendableCard'
              id: string
              title: string
            } | null
            minimalPanels: Array<{
              __typename: 'Panel'
              name: string
              previewUrl: any
              isFullBleed: boolean
              width: number
              height: number
            }>
          } | null
          giftVariation?: {
            __typename: 'GiftVariation'
            id: string
            isSendable: boolean
            isEligibleFreeGift: boolean
            name: string
            description?: string | null
            contents?: string | null
            shipping?: string | null
            imageUrls: Array<any>
            packaging?: string | null
            defaultImageUrl: any
            points: {
              __typename: 'Amount'
              amount: number
              currency: Currency
              description: string
            }
            price: {
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }
          } | null
          sendDelay: {
            __typename: 'SendDelay'
            type: SendDelayType
            delayNumber?: number | null
            delayType: SendDelayDelayType
            timeType: SendDelayTimeType
            specificDate: any
          }
        }>
      } | null
    }>
  }>
}

export type GetItemsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>
  category?: InputMaybe<Scalars['ID']['input']>
}>

export type GetItemsQuery = {
  __typename?: 'Query'
  items: Array<{
    __typename: 'Item'
    id: string
    description?: string | null
    itemCode: string
    price: number
    name?: string | null
    image?: string | null
    campaign?: {
      __typename: 'Campaign'
      id: string
      name: string
      isShareable: boolean
      lines: Array<{
        __typename: 'CampaignLine'
        name?: string | null
        card?: {
          __typename: 'Card'
          id: string
          type: CardType
          isHorizontal: boolean
          isNewEditorCard: boolean
          paperType: CardPaperType
          frontPreviewUrl: any
          insidePreviewUrl?: any | null
          flapPreviewUrl?: any | null
          backPreviewUrl: any
          tinyThumb: any
          sendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
          } | null
          minimalPanels: Array<{
            __typename: 'Panel'
            name: string
            previewUrl: any
            isFullBleed: boolean
            width: number
            height: number
          }>
        } | null
        giftVariation?: {
          __typename: 'GiftVariation'
          id: string
          isSendable: boolean
          isEligibleFreeGift: boolean
          name: string
          description?: string | null
          contents?: string | null
          shipping?: string | null
          imageUrls: Array<any>
          packaging?: string | null
          defaultImageUrl: any
          points: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          price: {
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }
        } | null
        sendDelay: {
          __typename: 'SendDelay'
          type: SendDelayType
          delayNumber?: number | null
          delayType: SendDelayDelayType
          timeType: SendDelayTimeType
          specificDate: any
        }
      }>
    } | null
  }>
}

export type GetLayoutQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetLayoutQuery = {
  __typename?: 'Query'
  layout: {
    __typename?: 'Layout'
    panel: {
      __typename: 'Panel'
      location: number
      isFullBleed: boolean
      isLocked: boolean
      name: string
      width: number
      height: number
      previewUrl: any
      backgroundColor?: {
        __typename: 'Color'
        red: number
        green: number
        blue: number
      } | null
      elements: Array<{
        __typename: 'Element'
        x: number
        y: number
        z: number
        rotation: number
        width: number
        height: number
        locked: boolean
        draggable?: boolean | null
        sticker?: {
          __typename: 'ElementSticker'
          scale: number
          sticker?: {
            __typename: 'EditorImageType'
            id: string
            url: string
            width: number
            height: number
            thumbnailUrl: string
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
        } | null
        image?: {
          __typename: 'ElementPicture'
          scale: number
          image?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
          filter?: { __typename?: 'Filter'; name: string } | null
        } | null
        text?: {
          __typename?: 'ElementText'
          paragraphs: Array<{
            __typename: 'Paragraph'
            alignment: TextAlignment
            textFragments: Array<{
              __typename: 'TextFragment'
              text: string
              fontSize: number
              isReplacement: boolean
              textColor: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              }
              font: { __typename: 'Font'; id: string; fontName: string }
            }>
          }>
        } | null
      }>
    }
  }
}

export type GetLayoutsQueryVariables = Exact<{
  category: LayoutCategory
  dimensions?: InputMaybe<LayoutDimensions>
  owner?: InputMaybe<Owner>
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
}>

export type GetLayoutsQuery = {
  __typename?: 'Query'
  layouts: Array<{
    __typename: 'Layout'
    id: string
    category?: LayoutCategory | null
    owner: Owner
    dimensions?: LayoutDimensions | null
    thumbnail: { __typename?: 'Image'; url: any }
    panel: {
      __typename: 'Panel'
      location: number
      isFullBleed: boolean
      isLocked: boolean
      name: string
      width: number
      height: number
      previewUrl: any
      backgroundColor?: {
        __typename: 'Color'
        red: number
        green: number
        blue: number
      } | null
      elements: Array<{
        __typename: 'Element'
        x: number
        y: number
        z: number
        rotation: number
        width: number
        height: number
        locked: boolean
        draggable?: boolean | null
        sticker?: {
          __typename: 'ElementSticker'
          scale: number
          sticker?: {
            __typename: 'EditorImageType'
            id: string
            url: string
            width: number
            height: number
            thumbnailUrl: string
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
        } | null
        image?: {
          __typename: 'ElementPicture'
          scale: number
          image?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
          } | null
          position: { __typename?: 'Position'; x: number; y: number }
          filter?: { __typename?: 'Filter'; name: string } | null
        } | null
        text?: {
          __typename?: 'ElementText'
          paragraphs: Array<{
            __typename: 'Paragraph'
            alignment: TextAlignment
            textFragments: Array<{
              __typename: 'TextFragment'
              text: string
              fontSize: number
              isReplacement: boolean
              textColor: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              }
              font: { __typename: 'Font'; id: string; fontName: string }
            }>
          }>
        } | null
      }>
    }
  }>
}

export type GetLeaderBoardsQueryVariables = Exact<{ [key: string]: never }>

export type GetLeaderBoardsQuery = {
  __typename?: 'Query'
  leaderBoards: Array<{
    __typename?: 'LeaderBoardType'
    title: string
    description: string
    rankTitle: string
    metricTitle: string
    listTitle: string
    shouldShowPastChallenge: boolean
    gradient?: string | null
    userAccess: LeaderboardLeaderBoardUserAccessChoices
    type: LeaderboardLeaderBoardTypeChoices
    challenges: Array<{
      __typename?: 'ChallengeType'
      id: string
      status: LeaderboardChallengeStatusChoices
      startDate: any
      endDate: any
      authUserEarnedCount: number
      leaders: Array<{
        __typename: 'Leader'
        profileImageUrl?: any | null
        firstName: string
        lastName: string
        earnedCount: number
        dateJoined: string
      }>
    }>
  }>
}

export type GetLeaderboardMarketingContentQueryVariables = Exact<{
  [key: string]: never
}>

export type GetLeaderboardMarketingContentQuery = {
  __typename?: 'Query'
  totalNewTransactions: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  totalTransactionsPerAssetOnePerUser: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  totalTransactionsPerAsset: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
}

export type GetMarketingBannersQueryVariables = Exact<{ [key: string]: never }>

export type GetMarketingBannersQuery = {
  __typename?: 'Query'
  banners: Array<{
    __typename: 'MarketingBanner'
    id: string
    title: string
    htmlContent: string
    createdAt: any
    updatedAt: any
  }>
}

export type GetMarketingContentQueryVariables = Exact<{ [key: string]: never }>

export type GetMarketingContentQuery = {
  __typename?: 'Query'
  complianceMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  whyMyPhoneNumberMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  unverifiedAccountsFoundCopy: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  phoneNotFoundCopy: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  customerReferralOptInMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  greenerStillAffiliateOptInMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  sharingInformationCopy: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  createAccountMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  bulkPromoJoinTitle: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  bulkPromoJoinMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  loginWelcomeMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  affiliateLoginMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  customerReferralOptInTermsAndConditions: {
    __typename: 'MarketingMultiParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  greenerStillAffiliateOptInTermsAndConditions: {
    __typename: 'MarketingMultiParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  immediateSendEditingMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  futureSendEditingMessage: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  subscriptionPurchaseSuccess: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  cardPackagePurchaseSuccess: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  compPlanTrainingLink: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  createAccountRateLimitReached: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  streamVaPromo: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  affiliateProfileCompleteTitle: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  affiliateProfileWarningTitle: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  affiliateProfileWarningBody: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  backPanelUpdateLayoutTitle: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  backPanelUpdateLayoutBody: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  cardOfTheMonthId: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
}

export type GetNotificationCountQueryVariables = Exact<{ [key: string]: never }>

export type GetNotificationCountQuery = {
  __typename?: 'Query'
  notificationCount: number
}

export type GetNotificationsQueryVariables = Exact<{
  types?: InputMaybe<
    Array<InputMaybe<NotificationType>> | InputMaybe<NotificationType>
  >
  acknowledged?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetNotificationsQuery = {
  __typename?: 'Query'
  notifications: Array<{
    __typename: 'Notification'
    id: string
    message?: string | null
    createdAt: any
    acceptText?: string | null
    denyText?: string | null
    link?: string | null
    linkText?: string | null
    data?: any | null
    type?: NotificationType | null
  }>
}

export type GetOrderQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetOrderQuery = {
  __typename?: 'Query'
  order: {
    __typename: 'Order'
    id: string
    isSendable: boolean
    createdAt: any
    isSent: boolean
    sendType: CardSendType
    isBulk: boolean
    bulkUpgradeCardQty?: number | null
    lines: Array<{
      __typename: 'Line'
      recipientCount: number
      card?: {
        __typename: 'Card'
        id: string
        type: CardType
        paperType: CardPaperType
        isHorizontal: boolean
        isNewEditorCard: boolean
        backPanelLocation: number
        frontPreviewUrl: any
        insidePreviewUrl?: any | null
        flapPreviewUrl?: any | null
        backPreviewUrl: any
        showInPicturePlus: boolean
        tinyThumb: any
        detailedSendableCard?: {
          __typename: 'SendableCard'
          id: string
          title: string
          description: string
          variationColor: string
          isFavorite: boolean
          isHorizontal: boolean
          isPremium: boolean
          variations: Array<{
            __typename: 'SendableCard'
            id: string
            title: string
            description: string
            variationColor: string
            isFavorite: boolean
            isHorizontal: boolean
            isPremium: boolean
            cardTemplate?: {
              __typename: 'Card'
              id: string
              backPanelLocation: number
              panels: Array<{
                __typename: 'Panel'
                location: number
                isFullBleed: boolean
                isLocked: boolean
                name: string
                width: number
                height: number
                previewUrl: any
                backgroundColor?: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                } | null
                elements: Array<{
                  __typename: 'Element'
                  x: number
                  y: number
                  z: number
                  rotation: number
                  width: number
                  height: number
                  locked: boolean
                  draggable?: boolean | null
                  sticker?: {
                    __typename: 'ElementSticker'
                    scale: number
                    sticker?: {
                      __typename: 'EditorImageType'
                      id: string
                      url: string
                      width: number
                      height: number
                      thumbnailUrl: string
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                  } | null
                  image?: {
                    __typename: 'ElementPicture'
                    scale: number
                    image?: {
                      __typename: 'Image'
                      id: string
                      url: any
                      width: number
                      height: number
                      smallThumb: any
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                    filter?: { __typename?: 'Filter'; name: string } | null
                  } | null
                  text?: {
                    __typename?: 'ElementText'
                    paragraphs: Array<{
                      __typename: 'Paragraph'
                      alignment: TextAlignment
                      textFragments: Array<{
                        __typename: 'TextFragment'
                        text: string
                        fontSize: number
                        isReplacement: boolean
                        textColor: {
                          __typename: 'Color'
                          red: number
                          green: number
                          blue: number
                        }
                        font: {
                          __typename: 'Font'
                          id: string
                          fontName: string
                        }
                      }>
                    }>
                  } | null
                }>
              }>
            } | null
            frontImage: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            }
            insideRightImage?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            } | null
            cost: {
              __typename?: 'ItemizedCost'
              total: Array<{
                __typename: 'Price'
                asString: string
                currency: CurrencyType
                amount: number
              }>
              entries: Array<{
                __typename: 'LabeledPrice'
                label: string
                asString: string
                currency: CurrencyType
                amount: number
              }>
            }
          }>
          cardTemplate?: {
            __typename: 'Card'
            id: string
            backPanelLocation: number
            panels: Array<{
              __typename: 'Panel'
              location: number
              isFullBleed: boolean
              isLocked: boolean
              name: string
              width: number
              height: number
              previewUrl: any
              backgroundColor?: {
                __typename: 'Color'
                red: number
                green: number
                blue: number
              } | null
              elements: Array<{
                __typename: 'Element'
                x: number
                y: number
                z: number
                rotation: number
                width: number
                height: number
                locked: boolean
                draggable?: boolean | null
                sticker?: {
                  __typename: 'ElementSticker'
                  scale: number
                  sticker?: {
                    __typename: 'EditorImageType'
                    id: string
                    url: string
                    width: number
                    height: number
                    thumbnailUrl: string
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                } | null
                image?: {
                  __typename: 'ElementPicture'
                  scale: number
                  image?: {
                    __typename: 'Image'
                    id: string
                    url: any
                    width: number
                    height: number
                    smallThumb: any
                  } | null
                  position: { __typename?: 'Position'; x: number; y: number }
                  filter?: { __typename?: 'Filter'; name: string } | null
                } | null
                text?: {
                  __typename?: 'ElementText'
                  paragraphs: Array<{
                    __typename: 'Paragraph'
                    alignment: TextAlignment
                    textFragments: Array<{
                      __typename: 'TextFragment'
                      text: string
                      fontSize: number
                      isReplacement: boolean
                      textColor: {
                        __typename: 'Color'
                        red: number
                        green: number
                        blue: number
                      }
                      font: { __typename: 'Font'; id: string; fontName: string }
                    }>
                  }>
                } | null
              }>
            }>
          } | null
          frontImage: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          }
          insideRightImage?: {
            __typename: 'Image'
            id: string
            url: any
            width: number
            height: number
            smallThumb: any
            mediumThumb: any
          } | null
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
        } | null
        panels: Array<{
          __typename: 'Panel'
          location: number
          isFullBleed: boolean
          isLocked: boolean
          name: string
          width: number
          height: number
          previewUrl: any
          backgroundColor?: {
            __typename: 'Color'
            red: number
            green: number
            blue: number
          } | null
          elements: Array<{
            __typename: 'Element'
            x: number
            y: number
            z: number
            rotation: number
            width: number
            height: number
            locked: boolean
            draggable?: boolean | null
            sticker?: {
              __typename: 'ElementSticker'
              scale: number
              sticker?: {
                __typename: 'EditorImageType'
                id: string
                url: string
                width: number
                height: number
                thumbnailUrl: string
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
            } | null
            image?: {
              __typename: 'ElementPicture'
              scale: number
              image?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
              filter?: { __typename?: 'Filter'; name: string } | null
            } | null
            text?: {
              __typename?: 'ElementText'
              paragraphs: Array<{
                __typename: 'Paragraph'
                alignment: TextAlignment
                textFragments: Array<{
                  __typename: 'TextFragment'
                  text: string
                  fontSize: number
                  isReplacement: boolean
                  textColor: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  }
                  font: { __typename: 'Font'; id: string; fontName: string }
                }>
              }>
            } | null
          }>
        }>
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
        paperUpgradeCosts: Array<{
          __typename?: 'PaperCosts'
          value: string
          upgrade: string
        }>
        digitalCard?: {
          __typename?: 'DigitalCard'
          pk: string
          shareMessageTitle: string
          shareMessageBody: string
          tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
        } | null
      } | null
      giftVariation?: {
        __typename: 'GiftVariation'
        id: string
        isSendable: boolean
        isEligibleFreeGift: boolean
        name: string
        description?: string | null
        contents?: string | null
        shipping?: string | null
        imageUrls: Array<any>
        packaging?: string | null
        defaultImageUrl: any
        points: {
          __typename: 'Amount'
          amount: number
          currency: Currency
          description: string
        }
        price: {
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }
      } | null
      sendDelay: {
        __typename: 'SendDelay'
        type: SendDelayType
        delayNumber?: number | null
        delayType: SendDelayDelayType
        timeType: SendDelayTimeType
        specificDate: any
      }
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
      baseCost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
    }>
    contacts: Array<{ __typename: 'Contact'; id: string }>
    groups: Array<{
      __typename: 'Group'
      id: string
      name: string
      members: Array<{ __typename: 'Contact'; id: string }>
    }>
    returnAddress?: {
      __typename: 'Address'
      firstName: string
      lastName: string
      company: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country: string
    } | null
    campaign?: { __typename: 'Campaign'; id: string; name: string } | null
    cost: {
      __typename?: 'ItemizedCost'
      total: Array<{
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }>
      entries: Array<{
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }>
    }
    warnings: Array<{
      __typename: 'Error'
      code?: number | null
      message?: string | null
    }>
    invalidContacts: Array<{ __typename: 'Contact'; id: string }>
    invalidSendDelayContacts: Array<{ __typename: 'Contact'; id: string }>
    bulkUpgradeShippingAddress?: {
      __typename: 'Address'
      firstName: string
      lastName: string
      company: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country: string
    } | null
  }
}

export type GetPaginatedInvitesQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
}>

export type GetPaginatedInvitesQuery = {
  __typename?: 'Query'
  paginatedInvites: {
    __typename?: 'PaginatedInvites'
    count: number
    results: Array<{
      __typename: 'Invite'
      id: string
      createdAt: any
      utmStream?: string | null
      hasProspectJoined: boolean
      hasSentCard: boolean
      prospect?: {
        __typename: 'Prospect'
        id: string
        firstName: string
        lastName: string
        email: string
        phoneNumber: string
        ordersSent: number
        address?: {
          __typename: 'Address'
          firstName: string
          lastName: string
          company: string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string
          country: string
        } | null
      } | null
      socProContact?: {
        __typename: 'SocProContact'
        id: string
        contactId: string
        email: string
        firstName: string
        lastName: string
        fullName: string
        smsPhone: string
        homePhone: string
        workPhone: string
        address: string
        address2: string
        zip: string
        city: string
        state: string
        country: string
        metadataBlob: any
        socContact?: {
          __typename: 'Contact'
          homePhone?: string | null
          cellPhone?: string | null
          workPhone?: string | null
          faxNumber?: string | null
          website: string
          emailAddress?: string | null
          id: string
          firstName: string
          lastName: string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string
          country?: string | null
          isPrimary: boolean
          company: string
          parent?: {
            __typename?: 'Contact'
            id: string
            firstName: string
            lastName: string
            address1: string
            address2: string
            city: string
            state: string
            postalCode: string
            country?: string | null
            isPrimary: boolean
            homePhone?: string | null
            cellPhone?: string | null
            workPhone?: string | null
            faxNumber?: string | null
            website: string
            emailAddress?: string | null
            company: string
            birthday?: {
              __typename: 'OptionalYearDate'
              day: number
              month: number
              year?: number | null
            } | null
            anniversary?: {
              __typename: 'OptionalYearDate'
              day: number
              month: number
              year?: number | null
            } | null
          } | null
          spouse?: {
            __typename?: 'Contact'
            id: string
            firstName: string
            lastName: string
            address1: string
            address2: string
            city: string
            state: string
            postalCode: string
            country?: string | null
            isPrimary: boolean
            homePhone?: string | null
            cellPhone?: string | null
            workPhone?: string | null
            faxNumber?: string | null
            website: string
            emailAddress?: string | null
            company: string
            birthday?: {
              __typename: 'OptionalYearDate'
              day: number
              month: number
              year?: number | null
            } | null
            anniversary?: {
              __typename: 'OptionalYearDate'
              day: number
              month: number
              year?: number | null
            } | null
          } | null
          children: Array<{
            __typename?: 'Contact'
            id: string
            firstName: string
            lastName: string
            address1: string
            address2: string
            city: string
            state: string
            postalCode: string
            country?: string | null
            isPrimary: boolean
            homePhone?: string | null
            cellPhone?: string | null
            workPhone?: string | null
            faxNumber?: string | null
            website: string
            emailAddress?: string | null
            company: string
            birthday?: {
              __typename: 'OptionalYearDate'
              day: number
              month: number
              year?: number | null
            } | null
            anniversary?: {
              __typename: 'OptionalYearDate'
              day: number
              month: number
              year?: number | null
            } | null
          }>
          groups: Array<{ __typename: 'Group'; id: string; name: string }>
          notes: Array<{
            __typename: 'Note'
            id: string
            text: string
            date?: {
              __typename: 'CalendarDate'
              day: number
              month: number
              year: number
            } | null
          }>
          birthday?: {
            __typename: 'OptionalYearDate'
            day: number
            month: number
            year?: number | null
          } | null
          anniversary?: {
            __typename: 'OptionalYearDate'
            day: number
            month: number
            year?: number | null
          } | null
        } | null
      } | null
      contact?: {
        __typename: 'Contact'
        homePhone?: string | null
        cellPhone?: string | null
        workPhone?: string | null
        faxNumber?: string | null
        website: string
        emailAddress?: string | null
        id: string
        firstName: string
        lastName: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country?: string | null
        isPrimary: boolean
        company: string
        parent?: {
          __typename?: 'Contact'
          id: string
          firstName: string
          lastName: string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string
          country?: string | null
          isPrimary: boolean
          homePhone?: string | null
          cellPhone?: string | null
          workPhone?: string | null
          faxNumber?: string | null
          website: string
          emailAddress?: string | null
          company: string
          birthday?: {
            __typename: 'OptionalYearDate'
            day: number
            month: number
            year?: number | null
          } | null
          anniversary?: {
            __typename: 'OptionalYearDate'
            day: number
            month: number
            year?: number | null
          } | null
        } | null
        spouse?: {
          __typename?: 'Contact'
          id: string
          firstName: string
          lastName: string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string
          country?: string | null
          isPrimary: boolean
          homePhone?: string | null
          cellPhone?: string | null
          workPhone?: string | null
          faxNumber?: string | null
          website: string
          emailAddress?: string | null
          company: string
          birthday?: {
            __typename: 'OptionalYearDate'
            day: number
            month: number
            year?: number | null
          } | null
          anniversary?: {
            __typename: 'OptionalYearDate'
            day: number
            month: number
            year?: number | null
          } | null
        } | null
        children: Array<{
          __typename?: 'Contact'
          id: string
          firstName: string
          lastName: string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string
          country?: string | null
          isPrimary: boolean
          homePhone?: string | null
          cellPhone?: string | null
          workPhone?: string | null
          faxNumber?: string | null
          website: string
          emailAddress?: string | null
          company: string
          birthday?: {
            __typename: 'OptionalYearDate'
            day: number
            month: number
            year?: number | null
          } | null
          anniversary?: {
            __typename: 'OptionalYearDate'
            day: number
            month: number
            year?: number | null
          } | null
        }>
        groups: Array<{ __typename: 'Group'; id: string; name: string }>
        notes: Array<{
          __typename: 'Note'
          id: string
          text: string
          date?: {
            __typename: 'CalendarDate'
            day: number
            month: number
            year: number
          } | null
        }>
        birthday?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
        anniversary?: {
          __typename: 'OptionalYearDate'
          day: number
          month: number
          year?: number | null
        } | null
      } | null
    }>
  }
}

export type GetPaginatedRecipientsQueryVariables = Exact<{
  paymentFailures?: InputMaybe<Scalars['Boolean']['input']>
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
  search?: InputMaybe<Scalars['String']['input']>
  contacts?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type GetPaginatedRecipientsQuery = {
  __typename?: 'Query'
  paginatedRecipients: {
    __typename: 'PaginatedRecipients'
    count: number
    recipients: Array<{
      __typename: 'Recipient'
      id: string
      cardQuantity: number
      paymentFailure?: { __typename?: 'PaymentFailure'; id: string } | null
      contact?: {
        __typename: 'Contact'
        id: string
        firstName: string
        lastName: string
      } | null
      address?: {
        __typename: 'Address'
        userVerified: boolean
        errorMessages: Array<string>
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      gift?: {
        __typename?: 'GiftVariation'
        name: string
        defaultImageUrl: any
      } | null
      card?: {
        __typename: 'Card'
        frontPreviewUrl: any
        isHorizontal: boolean
        id: string
        sendableCard?: {
          __typename: 'SendableCard'
          id: string
          title: string
        } | null
      } | null
    }>
  }
}

export type GetPlanMoveQueryVariables = Exact<{
  planIdFrom: Scalars['Int']['input']
  planIdTo: Scalars['Int']['input']
}>

export type GetPlanMoveQuery = {
  __typename?: 'Query'
  planMove: {
    __typename?: 'PlanMove'
    isImmediate: boolean
    type: PlansPlanMoveSettingsTypeChoices
    planTo: { __typename?: 'Plan'; id: string; stripeId: string; title: string }
    planFrom: {
      __typename?: 'Plan'
      id: string
      stripeId: string
      title: string
    }
  }
}

export type GetPlansQueryVariables = Exact<{ [key: string]: never }>

export type GetPlansQuery = {
  __typename?: 'Query'
  plans: Array<{
    __typename: 'Plan'
    id: string
    title: string
    subtitle?: string | null
    stripeId: string
    description?: any | null
    isAddon: boolean
    cardCost: number
    bulkCardCost: number
    giftDiscount: number
    isExpenseReloadRequired: boolean
    color?: string | null
    backgroundStartColor: string
    backgroundEndColor: string
    icon: PlansPlanIconChoices
    price: {
      __typename: 'Amount'
      amount: number
      currency: Currency
      description: string
    }
    relatedPackage?: {
      __typename?: 'RelatedPlanPackage'
      saleorProductId: string
      title: string
      description: string
      shouldShowByDefault: boolean
    } | null
  }>
}

export type GetProductionInfoQueryVariables = Exact<{
  contactId: Scalars['ID']['input']
  orderId: Scalars['ID']['input']
}>

export type GetProductionInfoQuery = {
  __typename?: 'Query'
  productionInfo: {
    __typename: 'ProductionInfo'
    id: string
    dateToSend?: any | null
    status: CardStatus
    hold: boolean
    fulfilled?: any | null
    rejectionReason?: string | null
    frontPreviewUrl: any
    rejectedCards: Array<{ __typename: 'RejectedCard'; id: string }>
    trackingInfo?: {
      __typename?: 'TrackingInfo'
      trackingNumber: string
      url?: any | null
    } | null
    recipient?: {
      __typename: 'Recipient'
      id: string
      cardQuantity: number
      paymentFailure?: { __typename?: 'PaymentFailure'; id: string } | null
      contact?: {
        __typename: 'Contact'
        id: string
        firstName: string
        lastName: string
      } | null
      address?: {
        __typename: 'Address'
        userVerified: boolean
        errorMessages: Array<string>
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      gift?: {
        __typename?: 'GiftVariation'
        name: string
        defaultImageUrl: any
      } | null
      card?: {
        __typename: 'Card'
        frontPreviewUrl: any
        isHorizontal: boolean
        id: string
        sendableCard?: {
          __typename: 'SendableCard'
          id: string
          title: string
        } | null
      } | null
    } | null
  }
}

export type GetPromptingsCoachDiscountsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetPromptingsCoachDiscountsQuery = {
  __typename?: 'Query'
  account: {
    __typename?: 'Account'
    promptingsCoach?: {
      __typename?: 'PromptingsCoach'
      discounts: Array<{
        __typename: 'PromptingsCoachDiscount'
        code: string
        eventbriteGroupId: string
        expirationDatetime: any
        isUsed: boolean
        amountOff: number
        percentOff: number
      }>
    } | null
  }
}

export type GetPromptingsEventDatesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetPromptingsEventDatesQuery = {
  __typename?: 'Query'
  promptingsProsperityEventDate: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  promptingsSeminarEventDate: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
}

export type GetReceivedPendingMembershipInvitesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetReceivedPendingMembershipInvitesQuery = {
  __typename?: 'Query'
  receivedPendingMembershipInvites: Array<{
    __typename: 'MembershipInvite'
    organizationName: string
    organizationOwnerName?: string | null
    createdAt: any
  }>
}

export type GetRemindersQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
}>

export type GetRemindersQuery = {
  __typename?: 'Query'
  reminders: Array<{
    __typename: 'Reminder'
    id: string
    type: ReminderType
    newType: NewReminderType
    message?: string | null
    title: string
    eventDate?: any | null
    campaign?: { __typename?: 'Campaign'; id: string; name: string } | null
    contact: {
      __typename?: 'Contact'
      id: string
      firstName: string
      lastName: string
      country?: string | null
      birthday?: {
        __typename?: 'OptionalYearDate'
        day: number
        month: number
      } | null
      anniversary?: {
        __typename?: 'OptionalYearDate'
        day: number
        month: number
      } | null
    }
    order?: {
      __typename: 'Order'
      id: string
      isSendable: boolean
      createdAt: any
      isSent: boolean
      sendType: CardSendType
      isBulk: boolean
      bulkUpgradeCardQty?: number | null
      lines: Array<{
        __typename: 'Line'
        recipientCount: number
        card?: {
          __typename: 'Card'
          id: string
          type: CardType
          paperType: CardPaperType
          isHorizontal: boolean
          isNewEditorCard: boolean
          backPanelLocation: number
          frontPreviewUrl: any
          insidePreviewUrl?: any | null
          flapPreviewUrl?: any | null
          backPreviewUrl: any
          showInPicturePlus: boolean
          tinyThumb: any
          detailedSendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
            description: string
            variationColor: string
            isFavorite: boolean
            isHorizontal: boolean
            isPremium: boolean
            variations: Array<{
              __typename: 'SendableCard'
              id: string
              title: string
              description: string
              variationColor: string
              isFavorite: boolean
              isHorizontal: boolean
              isPremium: boolean
              cardTemplate?: {
                __typename: 'Card'
                id: string
                backPanelLocation: number
                panels: Array<{
                  __typename: 'Panel'
                  location: number
                  isFullBleed: boolean
                  isLocked: boolean
                  name: string
                  width: number
                  height: number
                  previewUrl: any
                  backgroundColor?: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  } | null
                  elements: Array<{
                    __typename: 'Element'
                    x: number
                    y: number
                    z: number
                    rotation: number
                    width: number
                    height: number
                    locked: boolean
                    draggable?: boolean | null
                    sticker?: {
                      __typename: 'ElementSticker'
                      scale: number
                      sticker?: {
                        __typename: 'EditorImageType'
                        id: string
                        url: string
                        width: number
                        height: number
                        thumbnailUrl: string
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                    } | null
                    image?: {
                      __typename: 'ElementPicture'
                      scale: number
                      image?: {
                        __typename: 'Image'
                        id: string
                        url: any
                        width: number
                        height: number
                        smallThumb: any
                      } | null
                      position: {
                        __typename?: 'Position'
                        x: number
                        y: number
                      }
                      filter?: { __typename?: 'Filter'; name: string } | null
                    } | null
                    text?: {
                      __typename?: 'ElementText'
                      paragraphs: Array<{
                        __typename: 'Paragraph'
                        alignment: TextAlignment
                        textFragments: Array<{
                          __typename: 'TextFragment'
                          text: string
                          fontSize: number
                          isReplacement: boolean
                          textColor: {
                            __typename: 'Color'
                            red: number
                            green: number
                            blue: number
                          }
                          font: {
                            __typename: 'Font'
                            id: string
                            fontName: string
                          }
                        }>
                      }>
                    } | null
                  }>
                }>
              } | null
              frontImage: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              }
              insideRightImage?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
                mediumThumb: any
              } | null
              cost: {
                __typename?: 'ItemizedCost'
                total: Array<{
                  __typename: 'Price'
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
                entries: Array<{
                  __typename: 'LabeledPrice'
                  label: string
                  asString: string
                  currency: CurrencyType
                  amount: number
                }>
              }
            }>
            cardTemplate?: {
              __typename: 'Card'
              id: string
              backPanelLocation: number
              panels: Array<{
                __typename: 'Panel'
                location: number
                isFullBleed: boolean
                isLocked: boolean
                name: string
                width: number
                height: number
                previewUrl: any
                backgroundColor?: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                } | null
                elements: Array<{
                  __typename: 'Element'
                  x: number
                  y: number
                  z: number
                  rotation: number
                  width: number
                  height: number
                  locked: boolean
                  draggable?: boolean | null
                  sticker?: {
                    __typename: 'ElementSticker'
                    scale: number
                    sticker?: {
                      __typename: 'EditorImageType'
                      id: string
                      url: string
                      width: number
                      height: number
                      thumbnailUrl: string
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                  } | null
                  image?: {
                    __typename: 'ElementPicture'
                    scale: number
                    image?: {
                      __typename: 'Image'
                      id: string
                      url: any
                      width: number
                      height: number
                      smallThumb: any
                    } | null
                    position: { __typename?: 'Position'; x: number; y: number }
                    filter?: { __typename?: 'Filter'; name: string } | null
                  } | null
                  text?: {
                    __typename?: 'ElementText'
                    paragraphs: Array<{
                      __typename: 'Paragraph'
                      alignment: TextAlignment
                      textFragments: Array<{
                        __typename: 'TextFragment'
                        text: string
                        fontSize: number
                        isReplacement: boolean
                        textColor: {
                          __typename: 'Color'
                          red: number
                          green: number
                          blue: number
                        }
                        font: {
                          __typename: 'Font'
                          id: string
                          fontName: string
                        }
                      }>
                    }>
                  } | null
                }>
              }>
            } | null
            frontImage: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            }
            insideRightImage?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
              mediumThumb: any
            } | null
            cost: {
              __typename?: 'ItemizedCost'
              total: Array<{
                __typename: 'Price'
                asString: string
                currency: CurrencyType
                amount: number
              }>
              entries: Array<{
                __typename: 'LabeledPrice'
                label: string
                asString: string
                currency: CurrencyType
                amount: number
              }>
            }
          } | null
          panels: Array<{
            __typename: 'Panel'
            location: number
            isFullBleed: boolean
            isLocked: boolean
            name: string
            width: number
            height: number
            previewUrl: any
            backgroundColor?: {
              __typename: 'Color'
              red: number
              green: number
              blue: number
            } | null
            elements: Array<{
              __typename: 'Element'
              x: number
              y: number
              z: number
              rotation: number
              width: number
              height: number
              locked: boolean
              draggable?: boolean | null
              sticker?: {
                __typename: 'ElementSticker'
                scale: number
                sticker?: {
                  __typename: 'EditorImageType'
                  id: string
                  url: string
                  width: number
                  height: number
                  thumbnailUrl: string
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
              } | null
              image?: {
                __typename: 'ElementPicture'
                scale: number
                image?: {
                  __typename: 'Image'
                  id: string
                  url: any
                  width: number
                  height: number
                  smallThumb: any
                } | null
                position: { __typename?: 'Position'; x: number; y: number }
                filter?: { __typename?: 'Filter'; name: string } | null
              } | null
              text?: {
                __typename?: 'ElementText'
                paragraphs: Array<{
                  __typename: 'Paragraph'
                  alignment: TextAlignment
                  textFragments: Array<{
                    __typename: 'TextFragment'
                    text: string
                    fontSize: number
                    isReplacement: boolean
                    textColor: {
                      __typename: 'Color'
                      red: number
                      green: number
                      blue: number
                    }
                    font: { __typename: 'Font'; id: string; fontName: string }
                  }>
                }>
              } | null
            }>
          }>
          cost: {
            __typename?: 'ItemizedCost'
            total: Array<{
              __typename: 'Price'
              asString: string
              currency: CurrencyType
              amount: number
            }>
            entries: Array<{
              __typename: 'LabeledPrice'
              label: string
              asString: string
              currency: CurrencyType
              amount: number
            }>
          }
          paperUpgradeCosts: Array<{
            __typename?: 'PaperCosts'
            value: string
            upgrade: string
          }>
          digitalCard?: {
            __typename?: 'DigitalCard'
            pk: string
            shareMessageTitle: string
            shareMessageBody: string
            tokens: Array<{ __typename?: 'DigitalCardToken'; token: string }>
          } | null
        } | null
        giftVariation?: {
          __typename: 'GiftVariation'
          id: string
          isSendable: boolean
          isEligibleFreeGift: boolean
          name: string
          description?: string | null
          contents?: string | null
          shipping?: string | null
          imageUrls: Array<any>
          packaging?: string | null
          defaultImageUrl: any
          points: {
            __typename: 'Amount'
            amount: number
            currency: Currency
            description: string
          }
          price: {
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }
        } | null
        sendDelay: {
          __typename: 'SendDelay'
          type: SendDelayType
          delayNumber?: number | null
          delayType: SendDelayDelayType
          timeType: SendDelayTimeType
          specificDate: any
        }
        cost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
        baseCost: {
          __typename?: 'ItemizedCost'
          total: Array<{
            __typename: 'Price'
            asString: string
            currency: CurrencyType
            amount: number
          }>
          entries: Array<{
            __typename: 'LabeledPrice'
            label: string
            asString: string
            currency: CurrencyType
            amount: number
          }>
        }
      }>
      contacts: Array<{ __typename: 'Contact'; id: string }>
      groups: Array<{
        __typename: 'Group'
        id: string
        name: string
        members: Array<{ __typename: 'Contact'; id: string }>
      }>
      returnAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
      campaign?: { __typename: 'Campaign'; id: string; name: string } | null
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
      warnings: Array<{
        __typename: 'Error'
        code?: number | null
        message?: string | null
      }>
      invalidContacts: Array<{ __typename: 'Contact'; id: string }>
      invalidSendDelayContacts: Array<{ __typename: 'Contact'; id: string }>
      bulkUpgradeShippingAddress?: {
        __typename: 'Address'
        firstName: string
        lastName: string
        company: string
        address1: string
        address2: string
        city: string
        state: string
        postalCode: string
        country: string
      } | null
    } | null
  }>
}

export type GetSendableCardQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetSendableCardQuery = {
  __typename?: 'Query'
  sendableCard: {
    __typename: 'SendableCard'
    description: string
    variationColor: string
    id: string
    title: string
    isFavorite: boolean
    isHorizontal: boolean
    isPremium: boolean
    insideRightImage?: {
      __typename: 'Image'
      id: string
      url: any
      width: number
      height: number
      smallThumb: any
      mediumThumb: any
    } | null
    variations: Array<{
      __typename: 'SendableCard'
      id: string
      title: string
      description: string
      variationColor: string
      isFavorite: boolean
      isHorizontal: boolean
      isPremium: boolean
      cardTemplate?: {
        __typename: 'Card'
        id: string
        backPanelLocation: number
        panels: Array<{
          __typename: 'Panel'
          location: number
          isFullBleed: boolean
          isLocked: boolean
          name: string
          width: number
          height: number
          previewUrl: any
          backgroundColor?: {
            __typename: 'Color'
            red: number
            green: number
            blue: number
          } | null
          elements: Array<{
            __typename: 'Element'
            x: number
            y: number
            z: number
            rotation: number
            width: number
            height: number
            locked: boolean
            draggable?: boolean | null
            sticker?: {
              __typename: 'ElementSticker'
              scale: number
              sticker?: {
                __typename: 'EditorImageType'
                id: string
                url: string
                width: number
                height: number
                thumbnailUrl: string
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
            } | null
            image?: {
              __typename: 'ElementPicture'
              scale: number
              image?: {
                __typename: 'Image'
                id: string
                url: any
                width: number
                height: number
                smallThumb: any
              } | null
              position: { __typename?: 'Position'; x: number; y: number }
              filter?: { __typename?: 'Filter'; name: string } | null
            } | null
            text?: {
              __typename?: 'ElementText'
              paragraphs: Array<{
                __typename: 'Paragraph'
                alignment: TextAlignment
                textFragments: Array<{
                  __typename: 'TextFragment'
                  text: string
                  fontSize: number
                  isReplacement: boolean
                  textColor: {
                    __typename: 'Color'
                    red: number
                    green: number
                    blue: number
                  }
                  font: { __typename: 'Font'; id: string; fontName: string }
                }>
              }>
            } | null
          }>
        }>
      } | null
      frontImage: {
        __typename: 'Image'
        id: string
        url: any
        width: number
        height: number
        smallThumb: any
        mediumThumb: any
      }
      insideRightImage?: {
        __typename: 'Image'
        id: string
        url: any
        width: number
        height: number
        smallThumb: any
        mediumThumb: any
      } | null
      cost: {
        __typename?: 'ItemizedCost'
        total: Array<{
          __typename: 'Price'
          asString: string
          currency: CurrencyType
          amount: number
        }>
        entries: Array<{
          __typename: 'LabeledPrice'
          label: string
          asString: string
          currency: CurrencyType
          amount: number
        }>
      }
    }>
    cardTemplate?: {
      __typename: 'Card'
      id: string
      backPanelLocation: number
      panels: Array<{
        __typename: 'Panel'
        location: number
        isFullBleed: boolean
        isLocked: boolean
        name: string
        width: number
        height: number
        previewUrl: any
        backgroundColor?: {
          __typename: 'Color'
          red: number
          green: number
          blue: number
        } | null
        elements: Array<{
          __typename: 'Element'
          x: number
          y: number
          z: number
          rotation: number
          width: number
          height: number
          locked: boolean
          draggable?: boolean | null
          sticker?: {
            __typename: 'ElementSticker'
            scale: number
            sticker?: {
              __typename: 'EditorImageType'
              id: string
              url: string
              width: number
              height: number
              thumbnailUrl: string
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
          } | null
          image?: {
            __typename: 'ElementPicture'
            scale: number
            image?: {
              __typename: 'Image'
              id: string
              url: any
              width: number
              height: number
              smallThumb: any
            } | null
            position: { __typename?: 'Position'; x: number; y: number }
            filter?: { __typename?: 'Filter'; name: string } | null
          } | null
          text?: {
            __typename?: 'ElementText'
            paragraphs: Array<{
              __typename: 'Paragraph'
              alignment: TextAlignment
              textFragments: Array<{
                __typename: 'TextFragment'
                text: string
                fontSize: number
                isReplacement: boolean
                textColor: {
                  __typename: 'Color'
                  red: number
                  green: number
                  blue: number
                }
                font: { __typename: 'Font'; id: string; fontName: string }
              }>
            }>
          } | null
        }>
      }>
    } | null
    cost: {
      __typename?: 'ItemizedCost'
      total: Array<{
        __typename: 'Price'
        asString: string
        currency: CurrencyType
        amount: number
      }>
      entries: Array<{
        __typename: 'LabeledPrice'
        label: string
        asString: string
        currency: CurrencyType
        amount: number
      }>
    }
    frontImage: {
      __typename: 'Image'
      id: string
      url: any
      width: number
      height: number
      smallThumb: any
      mediumThumb: any
    }
  }
}

export type GetSentCardsQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
  search?: InputMaybe<Scalars['String']['input']>
  contacts?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  status?: InputMaybe<CardStatus>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  sendType?: InputMaybe<SentCardSendType>
}>

export type GetSentCardsQuery = {
  __typename?: 'Query'
  paginatedSentCards: {
    __typename: 'PaginatedSentCards'
    count: number
    sentCards: Array<{
      __typename: 'ProductionInfo'
      id: string
      dateToSend?: any | null
      status: CardStatus
      hold: boolean
      fulfilled?: any | null
      rejectionReason?: string | null
      frontPreviewUrl: any
      rejectedCards: Array<{ __typename: 'RejectedCard'; id: string }>
      trackingInfo?: {
        __typename?: 'TrackingInfo'
        trackingNumber: string
        url?: any | null
      } | null
      recipient?: {
        __typename: 'Recipient'
        id: string
        cardQuantity: number
        paymentFailure?: { __typename?: 'PaymentFailure'; id: string } | null
        contact?: {
          __typename: 'Contact'
          id: string
          firstName: string
          lastName: string
        } | null
        address?: {
          __typename: 'Address'
          userVerified: boolean
          errorMessages: Array<string>
          firstName: string
          lastName: string
          company: string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string
          country: string
        } | null
        gift?: {
          __typename?: 'GiftVariation'
          name: string
          defaultImageUrl: any
        } | null
        card?: {
          __typename: 'Card'
          frontPreviewUrl: any
          isHorizontal: boolean
          id: string
          sendableCard?: {
            __typename: 'SendableCard'
            id: string
            title: string
          } | null
        } | null
      } | null
    }>
  }
}

export type GetSentCardsCountQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input']
  endDate: Scalars['DateTime']['input']
  sendType?: InputMaybe<SentCardSendType>
}>

export type GetSentCardsCountQuery = {
  __typename?: 'Query'
  sentCards: {
    __typename?: 'SentCards'
    count: number
    sentCards: Array<{ __typename?: 'SentCard'; date: any; cardsSent: number }>
  }
}

export type GetSharedCampaignsQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
}>

export type GetSharedCampaignsQuery = {
  __typename?: 'Query'
  campaignShares: Array<{
    __typename: 'CampaignShare'
    id: string
    fromName: string
    dateShared: any
    campaign: { __typename: 'Campaign'; id: string; name: string }
  }>
}

export type GetSharedContactsQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
}>

export type GetSharedContactsQuery = {
  __typename?: 'Query'
  contactShares: Array<{
    __typename: 'ContactShare'
    id: string
    createdAt: any
    contact: {
      __typename: 'Contact'
      id: string
      firstName: string
      lastName: string
      address1: string
      address2: string
      city: string
      state: string
      postalCode: string
      country?: string | null
      isPrimary: boolean
      company: string
      birthday?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
      anniversary?: {
        __typename: 'OptionalYearDate'
        day: number
        month: number
        year?: number | null
      } | null
    }
    fromUser: {
      __typename: 'User'
      id: string
      username: string
      firstName: string
      lastName: string
      email: string
    }
  }>
}

export type GetShouldVerifyEmailQueryVariables = Exact<{ [key: string]: never }>

export type GetShouldVerifyEmailQuery = {
  __typename?: 'Query'
  shouldVerifyEmail: boolean
}

export type GetSponsorQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetSponsorQuery = {
  __typename?: 'Query'
  sponsor: {
    __typename: 'User'
    id: string
    firstName: string
    lastName: string
    email: string
    genealogyId: number
    storefront?: {
      __typename: 'Storefront'
      biography?: string | null
      slug?: string | null
      mugshot?: { __typename?: 'Image'; url: any } | null
    } | null
    shippingAddress?: {
      __typename?: 'Address'
      city: string
      state: string
      country: string
    } | null
  }
}

export type GetStickerCategoriesQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
}>

export type GetStickerCategoriesQuery = {
  __typename?: 'Query'
  stickerCategories?: {
    __typename?: 'PaginatedStickerCategory'
    hasMore?: boolean | null
    totalCount?: number | null
    results: Array<{ __typename: 'StickerCategory'; id: string; name: string }>
  } | null
}

export type GetStickersQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
  filters?: InputMaybe<StickerFilters>
}>

export type GetStickersQuery = {
  __typename?: 'Query'
  stickers: {
    __typename?: 'PaginatedStickers'
    hasMore?: boolean | null
    totalCount?: number | null
    results: Array<{
      __typename: 'Sticker'
      id: string
      title: string
      createdAt: any
      image: {
        __typename: 'EditorImageType'
        id: string
        url: string
        width: number
        height: number
        createdAt: any
        thumbnailUrl: string
      }
    }>
  }
}

export type GetSubmitRequestedContactMarketingContentQueryVariables = Exact<{
  [key: string]: never
}>

export type GetSubmitRequestedContactMarketingContentQuery = {
  __typename?: 'Query'
  submitRequestedContactTitle: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  submitRequestedContactSubtitle: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  submitRequestedContactCompletedSubtitle: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  submitRequestedContactSuccessTitle: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
  submitRequestedContactSuccessBody: {
    __typename: 'MarketingParagraph'
    createdAt: any
    updatedAt: any
    id: string
    title: string
    content: string
  }
}

export type GetUserCardTokensQueryVariables = Exact<{ [key: string]: never }>

export type GetUserCardTokensQuery = {
  __typename?: 'Query'
  account: {
    __typename?: 'Account'
    groupSends: number
    heartfeltSends: number
    heartfeltSystemSends: number
    scheduledSends: number
    systemSends: number
    cardPackageSends: number
    pointsTransitionSends: number
    employeeSends: number
    dailyHeartfeltSends: number
    cardSends: number
    giftSends: number
    actSends: number
  }
}

export type GetUsersQueryVariables = Exact<{
  search: Scalars['String']['input']
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
  isAffiliate?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetUsersQuery = {
  __typename?: 'Query'
  users: Array<{
    __typename: 'User'
    id: string
    firstName: string
    lastName: string
    email: string
    genealogyId: number
    storefront?: {
      __typename: 'Storefront'
      biography?: string | null
      slug?: string | null
      mugshot?: { __typename?: 'Image'; url: any } | null
    } | null
    shippingAddress?: {
      __typename?: 'Address'
      city: string
      state: string
      country: string
    } | null
  }>
}

export const QualificationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Qualification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Qualification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'graphic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<QualificationFragment, unknown>
export const AwardFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Award' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Award' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'graphicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Qualification' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Qualification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Qualification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'graphic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<AwardFragment, unknown>
export const CampaignShareFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignShare' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignShare' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateShared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CampaignShareFragment, unknown>
export const SendableCardImageFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SendableCardImageFragment, unknown>
export const PriceFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PriceFragment, unknown>
export const LabeledPriceFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LabeledPriceFragment, unknown>
export const ItemizedCostFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ItemizedCostFragment, unknown>
export const SendableCardFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SendableCardFragment, unknown>
export const ColorFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ColorFragment, unknown>
export const EditorImageFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<EditorImageFragment, unknown>
export const ElementStickerFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ElementStickerFragment, unknown>
export const ImageFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ImageFragment, unknown>
export const ElementPictureFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ElementPictureFragment, unknown>
export const TextFragmentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<TextFragmentFragment, unknown>
export const ParagraphFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ParagraphFragment, unknown>
export const ElementTextFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ElementTextFragment, unknown>
export const ElementFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ElementFragment, unknown>
export const PanelFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PanelFragment, unknown>
export const SendableCardWithTemplateFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SendableCardWithTemplateFragment, unknown>
export const PaperCostsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PaperCostsFragment, unknown>
export const CardWithoutCostFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardWithoutCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CardWithoutCostFragment, unknown>
export const OptionalYearDateFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionalYearDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptionalYearDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<OptionalYearDateFragment, unknown>
export const ContactFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionalYearDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptionalYearDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ContactFragment, unknown>
export const ContactShareFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactShare' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactShare' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Contact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fromUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionalYearDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptionalYearDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ContactShareFragment, unknown>
export const DetailedCountryFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedCountry' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Country' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alpha2' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subdivisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviationOrName' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DetailedCountryFragment, unknown>
export const LayoutFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Layout' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Layout' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LayoutFragment, unknown>
export const DetailedLayoutFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedLayout' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Layout' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Layout' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Layout' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Layout' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DetailedLayoutFragment, unknown>
export const LargeMinimalSendableCardFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LargeMinimalSendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LargeMinimalSendableCardFragment, unknown>
export const DetailedSendableCardFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedSendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'LargeMinimalSendableCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LargeMinimalSendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DetailedSendableCardFragment, unknown>
export const DraftedCardFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DraftedCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DraftedCardFragment, unknown>
export const FlagFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Flag' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Flag' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<FlagFragment, unknown>
export const FontFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Font' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Font' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ttfFile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomFont' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<FontFragment, unknown>
export const AmountFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<AmountFragment, unknown>
export const GiftVariationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GiftVariationFragment, unknown>
export const GiftFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Gift' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Gift' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'slug' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'realId' },
            name: { kind: 'Name', value: 'id' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GiftFragment, unknown>
export const AddressFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<AddressFragment, unknown>
export const ProspectFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Prospect' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Prospect' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ordersSent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ProspectFragment, unknown>
export const RelatedContactFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelatedContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faxNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionalYearDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptionalYearDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<RelatedContactFragment, unknown>
export const CalendarDateFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CalendarDateFragment, unknown>
export const NoteFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Note' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Note' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'text' },
            name: { kind: 'Name', value: 'note' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarDate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<NoteFragment, unknown>
export const DetailedContactFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Contact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faxNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Note' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionalYearDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptionalYearDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelatedContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faxNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Note' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Note' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'text' },
            name: { kind: 'Name', value: 'note' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarDate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DetailedContactFragment, unknown>
export const SocProContactFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocProContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocProContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadataBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedContact' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionalYearDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptionalYearDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelatedContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faxNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Note' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Note' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'text' },
            name: { kind: 'Name', value: 'note' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarDate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Contact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faxNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Note' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SocProContactFragment, unknown>
export const InviteFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Invite' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Invite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospect' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Prospect' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socProContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SocProContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedContact' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'utmStream' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasProspectJoined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSentCard' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionalYearDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptionalYearDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelatedContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faxNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Note' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Note' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'text' },
            name: { kind: 'Name', value: 'note' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarDate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Contact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faxNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Note' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Prospect' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Prospect' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ordersSent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocProContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocProContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadataBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedContact' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<InviteFragment, unknown>
export const MinimalPanelFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MinimalPanelFragment, unknown>
export const SendDelayFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SendDelayFragment, unknown>
export const CampaignLineFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignLine' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tinyThumb' },
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insidePreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flapPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'minimalPanels' },
                  name: { kind: 'Name', value: 'optimizedLegacyPanels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalPanel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CampaignLineFragment, unknown>
export const CampaignFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isShareable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignLine' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignLine' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tinyThumb' },
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insidePreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flapPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'minimalPanels' },
                  name: { kind: 'Name', value: 'optimizedLegacyPanels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalPanel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CampaignFragment, unknown>
export const ItemFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Item' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Item' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Campaign' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignLine' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tinyThumb' },
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insidePreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flapPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'minimalPanels' },
                  name: { kind: 'Name', value: 'optimizedLegacyPanels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalPanel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isShareable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignLine' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ItemFragment, unknown>
export const ItemCategoryFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImageUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Item' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignLine' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tinyThumb' },
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insidePreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flapPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'minimalPanels' },
                  name: { kind: 'Name', value: 'optimizedLegacyPanels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalPanel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isShareable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignLine' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Item' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Item' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Campaign' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ItemCategoryFragment, unknown>
export const LeaderFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Leader' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Leader' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileImageUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '64' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '64' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'earnedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LeaderFragment, unknown>
export const LeaderBoardFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeaderBoard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LeaderBoardType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rankTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metricTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldShowPastChallenge' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'gradient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userAccess' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'challenges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Leader' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'authUserEarnedCount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Leader' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Leader' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileImageUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '64' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '64' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'earnedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LeaderBoardFragment, unknown>
export const MarketingMultiParagraphFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingMultiParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingMultiParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MarketingMultiParagraphFragment, unknown>
export const MarketingParagraphFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MarketingParagraphFragment, unknown>
export const MembershipInviteFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MembershipInvite' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MembershipInvite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationOwnerName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MembershipInviteFragment, unknown>
export const CardFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CardFragment, unknown>
export const LineFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Line' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Card' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LineFragment, unknown>
export const ErrorFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Error' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ErrorFragment, unknown>
export const DetailedOrderFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Line' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Error' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidSendDelayContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBulk' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeShippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeCardQty' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Line' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Card' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Error' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DetailedOrderFragment, unknown>
export const MinimalReminderFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalReminder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'birthday' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'anniversary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedOrder' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Line' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Card' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Error' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Line' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Error' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidSendDelayContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBulk' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeShippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeCardQty' },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MinimalReminderFragment, unknown>
export const NotificationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Notification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'denyText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<NotificationFragment, unknown>
export const RecipientFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Recipient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Recipient' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentFailure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVerified' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorMessages' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gift' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultImageUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cardQuantity' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<RecipientFragment, unknown>
export const ProductionInfoFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductionInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductionInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateToSend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfilled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectedCards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackingInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackingNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Recipient' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Recipient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Recipient' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentFailure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVerified' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorMessages' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gift' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultImageUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cardQuantity' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ProductionInfoFragment, unknown>
export const PromptingsCoachDiscountFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoachDiscount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoachDiscount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventbriteGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expirationDatetime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isUsed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amountOff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentOff' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PromptingsCoachDiscountFragment, unknown>
export const StorefrontFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<StorefrontFragment, unknown>
export const SponsorFragmentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SponsorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SponsorFragmentFragment, unknown>
export const StickerFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Sticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Sticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'thumbnailUrl' },
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '300' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '300' },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<StickerFragment, unknown>
export const StickerCategoryFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StickerCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StickerCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<StickerCategoryFragment, unknown>
export const UserImageFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UserImageFragment, unknown>
export const VerifyPhoneNumberFailureFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VerifyPhoneNumberFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VerifyPhoneNumberFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failureType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failureMessage' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<VerifyPhoneNumberFailureFragment, unknown>
export const PlanFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PlanFragment, unknown>
export const UserSettingsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UserSettingsFragment, unknown>
export const MinimalSponsorFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MinimalSponsorFragment, unknown>
export const CreditCardBillingAddressFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CreditCardBillingAddressFragment, unknown>
export const CreditCardFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CreditCardFragment, unknown>
export const BankAccountFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<BankAccountFragment, unknown>
export const StripeSourceFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<StripeSourceFragment, unknown>
export const SubscriptionFeaturesFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SubscriptionFeaturesFragment, unknown>
export const SubscriptionMetadataFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SubscriptionMetadataFragment, unknown>
export const StripeSubscriptionFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<StripeSubscriptionFragment, unknown>
export const ExpenseReloadFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ExpenseReloadFragment, unknown>
export const PromptingsCoachFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PromptingsCoachFragment, unknown>
export const AccountFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<AccountFragment, unknown>
export const VerifyPhoneNumberSuccessFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VerifyPhoneNumberSuccess' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VerifyPhoneNumberSuccess' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'successType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Account' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<VerifyPhoneNumberSuccessFragment, unknown>
export const AcceptCampaignShareDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptCampaignShare' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptCampaignShare' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Campaign' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignLine' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tinyThumb' },
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insidePreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flapPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'minimalPanels' },
                  name: { kind: 'Name', value: 'optimizedLegacyPanels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalPanel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isShareable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignLine' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  AcceptCampaignShareMutation,
  AcceptCampaignShareMutationVariables
>
export const AcceptSharedContactDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptSharedContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptContactShare' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  AcceptSharedContactMutation,
  AcceptSharedContactMutationVariables
>
export const AddFreeCampaignDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddFreeCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFreeCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  AddFreeCampaignMutation,
  AddFreeCampaignMutationVariables
>
export const CancelStripeSubscriptionDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelStripeSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelStripeSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CancelStripeSubscriptionMutation,
  CancelStripeSubscriptionMutationVariables
>
export const ChangeSubscriptionDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subscriptionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sku' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subscriptionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ChangeSubscriptionMutation,
  ChangeSubscriptionMutationVariables
>
export const CreateAccountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'account' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AccountInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'verificationCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'verticalId' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'VerticalID' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recaptchaToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'account' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'account' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verificationCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'verificationCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verticalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'verticalId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recaptchaToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'recaptchaToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ValidateEmailVerificationCodeFailure',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'failureType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'success' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ValidateEmailVerificationCodeSuccess',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'success' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateAccountMutation,
  CreateAccountMutationVariables
>
export const CreateAffiliateDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAffiliate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'account' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AccountInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'govId1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'govId2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stripeToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'affiliate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'verticalId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerticalID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'queryParams' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Args' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'verificationCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDistributor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'account' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'account' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'govId1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'govId1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'govId2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'govId2' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stripeToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'stripeToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'affiliate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'affiliate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verificationCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'verificationCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ValidateEmailVerificationCodeFailure',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'failureType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'success' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'ValidateEmailVerificationCodeSuccess',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'success' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPeriodEnd' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserLoginLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verticalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'verticalId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'queryParams' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateAffiliateMutation,
  CreateAffiliateMutationVariables
>
export const CreateCampaignDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaign' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaign' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaign' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Campaign' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignLine' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tinyThumb' },
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insidePreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flapPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'minimalPanels' },
                  name: { kind: 'Name', value: 'optimizedLegacyPanels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalPanel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isShareable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignLine' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>
export const CreateCardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sendableCard' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CardType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paperType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CardPaperType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'card' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sponsor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isResend' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isNewEditorCard' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'card' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sendableCard' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sendableCard' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'paperType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'paperType' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'card' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'card' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sponsor' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sponsor' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isResend' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isResend' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isNewEditorCard' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'card' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Card' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CreateCardMutation, CreateCardMutationVariables>
export const CreateContactRequestDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateContactRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactUuid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createContactRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactUuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactUuid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expiresAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Contact' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionalYearDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptionalYearDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateContactRequestMutation,
  CreateContactRequestMutationVariables
>
export const CreateDigitalCardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDigitalCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cardId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DigitalCardInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDigitalCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cardId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cardId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'digitalCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'card' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateDigitalCardMutation,
  CreateDigitalCardMutationVariables
>
export const CreateDigitalCardTokenDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDigitalCardToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'digitalCardPk' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDigitalCardToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'digitalCardPk' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'digitalCardPk' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'digitalCardToken' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'permissions' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateDigitalCardTokenMutation,
  CreateDigitalCardTokenMutationVariables
>
export const CreateFreeAffiliateDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFreeAffiliate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'account' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AccountInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'govId1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'govId2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'verticalId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerticalID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'queryParams' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Args' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFreeAffiliate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'account' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'account' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'govId1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'govId1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'govId2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'govId2' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPeriodEnd' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserLoginLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verticalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'verticalId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'queryParams' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateFreeAffiliateMutation,
  CreateFreeAffiliateMutationVariables
>
export const CreateOrUpdateBackPanelDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOrUpdateBackPanel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'panel' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PanelInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'layoutId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createFromExisting' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrUpdateBackPanel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'panel' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'panel' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'layoutId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'layoutId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createFromExisting' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createFromExisting' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'layout' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Layout' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Layout' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Layout' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateOrUpdateBackPanelMutation,
  CreateOrUpdateBackPanelMutationVariables
>
export const CreateOrderDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateOrderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DetailedOrder' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Line' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Card' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Error' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Line' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Error' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidSendDelayContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBulk' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeShippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeCardQty' },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CreateOrderMutation, CreateOrderMutationVariables>
export const CreateSparseUserDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSparseUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inviteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSparseUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inviteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inviteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateSparseUserMutation,
  CreateSparseUserMutationVariables
>
export const CreateUserLoginLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUserLoginLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'verticalId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerticalID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'queryParams' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Args' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserLoginLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verticalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'verticalId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'queryParams' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateUserLoginLinkMutation,
  CreateUserLoginLinkMutationVariables
>
export const DeleteCampaignShareDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCampaignShare' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCampaignShare' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteCampaignShareMutation,
  DeleteCampaignShareMutationVariables
>
export const DeleteContactRequestDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteContactRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteContactRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteContactRequestMutation,
  DeleteContactRequestMutationVariables
>
export const DeleteContactShareDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteContactShare' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteContactShare' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteContactShareMutation,
  DeleteContactShareMutationVariables
>
export const DeletePanelLayoutDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePanelLayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePanelLayout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeletePanelLayoutMutation,
  DeletePanelLayoutMutationVariables
>
export const DeleteReminderDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteReminder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReminder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteReminderMutation,
  DeleteReminderMutationVariables
>
export const DenyNotificationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DenyNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'denyNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DenyNotificationMutation,
  DenyNotificationMutationVariables
>
export const GetOrCreateEditableCardForRecipientDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetOrCreateEditableCardForRecipient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recipientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'getOrCreateEditableCardForRecipient',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recipientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'recipientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'card' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isHorizontal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frontPreviewUrl' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'productionId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'productionId' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insidePreviewUrl' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'productionId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'productionId' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flapPreviewUrl' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'productionId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'productionId' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'backPreviewUrl' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'productionId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'productionId' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isNewEditorCard' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'panels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Panel' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetOrCreateEditableCardForRecipientMutation,
  GetOrCreateEditableCardForRecipientMutationVariables
>
export const LoginUserDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LoginUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'username' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'verticalId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerticalID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'queryParams' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Args' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'username' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'username' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'oldUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserLoginLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verticalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'verticalId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'queryParams' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LoginUserMutation, LoginUserMutationVariables>
export const PurchaseProductDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PurchaseProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stripeToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stripeToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'stripeToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'purchased' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  PurchaseProductMutation,
  PurchaseProductMutationVariables
>
export const RevertBulkOrderDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RevertBulkOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revertBulkOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DetailedOrder' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Line' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Card' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Error' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Line' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Error' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidSendDelayContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBulk' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeShippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeCardQty' },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  RevertBulkOrderMutation,
  RevertBulkOrderMutationVariables
>
export const SendMagicLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendMagicLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'verticalId' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'VerticalID' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'redirectUri' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendMagicLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verticalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'verticalId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectUri' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'redirectUri' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SendMagicLinkMutation,
  SendMagicLinkMutationVariables
>
export const UpdateAccountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'account' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AccountInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subscription' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SubscriptionInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'account' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'account' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscription' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subscription' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>
export const UpdateCampaignDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaign' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaign' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaign' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Campaign' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignLine' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tinyThumb' },
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insidePreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flapPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'minimalPanels' },
                  name: { kind: 'Name', value: 'optimizedLegacyPanels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalPanel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isShareable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignLine' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>
export const UpdateCardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'card' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCardInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'card' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'card' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'card' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Card' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UpdateCardMutation, UpdateCardMutationVariables>
export const UpdateDigitalCardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDigitalCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'digitalCardPk' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DigitalCardInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDigitalCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'digitalCardPk' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'digitalCardPk' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'digitalCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shareMessageTitle' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateDigitalCardMutation,
  UpdateDigitalCardMutationVariables
>
export const UpdateOrderDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOrderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'order' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DetailedOrder' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Line' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Card' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Error' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Line' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Error' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidSendDelayContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBulk' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeShippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeCardQty' },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UpdateOrderMutation, UpdateOrderMutationVariables>
export const UpdateProductionInfoDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProductionInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProductionInfoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProductionInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productionInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProductionInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Recipient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Recipient' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentFailure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVerified' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorMessages' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gift' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultImageUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cardQuantity' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductionInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductionInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateToSend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfilled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectedCards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackingInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackingNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Recipient' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateProductionInfoMutation,
  UpdateProductionInfoMutationVariables
>
export const UpdateRequestedContactDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRequestedContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contact' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRequestedContactInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRequestedContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contact' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contact' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateRequestedContactMutation,
  UpdateRequestedContactMutationVariables
>
export const UpdateUserLabelOverridesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserLabelOverrides' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'overrides' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'UserLabelOverrideInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserLabelOverrides' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'overrides' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'overrides' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'overrides' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateUserLabelOverridesMutation,
  UpdateUserLabelOverridesMutationVariables
>
export const UpgradeToGreenerStillAffiliateDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpgradeToGreenerStillAffiliate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'govId1' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'govId2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upgradeToGreenerStillAffiliate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'govId1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'govId1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'govId2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'govId2' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPeriodEnd' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpgradeToGreenerStillAffiliateMutation,
  UpgradeToGreenerStillAffiliateMutationVariables
>
export const UpgradeToAffiliateDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpgradeToAffiliate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'govId1' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'govId2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upgradeToAffiliate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'govId1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'govId1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'govId2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'govId2' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpgradeToAffiliateMutation,
  UpgradeToAffiliateMutationVariables
>
export const UpgradeToReferringCustomerDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpgradeToReferringCustomer' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upgradeToReferringCustomer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Account' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpgradeToReferringCustomerMutation,
  UpgradeToReferringCustomerMutationVariables
>
export const ValidateEmailVerificationCodeDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ValidateEmailVerificationCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateEmailVerificationCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'ValidateEmailVerificationCodeFailure',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'failureType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'success' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'ValidateEmailVerificationCodeSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'success' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ValidateEmailVerificationCodeMutation,
  ValidateEmailVerificationCodeMutationVariables
>
export const VerifyPhoneNumberDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyPhoneNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PhoneNumberInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'verificationCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sponsorGenealogyIdOrSlug' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shouldNotCreateNewAccount' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shouldReassignNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'verticalId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerticalID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'queryParams' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Args' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyPhoneNumber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verificationCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'verificationCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sponsorGenealogyIdOrSlug' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sponsorGenealogyIdOrSlug' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldNotCreateNewAccount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shouldNotCreateNewAccount' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldReassignNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shouldReassignNumber' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VerifyPhoneNumberSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'VerifyPhoneNumberSuccess',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VerifyPhoneNumberFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'VerifyPhoneNumberFailure',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserLoginLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verticalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'verticalId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'queryParams' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VerifyPhoneNumberSuccess' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VerifyPhoneNumberSuccess' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'successType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Account' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VerifyPhoneNumberFailure' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VerifyPhoneNumberFailure' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failureType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failureMessage' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  VerifyPhoneNumberMutation,
  VerifyPhoneNumberMutationVariables
>
export const GetAccountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Account' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBackPanel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceivePromoEmails' },
            name: { kind: 'Name', value: 'receivesPromoEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveNotificationEmails' },
            name: { kind: 'Name', value: 'receivesNotificationEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReceiptEmails' },
            name: { kind: 'Name', value: 'receivesReceiptEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldReceiveReminderEmails' },
            name: { kind: 'Name', value: 'receivesReminderEmails' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shouldPrintRecipientCompany' },
            name: { kind: 'Name', value: 'printRecipientCompany' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remindersAdvanceNoticeDays' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'remindersAdvanceNoticeDaysInternational',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentHold' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCardBillingAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardBillingAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'city' },
            name: { kind: 'Name', value: 'addressCity' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'country' },
            name: { kind: 'Name', value: 'addressCountry' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line1' },
            name: { kind: 'Name', value: 'addressLine1' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'line2' },
            name: { kind: 'Name', value: 'addressLine2' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'postalcode' },
            name: { kind: 'Name', value: 'addressZip' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'state' },
            name: { kind: 'Name', value: 'addressState' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CreditCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditCardBillingAddress' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'object' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSource' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CreditCard' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BankAccount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionFeatures' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SubscriptionMetadata' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planOnCancel' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeSubscription' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StripeSubscription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isSetToCancel' },
            name: { kind: 'Name', value: 'setToCancel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionFeatures' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SubscriptionMetadata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpenseReload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExpenseReload' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoach' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoach' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trainerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingCompleted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateTrainingExpires' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateCoachingPurchased' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateAttendedCertification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailPublic' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isPhoneNumberPublic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCertified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trainerEmail' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Account' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAffiliate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fullAccessFeatures' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'loyaltyRewards' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heartfeltSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heartfeltSystemSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardPackageSends' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pointsTransitionSends' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'employeeSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingProLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptinguLoginUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'streamMarketingLoginUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'promptinguJwt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBundlePlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'affiliateUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'couldQualifyForFirstFreeCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isQualifiedForFirstFreeCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrialAffiliate' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'isEligibleForFreeGsAffiliateAccount',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftShippingDiscount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hasEverHadSamplePack' },
            name: { kind: 'Name', value: 'hasEverHadAssetSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'StringValue', value: '3for5', block: false },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessContactManager' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasFullAccessFreeAffiliate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldDisplayUpsale' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlansAndAddons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSettings' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalSponsor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSource' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StripeSubscription' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expense' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expenseReload' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExpenseReload' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifiedPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nationalNumber' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promptingsCoach' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PromptingsCoach' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetAccountQuery, GetAccountQueryVariables>
export const GetAccountCreatedContentsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountCreatedContents' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'accountCreatedTitle' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '69' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'accountCreatedBody' },
            name: { kind: 'Name', value: 'multiParagraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '36' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingMultiParagraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingMultiParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingMultiParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAccountCreatedContentsQuery,
  GetAccountCreatedContentsQueryVariables
>
export const GetAddPaymentSettingsDailyCapDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAddPaymentSettingsDailyCap' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addPaymentSettingsDailyCap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'available' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cap' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAddPaymentSettingsDailyCapQuery,
  GetAddPaymentSettingsDailyCapQueryVariables
>
export const GetAffiliateCountriesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAffiliateCountries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'affiliateCountries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedCountry' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedCountry' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Country' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alpha2' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subdivisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviationOrName' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAffiliateCountriesQuery,
  GetAffiliateCountriesQueryVariables
>
export const GetAffiliateJoinMarketingContentDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAffiliateJoinMarketingContent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'priceCost' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '143' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'priceCostFreeAffiliate' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '155' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'agreement' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '144' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'agreementFreeAffiliate' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '154' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAffiliateJoinMarketingContentQuery,
  GetAffiliateJoinMarketingContentQueryVariables
>
export const GetAlertsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAlerts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notificationFilters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'NotificationFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reminderFilters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ReminderFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notificationFilters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Notification' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedReminders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reminderFilters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalReminder' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedContactShares' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContactShare' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedCampaignShares' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CampaignShare' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Line' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Card' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Error' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Line' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Error' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidSendDelayContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBulk' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeShippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeCardQty' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionalYearDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptionalYearDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Notification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'denyText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalReminder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'birthday' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'anniversary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedOrder' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactShare' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactShare' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Contact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fromUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignShare' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignShare' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateShared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetAlertsQuery, GetAlertsQueryVariables>
export const GetAvailableDailyHeartfeltDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableDailyHeartfelt' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasDailyHeartfeltSends' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dailyHeartfeltCap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'available' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cap' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAvailableDailyHeartfeltQuery,
  GetAvailableDailyHeartfeltQueryVariables
>
export const GetAvailableUnlimitedDailyHeartfeltDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableUnlimitedDailyHeartfelt' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasUnlimitedHeartfeltSends' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'heartfeltDailyCap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'available' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cap' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAvailableUnlimitedDailyHeartfeltQuery,
  GetAvailableUnlimitedDailyHeartfeltQueryVariables
>
export const GetByDesignLoginLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetByDesignLoginLink' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bydesignLoginLink' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetByDesignLoginLinkQuery,
  GetByDesignLoginLinkQueryVariables
>
export const GetCampaignDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Campaign' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignLine' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tinyThumb' },
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insidePreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flapPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'minimalPanels' },
                  name: { kind: 'Name', value: 'optimizedLegacyPanels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalPanel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isShareable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignLine' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetCampaignQuery, GetCampaignQueryVariables>
export const GetCampaignOrdersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orders' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'offset' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'results' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DetailedOrder' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Line' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Card' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Error' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Line' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Error' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidSendDelayContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBulk' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeShippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeCardQty' },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCampaignOrdersQuery,
  GetCampaignOrdersQueryVariables
>
export const GetCardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Card' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetCardQuery, GetCardQueryVariables>
export const GetCoachingBadgesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCoachingBadges' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hasEarned' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'awardGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'name' },
                            value: {
                              kind: 'StringValue',
                              value: 'coaching',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'awards' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'by' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'hasEarned' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'hasEarned' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Award' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Qualification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Qualification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'graphic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Award' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Award' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'graphicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Qualification' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCoachingBadgesQuery,
  GetCoachingBadgesQueryVariables
>
export const GetCoachingDashboardMarketingContentDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCoachingDashboardMarketingContent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'boostPotentialMoreInfo' },
            name: { kind: 'Name', value: 'marketingUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'boostPotentialMoreInfo',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'boostPotentialUnltdProductPath' },
            name: { kind: 'Name', value: 'marketingUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'boostPotentialUnltdProductPath',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'boostPotentialLtdProductPath' },
            name: { kind: 'Name', value: 'marketingUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'boostPotentialLtdProductPath',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'keepPlayingProductPath' },
            name: { kind: 'Name', value: 'marketingUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'keepPlayingProductPath',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCoachingDashboardMarketingContentQuery,
  GetCoachingDashboardMarketingContentQueryVariables
>
export const GetContactRequestByTokenDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContactRequestByToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRequestByToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requester' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalSponsor' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalSponsor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetContactRequestByTokenQuery,
  GetContactRequestByTokenQueryVariables
>
export const GetContactRequestMarketingContentDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContactRequestMarketingContent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'newContactRequestSubmitMessage' },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'newContactRequestSubmitMessage',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'viewContactRequestMessage' },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'viewContactRequestMessage',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetContactRequestMarketingContentQuery,
  GetContactRequestMarketingContentQueryVariables
>
export const GetContactRequestsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetContactRequests' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetContactRequestsQuery,
  GetContactRequestsQueryVariables
>
export const GetCreditCardManagerMarketingContentDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCreditCardManagerMarketingContent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'creditCardUpdateRateLimitReached' },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'creditCardUpdateRateLimitReached',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastCardRemovalDisclaimerTitle' },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'lastCardRemovalDisclaimerTitle',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastCardRemovalDisclaimerMessage' },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'lastCardRemovalDisclaimerMessage',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastCardRemovalDisclaimerDecline' },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'lastCardRemovalDisclaimerDecline',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastCardRemovalDisclaimerAccept' },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'lastCardRemovalDisclaimerAccept',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCreditCardManagerMarketingContentQuery,
  GetCreditCardManagerMarketingContentQueryVariables
>
export const GetCreditsBalanceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCreditsBalance' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'credits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Amount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCreditsBalanceQuery,
  GetCreditsBalanceQueryVariables
>
export const GetDetailedCountriesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDetailedCountries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'detailedCountries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedCountry' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedCountry' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Country' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alpha2' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subdivisions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'abbreviationOrName' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetDetailedCountriesQuery,
  GetDetailedCountriesQueryVariables
>
export const GetDigitalCardByTokenDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDigitalCardByToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCardByToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'card' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CardWithoutCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardWithoutCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetDigitalCardByTokenQuery,
  GetDigitalCardByTokenQueryVariables
>
export const GetDigitalCardsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDigitalCards' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPreviousPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pk' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'shareMessageTitle',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shareMessageBody' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tokens' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'token' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'card' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CardWithoutCost',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardWithoutCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetDigitalCardsQuery,
  GetDigitalCardsQueryVariables
>
export const GetDraftedCardsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDraftedCards' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drafts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DraftedCard' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DraftedCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetDraftedCardsQuery,
  GetDraftedCardsQueryVariables
>
export const GetFlagsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFlags' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Flag' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Flag' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Flag' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetFlagsQuery, GetFlagsQueryVariables>
export const GetFontsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFonts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Font' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Font' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Font' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ttfFile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSig' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomFont' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetFontsQuery, GetFontsQueryVariables>
export const GetGratitudeDayMarketingContentDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGratitudeDayMarketingContent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'worldGratitudeImageUrl' },
            name: { kind: 'Name', value: 'marketingUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'World Gratitude Day',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetGratitudeDayMarketingContentQuery,
  GetGratitudeDayMarketingContentQueryVariables
>
export const GetImagesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserImage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetImagesQuery, GetImagesQueryVariables>
export const GetItemDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'item' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'itemCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Item' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignLine' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tinyThumb' },
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insidePreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flapPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'minimalPanels' },
                  name: { kind: 'Name', value: 'optimizedLegacyPanels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalPanel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isShareable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignLine' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Item' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Item' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Campaign' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetItemQuery, GetItemQueryVariables>
export const GetItemCategoriesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetItemCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '100' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemCategory' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'children' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ItemCategory' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignLine' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tinyThumb' },
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insidePreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flapPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'minimalPanels' },
                  name: { kind: 'Name', value: 'optimizedLegacyPanels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalPanel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isShareable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignLine' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Item' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Item' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Campaign' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundImageUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Item' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetItemCategoriesQuery,
  GetItemCategoriesQueryVariables
>
export const GetItemsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'category' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '20' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Item' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalPanel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignLine' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isNewEditorCard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tinyThumb' },
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insidePreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flapPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '500' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'minimalPanels' },
                  name: { kind: 'Name', value: 'optimizedLegacyPanels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MinimalPanel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Campaign' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isShareable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignLine' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Item' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Item' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Campaign' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetItemsQuery, GetItemsQueryVariables>
export const GetLayoutDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'layout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetLayoutQuery, GetLayoutQueryVariables>
export const GetLayoutsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLayouts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'category' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LayoutCategory' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dimensions' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LayoutDimensions' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'owner' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Owner' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'layouts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'category' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dimensions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dimensions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'owner' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'owner' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Layout' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Layout' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Layout' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetLayoutsQuery, GetLayoutsQueryVariables>
export const GetLeaderBoardsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLeaderBoards' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leaderBoards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LeaderBoard' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Leader' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Leader' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileImageUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '64' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '64' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'earnedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateJoined' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeaderBoard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LeaderBoardType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rankTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metricTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shouldShowPastChallenge' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'gradient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userAccess' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'challenges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaders' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Leader' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'authUserEarnedCount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetLeaderBoardsQuery,
  GetLeaderBoardsQueryVariables
>
export const GetLeaderboardMarketingContentDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLeaderboardMarketingContent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'totalNewTransactions' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '156' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'totalTransactionsPerAssetOnePerUser',
            },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '157' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'totalTransactionsPerAsset' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '158' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetLeaderboardMarketingContentQuery,
  GetLeaderboardMarketingContentQueryVariables
>
export const GetMarketingBannersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMarketingBanners' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'banners' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'htmlContent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetMarketingBannersQuery,
  GetMarketingBannersQueryVariables
>
export const GetMarketingContentDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMarketingContent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'complianceMessage' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'whyMyPhoneNumberMessage' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '4' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'unverifiedAccountsFoundCopy' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '5' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'phoneNotFoundCopy' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '6' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'customerReferralOptInMessage' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '7' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'greenerStillAffiliateOptInMessage' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '8' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sharingInformationCopy' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '13' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'createAccountMessage' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '14' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'bulkPromoJoinTitle' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '20' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'bulkPromoJoinMessage' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '19' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'loginWelcomeMessage' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '15' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'affiliateLoginMessage' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '16' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'customerReferralOptInTermsAndConditions',
            },
            name: { kind: 'Name', value: 'multiParagraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingMultiParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'greenerStillAffiliateOptInTermsAndConditions',
            },
            name: { kind: 'Name', value: 'multiParagraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingMultiParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'immediateSendEditingMessage' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '17' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'futureSendEditingMessage' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '18' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'subscriptionPurchaseSuccess' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '53' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardPackagePurchaseSuccess' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '54' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'compPlanTrainingLink' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '57' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'createAccountRateLimitReached' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '137' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'streamVaPromo' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '148' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'affiliateProfileCompleteTitle' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '165' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'affiliateProfileWarningTitle' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '163' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'affiliateProfileWarningBody' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '164' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'backPanelUpdateLayoutTitle' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '178' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'backPanelUpdateLayoutBody' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '179' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardOfTheMonthId' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '183' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingMultiParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingMultiParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetMarketingContentQuery,
  GetMarketingContentQueryVariables
>
export const GetNotificationCountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'notificationCount' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetNotificationCountQuery,
  GetNotificationCountQueryVariables
>
export const GetNotificationsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'types' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NotificationType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'acknowledged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'types' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'types' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'acknowledged' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'acknowledged' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Notification' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Notification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'denyText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>
export const GetOrderDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedOrder' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Line' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Card' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Error' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Line' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Error' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidSendDelayContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBulk' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeShippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeCardQty' },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetOrderQuery, GetOrderQueryVariables>
export const GetPaginatedInvitesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaginatedInvites' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedInvites' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Invite' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Prospect' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Prospect' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ordersSent' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionalYearDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptionalYearDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelatedContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faxNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CalendarDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Note' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Note' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'text' },
            name: { kind: 'Name', value: 'note' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'date' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CalendarDate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Contact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cellPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faxNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Note' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocProContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocProContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'homePhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadataBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedContact' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Invite' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Invite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prospect' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Prospect' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socProContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SocProContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedContact' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'utmStream' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasProspectJoined' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSentCard' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetPaginatedInvitesQuery,
  GetPaginatedInvitesQueryVariables
>
export const GetPaginatedRecipientsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaginatedRecipients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentFailures' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contacts' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedRecipients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentFailures' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentFailures' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contacts' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contacts' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Recipient' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Recipient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Recipient' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentFailure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVerified' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorMessages' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gift' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultImageUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cardQuantity' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetPaginatedRecipientsQuery,
  GetPaginatedRecipientsQueryVariables
>
export const GetPlanMoveDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlanMove' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planIdFrom' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planIdTo' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'planMove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planIdFrom' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'planIdFrom' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planIdTo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'planIdTo' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isImmediate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'planTo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stripeId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'planFrom' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stripeId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetPlanMoveQuery, GetPlanMoveQueryVariables>
export const GetPlansDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlans' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Plan' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedPackage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleorProductId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldShowByDefault' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAddon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkCardCost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isExpenseReloadRequired' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundStartColor' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundEndColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetPlansQuery, GetPlansQueryVariables>
export const GetProductionInfoDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductionInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contactId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productionInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contactId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductionInfo' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Recipient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Recipient' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentFailure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVerified' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorMessages' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gift' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultImageUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cardQuantity' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductionInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductionInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateToSend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfilled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectedCards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackingInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackingNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Recipient' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetProductionInfoQuery,
  GetProductionInfoQueryVariables
>
export const GetPromptingsCoachDiscountsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPromptingsCoachDiscounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'promptingsCoach' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'PromptingsCoachDiscount',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromptingsCoachDiscount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromptingsCoachDiscount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventbriteGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expirationDatetime' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isUsed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amountOff' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentOff' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetPromptingsCoachDiscountsQuery,
  GetPromptingsCoachDiscountsQueryVariables
>
export const GetPromptingsEventDatesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPromptingsEventDates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'promptingsProsperityEventDate' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '102' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'promptingsSeminarEventDate' },
            name: { kind: 'Name', value: 'paragraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'IntValue', value: '103' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetPromptingsEventDatesQuery,
  GetPromptingsEventDatesQueryVariables
>
export const GetReceivedPendingMembershipInvitesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReceivedPendingMembershipInvites' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivedPendingMembershipInvites' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MembershipInvite' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MembershipInvite' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MembershipInvite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organizationName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationOwnerName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetReceivedPendingMembershipInvitesQuery,
  GetReceivedPendingMembershipInvitesQueryVariables
>
export const GetRemindersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReminders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reminders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MinimalReminder' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperCosts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaperCosts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upgrade' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Card' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'detailedSendableCard' },
            name: { kind: 'Name', value: 'sendableCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SendableCardWithTemplate',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNewEditorCard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backPanelLocation' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tinyThumb' },
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insidePreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flapPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showInPicturePlus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'panels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Panel' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paperUpgradeCosts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperCosts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalCard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareMessageBody' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tokens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Amount' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Amount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GiftVariation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GiftVariation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isEligibleFreeGift' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Amount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packaging' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultImageUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendDelay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendDelay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delayType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specificDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Line' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Line' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Card' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftVariation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GiftVariation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendDelay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendDelay' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'baseCost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Error' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedOrder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSendable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Line' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warnings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Error' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invalidSendDelayContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBulk' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeShippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpgradeCardQty' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MinimalReminder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Reminder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'birthday' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'anniversary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedOrder' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetRemindersQuery, GetRemindersQueryVariables>
export const GetSendableCardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSendableCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendableCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DetailedSendableCard' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '320' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mediumThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '700' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LargeMinimalSendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Price' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Price' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LabeledPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LabeledPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asString' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemizedCost' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ItemizedCost' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LabeledPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPremium' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Color' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Color' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'red' } },
          { kind: 'Field', name: { kind: 'Name', value: 'green' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EditorImageType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnailUrl' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '300' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '300' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementSticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementSticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorImage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Image' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'smallThumb' },
            name: { kind: 'Name', value: 'url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'height' },
                value: { kind: 'IntValue', value: '135' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: false },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementPicture' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementPicture' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Image' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'scale' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextFragment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReplacement' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paragraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paragraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textFragments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TextFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'alignment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementText' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ElementText' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paragraphs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paragraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Element' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Element' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rotation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'draggable' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sticker' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementSticker' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementPicture' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ElementText' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Panel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Panel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullBleed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'backgroundColor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Color' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '500' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Element' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SendableCardWithTemplate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SendableCard' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DetailedSendableCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SendableCard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'LargeMinimalSendableCard' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insideRightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardImage' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'variationColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SendableCardWithTemplate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cardTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'backPanelLocation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'panels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Panel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ItemizedCost' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetSendableCardQuery,
  GetSendableCardQueryVariables
>
export const GetSentCardsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSentCards' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contacts' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CardStatus' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sendType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SentCardSendType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paginatedSentCards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contacts' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contacts' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sendType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentCards' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProductionInfo' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Recipient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Recipient' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentFailure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVerified' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorMessages' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gift' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultImageUrl' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frontPreviewUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '150' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isHorizontal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendableCard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cardQuantity' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductionInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductionInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateToSend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfilled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectedCards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectionReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frontPreviewUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'width' },
                value: { kind: 'IntValue', value: '150' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fitIn' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackingInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackingNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Recipient' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetSentCardsQuery, GetSentCardsQueryVariables>
export const GetSentCardsCountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSentCardsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sendType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SentCardSendType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentCards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sendType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentCards' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cardsSent' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetSentCardsCountQuery,
  GetSentCardsCountQueryVariables
>
export const GetSharedCampaignsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSharedCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignShares' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignShare' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignShare' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignShare' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateShared' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetSharedCampaignsQuery,
  GetSharedCampaignsQueryVariables
>
export const GetSharedContactsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSharedContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactShares' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactShare' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionalYearDate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptionalYearDate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Contact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'company' },
            name: { kind: 'Name', value: 'companyName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'birthday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anniversary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionalYearDate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactShare' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactShare' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Contact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fromUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetSharedContactsQuery,
  GetSharedContactsQueryVariables
>
export const GetShouldVerifyEmailDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetShouldVerifyEmail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'shouldVerifyEmail' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetShouldVerifyEmailQuery,
  GetShouldVerifyEmailQueryVariables
>
export const GetSponsorDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSponsor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sponsor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SponsorFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SponsorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetSponsorQuery, GetSponsorQueryVariables>
export const GetStickerCategoriesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStickerCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stickerCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StickerCategory' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StickerCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StickerCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetStickerCategoriesQuery,
  GetStickerCategoriesQueryVariables
>
export const GetStickersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStickers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'StickerFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stickers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Sticker' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Sticker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Sticker' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'thumbnailUrl' },
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '300' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '300' },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetStickersQuery, GetStickersQueryVariables>
export const GetSubmitRequestedContactMarketingContentDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetSubmitRequestedContactMarketingContent',
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'submitRequestedContactTitle' },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'submitRequestedContactTitle',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'submitRequestedContactSubtitle' },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'submitRequestedContactSubtitle',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'submitRequestedContactCompletedSubtitle',
            },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'submitRequestedContactCompletedSubtitle',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: {
              kind: 'Name',
              value: 'submitRequestedContactSuccessTitle',
            },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'submitRequestedContactSuccessTitle',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'submitRequestedContactSuccessBody' },
            name: { kind: 'Name', value: 'paragraphByTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: {
                  kind: 'StringValue',
                  value: 'submitRequestedContactSuccessBody',
                  block: false,
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MarketingParagraph' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketingParagraph' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketingParagraph' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetSubmitRequestedContactMarketingContentQuery,
  GetSubmitRequestedContactMarketingContentQueryVariables
>
export const GetUserCardTokensDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserCardTokens' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'groupSends' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'heartfeltSends' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'heartfeltSystemSends' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scheduledSends' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'systemSends' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cardPackageSends' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pointsTransitionSends' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employeeSends' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dailyHeartfeltSends' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardSends' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftSends' } },
                { kind: 'Field', name: { kind: 'Name', value: 'actSends' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetUserCardTokensQuery,
  GetUserCardTokensQueryVariables
>
export const GetUsersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isAffiliate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isAffiliate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isAffiliate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SponsorFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Storefront' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Storefront' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mugshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'width' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'height' },
                      value: { kind: 'IntValue', value: '135' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fitIn' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'biography' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SponsorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genealogyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Storefront' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetUsersQuery, GetUsersQueryVariables>
