export const getPlanPillDescription = (id: string) => {
  const premiumPlanCopy = 'Done for You'
  const gratitudePackCopy = 'Do It Yourself'
  const monthlySubPillCopy = 'Monthly Subscription'
  const academyPillCopy = 'Academy'
  switch (id) {
    //Subscriptions
    case '2':
    case '161':
    case '184':
      return monthlySubPillCopy
    case '1':
      return 'Pay As You Go'

    // Gratitude Packages
    case '169':
    case '154':
    case '159':
    case '160':
    case '172':
    case '173':
      return gratitudePackCopy

    // Premium Plans
    case '185':
    case '130':
    case '162':
    case '163':
      return premiumPlanCopy

    // Events
    case '146':
    case '144':
    case '174':
    case '150':
      return academyPillCopy

    // The Others
    case '6':
      return 'Top Seller'
    case '128':
      return 'Most Popular'
    case '129':
      return 'Incredible Value'
    case '133':
      return '36% Off Retail'
    case '134':
      return '46% Off Retail'
    case '135':
      return '52% Off Retail'
    case '136':
      return '59% Off Retail'
    case '137':
      return '62% Off Retail'
    default:
      return ''
  }
}
